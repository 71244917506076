import { FC } from 'react';
import { Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dateFormatter from 'shared/utils/dateFormatter';
import { formatAmountWithoutCurrency } from 'shared/utils/amountFormatter';
import { InvoiceList, InvoiceStatus } from '../../../types';

interface Props {
    invoiceList: InvoiceList;
}

const generateChipColor = (status: InvoiceStatus) => {
    switch (status) {
        case InvoiceStatus.APPROVED:
            return 'warning';
        case InvoiceStatus.PAID:
            return 'success';
        default:
            return;
    }
};

const InvoicesTable: FC<Props> = ({ invoiceList }) => {
    const { t } = useTranslation();

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('purchase-order.invoices.invoice')}</TableCell>
                        <TableCell>{t('purchase-order.invoices.total-amount')}</TableCell>
                        <TableCell>{t('purchase-order.invoices.invoice-date')}</TableCell>
                        <TableCell>{t('purchase-order.invoices.due-date')}</TableCell>
                        <TableCell>{t('purchase-order.invoices.status')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {invoiceList.invoices.map(item => {
                        const currency = t(`purchase-order.${item.currency.toLowerCase()}`);
                        const total = formatAmountWithoutCurrency(item.grossTotal);
                        const statusColor = generateChipColor(item.status);

                        return (
                            <TableRow key={item.id}>
                                <TableCell>{item.number}</TableCell>
                                <TableCell>{`${currency} ${total}`}</TableCell>
                                <TableCell>{dateFormatter(item.invoiceDate)}</TableCell>
                                <TableCell>{item.dueDate ? dateFormatter(item.dueDate) : '-'}</TableCell>
                                <TableCell>
                                    <Chip variant={!statusColor ? 'outlined' : 'filled'} color={statusColor} label={item.status} />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default InvoicesTable;
