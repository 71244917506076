import { AutocompleteItem, Button, DialogTitle, Radio, makeStyles } from '@akelius-con/react-ui-kit-components';
import { Box, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormLabel, RadioGroup } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { cacheKeys } from 'modules/order/constants';
import forwardOrder from 'modules/order/graphql/mutations/forwardOrder';
import { ResponsiblePersonsQueryResponse, getResponsiblePersons } from 'modules/order/graphql/queries/getResponsiblePersons';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { snackbar } from 'shared/components/Snackbar';
import * as Yup from 'yup';
import { StaffSelectionDropdown } from '../StaffSelectionDropdown';
import { useGetUser } from 'modules/order/utils/useGetUser';

export interface IProps {
    closeForwardOrderDialog: () => void;
    orderId: string;
    isPropertyOrder: boolean;
    propertyId?: string;
}

interface FormValues {
    email: string;
}

const useStyles = makeStyles()({
    fullWidth: {
        width: '100%',
    },
});

const ForwardOrderConfirm = (props: IProps) => {
    const { classes } = useStyles();
    const { getToken } = useGetUser();
    const [selectedUser, setSelectedUser] = useState<AutocompleteItem | null>(null);
    const [showOtherField, setShowOtherField] = useState<boolean>(false);

    const { closeForwardOrderDialog, orderId, propertyId, isPropertyOrder } = props;
    const { t } = useTranslation();

    const initialValues: FormValues = {
        email: '',
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string().email().max(40).required().label(t('purchase-order.contact.email')),
    });

    const handleSubmitForwardOrder = (values: FormValues) => {
        formik.setSubmitting(true);

        forwardOrderMutation.mutate({
            getToken,
            orderId,
            email: values.email,
        });
    };

    const formik = useFormik({
        validationSchema,
        initialValues,
        onSubmit: handleSubmitForwardOrder,
    });

    const {
        data: responsiblePersonsData,
        isLoading: isResponsiblePersonsLoading,
        refetch: fetchResponsiblePersons,
    } = useQuery<ResponsiblePersonsQueryResponse>(cacheKeys.responsiblePersons(), getResponsiblePersons({ propertyId, getToken }), {
        enabled: false,
        refetchOnWindowFocus: false,
    });
    useEffect(() => {
        if (propertyId) {
            fetchResponsiblePersons();
        }
    }, [fetchResponsiblePersons, propertyId]);

    const forwardOrderMutation = useMutation(forwardOrder(), {
        onSuccess: () => {
            snackbar.success(t('purchase-order.forward.success-msg'));
            closeForwardOrderDialog();
        },
        onError: () => {
            snackbar.error(t('purchase-order.forward.error-msg'));
        },
        onSettled: () => {
            formik.setSubmitting(false);
        },
    });

    const onRadioChange = useCallback(
        (event: ChangeEvent<{ value: string }>) => {
            formik.setFieldValue('email', event.target.value);
        },
        [formik],
    );

    const { handleSubmit } = formik;

    return (
        <Dialog maxWidth="sm" fullWidth open={!!orderId} onClose={closeForwardOrderDialog} data-testid="forward-order-confirm-modal">
            <form onSubmit={handleSubmit}>
                <DialogTitle onClose={closeForwardOrderDialog} title={t('purchase-order.forward-order')} />
                <DialogContent>
                    {isPropertyOrder && (
                        <>
                            <FormLabel>{t('purchase-order.forward-order.description')}</FormLabel>
                            <FormControl component="fieldset">
                                <RadioGroup value={formik.values.email} onChange={onRadioChange}>
                                    {!!responsiblePersonsData &&
                                        responsiblePersonsData.responsiblePersons.map(responsiblePerson => (
                                            <FormControlLabel
                                                onChange={() => {
                                                    setShowOtherField(false);
                                                }}
                                                data-testid="staff-member-radio"
                                                className={classes.fullWidth}
                                                value={responsiblePerson.email}
                                                key={responsiblePerson.email}
                                                control={<Radio />}
                                                label={`${responsiblePerson.name}, ${responsiblePerson.role}`}
                                            />
                                        ))}
                                </RadioGroup>

                                <FormControlLabel
                                    className={classes.fullWidth}
                                    checked={showOtherField}
                                    control={<Radio />}
                                    onClick={() => {
                                        setShowOtherField(true);
                                        formik.setFieldValue('email', '');
                                    }}
                                    label={t('common.other')}
                                />
                            </FormControl>
                            <Box mt={1} />
                        </>
                    )}

                    {(showOtherField || !isPropertyOrder) && (
                        <StaffSelectionDropdown
                            selectedUser={selectedUser}
                            onChange={(selected: AutocompleteItem | null) => {
                                setSelectedUser(selected);
                                formik.setFieldValue('email', selected?.value);
                            }}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" label={t('common.cancel')} onClick={closeForwardOrderDialog} data-testid="cancel" />
                    <Button
                        isLoading={formik.isSubmitting}
                        variant="contained"
                        data-testid="forward-order-confirm-button"
                        label={t('purchase-order.button.forward')}
                        disabled={formik.isSubmitting || !formik.values.email?.length || isResponsiblePersonsLoading}
                        color="primary"
                        autoFocus
                        type="submit"
                    />
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default ForwardOrderConfirm;
