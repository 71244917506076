import { gql } from 'graphql-request';
import { ContactForSupplier } from 'modules/order/types';
import { GetToken } from 'modules/order/utils/useGetUser';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';

export interface Input {
    supplierId: string;
    companyId: string;
    costCenter?: string;
    title?: string;
    contactForSupplier: ContactForSupplier;
}

export interface ICreateAdminOrderParam {
    input: Input;
    getToken: GetToken;
}

export interface ICreateAdminOrderResponse {
    createAdministrativeOrder: {
        id: string;
    };
}

export const createAdminOrder = () => async (param: ICreateAdminOrderParam) => {
    const { getToken, ...payload } = param;
    const { graphQLClient } = await getGraphqlClient(getToken);

    const createAdminOrderMutation = gql`
        mutation createAdministrativeOrder($input: OrderCreateAdministrative!) {
            createAdministrativeOrder(input: $input) {
                id
            }
        }
    `;

    const data = await graphQLClient.request<ICreateAdminOrderResponse>(createAdminOrderMutation, {
        ...payload,
    });

    return data;
};
