import { Button, DialogTitle, makeStyles } from '@akelius-con/react-ui-kit-components';
import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()({
    content: {
        whiteSpace: 'pre-line',
    },
});

interface Props {
    open: boolean;
    onClose: () => void;
    title: string;
    content: string;
}

const InfoDialog: FC<Props> = props => {
    const { classes } = useStyles();
    const { t } = useTranslation();
    const { open, onClose, title, content } = props;

    return (
        <Dialog maxWidth="md" fullWidth open={open} onClose={onClose} data-testid="info-dialog">
            <DialogTitle title={title} onClose={onClose} />
            <DialogContent>
                <Typography variant="body1" className={classes.content}>
                    {content}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" label={t('common.ok')} onClick={onClose} data-testid="ok" />
            </DialogActions>
        </Dialog>
    );
};

export default InfoDialog;
