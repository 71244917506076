import { camelToSnakeCase } from './camelToSnake';

export const camelKeysObjToSnakeKeysObj = (newFilters: { [key: string]: unknown }) => {
    const camelKeysObj = Object.entries(newFilters).map(item => {
        const key = camelToSnakeCase(item[0]);
        return {
            [key]: item[1],
        };
    });

    const mergeInObject = Object.assign({}, ...camelKeysObj);

    return mergeInObject;
};
