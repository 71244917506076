import { gql } from 'graphql-request';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';
import { Address, ContactForSupplier, Order } from '../../types';
import { GetToken } from 'modules/order/utils/useGetUser';

export interface IUpdateOrderPayload {
    status?: string;
    title?: string | null;
    deliveryAddress?: Address;
    referenceNumber?: string | null;
    noteToSupplier?: string;
    selectedContacts?: string[];
    supplierId?: string;
    contactForSupplier?: ContactForSupplier;
}

export interface IUpdateOrderParam {
    getToken: GetToken;
    orderId: string;
    input: IUpdateOrderPayload;
}

export interface UpdateOrderResponse {
    updateOrder: Order;
}

const updateOrder = () => async (params: IUpdateOrderParam) => {
    const { getToken, ...payload } = params;
    const { graphQLClient } = await getGraphqlClient(getToken);

    const updateOrderMutation = gql`
        mutation updateOrder($orderId: String!, $input: UpdateOrder!) {
            updateOrder(orderId: $orderId, input: $input) {
                id
                orderNumber
                createdDate
                releasedDate
                status
                title
                currency
                updatedDate
                noteToSupplier
                referenceNumber
                totalPriceCentsNet
                totalPriceCentsGross
                totalVatAmountBreakdown {
                    vatRate
                    amountCents
                }
                supplier {
                    id
                    name
                    address
                    addressDetails {
                        city
                        country
                    }
                    contacts {
                        contactId
                        name
                        email
                        isSelected
                    }
                    additionalContacts {
                        contactId
                        name
                        email
                        phone
                    }
                }
                creator {
                    id
                    name
                }

                carrierOfCost {
                    costCenter
                    costCenterName
                    countryCode
                    company {
                        id
                        code
                        name
                        vatNumber
                    }
                    property {
                        id
                        name
                        code
                        address
                        addressDetails {
                            city
                            country
                        }
                        apartment {
                            id
                            name
                            code
                        }
                    }
                }

                case {
                    caseId
                }

                items {
                    ... on OrderItem {
                        id
                        description
                        quantity {
                            value
                            unit
                        }
                        userPrice {
                            value
                            vatIncluded
                            vatRate
                        }
                        unitPriceCentsNet
                        totalPriceCentsNet
                        totalPriceCentsGross
                        totalVatAmountCents
                        creator {
                            id
                            name
                        }
                        note
                        supplierPartNumber
                        manufacturerPartNumber
                        createdDate
                        updatedDate
                        deliveryDate {
                            start
                            end
                        }
                    }
                }
                invoiceAddress {
                    address1
                    address2
                    streetName
                    streetNumber
                    postalCode
                    city
                    region
                    country
                }
                deliveryAddress {
                    address1
                    address2
                    streetName
                    streetNumber
                    postalCode
                    city
                    region
                    country
                }
                documents {
                    ... on OrderDocument {
                        id
                        fileTitle
                        docType
                        createdDate
                        creatorName
                        sendToSupplier
                        sendToApproval
                        sizeInBytes
                    }
                }
                contactForSupplier {
                    email
                    name
                    phone
                    title
                }
            }
        }
    `;

    const data = await graphQLClient.request<UpdateOrderResponse>(updateOrderMutation, {
        ...payload,
    });

    return data;
};

export default updateOrder;
