import { FormikProps } from 'formik';
import { debounce } from 'lodash';
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { snackbar } from '../Snackbar';

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: FormikProps<any>;
    debounceMs?: number;
    error: null | boolean;
    dirtyTracker: [boolean, Dispatch<SetStateAction<boolean>>];
}

const FormikAutoSave = (props: Props) => {
    const { formik, debounceMs = 600, error, dirtyTracker } = props;
    const [isDirty, setIsDirty] = dirtyTracker;
    const { t } = useTranslation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSubmit = useCallback(
        debounce(() => {
            if (!formik.isValid || !isDirty) return false;
            return formik.submitForm();
        }, debounceMs),
        [formik.submitForm, formik.isValid, formik.initialValues, formik.values, debounceMs, isDirty],
    );

    useEffect(() => {
        debouncedSubmit();
        return debouncedSubmit.cancel;
    }, [debouncedSubmit, formik.values]);

    useEffect(() => {
        if (formik.dirty) {
            setIsDirty(true);
        }
    }, [setIsDirty, formik.dirty]);

    useEffect(() => {
        if (!formik.isSubmitting && isDirty) {
            if (error === false) {
                snackbar.success(t('common.auto-save.success-msg'));
            }

            if (error === true) {
                snackbar.error(t('common.auto-save.error-msg'));
            }
        }
    }, [error, formik.isSubmitting, t, isDirty]);

    return <div />;
};

export default FormikAutoSave;
