import { colors } from '@akelius-con/react-theme';
import { makeStyles } from '@akelius-con/react-ui-kit-components';
import { AkBed, AkBuilding, AkCostCenter, AkEdit } from '@akelius-con/react-ui-kit-icons';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import cn from 'classnames';
import { Apartment } from 'modules/order/types';
import { getPropertyImage } from 'modules/order/utils/getPropertyImage';
import { SyntheticEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import placeholderImage from '/src/assets/images/placeholder.png';

const useStyles = makeStyles()(() => ({
    contentContainer: {
        padding: '24px 16px',
        position: 'relative',
    },
    mediaContainer: {
        position: 'relative',
        width: '100%',
        height: '200px',
    },
    media: {
        width: '100%',
        height: 200,
        objectFit: 'cover',
    },
    wrapper: {
        backgroundColor: 'transparent',
        padding: 0,
        textAlign: 'left',
        border: `1px solid ${colors.lineGrey}`,
        borderRadius: '3px',
    },
    disabledLink: {
        pointerEvents: 'none',
        textDecoration: 'none',
    },
    disabledName: {
        color: colors.disabledGrey,
    },
    name: {
        color: colors.black,
    },
    propertySubInfo: {
        display: 'flex',
        paddingBottom: '16px',
        '& .MuiSvgIcon-root': {
            paddingRight: '10px',
            fontSize: '24px',
            color: colors.black,
        },
    },
    propertyInfoContainer: {
        paddingLeft: '50px',
    },
    editButton: {
        margin: 0,
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    disabledIcon: {
        color: `${colors.disabledGrey} !important`,
    },
}));

interface Props {
    onClick?: () => void;
    disabled?: boolean;
    profitCenter: string;
    propertyId: string;
    name: string;
    address: string;
    apartment: Apartment | null;
}

const PropertyCard = (props: Props) => {
    const { t } = useTranslation();
    const { onClick, disabled, profitCenter, propertyId, name, address, apartment } = props;

    const editEnabled = onClick && !disabled;

    const onClickHandler = useCallback(() => {
        if (editEnabled) onClick();
    }, [editEnabled, onClick]);

    const { classes } = useStyles();

    return (
        <div className={classes.wrapper} data-testid="property-card">
            <Grid container className={classes.contentContainer}>
                <Grid item xs={4} className={classes.mediaContainer}>
                    <img
                        className={classes.media}
                        src={getPropertyImage(propertyId, 600)}
                        alt="property"
                        onError={(e: SyntheticEvent<HTMLImageElement>) => {
                            e.currentTarget.onerror = null;
                            e.currentTarget.src = placeholderImage;
                        }}
                    />
                </Grid>
                <Grid item xs={8} className={classes.propertyInfoContainer}>
                    <div className={classes.propertySubInfo}>
                        <Typography data-testid="property-title" variant="body1">
                            {name}
                        </Typography>
                    </div>

                    {address && (
                        <div className={classes.propertySubInfo}>
                            <AkBuilding />
                            <Typography data-testid="property-address" variant="body1">
                                {address}
                            </Typography>
                        </div>
                    )}

                    {profitCenter && (
                        <div className={classes.propertySubInfo}>
                            <AkCostCenter />
                            <Typography data-testid="property-subtitle" variant="body1">
                                {profitCenter}
                            </Typography>
                        </div>
                    )}

                    {apartment && (
                        <div className={classes.propertySubInfo}>
                            <AkBed />
                            <Typography data-testid="apartment-name" variant="body1">
                                {apartment.name}
                            </Typography>
                        </div>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.actionContainer}>
                        <Tooltip
                            title={editEnabled ? (t('purchase-order.add-carrier-of-cost.change-property') as string) : ''}
                            placement="right-start"
                        >
                            <IconButton
                                size="medium"
                                className={classes.editButton}
                                disabled={!editEnabled}
                                onClick={onClickHandler}
                                data-testid="change-property"
                            >
                                <AkEdit className={cn({ [classes.disabledIcon]: !editEnabled })} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default PropertyCard;
