import { gql } from 'graphql-request';
import { Address, CarrierOfCost } from 'modules/order/types';
import { GetToken } from 'modules/order/utils/useGetUser';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';

export interface PropertyAssociationInput {
    propertyId: string;
    apartmentId?: string;
}

export interface IAssociatePropertyParam {
    orderId: string;
    input: PropertyAssociationInput;
    getToken: GetToken;
}

export interface IAssociatePropertyResponse {
    associateProperty: {
        id: string;
        deliveryAddress: Address;
        invoiceAddress: Address;
        carrierOfCost: CarrierOfCost;
    };
}

const associateProperty = () => async (param: IAssociatePropertyParam) => {
    const { orderId, input, getToken } = param;
    const { graphQLClient } = await getGraphqlClient(getToken);
    const associatePropertyMutation = gql`
        mutation associateProperty($orderId: String!, $input: PropertyAssociation!) {
            associateProperty(orderId: $orderId, input: $input) {
                id
                deliveryAddress {
                    address1
                    address2
                    streetName
                    streetNumber
                    postalCode
                    city
                    region
                    country
                }
                invoiceAddress {
                    address1
                    address2
                    streetName
                    streetNumber
                    postalCode
                    city
                    region
                    country
                }
                carrierOfCost {
                    costCenter
                    countryCode
                    company {
                        id
                        code
                        name
                    }
                    property {
                        id
                        name
                        code
                        address
                        addressDetails {
                            city
                            region
                            postalCode
                        }
                        apartment {
                            id
                            name
                            code
                        }
                    }
                }
            }
        }
    `;

    const data = await graphQLClient.request<IAssociatePropertyResponse>(associatePropertyMutation, {
        orderId,
        input,
    });

    return data;
};

export default associateProperty;
