export const delay = {
    redirectDelay: 1500,
};

export const TENANT_HEADER_KEY = 'x-akelius-tenant';

export const SERVICE_COUNTRY = 'service-country';

export const CacheConst = {
    supplier: 1 * 30,
    project: 1 * 30,
    property: 1 * 30,
    company: 1 * 30,
};

export const sharedCacheKeys = {
    settings: ['settings'],
    permissions: ['permissions'],
};

export const DEBOUNCE_INTERVAL = 300;

export const DOCUMENT_SERVICE_URL = 'https://document-service.akelius.com';

export const OAP_SERVICE_DESK_URL = 'https://akelius.atlassian.net/servicedesk/customer/portal/7/group/37/create/151';

export const DAY_IN_MS = 24 * 60 * 60 * 1000;

export const cacheKeys = {
    supplier: (id?: string | null) => ['suppliers', id],
    project: (id?: string | null) => ['projects', id],
    property: (id?: string | null) => ['property', id],
    apartment: (id?: string | null) => ['apartment', id],
    company: (id?: string | null) => ['company', id],
};
