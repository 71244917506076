import { Radio, makeStyles } from '@akelius-con/react-ui-kit-components';
import { FormControl, FormControlLabel, Grid, InputLabel, MenuItem, RadioGroup, Select, Typography } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { availableDocTypes } from '../../types';

export interface FormInputs {
    docType: string;
    sendToSupplier: string;
    sendToApproval: string;
}

interface DropdownOption {
    name: string;
    value: string;
}

export type IUploadAttachmentSubmit = (values: FormInputs, formikHelpers: FormikHelpers<FormInputs>) => void;

interface Props {
    handleUploadAttachmentSubmit: IUploadAttachmentSubmit;
    initialValues: FormInputs;
    disabled: boolean;
}

const useStyles = makeStyles()({
    container: {
        '& .innerContainer': {
            display: 'flex',
            justifyContent: 'space-between',
        },
    },
    radioGroupContainer: {
        marginBottom: '16px',
        display: 'flex',
        justifyContent: 'space-between',

        '& .MuiFormGroup-root': {
            flexDirection: 'row',
        },

        '& .MuiFormControl-root': {
            width: '100%',
        },
        '& .optionsLabel': {
            display: 'flex',
            alignItems: 'center',
        },
    },
    documentType: {
        marginTop: '20px',
        marginBottom: '20px',
    },
});

const UploadAttachmentsInfoForm = (props: Props) => {
    const { handleUploadAttachmentSubmit, initialValues, disabled } = props;
    const { t } = useTranslation();
    const { classes } = useStyles();

    const formik = useFormik({
        initialValues,
        onSubmit: handleUploadAttachmentSubmit,
    });

    const docTypeOptions: DropdownOption[] = availableDocTypes
        .map(docType => ({ value: docType, name: t(`purchase-order.attachments.doc-types.${docType.toLowerCase()}`) }))
        .sort((a, b) => {
            const aName = a.name.toLowerCase();
            const bName = b.name.toLowerCase();

            if (aName > bName) {
                return 1;
            }

            return aName < bName ? -1 : 0;
        });

    const { handleSubmit } = formik;

    return (
        <form onSubmit={handleSubmit}>
            <Grid item xs={12} className={classes.container}>
                <Grid container className="innerContainer">
                    <Grid item xs={8}>
                        <FormControl className={classes.documentType}>
                            <InputLabel id="document-type">{t('purchase-order.attachments.doc-type')}</InputLabel>
                            <Select
                                labelId="document-type"
                                data-testid="document-type"
                                onChange={event => {
                                    formik.setFieldValue('docType', event.target.value);
                                    formik.submitForm();
                                }}
                                value={formik.values.docType}
                                name="docType"
                                disabled={disabled}
                            >
                                {docTypeOptions.map(({ name, value }) => (
                                    <MenuItem key={value} value={value}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={8}>
                        <Grid item xs={12} className={classes.radioGroupContainer}>
                            <Grid item xs={8} className="optionsLabel">
                                <Typography variant="body1">{t('purchase-order.attachments.send-to-supplier-label')}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        value={formik.values.sendToSupplier}
                                        onChange={event => {
                                            formik.setFieldValue('sendToSupplier', event.target.value);
                                            formik.submitForm();
                                        }}
                                    >
                                        <FormControlLabel
                                            disabled={disabled}
                                            value="YES"
                                            control={<Radio />}
                                            label={t('purchase-order.attachments.yes')}
                                        />
                                        <FormControlLabel
                                            disabled={disabled}
                                            value="NO"
                                            control={<Radio />}
                                            label={t('purchase-order.attachments.no')}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className={classes.radioGroupContainer}>
                            <Grid item xs={8} className="optionsLabel">
                                <Typography variant="body1">{t('purchase-order.attachments.send-to-approval-label')}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        value={formik.values.sendToApproval}
                                        onChange={(event: ChangeEvent<{ value: string }>) => {
                                            formik.setFieldValue('sendToApproval', event.target.value);
                                            formik.submitForm();
                                        }}
                                    >
                                        <FormControlLabel
                                            disabled={disabled}
                                            value="YES"
                                            control={<Radio />}
                                            label={t('purchase-order.attachments.yes')}
                                        />
                                        <FormControlLabel
                                            disabled={disabled}
                                            value="NO"
                                            control={<Radio />}
                                            label={t('purchase-order.attachments.no')}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default UploadAttachmentsInfoForm;
