import { colors } from '@akelius-con/react-theme';
import { FormControl, Input, InputBaseComponentProps, InputLabel, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import { ElementType, FC } from 'react';
import { PriceMaskInput } from './PriceMaskInput';

interface Props {
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: FormikProps<any>;
    label: string;
    ['data-testid']?: string;
    placeholder?: string;
    multiline?: boolean;
    required?: boolean;
    disabled?: boolean;
    startAdornment?: string | JSX.Element;
    endAdornment?: string | JSX.Element;
    preventDisableOnSubmit?: boolean;
    rows?: string | number;
}

export const FormikPriceField: FC<Props> = props => {
    const { name, formik, label, disabled, rows, placeholder, multiline, required, startAdornment, endAdornment, preventDisableOnSubmit } = props;
    const { handleBlur: onBlur, setFieldValue } = formik;

    const handleOnChange = (value: string) => {
        setFieldValue(name, value);
    };

    const isErrorShown = !!formik.errors[name] && !!formik.touched[name];

    return (
        <FormControl variant="filled">
            <InputLabel htmlFor={name}>{`${label}${required ? '*' : ''}`}</InputLabel>
            <Input
                id={name}
                name={name}
                autoComplete="off"
                disabled={(!preventDisableOnSubmit && formik.isSubmitting) || disabled}
                value={formik.values[name]}
                data-testid={props['data-testid'] || name}
                error={isErrorShown}
                onBlur={onBlur}
                multiline={multiline}
                startAdornment={
                    startAdornment && (
                        <span
                            style={{
                                position: 'relative',
                                color: colors.textGray,
                                marginLeft: 11,
                                bottom: -10,
                            }}
                        >
                            EUR
                        </span>
                    )
                }
                endAdornment={endAdornment}
                placeholder={placeholder}
                rows={rows}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onChange={handleOnChange}
                inputComponent={PriceMaskInput as unknown as ElementType<InputBaseComponentProps>}
            />
            {isErrorShown && (
                <Typography style={{ minHeight: '24px', margin: '4px 0 8px 0' }} variant="body1" sx={{ color: colors.error.light }}>
                    {isErrorShown && String(formik.errors[name])}
                </Typography>
            )}
        </FormControl>
    );
};
