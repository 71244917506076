import { Box, Theme, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '@akelius-con/react-theme';
import { makeStyles } from '@akelius-con/react-ui-kit-components';
import { Supplier } from 'shared/graphql/queries/getSuppliers';
import InvalidSupplierWarning from '../../../modules/order/components/InvalidSupplierWarning';
import { LinkToSupplier } from '../LinkToSupplier';

const useStyles = makeStyles()((theme: Theme) => ({
    supplierCardWrapper: {
        height: 'calc(100% - 24px)',
        marginTop: '24px',
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    supplierAddress: {
        marginTop: '16px',
    },
    supplierName: {
        color: colors.black,
    },
    supplierBox: {
        position: 'relative',
        boxSizing: 'border-box',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${colors.lineGrey}`,
        padding: `24px 16px`,
        transition: 'border-color 200ms',
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

interface Props {
    supplier: Supplier;
}

const countryCodeMap: { [key: string]: string } = {
    FR: 'France',
};

const SupplierCard: FC<Props> = props => {
    const { classes } = useStyles();
    const { t } = useTranslation();
    const { supplier } = props;

    return (
        <div className={classes.supplierCardWrapper} data-testid="supplier-card">
            <Typography className={classes.title} variant="h2">
                {t('common.supplier')}
            </Typography>

            <div className={classes.supplierBox}>
                <div className={classes.row}>
                    <LinkToSupplier supplier={supplier} variant="h3" className={classes.supplierName} />
                </div>

                <Typography variant="body1" className={classes.supplierAddress} data-testid="supplier-address-street">
                    {supplier.address}
                </Typography>
                <Typography variant="body1" data-testid="supplier-address-city">
                    {supplier.addressDetails.city}
                </Typography>
                <Typography variant="body1" data-testid="supplier-address-country">
                    {supplier.addressDetails.country && countryCodeMap[supplier.addressDetails.country]}
                </Typography>

                <Box flexGrow={1} />
                {supplier.requiresAttention && <InvalidSupplierWarning />}
            </div>
        </div>
    );
};

export default SupplierCard;
