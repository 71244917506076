import { gql } from 'graphql-request';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';
import { SupplierContact } from 'shared/graphql/queries/getSuppliers';
import { GetToken } from 'modules/order/utils/useGetUser';

export interface DeleteContactParam {
    getToken: GetToken;
    contactId: string;
    orderId: string;
}

export interface DeleteContactRes {
    removeOrderSupplierContact: {
        supplier: {
            additionalContacts: SupplierContact[];
        };
    };
}

const deleteContact = () => async (param: DeleteContactParam) => {
    const { getToken, ...payload } = param;
    const { graphQLClient } = await getGraphqlClient(getToken);

    const deleteContactMutation = gql`
        mutation removeOrderSupplierContact($orderId: String!, $contactId: String!) {
            removeOrderSupplierContact(orderId: $orderId, contactId: $contactId) {
                supplier {
                    additionalContacts {
                        contactId
                        name
                        email
                        phone
                    }
                }
            }
        }
    `;

    const data = await graphQLClient.request<DeleteContactRes>(deleteContactMutation, {
        ...payload,
    });

    return data;
};

export default deleteContact;
