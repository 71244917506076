import { makeStyles } from '@akelius-con/react-ui-kit-components';
import { AkClose, AkUpload } from '@akelius-con/react-ui-kit-icons';
import { Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import FileTypeIcon from '../FileTypeIcon';
import { colors } from '@akelius-con/react-theme';

const useStyles = makeStyles()({
    dropzone: {
        display: 'flex',
        alignItems: 'center',
        border: `1px dashed ${colors.black}`,
        outline: 'none',
        borderRadius: '3px',
        padding: '24px',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: colors.primary.light,
            '& .label': {
                color: colors.black,
                textDecoration: 'none',
            },
            '& .MuiSvgIcon-root': {
                color: colors.black,
            },
        },
        '& .label': {
            color: colors.black,
        },
        '& .uploadIcon': {
            marginRight: '5px',
            '& .MuiSvgIcon-root': {
                color: colors.black,
                fontSize: '24px',
            },
        },
    },
    fileDrop: {
        cursor: 'pointer',
        border: `1px dashed ${colors.black}`,
        background: colors.white,
        '& .label': {
            color: colors.black,
            textDecoration: 'none',
        },
        '& .uploadIcon .MuiSvgIcon-root': {
            color: colors.black,
        },
    },
    fileList: {
        margin: '20px 0',
        '& .fileInfo': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderTop: `1px solid ${colors.lineGrey}`,
            padding: '10px 0',
            '& .fileName': {
                display: 'flex',
                alignItems: 'center',
                '& .fileIcon': {
                    marginRight: '5px',
                },
            },
            '& .closeIcon .MuiSvgIcon-root': {
                color: colors.black,
                fontSize: '24px',
                cursor: 'pointer',
            },
            '& .MuiSvgIcon-root': {
                color: colors.black,
                fontSize: '24px',
            },
        },
    },
});

interface Props {
    onAddFilesToUpload: (files: FileWithPath[]) => void;
    'data-testid'?: string;
}

const Dropzone = (props: Props) => {
    const { onAddFilesToUpload, 'data-testid': testid } = props;
    const { classes } = useStyles();
    const { t } = useTranslation();

    const [filesToUpload, setFilesToUpload] = useState<FileWithPath[]>([]);

    const { getRootProps, getInputProps, isDragAccept } = useDropzone({
        onDrop: files => {
            const newFiles = [...files, ...filesToUpload];
            setFilesToUpload(newFiles);
            onAddFilesToUpload(newFiles);
        },
    });

    const removeFile = (index: number) => () => {
        const newFilesArr = [...filesToUpload];
        newFilesArr.splice(index, 1);
        setFilesToUpload(newFilesArr);
        onAddFilesToUpload(newFilesArr);
    };

    return (
        <Grid container data-testid={testid || 'dropzone'}>
            <Grid item xs={12} {...getRootProps({ className: `${classes.dropzone} ${isDragAccept ? classes.fileDrop : ''}` })}>
                <input {...getInputProps()} />
                <Grid item className="uploadIcon">
                    <AkUpload />
                </Grid>
                <Grid item>
                    <Typography variant="body1" className="label">
                        {t('purchase-order.dropzone-text')}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.fileList}>
                {filesToUpload.map((file: FileWithPath, index: number) => {
                    return (
                        <Grid className="fileInfo" key={`${file.name}`}>
                            <Grid item className="fileName">
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <FileTypeIcon mimeType={file.type} />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1">{file.path}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className="closeIcon">
                                <AkClose onClick={removeFile(index)} />
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
    );
};

export default Dropzone;
