import { makeStyles } from '@akelius-con/react-ui-kit-components';
import { Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { cacheKeys } from 'modules/order/constants';
import { Order } from 'modules/order/types';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isOrderReadonlyByStatus } from 'shared/utils/order';
import * as Yup from 'yup';
import updateOrder, { UpdateOrderResponse } from '../../graphql/mutations/updateOrder';
import FormikAutoSave from 'shared/components/FormikAutoSave';
import { FormikTextField } from 'shared/components/FormikTextField';
import MandatoryFieldDisclaimer from 'shared/components/MandatoryFieldDisclaimer';
import { useGetUser } from 'modules/order/utils/useGetUser';
import { IOrderResponse } from 'modules/order/graphql/queries/useGetOrder';

const useStyles = makeStyles()({
    blockTitle: {
        margin: '12px 0 10px !important',
    },
});

interface FormValues {
    address1: string;
    address2: string;
    street: string;
    postalCode: string;
    city: string;
    country: string;
}

interface IProps {
    order: Order;
}

const DeliveryAddressForm: FC<IProps> = ({ order }) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const isFieldDisabled = isOrderReadonlyByStatus(order.status) || !order.deliveryAddress;
    const dirtyTracker = useState<boolean>(false);
    const [autoSaveError, setAutoSaveError] = useState<boolean | null>(null);
    const { getToken } = useGetUser();
    const queryClient = useQueryClient();

    let initialValues = {
        address1: '',
        address2: '',
        street: '',
        postalCode: '',
        city: '',
        country: '',
    };

    if (order.deliveryAddress) {
        initialValues = {
            address1: order.deliveryAddress.address1 || '',
            address2: order.deliveryAddress.address2 || '',
            street: order.deliveryAddress.streetName || '',
            postalCode: order.deliveryAddress.postalCode || '',
            city: order.deliveryAddress.city || '',
            country: order.deliveryAddress.country || '',
        };
    }

    const validationSchema = Yup.object().shape({
        address1: Yup.string().required().label(t('purchase-order.addresses.address1')),
        address2: Yup.string(),
        street: Yup.string().required().label(t('purchase-order.addresses.street')),
        postalCode: Yup.number().required().label(t('purchase-order.addresses.postal-code')),
        city: Yup.string().required().label(t('purchase-order.addresses.city')),
        country: Yup.string(),
    });

    const updateOrderMutation = useMutation(updateOrder(), {
        onSuccess: (successRes: UpdateOrderResponse) => {
            setTimeout(function () {
                setAutoSaveError(false);
                formik.setSubmitting(false);

                queryClient.setQueryData(cacheKeys.order(order.id), (data: IOrderResponse | undefined) => {
                    if (!data) {
                        return;
                    }

                    return {
                        order: {
                            ...data.order,
                            deliveryAddress: successRes.updateOrder.deliveryAddress,
                        },
                    };
                });
            }, 50);
        },
        onError: () => {
            setTimeout(function () {
                formik.setSubmitting(false);
                setAutoSaveError(true);
            }, 50);
        },
    });

    const onSubmit = (values: FormValues) => {
        const { address1, address2, street: streetName, postalCode, city, country } = values;
        const queryVars = {
            getToken,
            orderId: order.id,
            input: {
                deliveryAddress: {
                    address1,
                    address2,
                    streetName,
                    postalCode,
                    city,
                    country,
                    region: '',
                    streetNumber: '',
                },
            },
        };
        updateOrderMutation.mutate(queryVars);
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} mb={2}>
                <Typography className={classes.blockTitle} variant="h2">
                    {`${t('purchase-order.addresses-title')}*`}
                </Typography>
                <Typography variant="body1">{t('purchase-order.addresses.select-property')}</Typography>
            </Grid>

            <Grid item xs={12}>
                <form>
                    <Grid container spacing={3}>
                        <Grid md={4} item>
                            <FormikTextField
                                disabled={isFieldDisabled}
                                preventDisableOnSubmit
                                required
                                name="address1"
                                formik={formik}
                                label={t('purchase-order.addresses.address1')}
                            />
                        </Grid>
                        <Grid md={4} item>
                            <FormikTextField
                                disabled={isFieldDisabled}
                                preventDisableOnSubmit
                                name="address2"
                                formik={formik}
                                label={t('purchase-order.addresses.address2')}
                            />
                        </Grid>
                        <Grid md={4} item>
                            <FormikTextField
                                disabled={isFieldDisabled}
                                preventDisableOnSubmit
                                required
                                name="street"
                                formik={formik}
                                label={t('purchase-order.addresses.street')}
                            />
                        </Grid>
                        <Grid md={4} item>
                            <FormikTextField
                                disabled={isFieldDisabled}
                                preventDisableOnSubmit
                                required
                                name="postalCode"
                                formik={formik}
                                label={t('purchase-order.addresses.postal-code')}
                            />
                        </Grid>
                        <Grid md={4} item>
                            <FormikTextField
                                disabled={isFieldDisabled}
                                preventDisableOnSubmit
                                required
                                name="city"
                                formik={formik}
                                label={t('purchase-order.addresses.city')}
                            />
                        </Grid>

                        <FormikAutoSave error={autoSaveError} formik={formik} dirtyTracker={dirtyTracker} />

                        <Grid item xs={12}>
                            <MandatoryFieldDisclaimer />
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    );
};

export default DeliveryAddressForm;
