import { createContext } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next);

interface Languages {
    [key: string]: string;
}

interface TranslationConfig {
    languages: Languages | [];
    i18n: unknown;
}

interface Props {
    translationConfig: TranslationConfig;
    children: JSX.Element;
}

/**
 * The consumer of this provider is expected to pass the app specific i18n instance and the list of supported languages
 * Usually this provider will be used in App.tsx where the translationConfig will be passed as a prop
 */

export const TranslationContext = createContext<TranslationConfig>({
    i18n,
    languages: [],
});

export const TranslationProvider = ({ translationConfig, children }: Props) => (
    <TranslationContext.Provider value={translationConfig}>{children}</TranslationContext.Provider>
);
