import { OnChange } from '@akelius-con/react-ui-kit-components/Autocomplete';
import { AkFilterList } from '@akelius-con/react-ui-kit-icons';
import { Box, Button, Checkbox, FormControlLabel, Grid, IconButton, Tooltip } from '@mui/material';
import { OrderStatusEnum } from 'modules/order/graphql/queries/useGetOrder';
import { ChangeEvent, FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyDropdownProvider, IOnCompanyDropdownChange } from 'shared/components/CompanyDropdownProvider';
import { IOnProjectDropdownChange, ProjectDropdownProvider } from 'shared/components/ProjectDropdownProvider';
import { IOnPropertyDropdownChange, SelectPropertyDropdownProvider } from 'shared/components/PropertyDropdownProvider';
import { IOnSupplierDropdownChange, SupplierDropdownProvider } from 'shared/components/SupplierDropdownProvider';
import { useSetSearchParams } from 'shared/utils/useSetSearchParams';
import OrderSearchField from '../../../../components/OrderSearchField';
import SelectOrderApartmentDropdown from '../../../../components/SelectOrderApartmentDropdown';
import SelectOrderStatusDropdown, { getStatusesFromString } from '../../../../components/SelectOrderStatusDropdown';
import { Filters } from './Filters.interface';
import { initialFilters } from './initialFilters';

interface FilterExpandedInitialVal {
    propertyId?: string;
    supplierId?: string;
    orderStatuses?: OrderStatusEnum[];
    companyId?: string;
    projectId?: string;
}

interface Props {
    onSearch: (filters: Filters) => void;
}

export const isDropdownFilterSet = function (filters: Filters) {
    const { supplierId, propertyId, apartmentId, status, companyId, costCenterId, projectId } = filters;
    if (propertyId || apartmentId || supplierId || status?.length || companyId || costCenterId || projectId) {
        return true;
    }
};

const filterExpandedInitialVal = ({ propertyId, supplierId, orderStatuses, companyId, projectId }: FilterExpandedInitialVal) => {
    if (propertyId?.length || supplierId?.length || orderStatuses?.length || companyId?.length || projectId?.length) {
        return true;
    }

    return false;
};

/**
 * Example URL to test with all filters
 * http://order-testing.akelius.com/orders?apartment_id=8be6f60b-520a-11e8-bbbe-000d3a2b8cf3&company_id=34e81738-21f1-5aee-820f-ac4c91c14d1e&cost_center=910603&project_id=P.6358.0A02.01&property_id=6b08f69e-520a-11e8-bbbe-000d3a2b8cf3&status=RELEASED,CREATED&supplier_id=d39560ee-9668-426c-b40a-7bede7622098&term=abcd&user_orders=true
 */
export const OrderFilters: FC<Props> = props => {
    const { onSearch } = props;
    const { patchFilter, filters } = useSetSearchParams(initialFilters, onSearch);
    const hasActiveFilters = Object.values(filters).some(Boolean);
    const { t } = useTranslation();

    const [isFilterExpanded, setIsFilterExpanded] = useState<boolean>(
        filterExpandedInitialVal({
            propertyId: filters.propertyId,
            supplierId: filters.supplierId,
            orderStatuses: filters.orderStatuses,
            companyId: filters.companyId,
            projectId: filters.projectId,
        }),
    );

    const onChangeProject: IOnProjectDropdownChange = project => {
        patchFilter({ projectId: project?.projectId });
    };

    const onChangeCompany: IOnCompanyDropdownChange = ids => {
        patchFilter({ ...ids });
    };

    const onChangeSupplier: IOnSupplierDropdownChange = supplier => {
        patchFilter({ supplierId: supplier?.id });
    };

    const onChangeProperty: IOnPropertyDropdownChange = property => {
        patchFilter({ propertyId: property?.id });
    };

    const onChangeApartment: OnChange = (_event, apartment) => {
        patchFilter({ apartmentId: apartment?.value });
    };

    const toggleFilters = useCallback(() => {
        setIsFilterExpanded(!isFilterExpanded);
    }, [isFilterExpanded]);

    const removeFilters = useCallback(() => {
        setIsFilterExpanded(false);
        patchFilter(initialFilters);
    }, [patchFilter]);

    const onQueryClear = useCallback(() => {
        patchFilter({ term: '' });
    }, [patchFilter]);

    const onSearchStrChange = useCallback(
        (event: ChangeEvent<{ value: string }>) => {
            patchFilter({ term: event.target.value });
        },
        [patchFilter],
    );

    const onCheckboxChangeHandler = useCallback(
        (_event: ChangeEvent<{}>, checked: boolean) => {
            patchFilter({ userOrders: checked });
        },
        [patchFilter],
    );

    const onOrderStatusChange = useCallback(
        (status: OrderStatusEnum[]) => {
            if (status.length) {
                patchFilter({ status });
            } else {
                patchFilter({ status: null });
            }
        },
        [patchFilter],
    );

    return (
        <>
            <Grid container alignItems="center" spacing={2} mb={3}>
                <Grid item lg={4} sm={6} xs={12}>
                    <OrderSearchField
                        label={t('common.search')}
                        placeholder={t('purchase-order.filter.search.placeholder')}
                        data-testid="order-search"
                        value={filters.term}
                        queryClear={onQueryClear}
                        onChange={onSearchStrChange}
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        sx={{ mt: 1.2 }}
                        onChange={onCheckboxChangeHandler}
                        checked={!!filters.userOrders}
                        data-testid="order-filter-user-orders"
                        control={<Checkbox />}
                        label={t('purchase-order.filter.my-orders')}
                    />
                </Grid>
                <Grid item>
                    <Tooltip title={t('purchase-order.filter.tooltip.toggle-filter-panel')}>
                        <IconButton
                            sx={{ mr: 2, backgroundColor: isFilterExpanded ? 'primary.main' : 'transparent' }}
                            data-testid="toggle-order-filters"
                            size="medium"
                            onClick={toggleFilters}
                        >
                            <AkFilterList />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title={t('purchase-order.filter.tooltip.remove-filter')}>
                        <Box sx={{ display: 'inline-block' }}>
                            <Button disabled={!hasActiveFilters} data-testid="remove-all-filters" onClick={removeFilters} variant="outlined">
                                {t('purchase-order.filter.remove-all-filters')}
                            </Button>
                        </Box>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid container spacing={2} mb={4}>
                {isFilterExpanded && (
                    <>
                        <Box width="100%" />
                        <Grid item lg={3} sm={6} xs={12}>
                            <SelectPropertyDropdownProvider propertyId={filters.propertyId} onChange={onChangeProperty} />
                        </Grid>
                        <Grid item lg={3} sm={6} xs={12}>
                            <SelectOrderApartmentDropdown
                                apartmentId={filters.apartmentId || null}
                                propertyId={filters.propertyId || null}
                                onChange={onChangeApartment}
                            />
                        </Grid>
                        <Grid item lg={3} sm={6} xs={12}>
                            <SupplierDropdownProvider onChange={onChangeSupplier} supplierId={filters.supplierId} />
                        </Grid>
                        <Grid item lg={3} sm={6} xs={12}>
                            <SelectOrderStatusDropdown
                                selectedStatuses={getStatusesFromString(filters.status)}
                                onOrderStatusChange={onOrderStatusChange}
                                variant="standard"
                            />
                        </Grid>

                        <Grid item lg={6} sm={6} xs={12}>
                            <CompanyDropdownProvider companyId={filters.companyId} costCenterId={filters.costCenterId} onChange={onChangeCompany} />
                        </Grid>
                        <Grid item lg={3} sm={3} xs={12}>
                            <ProjectDropdownProvider onChange={onChangeProject} projectId={filters.projectId || null} />
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    );
};
