import { Button, makeStyles } from '@akelius-con/react-ui-kit-components';
import { AkPlus } from '@akelius-con/react-ui-kit-icons';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OrderAssociatedProperty } from '../../types';
import PropertyCard from '../PropertyCard';
import AddEditCarrierOfCostModal from './addEditCarrierOfCostModal';

const useStyles = makeStyles()({
    link: {
        textDecoration: 'none',
    },
    orderDetailsBody: {
        width: '100%',
        marginTop: '24px',
    },
    title: {
        '&.MuiTypography-root': {
            marginBottom: '16px',
        },
    },
    dataTableContainer: {
        minHeight: 'calc(100vh - 310px)',
    },
});

interface Props {
    property: OrderAssociatedProperty;
    caseOrder?: boolean;
    readonly?: boolean;
    profitCenter: string;
    onSave: (property: OrderAssociatedProperty) => void;
    triggerCocModal: () => void;
    closeCocModal: () => void;
    cocModalState: boolean;
    isLoading?: boolean;
}

export const PropertyCarrierOfCostInfo = (props: Props) => {
    const { profitCenter, property, readonly, caseOrder, onSave, triggerCocModal, closeCocModal, cocModalState, isLoading } = props;
    const propertyId = property.id;
    const { classes } = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.orderDetailsBody} data-testid="order-details-body">
            <Grid container>
                <Grid item xs={12}>
                    <Typography classes={{ root: classes.title }} variant="h2">
                        {`${t('purchase-order.general-info.carrier-of-cost')}*`}
                    </Typography>
                    {!propertyId && (
                        <>
                            <Box height="10px" />
                            <Button
                                label={t('purchase-order.common.property')}
                                variant="outlined"
                                startIcon={<AkPlus />}
                                onClick={triggerCocModal}
                                disabled={readonly}
                            />
                        </>
                    )}
                </Grid>
                <Grid item xs={12}>
                    {propertyId ? (
                        <PropertyCard
                            disabled={readonly}
                            profitCenter={profitCenter}
                            onClick={triggerCocModal}
                            propertyId={property.id}
                            name={property.name}
                            address={property.address || ''}
                            apartment={property.apartment || null}
                        />
                    ) : (
                        <Typography>{t('purchase-order.no-carrier-of-cost-selected')}</Typography>
                    )}
                </Grid>
            </Grid>

            <AddEditCarrierOfCostModal
                isLoading={!!isLoading}
                onClose={closeCocModal}
                open={cocModalState}
                property={property}
                caseOrder={caseOrder}
                onSave={onSave}
            />
        </div>
    );
};
