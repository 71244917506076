import { IOrderResponse } from 'modules/order/graphql/queries/useGetOrder';
import { SupplierContact } from 'shared/graphql/queries/getSuppliers';

export const getUpdateAdditionalContactCache = (prevData: IOrderResponse, additionalContacts: SupplierContact[]) => {
    const updatedCache = {
        ...prevData,
        order: {
            ...prevData.order,
            supplier: {
                ...prevData.order.supplier,
                additionalContacts,
            },
        },
    };
    return updatedCache;
};
