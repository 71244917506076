import { makeStyles } from '@akelius-con/react-ui-kit-components';
import { AkCostCenter, AkEdit } from '@akelius-con/react-ui-kit-icons';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import cn from 'classnames';
import { CompanyMinimal } from 'modules/order/graphql/queries/getCompany';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddEditAdminCarrierOfCostInfoModal } from './AddEditAdminCarrierOfCostInfoModal';
import { colors } from '@akelius-con/react-theme';

type IProps = {
    company: CompanyMinimal;
    costCenterId: string;
    costCenterName: string;
    disableEdit?: boolean;
};

const useStyles = makeStyles()({
    title: {
        marginBottom: '16px',
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    card: {
        marginTop: '24px',
        height: 'calc(100% - 24px)',
        display: 'flex',
        flexDirection: 'column',
    },
    propertySubInfo: {
        display: 'flex',
        paddingBottom: '16px',
        '& .MuiSvgIcon-root': {
            paddingRight: '10px',
            fontSize: '24px',
            color: colors.black,
        },
    },
    contentWrapper: {
        position: 'relative',
        boxSizing: 'border-box',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${colors.lineGrey}`,
        padding: `24px 16px`,
        transition: 'border-color 200ms',
    },
    disabledIcon: {
        color: `${colors.disabledGrey} !important`,
    },
});

export const AdminCarrierOfCostInfo: FC<IProps> = props => {
    const [openEditModal, setOpenEditModal] = useState<boolean>(false);
    const {
        company: { id: companyId, name, code },
        costCenterId,
        costCenterName,
        disableEdit,
    } = props;
    const { classes } = useStyles();
    const { t } = useTranslation();

    const onEditHandler = () => {
        setOpenEditModal(true);
    };

    return (
        <div className={classes.card}>
            <Typography className={classes.title} variant="h2">
                {t('purchase-order.general-info.carrier-of-cost')}*
            </Typography>

            <div className={classes.contentWrapper}>
                <Box>
                    <Typography variant="h3">
                        {code} - {name}
                    </Typography>
                </Box>

                <Box mt={2} className={classes.propertySubInfo}>
                    <AkCostCenter />
                    <div>
                        <Typography variant="body1">{costCenterId}</Typography>
                        <Typography variant="body1">{costCenterName}</Typography>
                    </div>
                </Box>

                <Box flexGrow={1} />

                <div className={classes.actionContainer}>
                    <Tooltip title={t('purchase-order.add-carrier-of-cost.change-property')} placement="right-start">
                        <IconButton size="medium" disabled={disableEdit} onClick={onEditHandler} data-testid="change-company">
                            <AkEdit className={cn({ [classes.disabledIcon]: disableEdit })} />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>

            {openEditModal && (
                <AddEditAdminCarrierOfCostInfoModal
                    selectedCostCenterId={costCenterId}
                    open={openEditModal}
                    selectedCompanyId={companyId}
                    onClose={() => setOpenEditModal(false)}
                />
            )}
        </div>
    );
};
