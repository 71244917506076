import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import { noop } from 'lodash';
import { PropertyCarrierOfCostInfo } from 'modules/order/components/PropertyCarrierOfCostInfo';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@akelius-con/react-ui-kit-components';
import * as Yup from 'yup';
import { OrderAssociatedProperty, Property } from '../../../../types';
import { FormikTextField } from 'shared/components/FormikTextField';
import LinkToCase from '../../../../components/LinkToCase';
import OrderCreationSupplierCard from '../../../../components/OrderCreationSupplierCard';
import DESIGN_CONSTANT from 'shared/designConstant';
import { Supplier } from 'shared/graphql/queries/getSuppliers';
import { colors } from '@akelius-con/react-theme';

const useStyles = makeStyles()({
    orderDetailsBody: {
        maxWidth: DESIGN_CONSTANT.formContentWidth,
    },
    contentWrapper: {
        maxHeight: 'calc(100vh - 270px)',
        overflow: 'auto',
        overflowX: 'hidden',
    },
    containerWrapper: {
        maxWidth: DESIGN_CONSTANT.formContentWidth,
        padding: '12px 0',
    },
    alignRight: {
        textAlign: 'right',
    },
    linkIcon: {
        color: `${colors.black} !important`,
    },
    removeUnderline: {
        textDecoration: 'none !important',
    },
});

export interface FormValues {
    title: string;
    referenceNumber: string;
}

export interface CaseInfo {
    supplierId: string;
    propertyId: string;
    caseId: string;
    apartmentId: string | undefined;
}
interface Props {
    setOrderFormValues: Dispatch<SetStateAction<FormValues>>;
    supplier: Supplier;
    property: Property;
    caseId: string;
    apartmentId: string | undefined;
    setIsFormValid: Dispatch<SetStateAction<boolean>>;
    onChangeSupplier: (supplier: Supplier | null) => void;
    onSaveCarrierOfCost: (property: OrderAssociatedProperty) => void;
    isLoadingSupplier: boolean;
}

const CaseOrderContent = (props: Props) => {
    const { setOrderFormValues, setIsFormValid, supplier, property, caseId, onSaveCarrierOfCost, onChangeSupplier, apartmentId, isLoadingSupplier } =
        props;
    const { classes } = useStyles();
    const { t } = useTranslation();
    const [cocModalState, setCocModalState] = useState<boolean>(false);

    const triggerCocModal = useCallback(() => {
        setCocModalState(true);
    }, [setCocModalState]);

    const closeCocModal = useCallback(() => {
        setCocModalState(false);
    }, [setCocModalState]);

    const [initialValues, setInitialValues] = useState<FormValues>({
        title: '',
        referenceNumber: '',
    });

    const validationSchema = Yup.object().shape({
        title: Yup.string().required().max(100).label(t('purchase-order.title')),
        referenceNumber: Yup.string().max(50).label(t('purchase-order.general-info.reference-number')),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: noop,
    });

    useEffect(() => {
        setInitialValues(prevState => ({
            ...prevState,
            referenceNumber: caseId,
        }));
    }, [caseId]);

    useEffect(() => {
        setOrderFormValues({ ...formik.values });
        setIsFormValid(formik.isValid && formik.dirty);
    }, [formik.isValid, formik.values, formik.dirty, setIsFormValid, setOrderFormValues]);

    return (
        <div className={classes.contentWrapper}>
            <form onSubmit={formik.handleSubmit} className={classes.orderDetailsBody} data-testid="general-information">
                <div className={classes.containerWrapper}>
                    <Grid container spacing={3}>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormikTextField
                                preventDisableOnSubmit
                                name="title"
                                data-testid="order-title"
                                label={`${t('purchase-order.general-info.title')}*`}
                                formik={formik}
                            />
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <LinkToCase caseId={caseId} />
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            {property.name && property.costCenter && property.addressDetails.country && (
                                <PropertyCarrierOfCostInfo
                                    triggerCocModal={triggerCocModal}
                                    closeCocModal={closeCocModal}
                                    cocModalState={cocModalState}
                                    profitCenter={property.costCenter}
                                    property={{
                                        ...property,
                                        id: property.id,
                                        apartment: property.apartments.find(a => a.id === apartmentId),
                                    }}
                                    caseOrder
                                    onSave={(property: OrderAssociatedProperty) => {
                                        onSaveCarrierOfCost(property);
                                        closeCocModal();
                                    }}
                                />
                            )}
                        </Grid>

                        <Grid item md={6} sm={12} xs={12}>
                            <OrderCreationSupplierCard isLoadingSupplier={isLoadingSupplier} supplier={supplier} onChange={onChangeSupplier} />
                        </Grid>
                    </Grid>
                </div>
            </form>
        </div>
    );
};

export default CaseOrderContent;
