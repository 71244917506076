import { colors } from '@akelius-con/react-theme';
import { Button, DialogTitle, LoadingSpinner, makeStyles } from '@akelius-con/react-ui-kit-components';
import { AkExternalLink } from '@akelius-con/react-ui-kit-icons';
import { Dialog, DialogActions, DialogContent, Link, List, ListItem, ListItemText, Typography } from '@mui/material';
import { useGetOrder } from 'modules/order/graphql/queries/useGetOrder';
import { ApprovalHistory, useOrderApprovalHistory } from 'modules/order/graphql/queries/useGetOrderApprovalHistory';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import formatDateTime from 'shared/utils/formatDateTime';

interface IProps {
    orderId: string;
    onClose: () => void;
}

const useStyles = makeStyles()({
    comments: {
        paddingLeft: '25px',
    },
    approvalStep: {
        borderBottom: `1px solid ${colors.lineGrey}`,
    },
    link: {
        textDecoration: 'none',
        display: 'inline-block',
        '&:hover': {
            textDecoration: `underline ${colors.black}`,
        },
    },
    approvalAppLink: {
        color: colors.black,
        textDecoration: 'none',
        marginBottom: '20px',
        display: 'inline-block',
        '& p': {
            fontWeight: 'bold',
            display: 'flex',
        },
        '& svg': {
            marginLeft: '5px',
            color: colors.black,
            fontSize: '20px',
            marginTop: '3px',
        },
    },
});

export const OrderHistoryDialog: FC<IProps> = props => {
    const { orderId, onClose } = props;
    const { t } = useTranslation();
    const { classes } = useStyles();

    const { data, isLoading: isHistoryLoading } = useOrderApprovalHistory(orderId);
    const { data: orderData, isLoading: orderDataLoading } = useGetOrder(orderId);

    const approvalDetails = data?.order.approval;
    const isLoading = isHistoryLoading || orderDataLoading;

    return (
        <Dialog maxWidth="md" title={t('purchase-order.button.order-history')} open={!!orderId} onClose={onClose} data-testid="order-history-dialog">
            <DialogTitle onClose={onClose} title={t('purchase-order.button.order-history')} />
            <DialogContent>
                {isLoading && <LoadingSpinner areaHeight="250px" />}

                {!isLoading && approvalDetails?.url && (
                    <a href={approvalDetails.url} className={classes.approvalAppLink} target="_blank" rel="noreferrer">
                        <Typography variant="body1">
                            <span>{t('purchase-order.order-history.approval-app-link')}</span>
                            <AkExternalLink />
                        </Typography>
                    </a>
                )}

                <List>
                    {!isLoading && orderData && (
                        <ListItem className={classes.approvalStep}>
                            <ListItemText>
                                <>
                                    <Typography variant="body1" mb={1}>
                                        <strong>{t('purchase-order.order-history.CREATED')}</strong>
                                    </Typography>

                                    <Typography variant="body1">
                                        <strong>{t('purchase-order.order-history.date')}: </strong>
                                        {formatDateTime(orderData.order.createdDate)}
                                    </Typography>

                                    <Typography variant="body1">
                                        <strong>{t('purchase-order.order-history.users')}:</strong> {orderData?.order?.creator.name}
                                    </Typography>
                                </>
                            </ListItemText>
                        </ListItem>
                    )}
                    {!isLoading && approvalDetails?.history && (
                        <>
                            {approvalDetails.history.map((item: ApprovalHistory) => (
                                <ListItem className={classes.approvalStep} key={`${item.date}-${item.event}-${item?.user?.id}`}>
                                    <ListItemText>
                                        <>
                                            <Typography variant="body1" mb={1}>
                                                <Link className={classes.link} href={item.url} target="__blank">
                                                    <strong>{t(`purchase-order.order-history.${item.event}`)}</strong>
                                                </Link>
                                            </Typography>

                                            <Typography variant="body1">
                                                <strong>{t('purchase-order.order-history.date')}:</strong> {formatDateTime(item.date)}
                                            </Typography>

                                            {item.level && (
                                                <Typography variant="body1">
                                                    <strong>{t('purchase-order.order-history.level')}:</strong> {item.level}
                                                </Typography>
                                            )}
                                            {item.user && (
                                                <Typography variant="body1">
                                                    <strong>{t('purchase-order.order-history.users')}:</strong> {item.user.name}
                                                </Typography>
                                            )}
                                            {!!item.comments.length && (
                                                <>
                                                    <Typography variant="body1">
                                                        <strong>{t('purchase-order.order-history.comments')}:</strong>
                                                    </Typography>
                                                    <div className={classes.comments}>
                                                        {item.comments.map(comment => (
                                                            <Typography key={comment} variant="body1">
                                                                {comment}
                                                            </Typography>
                                                        ))}
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    </ListItemText>
                                </ListItem>
                            ))}
                        </>
                    )}
                </List>
            </DialogContent>

            <DialogActions>
                <Button variant="outlined" label={t('common.close')} onClick={onClose} data-testid="close" />
            </DialogActions>
        </Dialog>
    );
};
