import { makeStyles } from '@akelius-con/react-ui-kit-components';
import { useCallback, useState } from 'react';
import { isOrderReadonlyByStatus } from 'shared/utils/order';
import { Order } from '../../types';
import { ProjectCard } from '../ProjectCard';
import { UpdateProjectDialog } from './UpdateProjectDialog';

const useStyles = makeStyles()({
    link: {
        textDecoration: 'none',
    },
    orderDetailsBody: {
        width: '100%',
        marginTop: '24px',
    },
    title: {
        '&.MuiTypography-root': {
            marginBottom: '16px',
        },
    },
    dataTableContainer: {
        minHeight: 'calc(100vh - 310px)',
    },
});

interface Props {
    order: Order;
}

export const ConstructionCarrierOfCostInfo = ({ order }: Props) => {
    const property = order.carrierOfCost.property;
    const project = order.carrierOfCost.project;
    const { classes } = useStyles();
    const [cocModalState, setCocModalState] = useState<boolean>(false);
    const isReadonly = isOrderReadonlyByStatus(order.status);

    const triggerCocModal = useCallback(() => {
        setCocModalState(true);
    }, [setCocModalState]);

    const closeCocModal = useCallback(() => {
        setCocModalState(false);
    }, [setCocModalState]);

    return (
        <div className={classes.orderDetailsBody} data-testid="order-details-body">
            {project && property && (
                <ProjectCard
                    disabled={isReadonly}
                    profitCenter={order.carrierOfCost.costCenter}
                    onClick={triggerCocModal}
                    project={project}
                    property={property}
                />
            )}

            {project && <UpdateProjectDialog order={order} onClose={closeCocModal} open={cocModalState} project={project} />}
        </div>
    );
};
