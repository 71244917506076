import { OrderDocument } from 'modules/order/types';
import byteToMegaByte from 'shared/helpers/byteToMegaByte';

const isAttachmentMaxSizeOver = (documents: OrderDocument[]) => {
    const totalSize = documents.reduce((acc, doc) => acc + (doc?.sizeInBytes || 0), 0);
    const sizeInMB = byteToMegaByte(totalSize);
    return sizeInMB > 10;
};

export default isAttachmentMaxSizeOver;
