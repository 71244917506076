import { useMutation, useQueryClient } from '@tanstack/react-query';
import associateProperty, { IAssociatePropertyResponse } from 'modules/order/graphql/mutations/associateProperty';
import { IOrderResponse } from 'modules/order/graphql/queries/useGetOrder';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { snackbar } from 'shared/components/Snackbar';

interface Params {
    orderFromCache: IOrderResponse | undefined;
    orderCacheKey: unknown[];
    setMutationLoading: Dispatch<SetStateAction<boolean>>;
    onClose: () => void;
}

const useAssociatedPropertyMutation = (params: Params) => {
    const { orderFromCache, orderCacheKey, setMutationLoading, onClose } = params;
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    const associatePropertyMutation = useMutation(associateProperty(), {
        onSuccess: (data: IAssociatePropertyResponse) => {
            if (data?.associateProperty?.carrierOfCost.property) {
                if (!orderFromCache) {
                    queryClient.refetchQueries(orderCacheKey);
                    snackbar.success(t('purchase-order.snackbar.carrier-of-cost-associated-success'));
                    return;
                }

                const updatedCache = {
                    ...orderFromCache,
                    order: {
                        ...orderFromCache.order,
                        deliveryAddress: data.associateProperty.deliveryAddress,
                        invoiceAddress: data.associateProperty.invoiceAddress,
                        property: data.associateProperty.carrierOfCost.property,
                        carrierOfCost: data.associateProperty.carrierOfCost,
                    },
                };

                queryClient.setQueryData(orderCacheKey, updatedCache);

                setMutationLoading(false);
                onClose();

                snackbar.success(t('purchase-order.snackbar.carrier-of-cost-associated-success'));
            } else {
                snackbar.error(t('purchase-order.snackbar.carrier-of-cost-associated-failure'));
            }
        },
        onError: () => {
            snackbar.error(t('purchase-order.snackbar.carrier-of-cost-associated-failure'));
            setMutationLoading(false);
        },
    });

    return associatePropertyMutation;
};

export default useAssociatedPropertyMutation;
