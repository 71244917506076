import { gql } from 'graphql-request';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';
import { Company } from './getCompany';
import { GetToken } from 'modules/order/utils/useGetUser';

interface CompanyParam {
    getToken: GetToken;
    pageSize: number;
    endcursor?: string;
    filter: {
        term: string;
    };
}

export interface CostCenter {
    active: boolean;
    code: string;
    name: string;
}

export interface ICompaniesQueryResponse {
    companies: {
        items: Company[];
        endCursor: string;
        pageSize: number;
        totalCount: number;
        hasNextPage: boolean;
    };
}

const getCompanies = (param: CompanyParam) => async () => {
    const { getToken, ...queryVars } = param;
    const { graphQLClient } = await getGraphqlClient(getToken);

    const getApartmentsQuery = gql`
        query companies($pageSize: Int!, $filter: CompaniesFilter!) {
            companies(pageSize: $pageSize, filter: $filter) {
                items {
                    ... on Company {
                        id
                        active
                        addressDetails {
                            address1
                            address2
                            postalCode
                            city
                            region
                            country
                            streetName
                        }
                        code
                        costCenters {
                            active
                            code
                            name
                        }
                        isPropertyCompany
                        name
                        vatNumber
                    }
                }
                endCursor
                pageSize
                totalCount
                hasNextPage
            }
        }
    `;

    const data = await graphQLClient.request<ICompaniesQueryResponse>(getApartmentsQuery, {
        ...queryVars,
    });

    return data;
};

export default getCompanies;
