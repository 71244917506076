import { GraphQLClient } from 'graphql-request';
import { GetToken } from 'modules/order/utils/useGetUser';
import { backendRoutes } from '../routes';

export const getGraphqlClient = async (getToken: GetToken) => {
    const endpoint = backendRoutes.graphql();
    const accessToken = await getToken();

    const graphQLClient = new GraphQLClient(endpoint, {
        headers: {
            authorization: `Bearer ${accessToken}`,
        },
    });

    return { graphQLClient };
};
