import { TabNameEnum } from './pages/OrderDetails/TabNameEnum';

export const cacheKeys = {
    suppliers: () => ['suppliers'],
    supplier: (id: string) => ['suppliers', id],
    case: {
        supplier: ['supplier'],
        property: ['property'],
    },
    projects: ['projects'],
    project: (projectId: string) => ['projects', projectId],
    createOrder: {
        supplier: ['supplier'],
    },
    company: (companyId: string) => [cacheKeys.companies, companyId],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ordersInfinite: (filters: any) => [...cacheKeys.orders(), filters],
    properties: () => ['properties'],
    companies: ['companies'],
    apartments: ['apartments'],
    invoices: ['invoices'],
    orders: () => ['orders'],
    order: (orderId: string) => [...cacheKeys.orders(), orderId],
    approvalHistory: (orderId: string) => ['approvalHistory', orderId],
    users: () => ['users'],
    responsiblePersons: () => ['responsiblePersons'],
};

export const routeConst = {
    orderDetailsTabs: {
        general: TabNameEnum.GENERAL,
    },
    order: '/orders',
    orderDetails: (orderId: string, tabName?: TabNameEnum) => `${routeConst.order}/${orderId}/${tabName || routeConst.orderDetailsTabs.general}`,
    orderCreate: () => `${routeConst.order}/create`,
};

// deprecated, use cacheKey functions instead
export const ORDERS_QUERY_KEY = cacheKeys.orders().join('');
export const PROPERTIES_QUERY_KEY = cacheKeys.properties().join('');
