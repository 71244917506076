export const initialFilters = {
    userOrders: undefined,
    term: undefined,
    supplierId: undefined,
    propertyId: undefined,
    projectId: undefined,
    apartmentId: undefined,
    status: undefined,
    companyId: undefined,
    costCenter: undefined,
};
