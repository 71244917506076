import { ContentLayout, MessageBox, makeStyles } from '@akelius-con/react-ui-kit-components';
import { Grid, Typography } from '@mui/material';
import { routeConst as orderRouteConst } from 'modules/order/constants';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import Header from 'shared/components/Header';

const useStyles = makeStyles()({
    pageWrapper: {
        marginTop: '20px',
        width: '100%',
    },
});

interface IProps {
    title: string;
    errors?: string[];
}

export const ErrorPage: FC<IProps> = props => {
    const { title, errors } = props;
    const { classes } = useStyles();
    const { t } = useTranslation();

    return (
        <BrowserRouter>
            <Header title="Order" link={orderRouteConst.order} />
            <ContentLayout>
                <div className={classes.pageWrapper}>
                    <Typography mb={3} data-testid="page-title" variant="h1">
                        {title}
                    </Typography>

                    <Grid container justifyContent="center">
                        <Grid md={5} item>
                            {errors &&
                                errors.map(e => (
                                    <MessageBox key={e} type="error">
                                        {t(`common.backend-errors.${e.toLowerCase()}`)}
                                    </MessageBox>
                                ))}
                        </Grid>
                    </Grid>
                </div>
            </ContentLayout>
        </BrowserRouter>
    );
};
