import { AutocompleteItem, Button, makeStyles } from '@akelius-con/react-ui-kit-components';
import { Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { cacheKeys } from 'modules/order/constants';
import { IOrderResponse } from 'modules/order/graphql/queries/useGetOrder';
import { Order } from 'modules/order/types';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as Yup from 'yup';
import updateOrder, { UpdateOrderResponse } from '../../graphql/mutations/updateOrder';
import FormikAutoSave from 'shared/components/FormikAutoSave';
import { FormikTextField } from 'shared/components/FormikTextField';
import MandatoryFieldDisclaimer from 'shared/components/MandatoryFieldDisclaimer';
import { StaffSelectionDropdown } from '../StaffSelectionDropdown';
import { useGetUser } from 'modules/order/utils/useGetUser';

const useStyles = makeStyles()({
    title: {
        margin: '12px 0 0',
    },
});

interface IProps {
    order: Order;
    disabled?: boolean;
}

interface FormValues {
    email: string;
    name: string;
    phone: string;
    title: string;
}

export const AkeliusContact: FC<IProps> = props => {
    const { classes } = useStyles();
    const [selectedUser, setSelectedUser] = useState<AutocompleteItem | null>(null);
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const [autoSaveError, setAutoSaveError] = useState<boolean | null>(null);
    const dirtyTracker = useState<boolean>(false);
    const [showStaffSelectionDropdown, setShowStaffSelectionDropdown] = useState<boolean>(false);
    const { order, disabled } = props;
    const { contactForSupplier } = order;
    const { getToken } = useGetUser();

    const updateOrderMutation = useMutation(updateOrder(), {
        onSuccess: (successRes: UpdateOrderResponse) => {
            setTimeout(function () {
                setAutoSaveError(false);
                formik.setSubmitting(false);

                queryClient.setQueryData<IOrderResponse | undefined>(cacheKeys.order(order.id), (orderCache: IOrderResponse | undefined) => {
                    if (orderCache) {
                        return {
                            order: {
                                ...orderCache.order,
                                contactForSupplier: successRes.updateOrder.contactForSupplier,
                            },
                        };
                    }
                });
            }, 50);
        },
        onError: () => {
            formik.setSubmitting(false);
            setAutoSaveError(true);
        },
    });

    const initialValues: FormValues = {
        email: contactForSupplier?.email || '',
        name: contactForSupplier?.name || '',
        phone: contactForSupplier?.phone || '',
        title: contactForSupplier?.title || '',
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string().max(200).label(t('purchase-order.contact.title')),
        name: Yup.string().max(200).label(t('purchase-order.contact.name')),
        phone: Yup.string().max(20).label(t('purchase-order.contact.phone')),
        email: Yup.string().email().max(200).label(t('purchase-order.contact.email')),
    });

    const onSubmit = useCallback(
        (values: FormValues) => {
            const queryVars = {
                orderId: order.id,
                getToken,
                input: {
                    contactForSupplier: values,
                },
            };
            updateOrderMutation.mutate(queryVars);
        },
        [getToken, order.id, updateOrderMutation],
    );

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    const onSelectUser = useCallback(
        (user: AutocompleteItem | null) => {
            if (user) {
                formik.setFieldValue('name', user.name);
                formik.setFieldValue('email', user.value);
                formik.setFieldValue('title', user.role);
                formik.setFieldValue('phone', user.phone);
            }
            setShowStaffSelectionDropdown(false);
            setSelectedUser(null);
        },
        [formik],
    );

    useEffect(() => {
        setSelectedUser(null);
        setShowStaffSelectionDropdown(false);
    }, [formik.values]);

    return (
        <Grid container>
            <Grid md={8} item>
                <Grid container spacing={2} mt={0}>
                    <Grid item xs={12}>
                        <Typography className={classes.title} variant="h2">{`${t('purchase-order.contacts.akelius-contact')} *`}</Typography>
                    </Grid>

                    {!showStaffSelectionDropdown && (
                        <Grid item xs={6}>
                            <Button
                                disabled={disabled}
                                variant="outlined"
                                label={t('purchase-order.staff-selection.import-from-staff-catalog')}
                                onClick={() => setShowStaffSelectionDropdown(true)}
                                data-testid="import-from-staff-catalog"
                            />
                        </Grid>
                    )}

                    {showStaffSelectionDropdown && (
                        <Grid item xs={6}>
                            <StaffSelectionDropdown
                                selectedUser={selectedUser}
                                onChange={value => setSelectedUser(value)}
                                onSelectUser={onSelectUser}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} />

                    <Grid item md={6} sm={6} xs={12}>
                        <FormikTextField
                            preventDisableOnSubmit
                            data-testid="name-input"
                            name="name"
                            required
                            label={t('purchase-order.contact.name')}
                            formik={formik}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <FormikTextField
                            preventDisableOnSubmit
                            data-testid="title-input"
                            name="title"
                            required
                            label={t('purchase-order.contact.title')}
                            formik={formik}
                            disabled={disabled}
                        />
                    </Grid>

                    <Grid item md={6} sm={6} xs={12}>
                        <FormikTextField
                            preventDisableOnSubmit
                            data-testid="email-input"
                            name="email"
                            required
                            label={t('purchase-order.contact.email')}
                            formik={formik}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <FormikTextField
                            preventDisableOnSubmit
                            data-testid="phone-input"
                            name="phone"
                            required
                            label={t('purchase-order.contact.phone')}
                            formik={formik}
                            disabled={disabled}
                        />
                    </Grid>
                </Grid>

                <FormikAutoSave error={autoSaveError} formik={formik} dirtyTracker={dirtyTracker} />

                <Grid item xs={12} mt={3}>
                    <MandatoryFieldDisclaimer />
                </Grid>
            </Grid>
        </Grid>
    );
};
