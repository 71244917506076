import { gql } from 'graphql-request';
import { getGraphqlClient } from '../useGraphQlClient';
import { ServiceCountriesEnum } from 'shared/types/ServicesCountriesEnum';
import { useQuery } from '@tanstack/react-query';
import { DAY_IN_MS, sharedCacheKeys } from 'shared/constant';
import { GetToken, useGetUser } from 'modules/order/utils/useGetUser';

export enum Currency {
    EUR = 'EUR',
}

export interface Setting {
    country: ServiceCountriesEnum;
    currency: Currency;
    vatRates: number[];
}

export interface GetSettingsParam {
    getToken: GetToken;
}

export interface SettingsQueryResponse {
    settings: Setting[];
}

export const useGetSettings = () => {
    const { getToken } = useGetUser();
    return useQuery(sharedCacheKeys.settings, getSettings({ getToken }), {
        cacheTime: DAY_IN_MS,
        staleTime: DAY_IN_MS,
    });
};

export const getSettings =
    ({ getToken }: GetSettingsParam) =>
    async () => {
        const { graphQLClient } = await getGraphqlClient(getToken);
        const getSettingsQuery = gql`
            query settings {
                settings {
                    country
                    currency
                    vatRates
                }
            }
        `;

        const data = await graphQLClient.request<SettingsQueryResponse>(getSettingsQuery);

        return data;
    };
