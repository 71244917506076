import { useNavigate } from 'react-router-dom';
import { routeConst } from '../constants';
import { TabNameEnum } from '../pages/OrderDetails/TabNameEnum';

export const useRedirect = () => {
    const navigate = useNavigate();

    const toOrders = () => {
        navigate(routeConst.order);
    };

    const toOrderDetails = (orderId: string, tabName?: TabNameEnum) => {
        navigate(routeConst.orderDetails(orderId, tabName));
    };

    return {
        toOrders,
        toOrderDetails,
    };
};
