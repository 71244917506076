import { BreadcrumbItem, Breadcrumbs, makeStyles } from '@akelius-con/react-ui-kit-components';
import { Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { routeConst } from 'modules/order/constants';
import { ICreateAdminOrderParam, ICreateAdminOrderResponse, createAdminOrder } from 'modules/order/graphql/mutations/createAdminOrder';
import {
    ICreateConstructionOrderParam,
    ICreateConstructionOrderResponse,
    createConstructionOrder,
} from 'modules/order/graphql/mutations/createConstructionOrder';
import CreateOrderForm from 'modules/order/pages/CreateOrder/components/CreateOrderForm';
import { HandleSubmitType } from 'modules/order/pages/CreateOrder/components/CreateOrderForm/types';
import { OrderTypesEnum } from 'modules/order/types';
import { useGetUser } from 'modules/order/utils/useGetUser';
import { useRedirect } from 'modules/order/utils/useRedirect';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { snackbar } from 'shared/components/Snackbar';
import { delay } from 'shared/constant';
import createOrder, { ICreateOrderParam, ICreateOrderResponse } from '../../graphql/mutations/createOrder';

const useStyles = makeStyles()({
    pageWrapper: {
        marginTop: '20px',
        width: '100%',
    },
    title: {
        marginTop: '20px',
    },
    noUnderline: {
        textDecoration: 'none',
    },
});

const CreateOrder: FC = () => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const redirect = useRedirect();
    const { title: userTitle, phoneNumber: userPhone, email: userEmail, fullName: userFullName, getToken } = useGetUser();
    const [isLoading, setIsLoading] = useState(false);

    const onError = useCallback(() => {
        setIsLoading(false);
        snackbar.error(t('purchase-order.snackbar.order-creation-fail'));
    }, [t]);

    const onSuccess = useCallback(
        (id: string) => {
            snackbar.success(t('purchase-order.snackbar.order-creation-successful'));

            setTimeout(() => {
                setIsLoading(false);
                redirect.toOrderDetails(id);
            }, delay.redirectDelay);
        },
        [redirect, t],
    );

    const createOrderMutation = useMutation(createOrder(), {
        onSuccess: ({ createOrder }: ICreateOrderResponse) => onSuccess(createOrder.id),
        onError,
    });

    const createAdminOrderMutation = useMutation(createAdminOrder(), {
        onSuccess: ({ createAdministrativeOrder }: ICreateAdminOrderResponse) => onSuccess(createAdministrativeOrder.id),
        onError,
    });

    const createConstructionOrderMutation = useMutation(createConstructionOrder(), {
        onSuccess: ({ createProjectOrder }: ICreateConstructionOrderResponse) => onSuccess(createProjectOrder.id),
        onError,
    });

    const handleCreateOrder: HandleSubmitType = params => {
        const { title, supplierId, propertyId, projectId, apartmentId, costCenterType, companyId, costCenter } = params;

        setIsLoading(true);

        const contactForSupplier = {
            email: userEmail,
            name: userFullName,
            phone: userPhone,
            title: userTitle,
        };

        if (costCenterType === OrderTypesEnum.PROPERTY) {
            const createOrderPayload: ICreateOrderParam = {
                getToken,
                input: { title, supplierId, apartmentId, propertyId, contactForSupplier },
            };
            createOrderMutation.mutate(createOrderPayload);
        } else if (costCenterType === OrderTypesEnum.CONSTRUCTION) {
            const createOrderPayload: ICreateConstructionOrderParam = {
                getToken,
                input: { title, supplierId, projectId, contactForSupplier },
            };
            createConstructionOrderMutation.mutate(createOrderPayload);
        } else {
            const createAdminOrderPayload: ICreateAdminOrderParam = {
                getToken,
                input: { supplierId, companyId, costCenter, title, contactForSupplier },
            };
            createAdminOrderMutation.mutate(createAdminOrderPayload);
        }
    };

    return (
        <div className={classes.pageWrapper}>
            <Breadcrumbs>
                <BreadcrumbItem label={t('dashboard.order-list-page-title')} href={routeConst.order} />
            </Breadcrumbs>

            <Typography className={classes.title} data-testid="title" variant="h1">
                {t('dashboard.create-purchase-order')}
            </Typography>

            <CreateOrderForm handleOrderSubmit={handleCreateOrder} isLoading={isLoading} />
        </div>
    );
};

export default CreateOrder;
