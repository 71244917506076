import { AutocompleteItem } from '@akelius-con/react-ui-kit-components';
import { UserForProperty } from 'modules/order/graphql/queries/getResponsiblePersons';

const mapPropertyToAutoCompleteItem = (user: UserForProperty): AutocompleteItem => ({
    label: `${user.name}, ${user.role}`,
    value: user.email,
    name: user.name,
    role: user.role || '',
    phone: user.phone || '',
});

export default mapPropertyToAutoCompleteItem;
