const PREFIX = 'FeatureToggle';

export enum FeatureToggleKeys {
    'ExampleForTests' = 'ExampleForTests',
}

export interface FeatureToggle {
    byKey?: FeatureToggleKeys;
    byDebugMode?: boolean;
}

export function getFeatureToggleState({ byDebugMode, byKey }: FeatureToggle = {}): boolean {
    const isDebugMode = import.meta.env.VITE_APP_SHOW_DEBUG_INFO === 'true';

    if (byKey && localStorage.getItem(`${PREFIX}.${byKey}`) === 'true') {
        return true;
    }

    if (byDebugMode === true) {
        return isDebugMode;
    }

    return false;
}

export function setFeatureToggleState(key: FeatureToggleKeys, state: boolean): void {
    localStorage.setItem(`${PREFIX}.${key}`, state ? 'true' : 'false');
}
