import { colors } from '@akelius-con/react-theme';
import { makeStyles } from '@akelius-con/react-ui-kit-components';
import { AkCostCenter } from '@akelius-con/react-ui-kit-icons';
import { Box, Typography } from '@mui/material';
import { Company, CompanyCostCenter } from 'modules/order/graphql/queries/getCompany';
import { useTranslation } from 'react-i18next';

type Props = {
    company: Company;
    costCenterId: string;
};

const useStyles = makeStyles()({
    title: {
        marginBottom: '16px',
    },
    cardWrapper: {
        marginTop: '24px',
        height: 'calc(100% - 24px)',
        display: 'flex',
        flexDirection: 'column',
    },
    propertySubInfo: {
        display: 'flex',
        paddingBottom: '16px',
        '& .MuiSvgIcon-root': {
            paddingRight: '10px',
            fontSize: '24px',
            color: colors.black,
        },
    },
    contentWrapper: {
        position: 'relative',
        boxSizing: 'border-box',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${colors.lineGrey}`,
        padding: `24px 16px`,
        transition: 'border-color 200ms',
    },
});

export const findConstCenter = (costCenters: CompanyCostCenter[], costCenterId: string) => {
    return costCenters.find(c => c.code === costCenterId);
};

const AdminCostCenterCard = (props: Props) => {
    const { company, costCenterId } = props;
    const { classes } = useStyles();
    const { t } = useTranslation();

    const { name, code, costCenters } = company;

    const costCenter = findConstCenter(costCenters, costCenterId);

    return (
        <div className={classes.cardWrapper}>
            <Typography className={classes.title} variant="h2">
                {t('purchase-order.general-info.carrier-of-cost')}
            </Typography>

            <div className={classes.contentWrapper}>
                <Box>
                    <Typography variant="body1">
                        {code} - {name}
                    </Typography>
                </Box>

                {costCenter && (
                    <Box className={classes.propertySubInfo} mt={2}>
                        <AkCostCenter />
                        <div>
                            <Typography variant="body1">{costCenter.name}</Typography>
                            <Typography variant="body1">{costCenter.code}</Typography>
                        </div>
                    </Box>
                )}
            </div>
        </div>
    );
};

export default AdminCostCenterCard;
