import { commaToDotSeparator } from './numberConverters';

export const commaDecimalTransformer = (_o: unknown, value: string): number | string => {
    const regex = /^\d+([,.]\d+)?$/;
    const isNumber = regex.test(value);
    if (!isNumber) {
        return value;
    }

    return parseFloat(commaToDotSeparator(value));
};

export const thousandSeparatorTransformer = (_o: unknown, value: string): number | string => {
    const regex = /(^\d{1,3}(,\d{3})*)([.]\d{1,2})?$/;
    const isValidNumber = regex.test(value);

    if (!isValidNumber) {
        return value;
    }

    return parseFloat(value);
};

export const priceCustomValidator = (val: number | undefined | string): boolean => {
    if (!val) return false;
    const regex = /^\d+(\.\d{1,2})?$/;
    return regex.test(val.toString());
};

export const muiYupDateTransformer = (_v: string, originalValue: string) => {
    if (!originalValue) {
        return originalValue;
    }

    return new Date(originalValue);
};
