import { MessageBox } from '@akelius-con/react-ui-kit-components';
import { useQuery } from '@tanstack/react-query';
import { cacheKeys } from 'shared/constant';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CacheConst } from 'shared/constant';
import { SupplierQueryResponse, getSupplier } from 'shared/graphql/queries/getSupplier';
import { Supplier } from 'shared/graphql/queries/getSuppliers';
import SuppliersDropdown, { getSupplierOptionFromSupplier } from '../SuppliersDropdown';
import { useGetUser } from 'modules/order/utils/useGetUser';

export interface IOnSupplierDropdownChange {
    (supplier: Supplier | null): void;
}

interface IProps {
    supplierId: string | null;
    onChange: IOnSupplierDropdownChange;
}

export const SupplierDropdownProvider: FC<IProps> = props => {
    const { t } = useTranslation();
    const { supplierId, onChange } = props;
    const { getToken } = useGetUser();
    const cacheKey = cacheKeys.supplier(supplierId);
    const [selectedSupplier, setSelectedSupplier] = useState<Supplier | null>(null);

    /**
     * @description get the full version of supplier
     * */
    const { error, refetch } = useQuery<SupplierQueryResponse>(cacheKey, getSupplier({ getToken, supplierId }), {
        retry: false,
        enabled: false,
        staleTime: CacheConst.supplier,
        onSuccess: data => {
            setSelectedSupplier(data.supplier);
        },
    });

    const onChangeSupplierHandler: IOnSupplierDropdownChange = useCallback(
        supplier => {
            if (supplier) {
                onChange(supplier);
                setSelectedSupplier(supplier);
                return;
            }

            setSelectedSupplier(null);
            onChange(null);
        },
        [onChange],
    );

    useEffect(() => {
        if (supplierId) {
            refetch();
        }
    }, [refetch, supplierId]);

    return (
        <>
            {error && <MessageBox type="error">{t('purchase-order.supplier.fetch-error')}</MessageBox>}
            <SuppliersDropdown
                label={t('purchase-order.supplier')}
                value={getSupplierOptionFromSupplier(selectedSupplier)}
                onChange={onChangeSupplierHandler}
            />
        </>
    );
};
