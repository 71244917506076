import { gql } from 'graphql-request';
import { SignedUrlsMap } from 'modules/order/types';
import { GetToken } from 'modules/order/utils/useGetUser';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';

interface UploadFileType {
    filename: string;
    docType: string;
    sendToSupplier?: boolean;
    sendToApproval?: boolean;
}

export interface GetS3UploadParam {
    getToken: GetToken;
    orderId: string;
    parentType: string;
    files: UploadFileType[];
}

export interface UploadDocumentMutationRes {
    uploadDocument: SignedUrlsMap[];
}

const getS3UploadUrls = () => async (param: GetS3UploadParam) => {
    const { getToken, ...payload } = param;
    const { graphQLClient } = await getGraphqlClient(getToken);

    const uploadDocumentMutation = gql`
        mutation uploadDocument($orderId: String!, $files: [DocumentUpload]!, $parentType: String) {
            uploadDocument(orderId: $orderId, files: $files, parentType: $parentType) {
                filename
                signedUrl
                httpHeaders
            }
        }
    `;

    const data = await graphQLClient.request<UploadDocumentMutationRes>(uploadDocumentMutation, {
        ...payload,
    });

    return data;
};

export default getS3UploadUrls;
