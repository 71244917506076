import { FC } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import './styles.css';
import { ToasterTemplate } from './SnackbarTemplate';

export const snackbar = {
    success: (msg: string) => toast.success(<ToasterTemplate msg={msg} />),
    error: (msg: string) => toast.error(<ToasterTemplate msg={msg} />),
    warning: (msg: string) => toast.warning(<ToasterTemplate msg={msg} />),
    info: (msg: string) => toast.info(<ToasterTemplate msg={msg} />),
};

export const SnackbarContainer: FC = () => {
    return (
        <ToastContainer
            rtl={false}
            hideProgressBar
            closeButton={false}
            pauseOnHover={false}
            icon={false}
            autoClose={1000}
            position={toast.POSITION.BOTTOM_CENTER}
        />
    );
};
