import { colors } from '@akelius-con/react-theme';
import { Autocomplete, AutocompleteItem, makeStyles } from '@akelius-con/react-ui-kit-components';
import { OnChange } from '@akelius-con/react-ui-kit-components/Autocomplete';
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { FormikHelpers, FormikProps } from 'formik';
import { Currency, PurchasingUnit } from 'modules/order/types';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'shared/components/DatePickerWrapper';
import { FormikPriceField } from 'shared/components/FormikPriceField';
import { FormikTextField } from 'shared/components/FormikTextField';
import MandatoryFieldDisclaimer from 'shared/components/MandatoryFieldDisclaimer';
import { sharedCacheKeys } from 'shared/constant';
import { SettingsQueryResponse } from 'shared/graphql/queries/getSettings';
import { Supplier } from 'shared/graphql/queries/getSuppliers';

const useStyles = makeStyles()({
    formContainer: {
        minHeight: '500px',
    },
    alignmentFix: {
        '& .MuiFormControl-root': {
            marginTop: 0,
        },
    },
});

interface DropdownOption {
    name: string;
    value: number;
}

export interface FormInputs {
    description: string;
    quantity: string;
    price: string;
    unit: string;
    note: string;
    manufacturerPartNumber: string | null;
    supplierPartNumber: string | null;
    deliveryStartDate: Date | null;
    deliveryEndDate: Date | null;
    vat: 'INCLUDED' | 'EXCLUDED' | null;
    vatRate: string;
}

interface IProps {
    initialFormValues: FormInputs;
    supplier: Supplier;
    currency: Currency;
    closeModal: () => void;
    formik: FormikProps<FormInputs>;
    handleAddItemsSubmit: (values: FormInputs, formikHelpers: FormikHelpers<FormInputs>) => void;
}

const AddItemsToOrderForm: FC<IProps> = props => {
    const { currency, supplier, initialFormValues, formik } = props;
    const { t } = useTranslation();
    const { classes } = useStyles();
    const queryClient = useQueryClient();
    const { setFieldValue, setFieldTouched } = formik;
    const settingsData = queryClient.getQueryData<SettingsQueryResponse>(sharedCacheKeys.settings);

    const unitOptions: AutocompleteItem[] = Object.keys(PurchasingUnit).map(u => ({
        label: t(`purchase-order.item.units.${u.toLowerCase()}`),
        value: u,
    }));

    const [selectedVatRate, setSelectedVatRate] = useState<DropdownOption | null>(null);
    const [selectedUnit, setSelectedUnit] = useState<AutocompleteItem | null>(null);
    const [vatOptions, setVatOptions] = useState<DropdownOption[]>([]);
    const [deliveryStartDate, setDeliveryStartDate] = useState<Date | null>(initialFormValues.deliveryStartDate);
    const [deliveryEndDate, setDeliveryEndDate] = useState<Date | null>(initialFormValues.deliveryEndDate);

    const handleDeliveryStartDateChange = (selectedDate: Date | null) => {
        setFieldValue('deliveryStartDate', selectedDate, true);
        setDeliveryStartDate(selectedDate);
        setFieldTouched('deliveryStartDate');

        if (!deliveryEndDate || (selectedDate && selectedDate > deliveryEndDate)) {
            setFieldValue('deliveryEndDate', selectedDate, true);
            setDeliveryEndDate(selectedDate);
            setFieldTouched('deliveryEndDate');
        }
    };

    const handleDeliveryEndDateChange = (selectedDate: Date | null) => {
        setFieldValue('deliveryEndDate', selectedDate, true);
        setDeliveryEndDate(selectedDate);
        setFieldTouched('deliveryEndDate');

        if (!deliveryStartDate || (selectedDate && deliveryStartDate > selectedDate)) {
            setFieldValue('deliveryStartDate', selectedDate, true);
            setDeliveryStartDate(selectedDate);
            setFieldTouched('deliveryStartDate');
        }
    };

    const handleUnitChange: OnChange = useCallback(
        (_event, unit) => {
            setFieldValue('unit', unit?.value || '');
            setSelectedUnit(unit || null);
        },
        [setFieldValue],
    );

    const handleVatRateChange = useCallback(
        (event: SelectChangeEvent<number>): void => {
            const vatRate = event.target.value;
            setFieldValue('vatRate', vatRate);
            const findFullForm = vatOptions.find(v => v.value === vatRate);
            setSelectedVatRate(findFullForm || null);
        },
        [setFieldValue, vatOptions],
    );

    useEffect(() => {
        const vatCountry = supplier.addressDetails.country;
        const selectedCountrySettings = settingsData?.settings.find(c => c.country === vatCountry);

        if (selectedCountrySettings) {
            const vatRateOptions: DropdownOption[] = selectedCountrySettings?.vatRates.map((vatRate: number) => ({
                name: `${vatRate}%`,
                value: vatRate,
            }));

            setVatOptions(vatRateOptions);
        }
    }, [settingsData?.settings, supplier.addressDetails.country]);

    // setting unit value for edit mode
    useEffect(() => {
        const selectedUnitVal = formik.values.unit;
        if (selectedUnitVal) {
            setSelectedUnit(unitOptions.find(u => u.value === selectedUnitVal) || null);
        }
    }, [formik.values.unit, unitOptions]);

    // setting vatRate value for edit mode
    useEffect(() => {
        const selectedVatRateVal = Number(formik.values.vatRate);
        if (selectedVatRateVal && vatOptions) {
            setSelectedVatRate(vatOptions.find(u => u.value === selectedVatRateVal) || null);
        }
    }, [formik.values.vatRate, vatOptions]);

    const clearStartDate = useCallback(() => {
        setFieldValue('deliveryStartDate', null);
        setDeliveryStartDate(null);
        setDeliveryEndDate(null);
    }, [setFieldValue]);

    const clearEndDate = useCallback(() => {
        setFieldValue('deliveryEndDate', null);
        setDeliveryEndDate(null);
    }, [setFieldValue]);

    return (
        <Grid container className={classes.formContainer} spacing={3}>
            <Grid md={12} item>
                <FormikTextField required name="description" formik={formik} label={t('purchase-order.item.description')} />
            </Grid>
            <Grid md={6} item>
                <FormikTextField required name="quantity" formik={formik} label={t('purchase-order.item.quantity')} />
            </Grid>
            <Grid md={6} item>
                <Autocomplete
                    data-testid="unit-selection"
                    onChange={handleUnitChange}
                    options={unitOptions}
                    disabled={formik.isSubmitting}
                    error={!!(formik.errors.unit && formik.touched.unit)}
                    loadingText={t('common.loading-suppliers')}
                    onBlur={() => formik.setFieldTouched('unit')}
                    value={selectedUnit}
                    noOptionsText={t('common.no-matching-result')}
                    inputProps={{
                        label: `${t('purchase-order.item.unit')} *`,
                        helperText: !!(formik.errors.unit && formik.touched.unit) ? <>{t('purchase-order.item.unit-required')}</> : '',
                    }}
                />
            </Grid>
            <Grid md={6} item>
                <FormikPriceField required name="price" startAdornment={currency} formik={formik} label={t('purchase-order.item.unit-price')} />
            </Grid>

            <Grid md={6} item>
                <FormControl error={!!(formik.errors.vatRate && formik.touched.vatRate)}>
                    <InputLabel id="vatRate">{`${t('purchase-order.item.vat-rate')} *`}</InputLabel>
                    <Select
                        labelId="vatRate"
                        data-testid="vat-rate"
                        name="vatRate"
                        onBlur={() => formik.setFieldTouched('vatRate')}
                        value={selectedVatRate?.value || ''}
                        onChange={handleVatRateChange}
                        disabled={formik.isSubmitting}
                    >
                        {vatOptions.map(({ name, value }) => (
                            <MenuItem key={value} value={value}>
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                    {formik.errors.vatRate && formik.touched.vatRate && <FormHelperText>{t('purchase-order.item.vat-rate-required')}</FormHelperText>}
                </FormControl>
            </Grid>

            <Grid md={6} item data-testid="delivery-start-date">
                <DatePicker
                    formik={formik}
                    name="deliveryStartDate"
                    disablePast
                    onBlur={() => setFieldTouched('deliveryStartDate')}
                    label={t('purchase-order.item.delivery-start-date')}
                    value={deliveryStartDate}
                    onChange={handleDeliveryStartDateChange}
                    clearDate={clearStartDate}
                />
                {!!(formik.errors.deliveryStartDate && formik.touched.deliveryStartDate) && (
                    <Typography sx={{ minHeight: '24px', color: colors.error.light }} variant="body1">
                        {formik.errors.deliveryStartDate}
                    </Typography>
                )}
            </Grid>
            <Grid md={6} item data-testid="delivery-end-date">
                <DatePicker
                    formik={formik}
                    name="deliveryEndDate"
                    disablePast
                    onBlur={() => setFieldTouched('deliveryEndDate')}
                    label={t('purchase-order.item.delivery-end-date')}
                    value={deliveryEndDate}
                    onChange={handleDeliveryEndDateChange}
                    clearDate={clearEndDate}
                />

                {formik.errors.deliveryEndDate && formik.touched.deliveryEndDate && (
                    <Typography sx={{ minHeight: '24px', color: colors.error.light }} variant="body1">
                        {formik.errors.deliveryEndDate}
                    </Typography>
                )}
            </Grid>

            <Grid item md={6}>
                <FormikTextField
                    data-testid="order-supplier-part-number"
                    name="supplierPartNumber"
                    formik={formik}
                    label={t('purchase-order.item.supplier-part-number')}
                />
            </Grid>
            <Grid item md={6}>
                <FormikTextField
                    data-testid="order-manufacturer-part-number"
                    name="manufacturerPartNumber"
                    formik={formik}
                    label={t('purchase-order.item.manufacturer-part-number')}
                />
            </Grid>

            <Grid item xs={12}>
                <FormikTextField data-testid="order-note" name="note" multiline formik={formik} label={t('purchase-order.note')} />
            </Grid>

            <Grid item xs={12}>
                <MandatoryFieldDisclaimer />
            </Grid>
        </Grid>
    );
};

export default AddItemsToOrderForm;
