import { InfiniteData } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { ContactForSupplier, Creator, Currency, IdAndName, Order, PurchasingUnit } from 'modules/order/types';
import { GetToken } from 'modules/order/utils/useGetUser';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';
import { PagedResponseDto } from 'shared/types/Response.interface';
import { OrderStatusEnum } from './useGetOrder';
import { Approval } from './useGetOrderApprovalHistory';
import { IFiltersFormatted } from 'modules/order/pages/Dashboard/components/OrderFilters/Filters.interface';

export interface GetOrdersParams {
    filters?: IFiltersFormatted;
    pageSize: number;
    endCursor?: string;
    getToken: GetToken;
}

export interface OrderFeedItem {
    id?: string;
    description: string;
    creator?: IdAndName;
    createdDate?: string | null;
    updatedDate?: string | null;
    quantity: {
        value: number;
        unit: PurchasingUnit;
    };
    note: string;
}

export interface OrderFeed {
    carrierOfCost: {
        company: {
            id: string;
            name: string;
        };
        property: {
            name: string;
        };
        project: null | {
            id: string;
        };
    };
    id: string;
    orderNumber: string | null;
    supplier: {
        id: string;
        name: string;
    };
    title: string;
    status: OrderStatusEnum;
    currency: Currency;
    metadata?: string;
    items: OrderFeedItem[];
    approval?: Approval;
    price?: number;
    creator: Creator;
    createdDate: string;
    updatedDate?: string | null;
    releasedDate?: string | null;
    releaseUser?: Creator | null;
    cursor?: string;
    totalPriceCentsNet: number;
    totalPriceCentsGross: number;
    referenceNumber?: string | null;
    contactForSupplier?: ContactForSupplier;
    case?: null | {
        caseId: string;
    };
}

export type InfiniteOrdersCache = InfiniteData<PagedResponseDto<OrderFeed>>;

const getOrders = async (queryVars: GetOrdersParams) => {
    const { graphQLClient } = await getGraphqlClient(queryVars.getToken);

    const getOrdersQuery = gql`
        query orders($pageSize: Int!, $endCursor: String, $filters: OrderFilter) {
            orders(pageSize: $pageSize, endCursor: $endCursor, filter: $filters) {
                endCursor
                hasNextPage
                totalCount
                items {
                    ... on Order {
                        id
                        orderNumber
                        createdDate
                        releasedDate
                        status
                        title
                        currency
                        referenceNumber
                        updatedDate
                        totalPriceCentsNet
                        totalPriceCentsGross
                        supplier {
                            id
                            name
                        }
                        creator {
                            id
                            name
                        }
                        releaseUser {
                            id
                            name
                        }
                        case {
                            caseId
                        }
                        carrierOfCost {
                            property {
                                name
                            }
                            company {
                                id
                                name
                            }
                            project {
                                id
                            }
                        }
                        items {
                            ... on OrderItem {
                                id
                                description
                                quantity {
                                    value
                                    unit
                                }
                                creator {
                                    id
                                    name
                                }
                                note
                                createdDate
                                updatedDate
                            }
                        }
                    }
                }
            }
        }
    `;

    const data = await graphQLClient.request<PagedResponseDto<Order>>(getOrdersQuery, {
        ...queryVars,
    });

    return data;
};

export default getOrders;
