import { Button, DialogTitle, MessageBox, makeStyles } from '@akelius-con/react-ui-kit-components';
import { AkPlus } from '@akelius-con/react-ui-kit-icons';
import { Box, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { cacheKeys } from 'modules/order/constants';
import deleteDocument, { IDeleteOrderParam } from 'modules/order/graphql/mutations/deleteDocument';
import editDocument, { QueryVars as EditDocumentQueryVars } from 'modules/order/graphql/mutations/editDocument';
import isAttachmentMaxSizeOver from 'modules/order/utils/isAttachmentMaxSizeOver';
import { useGetUser } from 'modules/order/utils/useGetUser';
import { FC, useEffect, useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Dropzone } from 'shared/components';
import FileTypeIcon from 'shared/components/FileTypeIcon';
import { snackbar } from 'shared/components/Snackbar';
import { SingleItemResponseDto } from 'shared/types/Response.interface';
import { isOrderReadonlyByStatus } from 'shared/utils/order';
import DeleteDocumentConfirm from '../../../../shared/components/DeleteDocumentConfirm';
import getS3DownloadUrl, { IDownloadDocumentRes } from '../../graphql/mutations/getS3DownloadUrl';
import getS3UploadUrls, { GetS3UploadParam, UploadDocumentMutationRes } from '../../graphql/mutations/getS3UploadUrls';
import { Order, OrderDocument, SignedUrlsMap } from '../../types';
import OrderDocumentsTable from '../OrderDocumentsTable';
import UploadAttachmentsInfoForm, { FormInputs, IUploadAttachmentSubmit } from '../UploadAttachmentsInfoForm';

interface IProps {
    order: Order;
}

const useStyles = makeStyles()({
    tabTitle: {
        margin: '12px 0 16px !important',
    },
});

const OrderAttachmentsTab: FC<IProps> = ({ order }) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const queryClient = useQueryClient();
    const { givenName, familyName } = useGetUser();
    const { getToken } = useGetUser();
    const { status, documents, id: orderId } = order;
    const cacheKey = cacheKeys.order(orderId);

    const TIME_TO_WAIT_AFTER_FILE_UPLOAD = 10000;

    const [uploadedDocuments, setUploadedDocuments] = useState<OrderDocument[]>(documents);

    useEffect(() => {
        setUploadedDocuments(documents);
    }, [documents]);

    const [dropzoneModalOpen, setDropzoneModalOpen] = useState<boolean>(false);
    const [isUploadProgress, setIsUploadProgress] = useState<boolean>(false);

    const initialFormValues = {
        docType: 'OTHER',
        sendToSupplier: 'YES',
        sendToApproval: 'YES',
    };

    const [filesFormData, setFilesFormData] = useState<FormInputs>(initialFormValues);
    const [filesToUpload, setFilesToUpload] = useState<FileWithPath[]>([]);

    const closeDropzoneModal = () => {
        setDocumentIdToEdit(null);
        setFilesFormData(initialFormValues);
        setDropzoneModalOpen(false);
    };
    const openDropzoneModal = () => {
        setFilesToUpload([]);
        setDropzoneModalOpen(true);
    };

    const uploadFileToS3 = (signedUrlsMap: SignedUrlsMap[]) => {
        if (signedUrlsMap && signedUrlsMap.length) {
            const promises: Promise<unknown>[] = [];
            const allUploadedDocuments = uploadedDocuments;
            signedUrlsMap.forEach(({ filename, signedUrl, httpHeaders }: SignedUrlsMap) => {
                const file = filesToUpload.find(i => i.name === filename);
                if (file) {
                    const headers: Record<string, string> = httpHeaders.reduce((acc: Record<string, string>, i: string) => {
                        const [key, val] = i.split('=');
                        acc[key] = val;
                        return acc;
                    }, {});

                    promises.push(
                        axios
                            .put(signedUrl, file, {
                                headers: { ...headers, 'Content-Type': '' },
                            })
                            .then(async () => {
                                const { name: fileTitle, size: sizeInBytes } = file;

                                allUploadedDocuments.unshift({
                                    fileTitle,
                                    sizeInBytes,
                                    docType: filesFormData.docType,
                                    createdDate: new Date().toString(),
                                    creatorName: `${givenName} ${familyName}`,
                                    sendToSupplier: filesFormData.sendToSupplier === 'NO' ? false : true,
                                    sendToApproval: filesFormData.sendToApproval === 'NO' ? false : true,
                                });
                            }),
                    );
                }
            });
            Promise.all(promises)
                .then(() => {
                    setUploadedDocuments([...allUploadedDocuments]);
                    closeDropzoneModal();
                    setIsUploadProgress(false);

                    const updatedData = {
                        order: {
                            ...order,
                            documents: allUploadedDocuments,
                        },
                    };

                    queryClient.setQueryData(cacheKey, updatedData);
                    snackbar.success(t('purchase-order.snackbar.file-upload-successful'));

                    setTimeout(() => {
                        (async () => {
                            await queryClient.refetchQueries(cacheKey);
                        })();
                    }, TIME_TO_WAIT_AFTER_FILE_UPLOAD);
                })
                .catch(() => {
                    snackbar.error(t('purchase-order.snackbar.file-upload-failure'));
                    handleUploadFailure();
                });
        }
    };

    const getS3UploadUrlsMutation = useMutation(cacheKey, getS3UploadUrls(), {
        onSuccess: (response: UploadDocumentMutationRes) => {
            if (response) {
                const { uploadDocument } = response;
                uploadFileToS3(uploadDocument);
            }
        },
        onError: () => {
            handleUploadFailure();
        },
    });

    const handleAddFilesToUpload = (files: FileWithPath[]) => {
        if (files) {
            const filesList = Object.entries(files).map(([, val]) => val);
            setFilesToUpload(filesList);
        }
    };

    const handleUploadFailure = () => {
        closeDropzoneModal();
        setIsUploadProgress(false);
        snackbar.error(t('purchase-order.snackbar.file-upload-failure'));
    };

    const handleUpload = () => {
        setIsUploadProgress(true);

        const queryVars: GetS3UploadParam = {
            getToken,
            orderId,
            parentType: 'order',
            files: filesToUpload.map(file => ({
                filename: file.name,
                docType: filesFormData.docType,
                sendToSupplier: filesFormData.sendToSupplier === 'NO' ? false : true,
                sendToApproval: filesFormData.sendToApproval === 'NO' ? false : true,
            })),
        };
        getS3UploadUrlsMutation.mutate(queryVars);
    };

    const handleUploadAttachmentSubmit: IUploadAttachmentSubmit = data => {
        setFilesFormData(data);
    };

    const getS3DownloadUrlMutation = useMutation(cacheKey, getS3DownloadUrl(), {
        onSuccess: (response: IDownloadDocumentRes) => {
            if (response) {
                const {
                    downloadDocument: { signedUrl },
                } = response;

                window.open(signedUrl, '_blank');

                snackbar.success(t('purchase-order.snackbar.file-download-successful'));
            }
        },
        onError: () => {
            snackbar.error(t('purchase-order.snackbar.file-download-failure'));
        },
    });

    const handleDocumentDownload = (documentId: string) => {
        if (documentId) {
            getS3DownloadUrlMutation.mutate({ getToken, documentId });
        }
    };

    const isAddDisabled = () => filesToUpload.length === 0;

    const [documentIdToDelete, setDocumentIdToDelete] = useState<string | null>(null);
    const [openDeleteDocumentConfirm, setOpenDeleteDocumentConfirm] = useState<boolean>(false);
    const [isDeleteDocumentProgress, setIsDeleteDocumentProgress] = useState<boolean>(false);

    const closeDeleteDocumentConfirm = () => setOpenDeleteDocumentConfirm(false);

    const initDeleteDocument = (documentId: string) => {
        if (documentId) {
            setDocumentIdToDelete(documentId);
            setOpenDeleteDocumentConfirm(true);
        }
    };

    const deleteDocumentMutation = useMutation(cacheKey, deleteDocument(), {
        onSuccess: () => {
            const oldData: SingleItemResponseDto<Order> | undefined = queryClient.getQueryData(cacheKey);
            if (oldData?.order) {
                const { order } = oldData;

                const updatedData = {
                    order: {
                        ...order,
                        documents: order.documents.filter((doc: OrderDocument) => doc.id !== documentIdToDelete),
                    },
                };

                queryClient.setQueryData(cacheKey, updatedData);
            }
            snackbar.success(t('purchase-order.snackbar.document-delete-successful'));
            setIsDeleteDocumentProgress(false);
            setDocumentIdToDelete(null);
        },
        onError: () => {
            snackbar.error(t('purchase-order.snackbar.document-delete-failed'));
            setIsDeleteDocumentProgress(false);
            setDocumentIdToDelete(null);
        },
    });

    const handleDeleteDocument = () => {
        if (!documentIdToDelete) return false;
        setIsDeleteDocumentProgress(true);
        const queryVars: IDeleteOrderParam = { getToken, documentId: documentIdToDelete };
        deleteDocumentMutation.mutate(queryVars);
    };

    const [documentIdToEdit, setDocumentIdToEdit] = useState<string | null>(null);
    const [documentTitleToEdit, setDocumentTitleToEdit] = useState<string | null>(null);
    const [isEditDocumentProgress, setIsEditDocumentProgress] = useState<boolean>(false);
    const initEditDocument = (document: OrderDocument) => {
        if (document.id) {
            const { id, fileTitle, docType, sendToSupplier, sendToApproval } = document;
            setDocumentIdToEdit(id);
            setDocumentTitleToEdit(fileTitle);
            setFilesFormData({
                docType,
                sendToSupplier: sendToSupplier ? 'YES' : 'NO',
                sendToApproval: sendToApproval ? 'YES' : 'NO',
            });
            openDropzoneModal();
        }
    };

    const editDocumentMutation = useMutation(cacheKey, editDocument(), {
        onSuccess: async () => {
            const updatedDocuments = uploadedDocuments.map((doc: OrderDocument) => {
                if (doc.id === documentIdToEdit) {
                    return {
                        id: documentIdToEdit,
                        fileTitle: documentTitleToEdit as string,
                        docType: filesFormData.docType,
                        createdDate: new Date().toString(),
                        creatorName: `${givenName} ${familyName}`,
                        sendToSupplier: filesFormData.sendToSupplier === 'NO' ? false : true,
                        sendToApproval: filesFormData.sendToApproval === 'NO' ? false : true,
                    };
                }
                return doc;
            });
            setUploadedDocuments([...updatedDocuments]);
            closeDropzoneModal();
            setIsUploadProgress(false);
            snackbar.success(t('purchase-order.snackbar.document-update-successful'));

            setTimeout(() => {
                (async () => {
                    await queryClient.refetchQueries(cacheKey);
                })();
            }, TIME_TO_WAIT_AFTER_FILE_UPLOAD);

            setIsEditDocumentProgress(false);
            setDocumentIdToEdit(null);
            setFilesFormData(initialFormValues);
        },
        onError: () => {
            closeDropzoneModal();
            snackbar.error(t('purchase-order.snackbar.document-update-failed'));
            setIsEditDocumentProgress(false);
            setDocumentIdToEdit(null);
            setFilesFormData(initialFormValues);
        },
    });

    const handleEditDocument = () => {
        if (!documentIdToEdit) return false;
        setIsEditDocumentProgress(true);

        const queryVars: EditDocumentQueryVars = {
            getToken,
            documentId: documentIdToEdit,
            input: {
                fileTitle: documentTitleToEdit as string,
                docType: filesFormData.docType,
                sendToSupplier: filesFormData.sendToSupplier === 'NO' ? false : true,
                sendToApproval: filesFormData.sendToApproval === 'NO' ? false : true,
            },
        };
        editDocumentMutation.mutate(queryVars);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography className={classes.tabTitle} variant="h2">
                    {t('purchase-order.attachment')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Button
                    data-testid="add-attachment-button"
                    label={t('purchase-order.attachment')}
                    variant="outlined"
                    startIcon={<AkPlus />}
                    onClick={openDropzoneModal}
                    disabled={isOrderReadonlyByStatus(status)}
                />
            </Grid>

            {isAttachmentMaxSizeOver(uploadedDocuments) && (
                <Grid item xs={12}>
                    <Box m={3} />
                    <MessageBox type="warning">
                        <Typography data-testid="attachment-size-warning" variant="body1">
                            {t('purchase-order.add-attachment.size-warning')}
                        </Typography>
                    </MessageBox>
                </Grid>
            )}

            <Grid item xs={12}>
                <Box m={3} />
                <OrderDocumentsTable
                    hasNotice={!!isAttachmentMaxSizeOver(uploadedDocuments)}
                    isReadonlyOrder={isOrderReadonlyByStatus(status)}
                    documents={uploadedDocuments}
                    isLoading={isUploadProgress || isDeleteDocumentProgress || isEditDocumentProgress}
                    onDocumentDownload={handleDocumentDownload}
                    onDocumentEdit={initEditDocument}
                    onDocumentRemove={initDeleteDocument}
                />
            </Grid>

            <Dialog maxWidth="md" fullWidth data-testid="add-edit-attachment-dialog" open={!!dropzoneModalOpen} onClose={closeDropzoneModal}>
                <DialogTitle
                    data-testid="add-edit-attachment-dialog-title"
                    onClose={closeDropzoneModal}
                    title={documentIdToEdit ? t('purchase-order.edit-attachment') : t('purchase-order.add-attachment')}
                />
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <div data-testid="upload">
                                {documentIdToEdit && documentTitleToEdit ? (
                                    <Typography variant="body1" data-testid="edit-document-info">
                                        <Grid container spacing={1}>
                                            <Grid item>
                                                <FileTypeIcon filename={documentTitleToEdit} />
                                            </Grid>
                                            <Grid item>{documentTitleToEdit}</Grid>
                                        </Grid>
                                    </Typography>
                                ) : (
                                    <Dropzone data-testid="attachment-dropzone" onAddFilesToUpload={handleAddFilesToUpload} />
                                )}
                            </div>
                            <UploadAttachmentsInfoForm
                                disabled={isUploadProgress || isEditDocumentProgress}
                                handleUploadAttachmentSubmit={handleUploadAttachmentSubmit}
                                initialValues={filesFormData}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" label={t('common.cancel')} onClick={closeDropzoneModal} data-testid="close-attachment-dialog" />
                    <Button
                        variant="contained"
                        data-testid="save-attachment-button"
                        label={documentIdToEdit ? t('purchase-order.edit') : t('purchase-order.add')}
                        onClick={documentIdToEdit ? handleEditDocument : handleUpload}
                        disabled={!documentIdToEdit && isAddDisabled()}
                        color="primary"
                        isLoading={isUploadProgress || isEditDocumentProgress}
                    />
                </DialogActions>
            </Dialog>

            {documentIdToDelete && (
                <DeleteDocumentConfirm open={openDeleteDocumentConfirm} onClose={closeDeleteDocumentConfirm} onDeleteConfirm={handleDeleteDocument} />
            )}
        </Grid>
    );
};

export default OrderAttachmentsTab;
