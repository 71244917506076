import { colors, theme } from '@akelius-con/react-theme';
import { MessageBox, makeStyles } from '@akelius-con/react-ui-kit-components';
import { Box, Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FormikProps } from 'formik';
import { cacheKeys } from 'modules/order/constants';
import { useGetUser } from 'modules/order/utils/useGetUser';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikTextField } from 'shared/components/FormikTextField';
import SuppliersDropdown, { getSupplierOptionFromSupplier } from 'shared/components/SuppliersDropdown';
import DESIGN_CONSTANT from 'shared/designConstant';
import { Project } from 'shared/graphql/queries/getProject';
import { getSupplier } from 'shared/graphql/queries/getSupplier';
import { Supplier } from 'shared/graphql/queries/getSuppliers';
import OrderCreationSupplierCard from '../../../../components/OrderCreationSupplierCard';
import { ProjectCard } from '../../../../components/ProjectCard';

const useStyles = makeStyles()({
    orderDetailsBody: {
        maxWidth: DESIGN_CONSTANT.formContentWidth,
    },
    contentWrapper: {
        maxHeight: 'calc(100vh - 270px)',
        overflow: 'auto',
        overflowX: 'hidden',
    },
    containerWrapper: {
        maxWidth: DESIGN_CONSTANT.formContentWidth,
        padding: '12px 0',
    },
    alignRight: {
        textAlign: 'right',
    },
    linkIcon: {
        color: `${colors.black} !important`,
    },
    removeUnderline: {
        textDecoration: 'none !important',
    },
});

export interface FormValues {
    title: string;
    supplierId: string;
}

interface Props {
    projectDetails?: Project;
    formik: FormikProps<FormValues>;
}

export const ConstructionOrderContent = (props: Props) => {
    const { t } = useTranslation();
    const { formik, projectDetails } = props;
    const { classes } = useStyles();
    const { setFieldTouched, setFieldValue, values } = formik;
    const { getToken } = useGetUser();
    const [selectedSupplier, setSelectedSupplier] = useState<Supplier | null>(null);

    const onChangeSupplier = (supplier: Supplier | null) => {
        setFieldTouched('supplierId');
        setFieldValue('supplierId', supplier?.id);
        setSelectedSupplier(supplier);
        setTimeout(function () {
            fetchSupplierInfo();
        });
    };

    const {
        error: supplierError,
        data: supplierInfo,
        isLoading: supplierInfoLoading,
        refetch: fetchSupplierInfo,
    } = useQuery(cacheKeys.createOrder.supplier, getSupplier({ supplierId: values.supplierId || null, getToken }), {
        retry: false,
        enabled: false,
    });

    return (
        <div className={classes.contentWrapper}>
            <form onSubmit={formik.handleSubmit} className={classes.orderDetailsBody} data-testid="construction-order-form">
                <div className={classes.containerWrapper}>
                    <Grid container spacing={3}>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormikTextField
                                preventDisableOnSubmit
                                name="title"
                                data-testid="order-title"
                                label={`${t('purchase-order.general-info.title')}*`}
                                formik={formik}
                            />
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <SuppliersDropdown
                                required
                                label={t('purchase-order.supplier')}
                                value={getSupplierOptionFromSupplier(selectedSupplier)}
                                formik={formik}
                                onChange={onChangeSupplier}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            {projectDetails && (
                                <ProjectCard
                                    hideEdit
                                    profitCenter={projectDetails.costCenter}
                                    disabled
                                    property={projectDetails.property}
                                    project={{
                                        ...projectDetails,
                                        id: projectDetails.projectId,
                                    }}
                                />
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            <>
                                {supplierError && (
                                    <>
                                        <MessageBox type="error">{t('purchase-order.supplier.fetch-error')}</MessageBox>
                                        <Box height={theme.spacing(2)} />
                                    </>
                                )}

                                {values.supplierId && supplierInfo && (
                                    <OrderCreationSupplierCard isLoadingSupplier={supplierInfoLoading} supplier={supplierInfo.supplier} />
                                )}
                            </>
                        </Grid>
                    </Grid>
                </div>
            </form>
        </div>
    );
};
