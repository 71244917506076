import { gql } from 'graphql-request';
import { InvoiceList } from 'modules/order/types';
import { GetToken } from 'modules/order/utils/useGetUser';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';

export interface IGetInvoiceResponse {
    invoices: InvoiceList;
}

export interface OrderNumber {
    getToken: GetToken;
    orderNumber: string | null;
}

export const getInvoices = (param: OrderNumber) => async () => {
    const { getToken, ...queryVars } = param;
    const { graphQLClient } = await getGraphqlClient(getToken);

    const getInvoicesQuery = gql`
        query invoices($orderNumber: String!) {
            invoices(orderNumber: $orderNumber) {
                grossTotal
                currency
                invoices {
                    id
                    number
                    currency
                    grossTotal
                    invoiceDate
                    dueDate
                    status
                }
            }
        }
    `;

    const data = await graphQLClient.request<IGetInvoiceResponse>(getInvoicesQuery, {
        ...queryVars,
    });

    return data;
};
