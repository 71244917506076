import i18n, { InitOptions, TFunction } from 'i18next';
import i18nBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import noop from 'lodash.noop';
import * as Yup from 'yup';
import * as Sentry from '@sentry/react';

declare module 'i18next' {
    interface CustomTypeOptions {
        returnNull: false;
    }
}

export const languages: { [key: string]: string } = {
    en: 'English',
    en_UK: 'English, United Kingdom',
    de: 'Deutsch',
    da: 'dansk',
    fr: 'français',
    fr_CA: 'français, Canada',
    sv: 'svenska',
};

const AVAILABLE_LANGUAGE_KEYS = Object.keys(languages);
const LANGUAGE_LOCAL_STORAGE_KEY = 'lang';
const FALLBACK_LANGUAGE = 'en';

function getPossibleLanguage(languageCode: string): string | null {
    const [code, region] = languageCode.split(/[_-]/);

    const lowCode = code.toLowerCase();

    if (region) {
        const upperRegion = region.toUpperCase().replace('GB', 'UK');
        const codeRegion = `${lowCode}_${upperRegion}`;

        if (AVAILABLE_LANGUAGE_KEYS.includes(codeRegion)) {
            return codeRegion;
        }
    }

    if (code) {
        if (AVAILABLE_LANGUAGE_KEYS.includes(lowCode)) {
            return lowCode;
        }
    }

    return null;
}

function getLanguageFromLocalStorage(): string | null {
    const language = localStorage.getItem(LANGUAGE_LOCAL_STORAGE_KEY);

    if (language) {
        const possibleLanguage = getPossibleLanguage(language);

        if (possibleLanguage) {
            return possibleLanguage;
        }
    }

    return null;
}

function getLanguageFromNavigator(): string | null {
    const navigatorLanguages = navigator.languages || [navigator.language];
    const preferredLanguage = navigatorLanguages.find(codeRegion => {
        return getPossibleLanguage(codeRegion);
    });

    if (preferredLanguage) {
        return getPossibleLanguage(preferredLanguage);
    }

    return null;
}

export function getPreferredLanguage(): string {
    const languageFromLocalStorage = getLanguageFromLocalStorage();
    if (languageFromLocalStorage) {
        return languageFromLocalStorage;
    }

    const preferredLanguage = getLanguageFromNavigator();
    if (preferredLanguage) {
        return preferredLanguage;
    }

    return FALLBACK_LANGUAGE;
}

function setHtmlLang(language: string) {
    document.documentElement.lang = language.replace('_', '-').replace('UK', 'GB');
}

export function setLanguage(language: string) {
    const possibleLanguage = getPossibleLanguage(language);
    const selectedLanguage = possibleLanguage || FALLBACK_LANGUAGE;

    localStorage.setItem(LANGUAGE_LOCAL_STORAGE_KEY, selectedLanguage);

    setHtmlLang(selectedLanguage);

    setTimeout(() => {
        i18n.changeLanguage(selectedLanguage).then(noop);
    });
}

export function setUpLanguage() {
    const language = getPreferredLanguage();
    setLanguage(language);
}

// Parameter store does not support double curly braces,
// using a colon for interpolation instead
const backendUrl = (import.meta.env.VITE_APP_TRANSLATION_BACKEND_URL || '').replace(':lng', '{{lng}}');

/**
 * passes i18n down to react-i18next
 */

export const buildYupLocale = (_: unknown, t: TFunction) => {
    Yup.setLocale({
        date: {
            min: t('common.validation-msg.yup.min-date-error'),
            max: t('common.validation-msg.yup.max-date-error'),
        },
        mixed: {
            default: t('common.validation-msg.yup.default'),
            required: t('common.validation-msg.yup.required'),
        },
        string: {
            max: t('common.validation-msg.yup.string.max'),
            min: t('common.validation-msg.yup.string.min'),
        },
        number: {
            moreThan: t('common.validation-msg.yup.number.more-than'),
            positive: t('common.validation-msg.yup.positive'),
        },
    });
};

export const checkMissingTranslations = () => {
    const languageKeys = Object.keys(languages);

    const style = (color: string = 'black') => `color: ${color}; font-weight: bold; padding: 0.5em; background-color: white;`;

    console.group('%ci18n', style());
    console.log(`%cLanguages: ${Object.values(languages).join(', ')}`, style());
    console.log(`%cLoading dictionaries...`, style());

    i18n.reloadResources(languageKeys).then(() => {
        const missingTranslations: {
            [key: string]: string[];
        } = {};

        languageKeys.forEach(languageKey => {
            const translations = i18n.getResourceBundle(languageKey, 'translation');

            Object.entries(translations).forEach(([key, value]) => {
                if (!value) {
                    const currentValue = missingTranslations[key] ?? [];
                    missingTranslations[key] = [...currentValue, languages[languageKey]];
                }
            });
        });

        if (Object.keys(missingTranslations).length > 0) {
            console.log('%cTranslations are missing for the following keys', style('red'));
            console.table(missingTranslations);
        } else {
            console.log('%cThere are no missing translations.', style('green'));
        }

        console.groupEnd();
    });
};

let i18nInitOptions: InitOptions = {
    backend: {
        loadPath: backendUrl,
    },
    returnNull: false,
    lng: localStorage.getItem('lang') || 'en',
    fallbackLng: 'en',
    nsSeparator: false, // Allow keys having '.'
    keySeparator: false, // Allow keys having '.'
    debug: import.meta.env.VITE_APP_TRAN_DEBUG === 'true',
    saveMissing: false,
    interpolation: {
        escapeValue: false, // react already safes from xss
    },
};

if (import.meta.env.VITE_APP_SENTRY_DSN) {
    const missingKeys = new Set<string>();

    i18nInitOptions = {
        ...i18nInitOptions,
        saveMissing: true,
        missingKeyHandler(_langulages, _ns, key) {
            if (!missingKeys.has(key)) {
                missingKeys.add(key);

                Sentry.captureMessage(`Missing translation key: ${key}`, {
                    tags: {
                        environment: import.meta.env.VITE_APP_ENVIRONMENT || 'testing',
                    },
                });
            }
        },
    };
}

i18n.use(i18nBackend).use(initReactI18next).init(i18nInitOptions, buildYupLocale);

export default i18n;
