import { AutocompleteItem } from '@akelius-con/react-ui-kit-components';
import { Property } from 'modules/order/types';
import mapPropertyToAutoCompleteItem from './mapPropertyAutoCompleteItem';

const preparePropertiesDropdownData = (properties?: Property[]): AutocompleteItem[] => {
    if (!properties?.length) {
        return [];
    }

    return properties.map(mapPropertyToAutoCompleteItem);
};

export default preparePropertiesDropdownData;
