import { gql } from 'graphql-request';
import { GetToken } from 'modules/order/utils/useGetUser';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';

export interface ForwardOrderParams {
    getToken: GetToken;
    orderId: string;
    email?: string;
}

const forwardOrder = () => async (param: ForwardOrderParams) => {
    const { getToken, ...payload } = param;
    const { graphQLClient } = await getGraphqlClient(getToken);

    const forwardOrderMutation = gql`
        mutation forwardOrder($orderId: String!, $email: String!) {
            forwardOrder(orderId: $orderId, email: $email)
        }
    `;

    const data = await graphQLClient.request(forwardOrderMutation, {
        ...payload,
    });

    return data;
};

export default forwardOrder;
