import { Button, DialogTitle, MessageBox, makeStyles } from '@akelius-con/react-ui-kit-components';
import { colors } from '@akelius-con/react-theme';
import { AkEdit } from '@akelius-con/react-ui-kit-icons';
import { Box, Dialog, DialogActions, DialogContent, IconButton, Theme, Tooltip, Typography } from '@mui/material';
import cn from 'classnames';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Supplier } from 'shared/graphql/queries/getSuppliers';
import SuppliersDropdown, { getSupplierOptionFromSupplier } from 'shared/components/SuppliersDropdown';

const useStyles = makeStyles()((theme: Theme) => ({
    cardWrapper: {
        marginTop: '24px',
        height: 'calc(100% - 24px)',
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    supplierAddress: {
        marginTop: '16px',
    },
    supplierName: {
        color: colors.black,
    },
    editButton: { margin: 0 },
    supplierBox: {
        position: 'relative',
        boxSizing: 'border-box',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${colors.lineGrey}`,
        padding: `24px 16px`,
        transition: 'border-color 200ms',
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    disabledIcon: {
        color: `${colors.disabledGrey} !important`,
    },
}));

interface Props {
    supplier: Supplier;
    readonly?: boolean;
    onChange?: (supplier: Supplier | null) => void;
    isLoadingSupplier: boolean;
}

const countryCodeMap: { [key: string]: string } = {
    FR: 'France',
};

const OrderCreationSupplierCard: FC<Props> = props => {
    const { classes } = useStyles();
    const { t } = useTranslation();
    const { supplier, readonly, onChange, isLoadingSupplier } = props;
    const [openSupplierChangeDialog, setOpenSupplierChangeDialog] = useState<boolean>(false);
    const [selectedSupplier, setSelectedSupplier] = useState<Supplier | null>(supplier);

    const closeDialog = useCallback(() => {
        setOpenSupplierChangeDialog(false);
    }, []);

    const performOpenSupplierChangeDialog = useCallback(() => {
        setOpenSupplierChangeDialog(true);
    }, []);

    const closeSupplierChangeDialog = useCallback(() => {
        setOpenSupplierChangeDialog(false);
    }, []);

    const onCloseDialogHandler = (_event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (reason !== 'backdropClick') {
            closeSupplierChangeDialog();
        }
    };

    const onChangeHandler = useCallback((supplier: Supplier | null) => {
        setSelectedSupplier(supplier);
    }, []);

    const onConfirmSupplierChange = () => {
        if (selectedSupplier && supplier?.id === selectedSupplier.id) {
            closeDialog();
        } else {
            onChange && onChange(selectedSupplier);
            closeDialog();
        }
    };

    return (
        <div className={classes.cardWrapper}>
            <Typography className={classes.title} variant="h2">
                {`${t('purchase-order.general-info.supplier')}*`}
            </Typography>

            <div className={classes.supplierBox}>
                {!supplier && <MessageBox type="error">{t('purchase-order.supplier.fetch-error')}</MessageBox>}

                <Typography className={classes.supplierName} variant="h3">
                    {supplier?.name}
                </Typography>

                <Typography variant="body1" className={classes.supplierAddress}>
                    {supplier?.address}
                </Typography>
                <Typography variant="body1">{supplier?.addressDetails.city}</Typography>
                <Typography variant="body1">{supplier?.addressDetails.country && countryCodeMap[supplier.addressDetails.country]}</Typography>

                <Box flexGrow={1} />

                {onChange && (
                    <div className={classes.actionContainer}>
                        <Tooltip title={!readonly ? t('purchase-order.add-carrier-of-cost.change-supplier') : ''} placement="right-start">
                            <IconButton
                                size="medium"
                                data-testid="change-supplier"
                                className={classes.editButton}
                                disabled={readonly}
                                onClick={performOpenSupplierChangeDialog}
                            >
                                <AkEdit className={cn({ [classes.disabledIcon]: readonly })} />
                            </IconButton>
                        </Tooltip>
                    </div>
                )}
            </div>

            <Dialog maxWidth="xs" fullWidth open={openSupplierChangeDialog} onClose={onCloseDialogHandler}>
                <DialogTitle onClose={closeSupplierChangeDialog} title={t('purchase-order.general-info.change-supplier-modal-title')} />
                <DialogContent>
                    {onChange && (
                        <SuppliersDropdown
                            label={t('purchase-order.supplier')}
                            value={getSupplierOptionFromSupplier(selectedSupplier)}
                            onChange={onChangeHandler}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" label={t('common.cancel')} onClick={closeSupplierChangeDialog} data-testid="cancel" />
                    <Button
                        isLoading={isLoadingSupplier}
                        variant="contained"
                        data-testid="change-supplier-button"
                        onClick={onConfirmSupplierChange}
                        disabled={!supplier}
                        label={t ? t('common.action.change') : 'change'}
                        color="primary"
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default OrderCreationSupplierCard;
