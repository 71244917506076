import { ListItemIcon, ListItemText, MenuItem, useTheme } from '@mui/material';
import { FC, memo, useCallback } from 'react';

export interface IMenuItem {
    id: string | number;
    testid?: string;
    label: string;
    handler: () => void;
    color?: 'default' | 'error';
    icon: FC;
    isDisabled?: boolean;
}

interface IProps {
    item: IMenuItem;
    onClose: () => void;
}

export const ContextMenuItem = memo((props: IProps) => {
    const theme = useTheme();
    const { item, onClose } = props;
    const onClickHandler = useCallback(() => {
        onClose();
        item.handler();
    }, [item, onClose]);

    const color = { color: item.color === 'error' ? theme.palette.error.main : theme.palette.text.primary };

    return (
        <MenuItem
            disabled={item.isDisabled}
            data-testid={item.testid}
            onClick={onClickHandler}
            component="button"
            sx={{ width: '100%', textAlign: 'left' }}
        >
            <>
                <ListItemIcon style={color}>{<item.icon />}</ListItemIcon>
                <ListItemText style={color}>{item.label}</ListItemText>
            </>
        </MenuItem>
    );
});

ContextMenuItem.displayName = 'ContextMenuItem';
