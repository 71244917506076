import { gql } from 'graphql-request';
import { GetToken } from 'modules/order/utils/useGetUser';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';

export interface DeleteOrderParams {
    orderId: string;
    getToken: GetToken;
}

const deleteOrder = () => async (params: DeleteOrderParams) => {
    const { getToken, ...queryVars } = params;
    const { graphQLClient } = await getGraphqlClient(getToken);

    const deleteOrderMutation = gql`
        mutation removeOrder($orderId: String!) {
            removeOrder(orderId: $orderId)
        }
    `;

    const data = await graphQLClient.request(deleteOrderMutation, {
        ...queryVars,
    });

    return data;
};

export default deleteOrder;
