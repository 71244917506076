import { gql } from 'graphql-request';
import { GetToken } from 'modules/order/utils/useGetUser';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';

export interface QueryVars {
    getToken: GetToken;
    documentId: string;
}

export interface IDownloadDocumentRes {
    downloadDocument: { signedUrl: string };
}

const getS3DownloadUrl = () => async (param: QueryVars) => {
    const { getToken, ...payload } = param;
    const { graphQLClient } = await getGraphqlClient(getToken);

    const downloadDocumentMutation = gql`
        mutation downloadDocument($documentId: String!) {
            downloadDocument(documentId: $documentId) {
                signedUrl
            }
        }
    `;

    const data = await graphQLClient.request<IDownloadDocumentRes>(downloadDocumentMutation, {
        ...payload,
    });

    return data;
};

export default getS3DownloadUrl;
