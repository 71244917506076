import { gql } from 'graphql-request';
import { Apartment } from 'modules/order/types';
import { GetToken } from 'modules/order/utils/useGetUser';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';

interface IGetApartmentParam {
    propertyId: string;
    getToken: GetToken;
}

export interface IApartmentsQueryResponse {
    property: {
        apartments: Apartment[];
    };
}

const getApartments = (param: IGetApartmentParam) => async () => {
    const { getToken, ...queryVars } = param;
    const { graphQLClient } = await getGraphqlClient(getToken);

    const getApartmentsQuery = gql`
        query property($propertyId: String!) {
            property(propertyId: $propertyId) {
                apartments {
                    id
                    name
                    code
                }
            }
        }
    `;

    const data = await graphQLClient.request<IApartmentsQueryResponse>(getApartmentsQuery, {
        ...queryVars,
    });

    return data;
};

export default getApartments;
