import { AutocompleteItem, Button, DialogTitle } from '@akelius-con/react-ui-kit-components';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { cacheKeys } from 'modules/order/constants';
import { UpdateOrderProjectParams, UpdateOrderProjectResponse, updateOrderProject } from 'modules/order/graphql/mutations/updateOrderProject';
import { IOrderResponse, OrderAssociatedProject } from 'modules/order/graphql/queries/useGetOrder';
import { Order } from 'modules/order/types';
import { useGetUser } from 'modules/order/utils/useGetUser';
import { MouseEventHandler, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectProjectDropdown } from 'shared/components/SelectProjectDropDown';
import { snackbar } from 'shared/components/Snackbar';
import { Project } from 'shared/graphql/queries/getProject';

interface Props {
    open: boolean;
    project: OrderAssociatedProject;
    onClose: () => void;
    order: Order;
}

export const UpdateProjectDialog = (props: Props) => {
    const { open, onClose, project, order } = props;
    const { t } = useTranslation();
    const { getToken } = useGetUser();
    const queryClient = useQueryClient();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedProject, setSelectedProject] = useState<AutocompleteItem | null>({
        label: project.id,
        value: project.id,
    });

    const cacheKey = cacheKeys.order(order.id);

    const updateProjectMutation = useMutation<UpdateOrderProjectResponse, undefined, UpdateOrderProjectParams>(updateOrderProject(), {
        onSuccess: ({ updateOrderProject }) => {
            queryClient.setQueryData<IOrderResponse | undefined>(cacheKey, (prevData: IOrderResponse | undefined) => {
                const { project, property } = updateOrderProject.carrierOfCost;

                if (prevData) {
                    const newData = { ...prevData };
                    newData.order.carrierOfCost.project = project;
                    newData.order.carrierOfCost.property = property;
                    return newData;
                }
            });

            snackbar.success(t('purchase-order.snackbar.carrier-of-cost.update-project-successful'));

            setIsLoading(false);

            onClose();
        },
        onError: () => {
            snackbar.error(t('purchase-order.snackbar.carrier-of-cost.update-project-failed'));
            setIsLoading(false);
        },
    });

    const onChangeHandler = useCallback((project: Project | null) => {
        if (!project) return null;

        setTimeout(function () {
            setSelectedProject({
                label: project.projectId,
                value: project?.projectId,
            });
        });
    }, []);

    const modalCloseHandler = useCallback(
        (_event: MouseEventHandler<HTMLButtonElement>, reason: 'backdropClick' | 'escapeKeyDown') => {
            if (reason !== 'backdropClick') onClose();
        },
        [onClose],
    );

    const saveProject = () => {
        if (!selectedProject) {
            return;
        }

        setIsLoading(true);

        updateProjectMutation.mutate({
            getToken,
            orderId: order.id,
            input: {
                projectId: selectedProject.value,
            },
        });
    };

    return (
        <Dialog maxWidth="sm" fullWidth open={open} onClose={modalCloseHandler} data-testid="add-edit-carrier-of-cost-modal">
            <DialogTitle onClose={onClose} title={t('purchase-order.add-carrier-of-cost')} />

            <DialogContent>
                <SelectProjectDropdown
                    required
                    label={t('purchase-order.construction-order.project')}
                    selectedProject={selectedProject}
                    onValueChange={onChangeHandler}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" label={t('common.cancel')} onClick={onClose} data-testid="cancel" />
                <Button
                    isLoading={isLoading}
                    onClick={saveProject}
                    data-testid="save-new-project-button"
                    variant="contained"
                    type="submit"
                    label={t('common.save')}
                />
            </DialogActions>
        </Dialog>
    );
};
