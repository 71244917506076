import { gql } from 'graphql-request';
import { CarrierOfCost } from 'modules/order/types';
import { GetToken } from 'modules/order/utils/useGetUser';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';

export interface CostCenterUpdateInput {
    companyId: string;
    costCenter: string;
}

export interface UpdateAdminOrderParam {
    getToken: GetToken;
    orderId: string;
    input: CostCenterUpdateInput;
}

interface UpdateAdminCostCenterResponse {
    updateOrderCostCenter: {
        id: string;
        carrierOfCost: CarrierOfCost;
    };
}

export const updateAdminCostCenter = () => async (param: UpdateAdminOrderParam) => {
    const { getToken, ...payload } = param;
    const { graphQLClient } = await getGraphqlClient(getToken);

    const updateAdminOrderCostCenterMutation = gql`
        mutation updateOrderCostCenter($orderId: String!, $input: UpdateOrderCostCenter!) {
            updateOrderCostCenter(orderId: $orderId, input: $input) {
                id
                carrierOfCost {
                    costCenter
                    costCenterName
                    countryCode
                    company {
                        id
                        code
                        name
                        vatNumber
                    }
                }
            }
        }
    `;

    const data = await graphQLClient.request<UpdateAdminCostCenterResponse>(updateAdminOrderCostCenterMutation, {
        ...payload,
    });

    return data;
};
