import { colors } from '@akelius-con/react-theme';
import { AkArrowDropDown, AkCheck, AkLocation } from '@akelius-con/react-ui-kit-icons';
import { Box, MenuItem, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingsQueryResponse } from 'shared/graphql/queries/getSettings';
import { countryCodeToFlag } from 'shared/utils/countryCodeToFlag';
import { NestedMenuItem } from '../NestedMenuItem';
import { getSelectedCountry, setSelectedCountryToLocalStorage } from 'shared/utils/serviceCountry';
import { ServiceCountriesEnum } from 'shared/types/ServicesCountriesEnum';
import { sharedCacheKeys } from 'shared/constant';
import { routeConst } from 'modules/order/constants';

interface SelectCountryMenuItemProps {
    onClose: () => void;
    anchorEl: null | HTMLElement;
}

export const SelectCountryMenuItem: FC<SelectCountryMenuItemProps> = props => {
    const { anchorEl, onClose } = props;
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const settingsData = queryClient.getQueryData<SettingsQueryResponse>(sharedCacheKeys.settings);
    const [selectedCountry, setSelectedCountry] = useState<string>('');

    const countryName = selectedCountry ? t(`common.country.${selectedCountry}`) : '';
    const countryNameWithFlag = `${countryCodeToFlag(selectedCountry)} ${countryName}`;
    const countryMenuName = selectedCountry ? t(`common.country.selected-country`, { name: countryNameWithFlag }) : '';

    const onClickChangeCountryHandler = useCallback(
        (country: ServiceCountriesEnum) => {
            setSelectedCountry(country);
            setSelectedCountryToLocalStorage(country);
            onClose();
            window.location.href = routeConst.order;
        },
        [onClose],
    );

    useEffect(() => {
        const selectedCountry = getSelectedCountry();
        setSelectedCountry(selectedCountry);
    }, [selectedCountry, settingsData]);

    return (
        <NestedMenuItem
            leftIcon={<AkLocation />}
            openFrom="right"
            rightIcon={
                <Box sx={{ transform: 'rotate(-90deg)', ml: 1, color: colors.textGray }}>
                    <AkArrowDropDown />
                </Box>
            }
            label={countryMenuName}
            parentMenuOpen={anchorEl?.id === 'userDropdown'}
        >
            {settingsData?.settings.map(settingSet => (
                <MenuItem key={settingSet.country} onClick={() => onClickChangeCountryHandler(settingSet.country)}>
                    {selectedCountry === settingSet.country ? <AkCheck /> : <div style={{ width: '24px' }} />}
                    <Typography ml={1}>
                        <span style={{ marginRight: '8px' }}>{countryCodeToFlag(settingSet.country)}</span>
                        {settingSet.country ? t(`common.country.${settingSet.country}`, selectedCountry) : ''}
                    </Typography>
                </MenuItem>
            ))}
        </NestedMenuItem>
    );
};

export default SelectCountryMenuItem;
