import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { GetToken, useGetUser } from 'modules/order/utils/useGetUser';
import { DAY_IN_MS, sharedCacheKeys } from 'shared/constant';
import { GraphqlErrorType } from 'shared/types/GraphqlErrorType';
import { getGraphqlClient } from '../useGraphQlClient';

export interface UserPermissions {
    userPermissions: string[];
}

export const useGetPermissions = () => {
    const { getToken } = useGetUser();

    return useQuery(sharedCacheKeys.permissions, getPermissions({ getToken }), {
        onError: (error: GraphqlErrorType) => error,
        cacheTime: DAY_IN_MS,
        staleTime: DAY_IN_MS,
    });
};

interface GetPermissionsParam {
    getToken: GetToken;
}

export const getPermissions =
    ({ getToken }: GetPermissionsParam) =>
    async () => {
        const { graphQLClient } = await getGraphqlClient(getToken);

        const getSettingsQuery = gql`
            query userPermissions {
                userPermissions
            }
        `;

        const data = await graphQLClient.request<UserPermissions>(getSettingsQuery);

        return data;
    };
