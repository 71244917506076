import { colors } from '@akelius-con/react-theme';
import { makeStyles } from '@akelius-con/react-ui-kit-components';
import { AkBed, AkBuilding, AkCostCenter, AkEdit } from '@akelius-con/react-ui-kit-icons';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import cn from 'classnames';
import { OrderAssociatedProject } from 'modules/order/graphql/queries/useGetOrder';
import { OrderAssociatedProperty } from 'modules/order/types';
import { getPropertyImage } from 'modules/order/utils/getPropertyImage';
import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { LinkToAcmProject } from 'shared/components/LinkToAcmProject';
import placeholderImage from '/src/assets/images/placeholder.png';

const useStyles = makeStyles()({
    contentContainer: {
        padding: '24px 16px',
        position: 'relative',
        border: `1px solid ${colors.lineGrey}`,
        borderRadius: '3px',
    },
    mediaContainer: {
        position: 'relative',
        width: '100%',
        height: '200px',
    },
    media: {
        width: '100%',
        height: 200,
        objectFit: 'cover',
    },
    wrapper: {
        marginTop: '24px',
        backgroundColor: 'transparent',
        padding: 0,
        textAlign: 'left',
    },
    disabledLink: {
        pointerEvents: 'none',
        textDecoration: 'none',
    },
    disabledName: {
        color: colors.disabledGrey,
    },
    name: {
        color: colors.black,
    },
    infoRow: {
        display: 'flex',
        '& .MuiSvgIcon-root': {
            paddingRight: '10px',
            fontSize: '24px',
            color: colors.black,
        },
    },
    propertyInfoContainer: {
        paddingLeft: '50px',
    },
    editButton: {
        margin: 0,
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    disabledIcon: {
        color: `${colors.disabledGrey} !important`,
    },
    title: {
        marginBottom: '16px',
    },
});

interface Props {
    hideEdit?: boolean;
    onClick?: () => void;
    disabled?: boolean;
    project: OrderAssociatedProject;
    property: OrderAssociatedProperty;
    profitCenter: string;
}

export const ProjectCard = (props: Props) => {
    const { t } = useTranslation();
    const { onClick, disabled, project, hideEdit, property, profitCenter } = props;
    const { address, apartment } = property;

    const editEnabled = onClick && !disabled;

    const onClickHandler = () => {
        if (editEnabled) onClick();
    };

    const { classes } = useStyles();

    return (
        <div className={classes.wrapper} data-testid="property-card">
            <Typography className={classes.title} variant="h2">
                {`${t('purchase-order.general-info.carrier-of-cost')}*`}
            </Typography>
            <Grid container className={classes.contentContainer}>
                <Grid item xs={4} className={classes.mediaContainer}>
                    <img
                        className={classes.media}
                        src={getPropertyImage(property.id, 600)}
                        alt="property"
                        onError={(e: SyntheticEvent<HTMLImageElement>) => {
                            e.currentTarget.onerror = null;
                            e.currentTarget.src = placeholderImage;
                        }}
                    />
                </Grid>
                <Grid item xs={8} className={classes.propertyInfoContainer}>
                    <Box mb={1}>
                        <Tooltip title={t('purchase-order.construction-order.project-id')} placement="left-start">
                            <div>
                                <LinkToAcmProject data-testid="project-id" variant="h3" projectId={project.id} iconSize={20} />
                            </div>
                        </Tooltip>
                    </Box>

                    <Box mb={2}>
                        <Tooltip title={t('purchase-order.construction-order.project-type')} placement="left-start">
                            <Typography data-testid="project-type" variant="body1">
                                {project.type} &gt; {project.subType}
                            </Typography>
                        </Tooltip>
                    </Box>

                    <Box className={classes.infoRow} mb={2}>
                        <Tooltip title={t('datatable.header.property-name')} placement="left-start">
                            <Typography data-testid="property-title" variant="body1">
                                {property.name}
                            </Typography>
                        </Tooltip>
                    </Box>

                    {address && (
                        <Tooltip title={t('purchase-order.add-carrier-of-cost.property-address')} placement="left-start">
                            <Box className={classes.infoRow} mb={2}>
                                <AkBuilding />
                                <Typography data-testid="property-address" variant="body1">
                                    {address}
                                </Typography>
                            </Box>
                        </Tooltip>
                    )}

                    {profitCenter && (
                        <Tooltip title={t('purchase-order.carrier-of-cost.datatable.header.profit-center')} placement="left-start">
                            <Box className={classes.infoRow} mb={2}>
                                <AkCostCenter />
                                <Typography data-testid="profit-center" variant="body1">
                                    {profitCenter}
                                </Typography>
                            </Box>
                        </Tooltip>
                    )}

                    {apartment && (
                        <Tooltip title={t('purchase-order.apartment')} placement="left-start">
                            <Box className={classes.infoRow} mb={!hideEdit ? 2 : 0}>
                                <AkBed />
                                <Typography data-testid="apartment" variant="body1">
                                    {apartment.name}
                                </Typography>
                            </Box>
                        </Tooltip>
                    )}
                </Grid>
                {!hideEdit && (
                    <Grid item xs={12}>
                        <div className={classes.actionContainer}>
                            <Tooltip title={editEnabled ? t('purchase-order.add-carrier-of-cost.change-property') : ''} placement="left-start">
                                <IconButton
                                    size="medium"
                                    className={classes.editButton}
                                    disabled={!editEnabled}
                                    onClick={onClickHandler}
                                    data-testid="change-project"
                                >
                                    <AkEdit className={cn({ [classes.disabledIcon]: !editEnabled })} />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};
