import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { cacheKeys } from 'modules/order/constants';
import { Order } from 'modules/order/types';
import { GetToken, useGetUser } from 'modules/order/utils/useGetUser';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';
import { GraphqlErrorType } from 'shared/types/GraphqlErrorType';

export interface OrderAssociatedProject {
    id: string;
    type: string;
    subType: string;
    costCenter: string;
    state?: string;
}

export enum OrderStatusEnum {
    CREATED = 'CREATED',
    RETURNED = 'RETURNED',
    SENT_TO_APPROVAL = 'SENT_TO_APPROVAL',
    PENDING_APPROVAL = 'PENDING_APPROVAL',
    RELEASED = 'RELEASED',
    EXPIRED = 'EXPIRED',
    DECLINED = 'DECLINED',
    CANCELLED = 'CANCELLED',
    CANCEL_REQUESTED = 'CANCEL_REQUESTED',
}

interface IUseQueryPageableOptions {
    onSuccess?: (data: IOrderResponse) => void;
    onError?: (error: GraphqlErrorType) => void;
    enabled?: boolean;
    refetchOnWindowFocus?: boolean;
}

export interface IOrderResponse {
    order: Order;
}

export interface GetOrder {
    orderId: string;
    getToken: GetToken;
}

interface UseGetOrder {
    (orderId: string, options?: IUseQueryPageableOptions): UseQueryResult<IOrderResponse, GraphqlErrorType>;
}

export const useGetOrder: UseGetOrder = (orderId, options) => {
    const { getToken } = useGetUser();
    return useQuery<IOrderResponse, GraphqlErrorType>(cacheKeys.order(orderId), getOrder({ getToken, orderId }), options);
};

const getOrder =
    ({ getToken, orderId }: GetOrder) =>
    async () => {
        const { graphQLClient } = await getGraphqlClient(getToken);

        const queryVars = { orderId };

        const getOrderQuery = gql`
            query order($orderId: String!) {
                order(orderId: $orderId) {
                    id
                    orderNumber
                    createdDate
                    releasedDate
                    releaseUser {
                        id
                        name
                    }
                    status
                    title
                    currency
                    updatedDate
                    noteToSupplier
                    referenceNumber
                    totalPriceCentsNet
                    totalPriceCentsGross
                    totalVatAmountBreakdown {
                        vatRate
                        amountCents
                    }
                    supplier {
                        id
                        name
                        address
                        addressDetails {
                            city
                            country
                        }
                        contacts {
                            contactId
                            name
                            email
                            isSelected
                        }
                        additionalContacts {
                            contactId
                            name
                            email
                            phone
                        }
                    }
                    creator {
                        id
                        name
                    }

                    carrierOfCost {
                        costCenter
                        costCenterName
                        countryCode
                        company {
                            id
                            code
                            name
                            vatNumber
                        }
                        property {
                            id
                            name
                            code
                            address
                            addressDetails {
                                city
                                country
                            }
                            apartment {
                                id
                                name
                                code
                            }
                        }
                        project {
                            id
                            type
                            subType
                            costCenter
                            state
                        }
                    }

                    case {
                        caseId
                    }

                    items {
                        ... on OrderItem {
                            id
                            description
                            quantity {
                                value
                                unit
                            }
                            userPrice {
                                value
                                vatIncluded
                                vatRate
                            }
                            unitPriceCentsNet
                            totalPriceCentsNet
                            totalPriceCentsGross
                            totalVatAmountCents
                            creator {
                                id
                                name
                            }
                            note
                            supplierPartNumber
                            manufacturerPartNumber
                            createdDate
                            updatedDate
                            deliveryDate {
                                start
                                end
                            }
                        }
                    }
                    invoiceAddress {
                        address1
                        address2
                        streetName
                        streetNumber
                        postalCode
                        city
                        region
                        country
                    }
                    deliveryAddress {
                        address1
                        address2
                        streetName
                        streetNumber
                        postalCode
                        city
                        region
                        country
                    }
                    documents {
                        ... on OrderDocument {
                            id
                            fileTitle
                            docType
                            createdDate
                            creatorName
                            sendToSupplier
                            sendToApproval
                            sizeInBytes
                        }
                    }
                    contactForSupplier {
                        email
                        name
                        phone
                        title
                    }
                }
            }
        `;

        const data = await graphQLClient.request<IOrderResponse>(getOrderQuery, {
            ...queryVars,
        });

        return data;
    };

export default getOrder;
