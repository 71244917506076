import { Chip } from '@mui/material';
import { CSSObject } from 'tss-react';
import { OrderStatusEnum } from 'modules/order/graphql/queries/useGetOrder';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
    status: OrderStatusEnum;
    cursor?: CSSObject['cursor'];
}

export const generateChipColor = (status: OrderStatusEnum) => {
    switch (status) {
        case OrderStatusEnum.SENT_TO_APPROVAL:
        case OrderStatusEnum.PENDING_APPROVAL:
            return 'warning';
        case OrderStatusEnum.RELEASED:
            return 'success';
        case OrderStatusEnum.CANCELLED:
        case OrderStatusEnum.EXPIRED:
        case OrderStatusEnum.CANCEL_REQUESTED:
        case OrderStatusEnum.DECLINED:
            return 'error';
        default:
            return;
    }
};

const OrderStatus: FC<IProps> = props => {
    const { status, cursor } = props;
    const { t } = useTranslation();
    const color = generateChipColor(status);
    return (
        <Chip
            sx={{ cursor }}
            variant={!color ? 'outlined' : 'filled'}
            color={generateChipColor(status)}
            label={t(`purchase-order.status.${status.toLowerCase()}`)}
        />
    );
};

export default OrderStatus;
