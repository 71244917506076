import { makeStyles } from '@akelius-con/react-ui-kit-components';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatAmountWithoutCurrency } from 'shared/utils/amountFormatter';
import { InvoiceList } from '../../../types';

interface Props {
    invoiceList: InvoiceList;
}

const useStyles = makeStyles()({
    title: {
        marginBottom: '10px',
    },
});

const InvoicesSummary = ({ invoiceList }: Props) => {
    const { t } = useTranslation();
    const { classes } = useStyles();

    const grossTotalCurrency = t(`purchase-order.${invoiceList.currency.toLowerCase()}`);
    const grossTotal = formatAmountWithoutCurrency(invoiceList.grossTotal);

    return (
        <>
            <Typography className={classes.title} variant="body1">
                {t('purchase-order.invoices.total-amount')}
            </Typography>
            <Typography variant="body1">{`${grossTotalCurrency} ${grossTotal}`}</Typography>
        </>
    );
};

export default InvoicesSummary;
