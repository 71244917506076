import { makeStyles } from '@akelius-con/react-ui-kit-components';
import { AkHelp } from '@akelius-con/react-ui-kit-icons';
import { Grid, IconButton, Typography } from '@mui/material';
import DESIGN_CONSTANT from 'shared/designConstant';
import { useFormik } from 'formik';
import { cacheKeys } from 'modules/order/constants';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isOrderReadonlyByStatus } from 'shared/utils/order';
import * as Yup from 'yup';
import updateOrder, { IUpdateOrderParam, UpdateOrderResponse } from '../../graphql/mutations/updateOrder';
import { Order } from '../../types';
import FormikAutoSave from 'shared/components/FormikAutoSave';
import { FormikTextField } from 'shared/components/FormikTextField';
import InfoDialog from '../InfoDialog';
import { useGetUser } from 'modules/order/utils/useGetUser';
import { IOrderResponse } from 'modules/order/graphql/queries/useGetOrder';

interface IProps {
    order: Order;
}

interface FormValues {
    noteToSupplier: string;
}

interface SupportInfoLabels {
    note: {
        title: string;
        content: string;
    };
}

const useStyles = makeStyles()({
    containerWrapper: {
        maxWidth: DESIGN_CONSTANT.formContentWidth,
        padding: '12px 0',
    },
});

const OrderNotesTab: FC<IProps> = ({ order }) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const dirtyTracker = useState<boolean>(false);
    const [autoSaveError, setAutoSaveError] = useState<boolean | null>(null);
    const queryClient = useQueryClient();
    const { getToken } = useGetUser();
    const supportInfoLabels: SupportInfoLabels = {
        note: {
            title: t('purchase-order.notes'),
            content: t('purchase-order.item-note.info'),
        },
    };
    const [supportInfoContext, setSupportInfoContext] = useState<'note' | null>(null);

    const initialValues: FormValues = {
        noteToSupplier: order.noteToSupplier || '',
    };

    const validationSchema = Yup.object().shape({
        noteToSupplier: Yup.string().max(500).label(t('purchase-order.notes')),
    });

    const closeInfoModal = () => {
        setSupportInfoContext(null);
    };

    const openNoteInfo = () => {
        setSupportInfoContext('note');
    };

    const updateOrderMutation = useMutation(updateOrder(), {
        onSuccess: (successRes: UpdateOrderResponse) => {
            setTimeout(function () {
                setAutoSaveError(false);
                formik.setSubmitting(false);
            }, 50);

            queryClient.setQueryData(cacheKeys.order(order.id), (data: IOrderResponse | undefined) => {
                if (!data) {
                    return;
                }

                return {
                    order: {
                        ...data.order,
                        noteToSupplier: successRes.updateOrder.noteToSupplier,
                    },
                };
            });
        },
        onError: () => {
            setTimeout(function () {
                formik.setSubmitting(false);
                setAutoSaveError(true);
            }, 50);
        },
    });

    const onSubmit = (values: FormValues) => {
        const queryVars: IUpdateOrderParam = {
            getToken,
            orderId: order.id,
            input: {
                noteToSupplier: values.noteToSupplier,
            },
        };
        updateOrderMutation.mutate(queryVars);
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    return (
        <div className={classes.containerWrapper}>
            <Typography variant="h2" sx={{ m: '0 0 16px !important' }}>
                {t('purchase-order.notes-title')}
                <IconButton sx={{ ml: '16px' }} size="small" onClick={openNoteInfo}>
                    <AkHelp />
                </IconButton>
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid md={6} item>
                                <FormikTextField
                                    disabled={isOrderReadonlyByStatus(order.status)}
                                    preventDisableOnSubmit
                                    name="noteToSupplier"
                                    data-testid="note-to-supplier"
                                    label={t('purchase-order.notes')}
                                    formik={formik}
                                    multiline
                                    rows={5}
                                />
                            </Grid>

                            <FormikAutoSave error={autoSaveError} formik={formik} dirtyTracker={dirtyTracker} />
                        </Grid>
                    </form>
                </Grid>
                {supportInfoContext === 'note' && (
                    <InfoDialog
                        open={!!supportInfoContext}
                        onClose={closeInfoModal}
                        title={supportInfoLabels[supportInfoContext].title}
                        content={supportInfoLabels[supportInfoContext].content}
                    />
                )}
            </Grid>
        </div>
    );
};

export default OrderNotesTab;
