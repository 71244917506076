import { LoadingSpinner } from '@akelius-con/react-ui-kit-components';
import { useAuth0 } from '@auth0/auth0-react';
import { ErrorPage } from 'modules/order/pages/ErrorPage';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePermissions } from './usePermission';
import { useGetSettings } from 'shared/graphql/queries/getSettings';
import { getSelectedCountry, removeSelectedCountry, setSelectedCountryToLocalStorage } from 'shared/utils/serviceCountry';

interface IProps {
    children: React.ReactNode;
}

export const HandleAuthPermissionsSettings: FC<IProps> = props => {
    const { children } = props;
    const { t } = useTranslation();

    const { isAuthenticated, loginWithRedirect, isLoading: isAuthLoading } = useAuth0();
    const { permissionsData, permissionsError, isPermissionLoading } = usePermissions();
    const { data, isLoading: isSettingsLoading } = useGetSettings();

    const selectedCountry = getSelectedCountry();
    const settings = data?.settings;

    useEffect(() => {
        if (!isAuthLoading && !isAuthenticated) {
            loginWithRedirect({
                appState: {
                    returnTo: window.location.pathname,
                },
            });
        }
    }, [isAuthenticated, isAuthLoading, loginWithRedirect]);

    const setDefaultCountry = useCallback(() => {
        if (selectedCountry && settings?.length) {
            const isSelectedCountryValid = settings?.find(setting => setting.country === selectedCountry);

            if (!isSelectedCountryValid) {
                removeSelectedCountry();
                setSelectedCountryToLocalStorage(settings[0].country);
            }
        }

        if (settings?.length && !selectedCountry) {
            setSelectedCountryToLocalStorage(settings[0].country);
        }
    }, [settings, selectedCountry]);

    useEffect(() => {
        setDefaultCountry();
    }, [setDefaultCountry]);

    if (isAuthLoading || isPermissionLoading || isSettingsLoading) {
        return <LoadingSpinner />;
    }

    if (!permissionsData?.userPermissions?.length) {
        return <ErrorPage title={t('purchase-order.unauthorized.error')} errors={['unauthorized']} />;
    }

    const errors = permissionsError?.response.errors.map(e => e.errorType);

    if (errors?.length) {
        return <ErrorPage title={t('purchase-order.unauthorized.error')} errors={errors} />;
    }

    if (!settings?.length) {
        return <ErrorPage title={t('purchase-order.unauthorized.error')} errors={['unauthorized']} />;
    }

    return <>{children}</>;
};
