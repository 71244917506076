import { gql } from 'graphql-request';
import { GetToken } from 'modules/order/utils/useGetUser';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';
import { Property, PropertyFilters } from '../../types';

interface PropertyFilter {
    countryCode: string;
    city?: string;
    postalCode?: string;
    name?: string;
    term?: string;
}

interface GetPropertiesParams {
    filters?: PropertyFilter;
    pageSize: number;
    getToken: GetToken;
}

export interface PropertiesQueryResponse {
    properties: {
        items: Property[];
    };
}

interface IParam {
    pageParam?: {
        pageSize: number;
        filters: PropertyFilters;
    };
}

const getProperties =
    (params: GetPropertiesParams) =>
    async ({ pageParam }: IParam) => {
        const { pageSize, filters, getToken } = params;
        const { graphQLClient } = await getGraphqlClient(getToken);

        let queryVars = { pageSize, filters };

        if (pageParam) {
            queryVars = pageParam;
        }

        const getPropertiesQuery = gql`
            query properties($pageSize: Int!, $filters: PropertyFilter) {
                properties(pageSize: $pageSize, filter: $filters) {
                    items {
                        ... on Property {
                            id
                            name
                            code
                            costCenter
                            address
                            addressDetails {
                                city
                                country
                            }
                            apartments {
                                id
                                name
                                code
                            }
                        }
                    }
                }
            }
        `;

        const data = await graphQLClient.request<PropertiesQueryResponse>(getPropertiesQuery, {
            ...queryVars,
        });

        return data;
    };

export default getProperties;
