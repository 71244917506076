import { gql } from 'graphql-request';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';
import { SupplierContact } from 'shared/graphql/queries/getSuppliers';
import { GetToken } from 'modules/order/utils/useGetUser';

export interface UpdateContactParams {
    getToken: GetToken;
    contactId: string;
    orderId: string;
    input: {
        name: string;
        email: string;
        phone?: string;
    };
}

export interface UpdateContactRes {
    updateOrderSupplierContact: {
        supplier: {
            additionalContacts: SupplierContact[];
        };
    };
}

const updateContact = () => async (param: UpdateContactParams) => {
    const { getToken, ...payload } = param;
    const { graphQLClient } = await getGraphqlClient(getToken);

    const updateOrderSupplierContactMutation = gql`
        mutation updateOrderSupplierContact($orderId: String!, $contactId: String!, $input: OrderSupplierContactUpdate!) {
            updateOrderSupplierContact(orderId: $orderId, contactId: $contactId, input: $input) {
                supplier {
                    ...AdditionalContacts
                }
            }
        }

        fragment AdditionalContacts on OrderSupplier {
            additionalContacts {
                contactId
                name
                email
                phone
            }
        }
    `;

    const data = await graphQLClient.request<UpdateContactRes>(updateOrderSupplierContactMutation, {
        ...payload,
    });

    return data;
};

export default updateContact;
