import { Checkbox, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, SelectProps } from '@mui/material';
import { uniqueId } from 'lodash';
import { OrderStatusEnum } from 'modules/order/graphql/queries/useGetOrder';
import { FC, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import OrderStatus from '../OrderStatus';

type IProps = SelectProps & {
    onOrderStatusChange: (value: OrderStatusEnum[]) => void;
    selectedStatuses?: OrderStatusEnum[];
};

export const getStatusesFromString = (statuses: string): OrderStatusEnum[] => {
    const allowedStatuses = Object.keys(OrderStatusEnum);
    if (statuses?.length) {
        return statuses.split(',').filter(status => allowedStatuses.includes(status)) as OrderStatusEnum[];
    }

    return [];
};

const SelectOrderStatusDropdown: FC<IProps> = props => {
    const { t } = useTranslation();
    const { selectedStatuses = [], onOrderStatusChange, id, ...otherProps } = props;

    const handleChange = useCallback(
        (event: SelectChangeEvent<unknown>) => {
            const statuses = event.target.value as OrderStatusEnum[];

            onOrderStatusChange(statuses);
        },
        [onOrderStatusChange],
    );

    const renderValue = (selected: unknown) => {
        if (Array.isArray(selected)) {
            return selected.map((value: string) => t(`purchase-order.status.${value.toLocaleLowerCase()}`)).join(', ');
        }
    };

    const componentId = useRef(id || uniqueId('order-status-dropdown'));

    return (
        <FormControl>
            <InputLabel htmlFor={componentId.current}>{t ? t('purchase-order.status') : 'status'}</InputLabel>
            <Select
                data-testid="order-status-selection"
                multiple
                value={selectedStatuses}
                onChange={handleChange}
                renderValue={renderValue}
                inputProps={{
                    name: 'order-status',
                    id: componentId.current,
                }}
                id={componentId.current}
                {...otherProps}
            >
                {Object.values(OrderStatusEnum).map((status: OrderStatusEnum) => (
                    <MenuItem key={status} value={status}>
                        <Checkbox checked={selectedStatuses?.indexOf(status) > -1} />
                        <OrderStatus cursor={'pointer'} status={status} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SelectOrderStatusDropdown;
