import { gql } from 'graphql-request';
import { GetToken } from 'modules/order/utils/useGetUser';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';

export interface DocumentUpdateInput {
    docType?: string;
    fileTitle: string;
    sendToSupplier?: boolean;
    sendToApproval?: boolean;
}

export interface QueryVars {
    getToken: GetToken;
    documentId: string;
    input: DocumentUpdateInput;
}

const editDocument = () => async (param: QueryVars) => {
    const { getToken, ...payload } = param;
    const { graphQLClient } = await getGraphqlClient(getToken);

    const editDocumentMutation = gql`
        mutation updateDocument($documentId: String!, $input: DocumentUpdate!) {
            updateDocument(documentId: $documentId, input: $input) {
                id
            }
        }
    `;

    const data = await graphQLClient.request(editDocumentMutation, {
        ...payload,
    });

    return data;
};

export default editDocument;
