import { useGetPermissions } from 'shared/graphql/queries/getPermissions';
import { PermissionEnum } from './PermissionEnum';

export const usePermissions = () => {
    const { data: permissionsData, error: permissionsError, isLoading: isPermissionLoading } = useGetPermissions();

    const useIsPermitted = (requiredPermission: PermissionEnum): boolean => {
        return !!permissionsData?.userPermissions?.includes(requiredPermission);
    };

    return {
        permissionsData,
        permissionsError,
        isPermissionLoading,
        useIsPermitted,
    };
};
