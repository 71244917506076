import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { cacheKeys } from 'modules/order/constants';
import { GetToken, useGetUser } from 'modules/order/utils/useGetUser';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';
import { GraphqlErrorType } from 'shared/types/GraphqlErrorType';

enum OrderApprovalEvent {
    SENT_TO_APPROVAL = 'SENT_TO_APPROVAL',
    PENDING = 'PENDING',
    DECLINED = 'DECLINED',
    RETURNED = 'RETURNED',
    TRANSFERRED = 'TRANSFERRED',
    APPROVED = 'APPROVED',
    AUTO_APPROVED = 'AUTO_APPROVED',
}

export interface ApprovalHistory {
    date: string;
    level: string;
    user?: {
        id: string;
        name: string;
    };
    event: OrderApprovalEvent;
    url: string;
    comments: string[];
}

export interface Approval {
    url: string;
    history: ApprovalHistory[];
}

export interface ApprovalQueryResponse {
    order: {
        approval: Approval;
    };
}

export interface GetOrderApprovalHistory {
    orderId: string;
    getToken: GetToken;
}

interface IUseQueryPageableOptions {
    onSuccess?: (data: ApprovalQueryResponse) => void;
    onError?: (error: GraphqlErrorType) => void;
    enabled?: boolean;
    refetchOnWindowFocus?: boolean;
}

interface UseOrderApprovalHistory {
    (orderId: string, options?: IUseQueryPageableOptions): UseQueryResult<ApprovalQueryResponse>;
}

export const useOrderApprovalHistory: UseOrderApprovalHistory = (orderId, options) => {
    const { getToken } = useGetUser();
    return useQuery(cacheKeys.approvalHistory(orderId), getOrderApprovalHistory({ getToken, orderId }), options);
};

const getOrderApprovalHistory =
    ({ getToken, orderId }: GetOrderApprovalHistory) =>
    async () => {
        const { graphQLClient } = await getGraphqlClient(getToken);

        const queryVars = { orderId };

        const getOrderQuery = gql`
            query order($orderId: String!) {
                order(orderId: $orderId) {
                    approval {
                        url
                        history {
                            date
                            user {
                                id
                                name
                            }
                            event
                            url
                            comments
                        }
                    }
                }
            }
        `;

        const data = await graphQLClient.request<ApprovalQueryResponse>(getOrderQuery, {
            ...queryVars,
        });

        return data;
    };

export default getOrderApprovalHistory;
