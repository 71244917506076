import { theme } from '@akelius-con/react-theme';
import { LoadingSpinner } from '@akelius-con/react-ui-kit-components';
import { Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { FC, Suspense, useEffect } from 'react';
import { HandleAuthPermissionsSettings } from 'shared/components/HandleAuthPermissionsSettings';
import { SnackbarContainer } from 'shared/components/Snackbar';
import { TranslationProvider } from 'shared/components/TranslationProvider';
import i18n, { languages, setUpLanguage } from 'shared/components/TranslationProvider/i18n';
import { RouteDefinitions } from './RouteDefinitions';
import { DAY_IN_MS } from 'shared/constant';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';

const defaultAuthScope = 'openid token id_token phone title';

export const muiCache = createCache({
    key: 'mui',
    prepend: true,
});

const App: FC = () => {
    let domain = import.meta.env.VITE_APP_AUTH0_DOMAIN as string;
    if (domain.startsWith('https')) {
        domain = domain.substr(8).replace(/\/$/, ''); // strip the protocol and pass only the hostname
    }

    const persister = createSyncStoragePersister({
        storage: window.localStorage,
    });

    const auth0ProviderOptions: Auth0ProviderOptions = {
        domain,
        clientId: import.meta.env.VITE_APP_AUTH0_CLIENT_ID as string,
        useRefreshTokens: true,
        useRefreshTokensFallback: true,
        cacheLocation: 'localstorage',
        authorizationParams: {
            redirect_uri: `${window.location.origin}/order/callback`,
            connection: import.meta.env.VITE_APP_AUTH0_CONNECTION,
            audience: import.meta.env.VITE_APP_API_AUTH0_AUDIENCE,
            scope: defaultAuthScope,
        },
    };

    useEffect(() => {
        setUpLanguage();
    }, []);

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                cacheTime: DAY_IN_MS,
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Suspense fallback={<LoadingSpinner />}>
                <Auth0Provider {...auth0ProviderOptions}>
                    <TranslationProvider translationConfig={{ languages, i18n }}>
                        <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
                            <CacheProvider value={muiCache}>
                                <HandleAuthPermissionsSettings>
                                    <RouteDefinitions />
                                </HandleAuthPermissionsSettings>
                            </CacheProvider>
                            <SnackbarContainer />
                            <ReactQueryDevtools initialIsOpen={false} />
                        </PersistQueryClientProvider>
                    </TranslationProvider>
                </Auth0Provider>
            </Suspense>
        </ThemeProvider>
    );
};

export default App;
