import { CostCenter } from 'modules/order/graphql/queries/getCompanies';

export const prepareCostCenterDropdownData = (costCenters: CostCenter[]) => {
    return costCenters.map(costCenter => {
        return {
            label: `${costCenter.code} - ${costCenter.name}`,
            value: costCenter.code,
        };
    });
};
