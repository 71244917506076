import { TFunction } from 'i18next';
import { commaDecimalTransformer, muiYupDateTransformer, priceCustomValidator, thousandSeparatorTransformer } from 'shared/helpers/formikHelpers';
import { date, mixed, number, object, ref, string } from 'yup';

export const priceValidationRule = (t: TFunction) => {
    return number()
        .typeError(t('common.validation-msg.yup.default'))
        .transform(thousandSeparatorTransformer)
        .moreThan(0)
        .required()
        .test('two-digit-after-decimal', t('common.validation-msg.price.two-digits', { field: t('purchase-order.item.price') }), priceCustomValidator)
        .label(t('purchase-order.item.price'));
};

export const getValidationSchema = (t: TFunction) => {
    const schema = object().shape({
        description: string().required().max(100).label(t('purchase-order.item.description')),
        quantity: number().transform(commaDecimalTransformer).positive().required().label(t('purchase-order.item.quantity')),
        unit: string().required().label(t('purchase-order.item.unit')),
        price: priceValidationRule(t),
        vat: mixed().required().label(t('purchase-order.item.vat')),
        vatRate: string().required().label(t('purchase-order.item.vat-rate')),
        supplierPartNumber: string().max(30).label(t('purchase-order.item.supplier-part-number')),
        manufacturerPartNumber: string().max(30).nullable().label(t('purchase-order.item.manufacturer-part-number')),
        note: string().max(200).label(t('purchase-order.item.note')),
        deliveryStartDate: date().nullable().transform(muiYupDateTransformer).typeError(t('common.validation-msg.date.invalid-date')),
        deliveryEndDate: date()
            .nullable()
            .transform(muiYupDateTransformer)
            .typeError(t('common.validation-msg.date.invalid-date'))
            .min(ref('deliveryStartDate'))
            .when('deliveryStartDate', {
                is: (deliveryStartDate: string) => !!deliveryStartDate,
                then: schema => schema.required(),
            }),
    });

    return schema;
};
