import { Button, DialogTitle } from '@akelius-con/react-ui-kit-components';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import deleteOrder from 'modules/order/graphql/mutations/deleteOrder';
import { InfiniteOrdersCache } from 'modules/order/graphql/queries/useGetOrders';
import { useGetUser } from 'modules/order/utils/useGetUser';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { snackbar } from 'shared/components/Snackbar';
import { deleteOrderFromInfiniteOrderCache } from './deleteOrderFromInfiniteOrderCache';

interface Props {
    onClose: () => void;
    deleteContext: string | null;
    queryKey?: QueryKey;
    onSuccess?: () => void;
}

const DeleteOrderConfirm = (props: Props) => {
    const [actionDisabled, setActionDisabled] = useState<boolean>(false);
    const { onClose, onSuccess, deleteContext, queryKey } = props;
    const { t } = useTranslation();
    const { getToken } = useGetUser();
    const queryClient = useQueryClient();

    const deleteOrderMutation = useMutation({
        mutationFn: deleteOrder(),
        onSuccess: () => {
            if (queryKey && deleteContext) {
                queryClient.setQueryData(queryKey, (prev?: InfiniteOrdersCache) =>
                    prev ? deleteOrderFromInfiniteOrderCache(prev, deleteContext) : prev,
                );
            }
            snackbar.success(t('purchase-order.snackbar.order-delete-successful'));
            onClose();
            onSuccess && onSuccess();
        },
        onError: () => {
            snackbar.error(t('purchase-order.snackbar.order-delete-failed'));
        },
        onSettled: () => {
            setActionDisabled(false);
        },
    });

    const performDeleteOrder = (deleteContext: string) => {
        const queryVars = { orderId: deleteContext, getToken };
        setActionDisabled(true);
        deleteOrderMutation.mutate(queryVars);
    };

    if (!deleteContext) return null;

    return (
        <Dialog open={!!deleteContext} onClose={onClose}>
            <DialogTitle onClose={onClose} title={t('purchase-order-order-delete-title')} />
            <DialogContent>
                <DialogContentText>{t('purchase-order-order-delete-description')}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button disabled={actionDisabled} variant="outlined" label={t('common.cancel')} onClick={onClose} data-testid="cancel" />
                <Button
                    variant="contained"
                    data-testid="dialog-confirm"
                    appearance="danger"
                    label={t('common.action.delete')}
                    disabled={actionDisabled}
                    onClick={() => performDeleteOrder(deleteContext)}
                    color="primary"
                    autoFocus
                />
            </DialogActions>
        </Dialog>
    );
};

export default DeleteOrderConfirm;
