import { Autocomplete, AutocompleteItem } from '@akelius-con/react-ui-kit-components';
import { OnChange } from '@akelius-con/react-ui-kit-components/Autocomplete';
import { useQuery } from '@tanstack/react-query';
import debounce from 'lodash.debounce';
import { cacheKeys } from 'modules/order/constants';
import { getUsers } from 'modules/order/graphql/queries/getUsers';
import { UserFilter } from 'modules/order/types';
import { useGetUser } from 'modules/order/utils/useGetUser';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEBOUNCE_INTERVAL } from 'shared/constant';
import prepareUsersDropdownData from './prepareUsersDropdownData';

export interface IProps {
    onChange: (selectedUser: AutocompleteItem | null) => void;
    selectedUser: AutocompleteItem | null;
    disabled?: boolean;
    onSelectUser?: (user: AutocompleteItem | null) => void;
}

export const StaffSelectionDropdown = (props: IProps) => {
    const { onChange, selectedUser, onSelectUser, disabled } = props;
    const { t } = useTranslation();
    const { getToken } = useGetUser();
    const [userFilters, setUserFilters] = useState<UserFilter>({ name: '' });

    const { data: usersData, refetch: fetchUsers } = useQuery(cacheKeys.users(), getUsers({ getToken, pageSize: 10, filters: userFilters }), {
        refetchOnWindowFocus: false,
    });
    const debouncedSearch = useMemo(() => debounce(() => fetchUsers(), DEBOUNCE_INTERVAL), [fetchUsers]);

    const onUserStrChange = useCallback(
        (event: ChangeEvent<{ value: string }>) => {
            const newFilters = {
                ...userFilters,
                name: event.target.value,
            };

            setUserFilters(newFilters);
            debouncedSearch();
        },
        [userFilters, setUserFilters, debouncedSearch],
    );

    const onChangeUserHandler: OnChange = useCallback(
        (_event, user) => {
            let transformed: AutocompleteItem | null = null;

            if (user) {
                transformed = {
                    ...user,
                    label: user.name,
                };
            }

            onChange(transformed);
            onSelectUser && onSelectUser(transformed);

            if (!user) {
                const filters = { ...userFilters, name: '' };
                setUserFilters(filters);
                setTimeout(function () {
                    fetchUsers();
                });
            }
        },
        [onChange, userFilters, onSelectUser, fetchUsers],
    );

    const getSelectedUser = useCallback((user: AutocompleteItem | null) => {
        let transformed = null;
        if (user) {
            transformed = {
                ...user,
                label: `${user.name}, ${user.role}`,
            };
        }
        return transformed;
    }, []);

    return (
        <Autocomplete
            data-testid="staff-selection"
            onChange={onChangeUserHandler}
            options={prepareUsersDropdownData(usersData)}
            loadingText={t('common.loading')}
            value={getSelectedUser(selectedUser)}
            disabled={disabled}
            noOptionsText={t('common.no-matching-result')}
            inputProps={{
                label: `${t('purchase-order.staff-selection.select-staff')} *`,
                onChange: onUserStrChange,
            }}
        />
    );
};
