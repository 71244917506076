import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { GetToken, useGetUser } from 'modules/order/utils/useGetUser';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';
import { cacheKeys } from '../../../modules/order/constants';
import { GraphqlErrorType } from '../../types/GraphqlErrorType';
import { Supplier } from './getSuppliers';

export interface SupplierQueryResponse {
    supplier: Supplier;
}

export interface GetSupplierParam {
    supplierId: string | null;
    getToken: GetToken;
}

export const useGetSupplierAttentioRequried = (supplierId: string, options?: {}) => {
    const { getToken } = useGetUser();
    return useQuery<SupplierQueryResponse, GraphqlErrorType>(
        cacheKeys.supplier(supplierId),
        getSupplierAttentionRequired({ getToken, supplierId }),
        options,
    );
};

export const getSupplier = (param: GetSupplierParam) => async () => {
    const { supplierId, getToken } = param;
    const { graphQLClient } = await getGraphqlClient(getToken);

    const getSupplierQuery = gql`
        query supplier($supplierId: String!) {
            supplier(supplierId: $supplierId) {
                id
                name
                address
                addressDetails {
                    region
                    city
                    country
                }
                requiresAttention
            }
        }
    `;

    const data = await graphQLClient.request<SupplierQueryResponse>(getSupplierQuery, {
        supplierId,
    });

    return data;
};

export const getSupplierAttentionRequired = (param: GetSupplierParam) => async () => {
    const { supplierId, getToken } = param;
    const { graphQLClient } = await getGraphqlClient(getToken);

    const getSupplierQuery = gql`
        query supplier($supplierId: String!) {
            supplier(supplierId: $supplierId) {
                requiresAttention
            }
        }
    `;

    const data = await graphQLClient.request<SupplierQueryResponse>(getSupplierQuery, {
        supplierId,
    });

    return data;
};
