import { colors, theme } from '@akelius-con/react-theme';
import { makeStyles } from '@akelius-con/react-ui-kit-components';
import { AkDelete, AkDownload, AkExternalLink, AkFileCopy, AkHistory } from '@akelius-con/react-ui-kit-icons';
import { Chip, Grid, Tooltip, Typography } from '@mui/material';
import { routeConst } from 'modules/order/constants';
import { useDownloadOrderPdf } from 'modules/order/utils/useDownloadOrderPdf';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ContextMenu } from 'shared/components/ContextMenu';
import { PermissionEnum } from 'shared/components/HandleAuthPermissionsSettings/PermissionEnum';
import { usePermissions } from 'shared/components/HandleAuthPermissionsSettings/usePermission';
import { LinkToAcmProject } from 'shared/components/LinkToAcmProject';
import { centsToPrice } from 'shared/helpers/priceConverter';
import { formatAmountWithoutCurrency } from 'shared/utils/amountFormatter';
import formatDateTime from 'shared/utils/formatDateTime';
import { isOrderReadonlyByStatus } from 'shared/utils/order';
import { Order } from '../../types';
import LinkToCase from '../LinkToCase';
import OrderStatus from '../OrderStatus';
import { useOrderApprovalHistory } from 'modules/order/graphql/queries/useGetOrderApprovalHistory';
import { OrderStatusEnum } from 'modules/order/graphql/queries/useGetOrder';
import { LinkToSupplier } from 'shared/components/LinkToSupplier';

const useStyles = makeStyles()({
    order: {
        border: `1px solid ${colors.lineGrey}`,
        boxShadow: 'none',
        transition: 'border-color 200ms',
        borderRadius: '3px',
        marginBottom: '16px',
        minHeight: '120px',
    },
    singleBottomMargin: {
        marginBottom: theme.spacing(1),
    },
    orderTitle: {
        color: `${colors.black}`,
        '&:hover': {
            cursor: 'pointer',
            '& h3': {
                color: `${colors.black}`,
            },
            '& a': {
                textDecoration: `underline ${colors.black}`,
            },
        },
    },
    orderInner: {
        padding: theme.spacing(2),
    },
    orderNumber: {
        marginTop: theme.spacing(2),
    },
    link: {
        textDecoration: 'none',
        display: 'inline-block',
        '&:hover': {
            textDecoration: `underline ${colors.black}`,
        },
    },
    id: {
        display: 'inline-block',
        marginBottom: '8px',
    },
    rowContent: {
        paddingTop: '8px !important',
        paddingBottom: '৪px',
    },
});

interface IProps {
    order: Order;
    onDelete: (id: string) => void;
    onCopyOrder: (id: string) => void;
    onHistory: (id: string) => void;
}

const OrderRow: FC<IProps> = props => {
    const { onCopyOrder, onDelete, order, onHistory } = props;
    const {
        title,
        id,
        creator,
        currency,
        releaseUser,
        orderNumber,
        createdDate,
        status,
        referenceNumber,
        totalPriceCentsGross,
        supplier,
        carrierOfCost,
    } = order;
    const { classes } = useStyles();
    const { t } = useTranslation();
    const [orderApprovalLinkContext, setOrderApprovalLinkContext] = useState<string | null>(null);
    const { performDownload, isDownloadDisabled } = useDownloadOrderPdf(order);
    const { useIsPermitted } = usePermissions();

    const { refetch: openApprovalLink } = useOrderApprovalHistory(id, {
        enabled: false,
        refetchOnWindowFocus: false,
        onError: error => console.log(error),
        onSuccess: data => {
            if (orderApprovalLinkContext) {
                const approvalLink = data?.order.approval.url;
                if (approvalLink) {
                    setOrderApprovalLinkContext(null);
                    window.open(approvalLink, '_blank');
                }
            }
        },
    });

    const submitterName = (releaseUser && releaseUser.name) || creator.name;
    const isDeleteOrderPermitted = useIsPermitted(PermissionEnum.DELETE_ORDER);

    const contextMenuConfig = [
        {
            id: 1,
            testid: 'view-in-approval-app',
            label: t('purchase-order.button.view-in-approval-app'),
            handler: () => {
                setOrderApprovalLinkContext(order.id);
                openApprovalLink();
            },
            icon: AkExternalLink,
            isDisabled: order.status === OrderStatusEnum.CREATED || order.status === OrderStatusEnum.SENT_TO_APPROVAL,
        },
        {
            id: 2,
            testid: 'order-pdf-download',
            label: t('purchase-order.button.download-draft'),
            handler: performDownload,
            icon: AkDownload,
            isDisabled: isDownloadDisabled,
        },
        {
            id: 3,
            testid: 'copy-order',
            label: t('purchase-order.button.copy-order'),
            handler: () => onCopyOrder(id),
            icon: AkFileCopy,
        },
        {
            id: 4,
            testid: 'delete-order',
            color: 'error' as const,
            label: t('purchase-order.button.delete-order'),
            handler: () => onDelete(id),
            icon: AkDelete,
            isDisabled: !isDeleteOrderPermitted || isOrderReadonlyByStatus(status),
        },
        {
            id: 5,
            testid: 'order-history',
            label: t('purchase-order.button.order-history'),
            handler: () => onHistory(id),
            icon: AkHistory,
        },
    ];

    return (
        <div className={classes.order} data-testid={`order-row`}>
            <Grid container spacing={2} className={classes.orderInner}>
                <Grid item xs={12} sm={12}>
                    <Grid container>
                        <Grid item flexGrow={1}>
                            <Link className={classes.link} to={routeConst.orderDetails(id)} data-testid="title">
                                <Typography className={classes.orderTitle} variant="h3">
                                    {title}
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid item>
                            <ContextMenu data-testid="order-row-context-menu" items={contextMenuConfig} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} className={classes.rowContent}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={3}>
                            <Tooltip title={t('datatable.header.orderStatus') || ''} placement="right">
                                <span>
                                    <OrderStatus status={status} />
                                </span>
                            </Tooltip>

                            <div />

                            {orderNumber && (
                                <div className={classes.orderNumber}>
                                    <Tooltip title={t('datatable.header.order-number') || ''} placement="right">
                                        <span>
                                            <Chip variant="outlined" label={orderNumber} />
                                        </span>
                                    </Tooltip>
                                </div>
                            )}
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <div className={classes.id}>
                                <Tooltip
                                    title={carrierOfCost.property?.name ? t('datatable.header.property-name') : t('datatable.header.company-name')}
                                    placement="right"
                                >
                                    <Typography data-testid="company-id" variant="body1">
                                        {carrierOfCost.property?.name || carrierOfCost?.company?.name}
                                    </Typography>
                                </Tooltip>
                            </div>

                            <div />

                            {order?.case?.caseId && (
                                <div className={classes.id}>
                                    <Tooltip title={t('purchase-order.general-info.case-id') || ''} placement="right">
                                        <span>
                                            <LinkToCase noLabel caseId={order.case.caseId} />
                                        </span>
                                    </Tooltip>
                                </div>
                            )}

                            <div />

                            {carrierOfCost.project?.id ? (
                                <div className={classes.id}>
                                    <Tooltip title={t('datatable.header.project-id')} placement="right">
                                        <span>
                                            <LinkToAcmProject projectId={carrierOfCost.project.id} data-testid="project-id" />
                                        </span>
                                    </Tooltip>
                                </div>
                            ) : (
                                <div className={classes.id}>
                                    <Tooltip title={t('purchase-order.general-info.akelius-ref') || ''} placement="right">
                                        <Typography data-testid="company-id" variant="body1">
                                            {referenceNumber}
                                        </Typography>
                                    </Tooltip>
                                </div>
                            )}
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <Tooltip title={t('datatable.header.supplier') || ''} placement="right">
                                <div>
                                    <LinkToSupplier
                                        className={classes.singleBottomMargin}
                                        supplier={supplier}
                                        data-testid="supplier"
                                        variant="body1"
                                    />
                                </div>
                            </Tooltip>

                            <Typography data-testid="price" variant="body1" className={classes.singleBottomMargin}>
                                <Tooltip title={t('datatable.header.price') || ''} placement="right">
                                    <span>{`${currency} ${formatAmountWithoutCurrency(centsToPrice(totalPriceCentsGross))}`}</span>
                                </Tooltip>
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            {createdDate && (
                                <Typography data-testid="createdDate" variant="body1" className={classes.singleBottomMargin}>
                                    <Tooltip title={t('datatable.header.createDate') || ''} placement="right">
                                        <span>{formatDateTime(createdDate)}</span>
                                    </Tooltip>
                                </Typography>
                            )}
                            <Typography data-testid="submitter" variant="body1">
                                <Tooltip title={t('datatable.header.submitter') || ''} placement="right">
                                    <span>{submitterName}</span>
                                </Tooltip>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default OrderRow;
