const dateFormatter = (isoString: string) => {
    const dateObj = new Date(isoString);

    if (Number.isNaN(Date.parse(isoString))) return 'Invalid Date';

    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');

    const date = dateObj.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${date}`;
};

export default dateFormatter;
