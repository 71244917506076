import { theme } from '@akelius-con/react-theme';
import { AcImageOption, Autocomplete, AutocompleteItem, Button, DialogTitle, MessageBox } from '@akelius-con/react-ui-kit-components';
import { OnChange, RenderOption } from '@akelius-con/react-ui-kit-components/Autocomplete';
import { Box, Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import debounce from 'lodash.debounce';
import { cacheKeys } from 'modules/order/constants';
import getApartments from 'modules/order/graphql/queries/getApartments';
import { Apartment, OrderAssociatedProperty, PropertyFilters } from 'modules/order/types';
import { getPropertyImage } from 'modules/order/utils/getPropertyImage';
import { useGetUser } from 'modules/order/utils/useGetUser';
import { ChangeEvent, MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEBOUNCE_INTERVAL } from 'shared/constant';
import { getSelectedCountry } from 'shared/utils/serviceCountry';
import getProperties from '../../../graphql/queries/getProperties';
import mapPropertyToAutoCompleteItem from './mapPropertyAutoCompleteItem';
import prepareApartmentsDropdownData from './prepareApartmentDropdownData';
import preparePropertiesDropdownData from './preparePropertiesDropdownData';

interface Props {
    open: boolean;
    caseOrder?: boolean;
    isLoading: boolean;
    property: OrderAssociatedProperty;
    onSave: (property: OrderAssociatedProperty) => void;
    onClose: () => void;
}

const selectedApartmentAcItem = (property?: OrderAssociatedProperty) => {
    if (!property?.apartment) return null;
    return { label: property.apartment?.name, value: property.apartment?.id };
};

const AddEditCarrierOfCostModal = (props: Props) => {
    const { open, onClose, property, caseOrder, onSave, isLoading } = props;
    const { t } = useTranslation();
    const { getToken } = useGetUser();
    const [selectedProperty, setSelectedProperty] = useState<AutocompleteItem | null>(null);
    const [selectedApartment, setSelectedApartment] = useState<AutocompleteItem | null>(null);
    const selectedCountry = getSelectedCountry();
    const [propertyFilters, setPropertyFilters] = useState<PropertyFilters>({ countryCode: selectedCountry, term: '' });

    const {
        isError: propertiesDataError,
        data: propertiesData,
        refetch: fetchProperties,
    } = useQuery(cacheKeys.properties(), getProperties({ pageSize: 10, filters: propertyFilters, getToken }), {
        enabled: true,
        refetchOnWindowFocus: false,
    });

    const {
        isError: apartmentsDataError,
        isLoading: apartmentDataLoading,
        data: apartmentsData,
        refetch: fetchApartments,
    } = useQuery(cacheKeys.apartments, getApartments({ getToken, propertyId: selectedProperty?.value as string }), {
        enabled: false,
        refetchOnWindowFocus: false,
    });

    const modalCloseHandler = useCallback(
        (_event: MouseEventHandler<HTMLButtonElement>, reason: 'backdropClick' | 'escapeKeyDown') => {
            if (reason !== 'backdropClick') onClose();
        },
        [onClose],
    );

    const debouncedRefetchProperties = useMemo(() => debounce(() => fetchProperties(), DEBOUNCE_INTERVAL), [fetchProperties]);

    const onChangePropertyFilter = useCallback(
        (event: ChangeEvent<{ value: string }>) => {
            const term = event.target.value;

            const filters = { ...propertyFilters, term };
            setPropertyFilters(filters);
            debouncedRefetchProperties();
        },
        [propertyFilters, setPropertyFilters, debouncedRefetchProperties],
    );

    const onChangeProperty: OnChange = useCallback(
        (_event, property) => {
            setSelectedProperty(property);
            setSelectedApartment(null);
            if (!property) {
                const filters = { ...propertyFilters, term: '' };
                setPropertyFilters(filters);
                setTimeout(function () {
                    fetchProperties();
                }, 200);
            }
        },
        [setSelectedProperty, fetchProperties, propertyFilters],
    );

    const onChangeApartment: OnChange = useCallback(
        (_event, apartment) => {
            setSelectedApartment(apartment);
        },
        [setSelectedApartment],
    );

    const saveCarrierOfCost = useCallback(() => {
        const apartment: Apartment | null = apartmentsData?.property.apartments.find(a => a.id === selectedApartment?.value) || null;
        if (selectedProperty) {
            const { address, code, addressDetails, name, id } = selectedProperty;
            const associatedProperty: OrderAssociatedProperty = {
                id,
                code,
                name,
                address,
                addressDetails,
                apartment,
            };
            onSave(associatedProperty);
        }
    }, [apartmentsData?.property.apartments, selectedProperty, onSave, selectedApartment?.value]);

    const isSaveEnabled = useCallback(() => {
        if (selectedProperty?.value) return true;
        return false;
    }, [selectedProperty]);

    useEffect(() => {
        if (selectedProperty) fetchApartments();
    }, [fetchApartments, selectedProperty]);

    useEffect(() => {
        const propertyAutoCompleteItem = property ? mapPropertyToAutoCompleteItem(property) : null;
        setSelectedProperty(propertyAutoCompleteItem);

        setSelectedApartment(selectedApartmentAcItem(property));
    }, [property]);

    const renderOption: RenderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: AutocompleteItem) => {
        return <AcImageOption {...props} title={option.label} subtitle={option.address} thumbnail={getPropertyImage(option.value)} />;
    };

    return (
        <Dialog maxWidth="sm" fullWidth open={open} onClose={modalCloseHandler} data-testid="add-edit-carrier-of-cost-modal">
            <DialogTitle onClose={onClose} title={t('purchase-order.add-carrier-of-cost')} />

            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <MessageBox type="info">{t('purchase-order.add-carrier-of-cost.info')}</MessageBox>
                        <Box height={theme.spacing(2)} />

                        {propertiesDataError && (
                            <>
                                <MessageBox type="error">{t('purchase-order.add-carrier-of-cost.property-fetch-error')}</MessageBox>
                                <Box height={theme.spacing(2)} />
                            </>
                        )}

                        {apartmentsDataError && (
                            <>
                                <MessageBox type="error">{t('purchase-order.add-carrier-of-cost.apartment-fetch-error')}</MessageBox>
                                <Box height={theme.spacing(2)} />
                            </>
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        <Autocomplete
                            data-testid="property-selection"
                            onChange={onChangeProperty}
                            filterOptions={x => x}
                            options={preparePropertiesDropdownData(propertiesData?.properties.items)}
                            disabled={isLoading || caseOrder}
                            loadingText={t('common.loading')}
                            value={selectedProperty}
                            noOptionsText={t('common.no-matching-result')}
                            renderOption={renderOption}
                            inputProps={{
                                helperText: t('purchase-order.carrier-of-cost.search-options'),
                                label: `${t('purchase-order.common.property')} *`,
                                onChange: onChangePropertyFilter,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            data-testid="apartment-selection"
                            onChange={onChangeApartment}
                            options={prepareApartmentsDropdownData(apartmentsData?.property?.apartments)}
                            disabled={isLoading}
                            loading={apartmentDataLoading}
                            loadingText={t('common.loading')}
                            value={selectedApartment}
                            noOptionsText={t('common.no-matching-result')}
                            inputProps={{
                                label: t('purchase-order.carrier-of-cost.apartment'),
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" label={t('common.cancel')} onClick={onClose} data-testid="cancel" />
                <Button
                    isLoading={isLoading}
                    onClick={saveCarrierOfCost}
                    disabled={!isSaveEnabled()}
                    data-testid="coc-save-button"
                    variant="contained"
                    type="submit"
                    label={t('common.save')}
                />
            </DialogActions>
        </Dialog>
    );
};

export default AddEditCarrierOfCostModal;
