import { colors } from '@akelius-con/react-theme';
import { Button, makeStyles } from '@akelius-con/react-ui-kit-components';
import { AkExternalLink, AkFileCopy, AkGlobe } from '@akelius-con/react-ui-kit-icons';
import { Box, Grid, Link, Typography } from '@mui/material';
import { FeatureToggleListItem } from 'modules/order/pages/DebugInfo/components/FeatureToggleListItem';
import { useGetUser } from 'modules/order/utils/useGetUser';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { snackbar } from 'shared/components/Snackbar';
import { checkMissingTranslations } from 'shared/components/TranslationProvider/i18n';
import { FeatureToggleKeys } from 'shared/utils/featureToggle';

const useStyles = makeStyles()({
    pageWrapper: {
        marginTop: '20px',
        width: '100%',
    },
    copySuccess: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        color: colors.success,
        '& svg': {
            color: colors.success,
        },
    },
    commitLink: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        color: colors.black,
        '&:hover': {
            textDecoration: 'underline',
        },
    },
});

const camelCaseToSentence = (text: string) => {
    const result = text.replace(/([A-Z])/g, ' $1');
    return result.toLocaleLowerCase();
};

const Dashboard: FC = () => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const [token, setToken] = useState<string | null>(null);
    const commitHash = import.meta.env.VITE_APP_LAST_COMMIT_HASH as string;
    const commitUrl = commitHash ? `https://github.com/Akelius/order-application-v2/commit/${commitHash}` : '';
    const deployedOn = import.meta.env.VITE_APP_DEPLOYED_ON;
    const { getToken } = useGetUser();
    const user = useGetUser();

    useEffect(() => {
        getToken().then(token => setToken(token));
    }, [getToken, setToken]);

    const handleCopyButtonClick = useCallback(() => {
        if (token) {
            navigator.clipboard.writeText(token);
            snackbar.success('token copied to clipboard');
        }
    }, [token]);

    const handleCheckMissingTranslations = useCallback(() => {
        snackbar.success('check translations in console');
        checkMissingTranslations();
    }, []);

    const featureFlags = Object.values(FeatureToggleKeys);

    return (
        <div className={classes.pageWrapper}>
            <Typography data-testid="section-title" variant="h2" mb={4}>
                {t('debug-info.title')}
            </Typography>

            {Object.entries(user)
                .filter(([key]) => key !== 'picture')
                .map(([key, value]) => (
                    <Grid container spacing={2} key={key}>
                        <Grid item xs={4}>
                            <Typography variant="body1">{key}</Typography>
                        </Grid>
                        <Grid item xs={8} style={{ marginTop: '10px' }}>
                            {typeof value === 'string' && <Typography variant="body1">{value}</Typography>}
                            {Array.isArray(value) && (
                                <ul style={{ paddingLeft: '18px', marginTop: 0 }}>
                                    {value.map(item => (
                                        <li key={item}>
                                            <Typography variant="body1">{item}</Typography>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </Grid>
                    </Grid>
                ))}

            {token && (
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Typography variant="body1">access token</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Button onClick={handleCopyButtonClick} variant="outlined" label="copy access token" startIcon={<AkFileCopy />} />
                    </Grid>
                </Grid>
            )}

            <Box mt={4} />

            {commitUrl && (
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Typography variant="body1">deployed on</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body1">{deployedOn}</Typography>
                    </Grid>
                </Grid>
            )}

            {commitUrl && (
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Typography variant="body1">commit info</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Link target="_blank" href={commitUrl}>
                            <Typography variant="body1" className={classes.commitLink}>
                                <AkExternalLink fontSize={22} />
                                {commitHash?.slice(-8)}
                            </Typography>
                        </Link>
                    </Grid>
                </Grid>
            )}

            <Box mt={4} />

            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Typography variant="body1">i18n</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Button onClick={handleCheckMissingTranslations} variant="outlined" label="check translations" startIcon={<AkGlobe />} />
                </Grid>
            </Grid>

            {featureFlags && (
                <Grid container spacing={2} mt={4}>
                    <Grid xs={12} item>
                        <Typography data-testid="section-title" variant="h2">
                            show hide features
                        </Typography>
                    </Grid>

                    {featureFlags.map(ff => (
                        <FeatureToggleListItem key={ff} featureKey={FeatureToggleKeys[ff]} label={camelCaseToSentence(ff)} />
                    ))}
                </Grid>
            )}
        </div>
    );
};

export default Dashboard;
