import { Button, DialogTitle } from '@akelius-con/react-ui-kit-components';
import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { cacheKeys } from 'modules/order/constants';
import cancelOrder from 'modules/order/graphql/mutations/cancelOrder';
import { OrderStatusEnum } from 'modules/order/graphql/queries/useGetOrder';
import { Order } from 'modules/order/types';
import { useGetUser } from 'modules/order/utils/useGetUser';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { snackbar } from 'shared/components/Snackbar';

interface Props {
    closeCancelOrderDialog: () => void;
    order: Order;
}

const CancelOrderConfirm: FC<Props> = props => {
    const [actionDisabled, setActionDisabled] = useState<boolean>(false);
    const { getToken } = useGetUser();
    const { closeCancelOrderDialog, order } = props;
    const { id: orderId, status } = order;
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const cancelOrderMutation = useMutation(cancelOrder(), {
        onSuccess: res => {
            snackbar.success(t('purchase-order.cancel.success-msg'));

            queryClient.invalidateQueries(cacheKeys.order(res.cancelOrder.id));

            setActionDisabled(false);
            closeCancelOrderDialog();
        },
    });

    const performCancelOrder = useCallback(() => {
        setActionDisabled(true);
        cancelOrderMutation.mutate({ orderId, getToken });
    }, [cancelOrderMutation, getToken, orderId]);

    return (
        <Dialog open={!!orderId} onClose={closeCancelOrderDialog} data-testid="cancel-order-confirm-modal">
            <DialogTitle onClose={closeCancelOrderDialog} title={t('purchase-order.cancel-order-dialog.title')} />
            <DialogContent>
                <Typography variant="body1">
                    {status === OrderStatusEnum.SENT_TO_APPROVAL || status === OrderStatusEnum.PENDING_APPROVAL
                        ? t('purchase-order.cancel-order-dialog.description2')
                        : t('purchase-order.cancel-order-dialog.description1')}
                </Typography>
                <Typography mt={1.5} variant="body1">
                    {t('purchase-order.cancel-order-dialog.description-second-paragraph')}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={actionDisabled}
                    variant="outlined"
                    label={t('common.cancel')}
                    onClick={closeCancelOrderDialog}
                    data-testid="cancel"
                />
                <Button
                    variant="contained"
                    data-testid="dialog-confirm"
                    label={t('purchase-order.cancel-order')}
                    disabled={actionDisabled}
                    onClick={performCancelOrder}
                    color="primary"
                    appearance="danger"
                    autoFocus
                />
            </DialogActions>
        </Dialog>
    );
};

export default CancelOrderConfirm;
