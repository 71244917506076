import { Box, Grid } from '@mui/material';
import cn from 'classnames';
import { makeStyles } from '@akelius-con/react-ui-kit-components';
import { colors } from '@akelius-con/react-theme';

const useStyles = makeStyles()({
    stickyFooter: {
        minHeight: '48px',
        bottom: 0,
        left: 0,
        boxShadow: '-2px 0px 4px 0px rgb(0 0 0 / 18%)',
        position: 'fixed',
        top: 'auto',
        width: '100%',
        padding: '16px',
        backgroundColor: colors.white,
        boxSizing: 'border-box',
        zIndex: 1,
    },
});

interface Props {
    className?: string;
    children: JSX.Element;
}

const StickyPageFooter = (props: Props) => {
    const { className, children } = props;
    const { classes } = useStyles();
    return (
        <Box className={cn(classes.stickyFooter, className || '')}>
            <Grid container spacing={2}>
                <Grid item xs={1} />
                <Grid item xs={10}>
                    {children}
                </Grid>
                <Grid item xs={1} />
            </Grid>
        </Box>
    );
};

export default StickyPageFooter;
