import { colors } from '@akelius-con/react-theme';
import { Autocomplete, AutocompleteItem as Option, makeStyles } from '@akelius-con/react-ui-kit-components';
import { OnChange } from '@akelius-con/react-ui-kit-components/Autocomplete';
import { Typography } from '@mui/material';
import { FormikProps } from 'formik';
import { t } from 'i18next';
import { OrderTypesEnum } from 'modules/order/types';
import { FC } from 'react';

interface Props {
    disabled?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik?: FormikProps<any>;
    value?: Option | null;
    required?: boolean;
    label: string;
    preventDisableOnSubmit?: boolean;
    onChange: (purpose: Option) => void;
}

const useStyles = makeStyles()({
    hide: {
        display: 'none',
    },
    error: {
        color: colors.error.main,
    },
});

export const getPurposeOption = (purpose: OrderTypesEnum) => {
    return {
        label: t(`purchase-order.order-types.${purpose.toLowerCase()}-related-cost`),
        value: purpose,
    };
};

const PurposeDropdown: FC<Props> = props => {
    const { onChange, value, formik, required, label, disabled, preventDisableOnSubmit } = props;
    const { classes } = useStyles();

    const handleChangePurpose: OnChange = (_event, selectedOption) => {
        if (selectedOption === null || (formik && formik.values.costCenterType === selectedOption.value)) {
            return;
        }

        onChange(selectedOption);
    };

    const getAllPurposeOptions = (): Option[] => {
        return Object.keys(OrderTypesEnum).map(key => getPurposeOption(OrderTypesEnum[key as keyof typeof OrderTypesEnum]));
    };

    return (
        <>
            <Autocomplete
                onChange={handleChangePurpose}
                options={getAllPurposeOptions()}
                filterOptions={purpose => purpose}
                disabled={disabled || (!preventDisableOnSubmit && formik && formik.isSubmitting)}
                error={formik && !!(formik.errors.costCenterType && formik.touched.costCenterType)}
                onBlur={() => formik && formik.setFieldTouched('costCenterType')}
                value={value || null}
                data-testid="purpose-selection"
                inputProps={{
                    label: `${label} ${required ? '*' : ''}`,
                    onKeyPress: e => e.preventDefault(),
                    sx: { caretColor: 'transparent' },
                }}
            />

            {formik && formik.errors.costCenterType && formik.touched.costCenterType && (
                <Typography variant="body1" className={classes.error}>
                    {formik.errors.costCenterType as string}
                </Typography>
            )}
        </>
    );
};

export default PurposeDropdown;
