import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { snackbar } from 'shared/components/Snackbar';
import { isOrderReadonlyByStatus } from 'shared/utils/order';
import { backendRoutes } from '../../../shared/routes';
import { Order } from '../types';
import { useGetUser } from './useGetUser';

export const useDownloadOrderPdf = (order: Order) => {
    const { t } = useTranslation();
    const [downloadPdfInProgress, setDownloadPdfInProgress] = useState<boolean>(false);
    const { getToken } = useGetUser();

    const performDownload = async () => {
        setDownloadPdfInProgress(true);
        snackbar.info(t('purchase-order.snackbar.file-downloading'));
        const url = backendRoutes.orderPdf(order.id);

        const accessToken = await getToken();

        const response = await fetch(url, {
            method: 'GET',
            headers: { authorization: `Bearer ${accessToken}` },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const blob = await response.blob(),
            fileUrl = window.URL.createObjectURL(blob),
            tab = window.open();

        if (tab) {
            tab.location.href = fileUrl;
        }

        setDownloadPdfInProgress(false);
    };

    const isDownloadDisabled = !order?.status || isOrderReadonlyByStatus(order.status) || downloadPdfInProgress;

    return { performDownload, downloadPdfInProgress, isDownloadDisabled };
};
