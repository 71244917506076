import { LoadingSpinner, MessageBox, makeStyles } from '@akelius-con/react-ui-kit-components';
import { Box, Grid, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Order } from 'modules/order/types';
import { useGetUser } from 'modules/order/utils/useGetUser';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { cacheKeys } from '../../constants';
import { getInvoices } from '../../graphql/queries/getInvoices';
import InvoicesSummary from './InvoicesSummary';
import InvoicesTable from './InvoicesTable';

const useStyles = makeStyles()({
    title: {
        margin: '0 0 10px',
    },
    containerWrapper: {
        marginTop: '26px',
    },
});

interface IProps {
    order: Order;
}

const InvoicesTab: FC<IProps> = ({ order }) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const { getToken } = useGetUser();

    const {
        isLoading,
        data: invoicesRequestData,
        error: invoicesRequestError,
        refetch: fetchInvoice,
    } = useQuery(cacheKeys.invoices, getInvoices({ orderNumber: order.orderNumber, getToken }), {
        enabled: false,
        retry: false,
    });

    useEffect(() => {
        if (order.orderNumber) {
            fetchInvoice();
        }
    }, [fetchInvoice, order.orderNumber]);

    return (
        <div className={classes.containerWrapper}>
            <Grid container spacing={2}>
                <>
                    {invoicesRequestError && (
                        <Grid item xs={12}>
                            <MessageBox type="error">{t('purchase-order.invoices.loading-failed')}</MessageBox>
                        </Grid>
                    )}

                    {!isLoading && !!invoicesRequestData?.invoices.invoices.length && (
                        <>
                            <Grid xs={12} item>
                                <Typography className={classes.title} variant="h2">
                                    {t('purchase-order.invoices.ordered')}
                                </Typography>

                                <InvoicesSummary invoiceList={invoicesRequestData.invoices} />
                            </Grid>
                            <Grid xs={12}>
                                <Box mt={2} />
                            </Grid>
                            <Grid xs={12} item>
                                <Typography className={classes.title} variant="h2">
                                    {t('purchase-order.invoices.invoiced')}
                                </Typography>
                                <InvoicesTable invoiceList={invoicesRequestData.invoices} />
                            </Grid>
                        </>
                    )}

                    {!isLoading && invoicesRequestData?.invoices?.invoices?.length === 0 && (
                        <Grid xs={12} item>
                            <MessageBox type="info">{t('purchase-order.invoices.no-invoices')}</MessageBox>
                        </Grid>
                    )}
                </>
            </Grid>

            {isLoading && <LoadingSpinner areaHeight="auto" />}
        </div>
    );
};

export default InvoicesTab;
