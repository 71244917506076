import { LoadingSpinner, makeStyles } from '@akelius-con/react-ui-kit-components';
import { AkDelete, AkEdit } from '@akelius-con/react-ui-kit-icons';
import { Box, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import FileTypeIcon from 'shared/components/FileTypeIcon';
import byteToMegaByte from 'shared/helpers/byteToMegaByte';
import dateFormatter from 'shared/utils/dateFormatter';
import { DocType, OrderDocument, availableDocTypes } from '../../types';
import { colors } from '@akelius-con/react-theme';

const useStyles = makeStyles()({
    tableTitle: {
        marginBottom: '20px',
    },
    tableCell: {
        padding: '16px',
        fontSize: '1rem',
    },
    withoutNotice: {
        maxHeight: '486px',
    },
    withNotice: {
        maxHeight: '386px',
    },
    tableHeadCell: {
        top: 0,
        zIndex: 2,
        position: 'sticky',
        backgroundColor: colors.white,
        borderBottom: `1px solid ${colors.black}`,
    },

    tableWrapper: {
        position: 'relative',
    },
    tableDataLoading: {
        position: 'absolute',
        top: 0,
        zIndex: 3,
        left: 0,
        height: '100%',
        width: '100%',
        backgroundColor: 'rgb(255 255 255 / 80%)',
    },
    fileTitleLink: {
        fontWeight: 'bold',
        textDecoration: 'none',
        color: colors.black,
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
    },
    actionButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& > div:first-of-type': {
            marginRight: '20px',
        },
        '& button': {
            marginRight: 0,
        },
    },
    ellipsis: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: 'calc(100% - 34px)',
    },
});

interface Header {
    title: string;
    align: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
    testid: string;
}

interface Props {
    hasNotice: boolean;
    documents: OrderDocument[];
    isReadonlyOrder: boolean;
    isLoading: boolean;
    onDocumentDownload: (documentId: string) => void;
    onDocumentEdit: (document: OrderDocument) => void;
    onDocumentRemove: (documentId: string) => void;
}

const OrderDocumentsTable = ({ documents, hasNotice, isReadonlyOrder, isLoading, onDocumentDownload, onDocumentEdit, onDocumentRemove }: Props) => {
    const { classes } = useStyles();
    const { t } = useTranslation();

    const headers: Header[] = [
        { title: t('purchase-order.documents.file'), align: 'left', testid: 'attachment-title' },
        { title: t('purchase-order.documents.document-type'), align: 'left', testid: 'attachment-type' },
        { title: t('purchase-order.documents.upload-date'), align: 'left', testid: 'attachment-upload-date' },
        { title: t('purchase-order.documents.uploaded-by'), align: 'left', testid: 'attachment-uploaded-by' },
        { title: t('purchase-order.documents.file-size'), align: 'left', testid: 'attachment-file-size' },
        { title: t('purchase-order.documents.supplier-attachment'), align: 'left', testid: 'is-supplier-attachment' },
        { title: t('purchase-order.documents.action'), align: 'right', testid: 'attachment-actions' },
    ];

    const isApplicationGeneratedDoc = (docType: string) => !availableDocTypes.includes(docType as DocType);

    return (
        <div className={classes.tableWrapper}>
            <TableContainer
                className={cn({ [classes.withoutNotice]: !hasNotice, [classes.withNotice]: hasNotice })}
                component={Box}
                data-testid="order-attachment-table"
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            {headers.map(({ title, align, testid }) => (
                                <TableCell className={cn(classes.tableCell, classes.tableHeadCell)} key={title} align={align} data-testid={testid}>
                                    <strong>{title}</strong>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {documents.map((document: OrderDocument) => {
                                const { id, fileTitle, docType, createdDate, creatorName, sendToSupplier, sizeInBytes } = document;
                                return (
                                    <TableRow key={id || fileTitle} data-testid={fileTitle} hover>
                                        <TableCell className={classes.tableCell} component="th" scope="row">
                                            <a className={classes.fileTitleLink} onClick={() => id && onDocumentDownload(id)}>
                                                <Grid container spacing={1}>
                                                    <Grid item>
                                                        <FileTypeIcon filename={fileTitle} />
                                                    </Grid>
                                                    <Grid item className={classes.ellipsis}>
                                                        {fileTitle}
                                                    </Grid>
                                                </Grid>
                                            </a>
                                        </TableCell>
                                        <TableCell className={classes.tableCell} component="th" scope="row">
                                            {t(`purchase-order.attachments.doc-types.${docType.toLowerCase()}`)}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>{dateFormatter(createdDate)}</TableCell>
                                        <TableCell className={classes.tableCell}>{creatorName}</TableCell>
                                        <TableCell className={classes.tableCell}>{`${byteToMegaByte(sizeInBytes || 0)} MB`}</TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {sendToSupplier ? t('purchase-order.attachments.yes') : t('purchase-order.attachments.no')}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} data-testid="order-item-action" align="right">
                                            <div className={classes.actionButtonContainer}>
                                                <Tooltip title={t('purchase-order.button.edit-document.tooltip') || ''}>
                                                    <div>
                                                        <IconButton
                                                            size="small"
                                                            data-testid="edit-item"
                                                            onClick={() => onDocumentEdit(document)}
                                                            disabled={isReadonlyOrder || isApplicationGeneratedDoc(docType) || !id}
                                                        >
                                                            <AkEdit />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>
                                                <Tooltip title={t('purchase-order.button.delete-document.tooltip') || ''}>
                                                    <div>
                                                        <IconButton
                                                            size="small"
                                                            data-testid="delete-item"
                                                            onClick={() => id && onDocumentRemove(id)}
                                                            disabled={isReadonlyOrder || isApplicationGeneratedDoc(docType) || !id}
                                                        >
                                                            <AkDelete />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </>
                    </TableBody>
                </Table>
            </TableContainer>
            {isLoading && (
                <div className={classes.tableDataLoading}>
                    <LoadingSpinner areaHeight="auto" label={t('purchase-order.common.please-wait')} />
                </div>
            )}
        </div>
    );
};

export default OrderDocumentsTable;
