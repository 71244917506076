import { makeStyles } from '@akelius-con/react-ui-kit-components';
import { AkDelete, AkEdit } from '@akelius-con/react-ui-kit-icons';
import { Checkbox, Chip, FormControlLabel, IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import classNames from 'classnames';
import { SupplierContact } from 'shared/graphql/queries/getSuppliers';
import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '@akelius-con/react-theme';

const useStyles = makeStyles()({
    checkboxColumn: {
        width: '24px',
        paddingRight: 0,
    },
    checkboxLabel: {
        '& .MuiCheckbox-root': {
            display: 'none',
        },
        '& .MuiTypography-root': {
            paddingLeft: '12px',
            fontWeight: 'bold',
        },
    },
    additionalContact: {
        '&+.MuiTypography-root': {
            color: `${colors.disabledGrey} !important`,
        },
    },
    tableCheckbox: {},
    blackColorOnSelection: {
        '&+span': {
            color: `${colors.black} !important`,
        },
    },
    actionButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& > div:first-of-type': {
            marginRight: '20px',
        },
        '& button': {
            marginRight: 0,
        },
    },
    vCenter: {
        verticalAlign: 'middle',
    },
});

type Contact = SupplierContact;

interface IProps {
    ['data-testid']: string;
    contactStateLoading: boolean;
    isReadOnly: boolean;
    contact: Contact;
    onSelectContact: (event: ChangeEvent<HTMLInputElement>) => void;
    isAdditionalContact?: boolean;
    editAdditionalContact: (additionalContact: SupplierContact) => void;
    handleDeleteContact: (additionalContact: SupplierContact) => void;
}

const ContactRow: FC<IProps> = props => {
    const { classes } = useStyles();
    const { t } = useTranslation();
    const {
        'data-testid': testid,
        contactStateLoading,
        isAdditionalContact,
        isReadOnly,
        contact,
        onSelectContact,
        editAdditionalContact,
        handleDeleteContact,
    } = props;

    return (
        <TableRow key={contact.name} data-testid={testid} hover>
            <TableCell className={classes.checkboxColumn}>
                <Checkbox
                    className={classes.tableCheckbox}
                    disabled={contactStateLoading || isReadOnly || isAdditionalContact}
                    checked={isAdditionalContact || contact?.isSelected}
                    onChange={onSelectContact}
                    name={contact.contactId}
                />
            </TableCell>
            <TableCell>
                <FormControlLabel
                    className={classes.checkboxLabel}
                    control={
                        <Checkbox
                            className={classNames({
                                [classes.blackColorOnSelection]: isAdditionalContact && !contactStateLoading && !isReadOnly,
                                [classes.additionalContact]: isAdditionalContact || isReadOnly,
                            })}
                            disabled={contactStateLoading || isReadOnly || isAdditionalContact}
                            checked={contact?.isSelected || isAdditionalContact}
                            onChange={onSelectContact}
                            name={contact.contactId}
                        />
                    }
                    label={contact.name}
                />
                {isAdditionalContact && <Chip variant="outlined" label="additional contact" />}
            </TableCell>
            <TableCell className={classes.vCenter}>{contact.email}</TableCell>
            <TableCell className={classes.vCenter} align="right">
                {contact.phone}
            </TableCell>
            <TableCell align="right" className={classes.vCenter}>
                <div className={classes.actionButtonContainer}>
                    <Tooltip title={isAdditionalContact && !isReadOnly && t('purchase-order.item.edit.tooltip')}>
                        <div>
                            <IconButton
                                size="small"
                                data-testid="edit-item"
                                onClick={() => editAdditionalContact(contact)}
                                disabled={!isAdditionalContact || isReadOnly}
                            >
                                <AkEdit />
                            </IconButton>
                        </div>
                    </Tooltip>
                    <Tooltip title={isAdditionalContact && !isReadOnly && t('purchase-order.item.delete.tooltip')}>
                        <div>
                            <IconButton
                                size="small"
                                data-testid="delete-item"
                                onClick={() => handleDeleteContact(contact)}
                                disabled={!isAdditionalContact || isReadOnly}
                            >
                                <AkDelete />
                            </IconButton>
                        </div>
                    </Tooltip>
                </div>
            </TableCell>
        </TableRow>
    );
};

export default ContactRow;
