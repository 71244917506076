import { MessageBox, makeStyles } from '@akelius-con/react-ui-kit-components';
import { AkWarning } from '@akelius-con/react-ui-kit-icons';
import { Box, Grid, Theme, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()((theme: Theme) => ({
    warning: {
        color: theme.palette.warning.main,
    },
}));

type Props = {
    minimal?: boolean;
};

const WARNING_TEXT_KEY = 'common.supplier.invalid-supplier';

function InvalidSupplierWarning(props: Props) {
    const { t } = useTranslation();
    const { classes } = useStyles();

    const { minimal } = props;

    if (minimal) {
        return (
            <Tooltip title={t(WARNING_TEXT_KEY)} placement="top">
                <div>
                    <AkWarning className={classes.warning} />
                </div>
            </Tooltip>
        );
    }

    return (
        <Grid item xs={12}>
            <Box mt={2} />
            <MessageBox type="warning">
                <Typography variant="body1">{t(WARNING_TEXT_KEY)}</Typography>
            </MessageBox>
        </Grid>
    );
}

export default InvalidSupplierWarning;
