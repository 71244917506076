import { Button, makeStyles } from '@akelius-con/react-ui-kit-components';
import { AkClose, AkDelete, AkDownload, AkFileCopy, AkForward, AkHelp, AkHistory } from '@akelius-con/react-ui-kit-icons';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import { OrderStatusEnum } from 'modules/order/graphql/queries/useGetOrder';
import { useDownloadOrderPdf } from 'modules/order/utils/useDownloadOrderPdf';
import { Order } from 'modules/order/types';
import isAttachmentMaxSizeOver from 'modules/order/utils/isAttachmentMaxSizeOver';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { StickyPageFooter } from 'shared/components';
import { usePermissions } from 'shared/components/HandleAuthPermissionsSettings/usePermission';
import { isOrderReadonlyByStatus } from 'shared/utils/order';
import validateOrder from '../validateOrder';
import { PermissionEnum } from 'shared/components/HandleAuthPermissionsSettings/PermissionEnum';
import { ContextMenu } from 'shared/components/ContextMenu';

interface IProps {
    order: Order;
    supportInfoLabels: {
        releaseInfo: string;
    };
    openCopyOrderDialog: () => void;
    openDeleteOrderDialog: () => void;
    openCancelOrderDialog: () => void;
    openReleaseOrderDialog: () => void;
    openForwardOrderDialog: () => void;
    onHistoryDialogHandlerOpen: (orderId: string) => void;
}

const useStyles = makeStyles()({
    lineBreak: {
        whiteSpace: 'pre-line',
    },
    validationInfo: {
        marginLeft: '8px !important',
        cursor: 'auto',
    },
});

export const Footer: FC<IProps> = props => {
    const {
        order,
        supportInfoLabels,
        openCopyOrderDialog,
        openDeleteOrderDialog,
        openReleaseOrderDialog,
        openForwardOrderDialog,
        onHistoryDialogHandlerOpen,
        openCancelOrderDialog,
    } = props;
    const { performDownload, isDownloadDisabled } = useDownloadOrderPdf(order);
    const { t } = useTranslation();
    const params = useParams<{ orderId: string; tabName: string }>();
    const { classes } = useStyles();
    const orderId = params.orderId as string;
    const { useIsPermitted } = usePermissions();
    const isDeleteOrderPermitted = useIsPermitted(PermissionEnum.DELETE_ORDER);

    const isOrderReleasable =
        order && validateOrder(order).size === 0 && !isAttachmentMaxSizeOver(order.documents) && !isOrderReadonlyByStatus(order.status);

    const contextMenuConfig = [
        {
            id: 2,
            testid: 'copy-order',
            label: t('purchase-order.button.copy-order'),
            handler: openCopyOrderDialog,
            icon: AkFileCopy,
            isDisabled: false,
        },
        {
            id: 5,
            testid: 'forward-order',
            label: t('purchase-order.button.forward'),
            handler: openForwardOrderDialog,
            icon: AkForward,
            isDisabled: !order?.status || isOrderReadonlyByStatus(order.status),
        },
        {
            id: 3,
            testid: 'order-history',
            label: t('purchase-order.button.order-history'),
            handler: () => onHistoryDialogHandlerOpen(orderId),
            icon: AkHistory,
            isDisabled: false,
        },
        {
            id: 1,
            testid: 'cancel-order',
            label: t('purchase-order.cancel-order'),
            handler: () => openCancelOrderDialog(),
            icon: AkClose,
            isDisabled:
                order?.status !== OrderStatusEnum.RELEASED &&
                order?.status !== OrderStatusEnum.PENDING_APPROVAL &&
                order?.status !== OrderStatusEnum.SENT_TO_APPROVAL,
        },
        {
            id: 4,
            testid: 'delete-order',
            label: t('purchase-order.button.delete-order'),
            handler: openDeleteOrderDialog,
            icon: AkDelete,
            color: 'error' as const,
            isDisabled: !isDeleteOrderPermitted || isOrderReadonlyByStatus(order.status),
        },
        {
            id: 6,
            testid: 'download-order-pdf',
            label: t('purchase-order.download-pdf.button'),
            handler: performDownload,
            icon: AkDownload,
            isDisabled: isDownloadDisabled,
        },
    ];

    return (
        <StickyPageFooter>
            <Grid container spacing={3} justifyContent="space-between">
                <Grid item flexGrow={1} />

                <Grid item>
                    <ContextMenu data-testid="order-details-footer-context-menu" label="more" items={contextMenuConfig} />
                </Grid>

                <Grid item>
                    <Button
                        data-testid="release-order"
                        variant="contained"
                        onClick={openReleaseOrderDialog}
                        label={t('purchase-order.button.release')}
                        disabled={!isOrderReleasable || isAttachmentMaxSizeOver(order?.documents || [])}
                    />

                    {!isOrderReleasable && supportInfoLabels.releaseInfo && (
                        <>
                            <Box hidden data-testid="release-errors">
                                {supportInfoLabels.releaseInfo}
                            </Box>
                            <Tooltip title={<div className={classes.lineBreak}>{supportInfoLabels.releaseInfo}</div>}>
                                <IconButton size="small" className={classes.validationInfo}>
                                    <AkHelp />
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                </Grid>
            </Grid>
        </StickyPageFooter>
    );
};
