import { Button, DialogTitle, MessageBox } from '@akelius-con/react-ui-kit-components';
import { AkFileCopy } from '@akelius-con/react-ui-kit-icons';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import copyOrder from 'modules/order/graphql/mutations/copyOrder';
import { useGetUser } from 'modules/order/utils/useGetUser';
import { useRedirect } from 'modules/order/utils/useRedirect';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { snackbar } from 'shared/components/Snackbar';

interface Props {
    closeCopyOrderDialog: () => void;
    orderId: string;
}

const CopyOrderConfirm = (props: Props) => {
    const [actionDisabled, setActionDisabled] = useState<boolean>(false);
    const { getToken } = useGetUser();
    const redirect = useRedirect();

    const copyOrderMutation = useMutation(copyOrder(), {
        onSuccess: response => {
            snackbar.success(t('purchase-order.copy.success-msg'));
            redirect.toOrderDetails(response.copyOrder.id);
            setActionDisabled(false);
            closeCopyOrderDialog();
        },
    });

    const performCopyOrder = () => {
        setActionDisabled(true);
        copyOrderMutation.mutate({ orderId: orderId, getToken });
    };

    const { closeCopyOrderDialog, orderId } = props;
    const { t } = useTranslation();

    return (
        <Dialog open={!!orderId} onClose={closeCopyOrderDialog} data-testid="copy-order-confirm-modal">
            <DialogTitle onClose={closeCopyOrderDialog} title={t('purchase-order.copy.title')} />
            <DialogContent>
                <MessageBox type="warning">
                    <Typography variant="body1">{t('purchase-order.copy.warning')}</Typography>
                </MessageBox>

                <Box marginTop="20px" />

                <DialogContentText>{t('purchase-order.copy.description')}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button disabled={actionDisabled} variant="outlined" label={t('common.cancel')} onClick={closeCopyOrderDialog} data-testid="cancel" />
                <Button
                    startIcon={<AkFileCopy />}
                    variant="contained"
                    data-testid="dialog-confirm"
                    label={t('purchase-order.button.copy-order')}
                    disabled={actionDisabled}
                    onClick={performCopyOrder}
                    color="primary"
                    autoFocus
                />
            </DialogActions>
        </Dialog>
    );
};

export default CopyOrderConfirm;
