import { useAuth0 } from '@auth0/auth0-react';

export interface GetToken {
    (): Promise<string>;
}

interface UserInfo {
    userId: string;
    roles: string;
    givenName: string;
    familyName: string;
    fullName: string;
    email: string;
    title: string;
    identityRoles: string[];
    tenantNames: string[];
    phoneNumber: string;
    getToken: GetToken;
}

export const useGetUser = (): UserInfo => {
    const { user, getAccessTokenSilently } = useAuth0();

    const userId: string = user && user['https://akelius.com/claims/userid'];
    const roles: string = user && user['https://construction.akelius.com/roles'];
    const givenName: string = user && user['https://akelius.com/claims/given_name'];
    const familyName: string = user && user['https://akelius.com/claims/family_name'];
    const email: string = user && user['https://akelius.com/claims/email'];
    const title: string = user && user['https://akelius.com/title'];
    const identityRoles: string[] = user && user['https://akelius.com/identityroles'];
    const tenantNames: string[] = user && user['https://akelius.com/claims/tenant-names'];
    const phoneNumber: string = (user && user.phone_number) || '';

    return {
        userId,
        roles,
        givenName,
        familyName,
        fullName: `${givenName} ${familyName}`,
        email,
        title,
        identityRoles,
        tenantNames,
        phoneNumber,
        getToken: getAccessTokenSilently,
    };
};
