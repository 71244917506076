export const backendRoutes = {
    root: (path: string): string => {
        if (!path.startsWith('/')) {
            throw `path ${path} should be absolute and start with /`;
        }

        const baseUrl = (import.meta.env.BASE_URL || '').replace(/\/$/, '');

        return baseUrl + path;
    },
    orderPdf: (orderId: string) => backendRoutes.root(`/api/order/${orderId}/pdf`),
    graphql: () => backendRoutes.root('/graphql'),
};
