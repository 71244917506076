import { colors } from '@akelius-con/react-theme';
import { makeStyles } from '@akelius-con/react-ui-kit-components';
import { AkClose, AkError, AkInfoFilled, AkSuccess, AkWarning } from '@akelius-con/react-ui-kit-icons';
import { Typography } from '@mui/material';
import cn from 'classnames';
import { ReactElement } from 'react';
import { ToastProps } from 'react-toastify/dist/types';

interface Props {
    msg: string;
    toastProps?: ToastProps;
}

const useStyles = makeStyles()(() => ({
    container_wrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        display: 'flex',
        justifyContent: 'flex-start',
        borderRadius: '3px',
        width: '100%',
    },
    iconContainer: {
        padding: '16px 16px 10px 16px',
        '& .MuiSvgIcon-root': {
            borderRadius: '50%',
            color: colors.white,
        },
    },
    message: {
        padding: '13px 16px',
        flexGrow: 1,
        background: colors.white,
    },
    closeAction: {
        position: 'relative',
        background: colors.white,
        borderRadius: '0 3px 3px 0',
    },
    notificationClose: {
        backgroundColor: 'transparent',
        margin: '16px 16px 16px 0',
        padding: 0,
        lineHeight: 0,
        borderRadius: '3px',
        cursor: 'pointer',
        border: 0,
    },
    error: {
        border: `1px solid ${colors.error.light}`,
        '& .icon-container': {
            background: colors.error.light,
        },
    },
    success: {
        border: `1px solid ${colors.success}`,
        '& .icon-container': {
            background: colors.success,
        },
    },
    warning: {
        border: `1px solid ${colors.warning.main}`,
        '& .icon-container': {
            background: colors.warning.main,
        },
    },
    info: {
        border: `1px solid ${colors.textGray}`,
        '& .icon-container': {
            background: colors.textGray,
        },
    },
    default: {},
}));

export const ToasterTemplate = (props: Props) => {
    const { msg, toastProps } = props;
    const { classes } = useStyles();

    const icons = new Map<string, ReactElement>([
        ['error', <AkError key="error" />],
        ['success', <AkSuccess key="success" />],
        ['warning', <AkWarning key="warning" />],
        ['info', <AkInfoFilled key="info" />],
    ]);

    if (!toastProps) {
        return null;
    }

    const { type } = toastProps;

    return (
        <div className={cn(classes.container_wrapper)} data-testid="message-box">
            <div className={cn(classes[type], classes.container, `notification-${type}`)}>
                <div
                    className={cn({
                        [classes.iconContainer]: true,
                        'icon-container': true,
                    })}
                >
                    {icons.get(type)}
                </div>
                <div className={classes.message}>
                    <Typography variant="body1" color={colors.black}>
                        {msg}
                    </Typography>
                </div>

                <div className={classes.closeAction}>
                    <button type="button" className={cn('notification-dismiss', classes.notificationClose)}>
                        <AkClose />
                    </button>
                </div>
            </div>
        </div>
    );
};
