import { AutocompleteItem } from '@akelius-con/react-ui-kit-components';
import { ICompaniesQueryResponse } from '../../graphql/queries/getCompanies';

export const prepareCompaniesDropdownData = (companiesResponse?: ICompaniesQueryResponse): AutocompleteItem[] => {
    const companies = companiesResponse?.companies?.items;

    if (!companies?.length) {
        return [];
    }

    return companies.map(company => ({
        label: `${company.code} - ${company.name}`,
        value: company.id,
    }));
};
