import { camelKeysObjToSnakeKeysObj } from './camelKeysObjToSnakeKeysObj';

export const transformFiltersToUrlParamObj = (filter: { [key: string]: unknown }): Record<string, string | string[]> => {
    const snakeKeyObj = camelKeysObjToSnakeKeysObj(filter);

    const formatted: Record<string, string | string[]> = {};

    Object.entries(snakeKeyObj).forEach(item => {
        if (typeof item[1] === 'boolean' && item[1] === true) {
            formatted[item[0]] = JSON.stringify(item[1]);
        }

        if (Array.isArray(item[1])) {
            formatted[item[0]] = item[1].join(',');
        }

        if (typeof item[1] === 'string') {
            formatted[item[0]] = item[1];
        }

        if (typeof item[1] === 'number' && !Number.isNaN(item[1])) {
            formatted[item[0]] = Number(item[1]).toString();
        }
    });

    return formatted;
};
