import { capitalize } from '@mui/material';
import { useGetUser } from 'modules/order/utils/useGetUser';
import { useCallback, useEffect, useState } from 'react';
import { TENANT_HEADER_KEY } from 'shared/constant';

export const useTenant = () => {
    const { tenantNames } = useGetUser();
    const [selectedTenant, setSelectedTenant] = useState<string>('');

    const setSelectedTenantToLocalStorage = async (tenant: string) => {
        localStorage.setItem(TENANT_HEADER_KEY, tenant);
        setSelectedTenant(tenant);
    };

    const resetSelectedTenant = () => {
        localStorage.removeItem(TENANT_HEADER_KEY);
    };

    const formatTenantName = (tenantName: string) => {
        return capitalize(tenantName.replace(/-/g, ' '));
    };

    const getSelectedTenant = useCallback(() => {
        const selectedTenant = localStorage.getItem(TENANT_HEADER_KEY);

        if (selectedTenant && !tenantNames.includes(selectedTenant)) {
            resetSelectedTenant();
        }

        if (selectedTenant) {
            return selectedTenant;
        }

        const defaultTenant = tenantNames[0];

        if (defaultTenant) {
            setSelectedTenantToLocalStorage(defaultTenant);
        }

        return defaultTenant;
    }, [tenantNames]);

    useEffect(() => {
        if (tenantNames) setSelectedTenant(getSelectedTenant());
    }, [tenantNames, getSelectedTenant]);

    return {
        getSelectedTenant,
        resetSelectedTenant,
        formatTenantName,
        selectedTenant,
        tenantNames,
        setSelectedTenantToLocalStorage,
    };
};
