import { gql } from 'graphql-request';
import { ContactForSupplier } from 'modules/order/types';
import { GetToken } from 'modules/order/utils/useGetUser';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';

export interface Input {
    title: string;
    supplierId: string;
    projectId: string;
    contactForSupplier: ContactForSupplier;
}

export interface ICreateConstructionOrderParam {
    getToken: GetToken;
    input: Input;
}

export interface ICreateConstructionOrderResponse {
    createProjectOrder: {
        id: string;
    };
}

export const createConstructionOrder = () => async (param: ICreateConstructionOrderParam) => {
    const { getToken, ...payload } = param;
    const { graphQLClient } = await getGraphqlClient(getToken);

    const createOrderMutation = gql`
        mutation createProjectOrder($input: OrderCreateProject!) {
            createProjectOrder(input: $input) {
                id
            }
        }
    `;

    const data = await graphQLClient.request<ICreateConstructionOrderResponse>(createOrderMutation, {
        ...payload,
    });

    return data;
};
