import { colors } from '@akelius-con/react-theme';
import { makeStyles } from '@akelius-con/react-ui-kit-components';
import {
    AkArrowDropDown,
    AkChangeTenant,
    AkCheck,
    AkExternalLink,
    AkInfo,
    AkSupport,
    AkGlobeWeb as LanguageIcon,
    AkMenu as MenuIcon,
    AkPerson as PersonIcon,
} from '@akelius-con/react-ui-kit-icons';
import { useAuth0 } from '@auth0/auth0-react';
import { AppBar, Backdrop, Box, Fade, IconButton, ListItemIcon, Link as MaterialLink, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { FC, MouseEvent, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { OAP_SERVICE_DESK_URL, sharedCacheKeys } from 'shared/constant';
import { SettingsQueryResponse } from 'shared/graphql/queries/getSettings';
import { getFeatureToggleState } from 'shared/utils/featureToggle';
import { useTenant } from 'shared/utils/useTenant';
import { NestedMenuItem } from '../NestedMenuItem';
import SelectCountryMenuItem from '../SelectCountryMenuItem';
import { TranslationContext } from '../TranslationProvider';
import { setLanguage } from '../TranslationProvider/i18n';

type IProps = {
    title: string;
    link?: string;
    reloadPageOnClick?: boolean;
    onMenuClick?: Function;
};

const useStyles = makeStyles()({
    headerTitleLink: {
        textDecoration: 'none',
        color: colors.black,
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    grow: {
        flexGrow: 1,
    },
    dropdownPaper: {
        top: `56px !important`,
        maxHeight: '316px',
    },
});

const Header: FC<IProps> = props => {
    const { title, link, reloadPageOnClick, onMenuClick } = props;
    const queryClient = useQueryClient();
    const settingsData = queryClient.getQueryData<SettingsQueryResponse>(sharedCacheKeys.settings);
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
    const { formatTenantName, tenantNames, selectedTenant, resetSelectedTenant, setSelectedTenantToLocalStorage } = useTenant();

    const { classes } = useStyles();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { languages } = useContext(TranslationContext);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const onMenuClickHandler = useCallback(() => {
        if (typeof onMenuClick === 'function') onMenuClick();
    }, [onMenuClick]);

    const handleDropdownClick = useCallback((event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleSupportClick = useCallback(() => {
        window.open(OAP_SERVICE_DESK_URL, '_blank');
    }, []);

    const handleDropdownClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const changeLang = useCallback(
        (key: string) => {
            setLanguage(key);
            handleDropdownClose();
        },
        [handleDropdownClose],
    );

    const routeToDebugInfo = useCallback(() => {
        handleDropdownClose();
        navigate('/debug-info');
    }, [handleDropdownClose, navigate]);

    const onClickTenantHandler = useCallback(
        (tenant: string) => {
            setSelectedTenantToLocalStorage(tenant);
            handleDropdownClose();
            window.location.reload();
        },
        [handleDropdownClose, setSelectedTenantToLocalStorage],
    );

    const onClickLogoutHandler = useCallback(() => {
        logout({
            logoutParams: {
                returnTo: window.location.origin,
                federated: true,
            },
        });
        resetSelectedTenant();
    }, [logout, resetSelectedTenant]);

    const isDebugMenuVisible = getFeatureToggleState({ byDebugMode: true });

    const multiTenantMenuName = selectedTenant
        ? t('common.multitenancy.selected-tenant', {
              name: t(`common.multitenancy.tenants.${selectedTenant}`, formatTenantName(selectedTenant)),
          })
        : '';

    return (
        <div className={classes.grow}>
            <AppBar sx={{ borderBottom: `1px solid ${colors.lineGrey}` }}>
                <Toolbar>
                    <IconButton disabled={!onMenuClick} size="large" color="inherit" aria-label="Open drawer" onClick={onMenuClickHandler}>
                        <MenuIcon />
                    </IconButton>
                    {reloadPageOnClick ? (
                        <MaterialLink href={link ? `${link}` : '/'} className={classes.headerTitleLink}>
                            <Typography variant="h3" component="span" data-testid="appName">
                                {title}
                            </Typography>
                        </MaterialLink>
                    ) : (
                        <Link to={link ? `${link}` : '/'} className={classes.headerTitleLink}>
                            <Typography variant="h3" component="span" data-testid="appName">
                                {title}
                            </Typography>
                        </Link>
                    )}

                    <Box className={classes.grow} />

                    <Box display="flex">
                        <IconButton size="large" color="inherit" onClick={handleSupportClick}>
                            <AkSupport />
                        </IconButton>
                        <IconButton
                            size="large"
                            color="inherit"
                            id="langDropdown"
                            data-testid="languageDropDownTrigger"
                            onClick={handleDropdownClick}
                        >
                            <LanguageIcon />
                        </IconButton>
                        <Menu
                            classes={{ paper: classes.dropdownPaper }}
                            anchorEl={anchorEl}
                            open={anchorEl?.id === 'langDropdown'}
                            onClose={handleDropdownClose}
                            TransitionComponent={Fade}
                        >
                            {Object.entries(languages).map(([key, value]) => [
                                <MenuItem key={value} onClick={() => changeLang(key)}>
                                    {value}
                                </MenuItem>,
                            ])}
                        </Menu>

                        <IconButton size="large" id="userDropdown" onClick={handleDropdownClick}>
                            <PersonIcon />
                        </IconButton>

                        <Menu
                            classes={{ paper: classes.dropdownPaper }}
                            anchorEl={anchorEl}
                            open={anchorEl?.id === 'userDropdown'}
                            onClose={handleDropdownClose}
                            TransitionComponent={Fade}
                        >
                            {[
                                !isAuthenticated && (
                                    <MenuItem key="login" onClick={() => loginWithRedirect()}>
                                        {t('common.login')}
                                    </MenuItem>
                                ),

                                isAuthenticated && isDebugMenuVisible && (
                                    <MenuItem key="debug-info" onClick={routeToDebugInfo}>
                                        <ListItemIcon>
                                            <AkInfo />
                                        </ListItemIcon>
                                        <Typography>{t('common.debug-info')}</Typography>
                                    </MenuItem>
                                ),

                                settingsData && settingsData?.settings.length > 1 && (
                                    <SelectCountryMenuItem key="select-country" onClose={handleDropdownClose} anchorEl={anchorEl} />
                                ),

                                !!tenantNames?.length && isAuthenticated && (
                                    <NestedMenuItem
                                        key="change-tenant"
                                        leftIcon={<AkChangeTenant />}
                                        openFrom="right"
                                        rightIcon={
                                            <Box sx={{ transform: 'rotate(-90deg)', ml: 1, color: colors.textGray }}>
                                                <AkArrowDropDown />
                                            </Box>
                                        }
                                        label={multiTenantMenuName}
                                        parentMenuOpen={anchorEl?.id === 'userDropdown'}
                                    >
                                        {tenantNames.map(tenant => (
                                            <MenuItem key={tenant} onClick={() => onClickTenantHandler(tenant)}>
                                                {selectedTenant === tenant ? <AkCheck /> : <div style={{ width: '24px' }} />}
                                                <Typography ml={1}>{t(`common.multitenancy.tenants.${tenant}`, formatTenantName(tenant))}</Typography>
                                            </MenuItem>
                                        ))}
                                    </NestedMenuItem>
                                ),
                                isAuthenticated && (
                                    <MenuItem onClick={onClickLogoutHandler} key="logout">
                                        <ListItemIcon>
                                            <AkExternalLink />
                                        </ListItemIcon>
                                        <Typography>{t('common.logout')}</Typography>
                                    </MenuItem>
                                ),
                            ]}
                        </Menu>

                        <Backdrop open={anchorEl?.id === 'langDropdown' || anchorEl?.id === 'userDropdown'} onClick={handleDropdownClose} />
                    </Box>
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default Header;
