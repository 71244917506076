import { TextField } from '@akelius-con/react-ui-kit-components';
import { FormikProps } from 'formik';
import { FC } from 'react';

interface IProps {
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: FormikProps<any>;
    label: string;
    ['data-testid']?: string;
    placeholder?: string;
    multiline?: boolean;
    required?: boolean;
    disabled?: boolean;
    startAdornment?: string | JSX.Element;
    endAdornment?: string | JSX.Element;
    preventDisableOnSubmit?: boolean;
    rows?: string | number;
    alwaysShowError?: boolean;
}

export const FormikTextField: FC<IProps> = props => {
    const {
        name,
        formik,
        label,
        disabled,
        rows,
        placeholder,
        multiline,
        required,
        startAdornment,
        endAdornment,
        preventDisableOnSubmit,
        alwaysShowError = false,
    } = props;

    const { handleChange: onChange, handleBlur: onBlur } = formik;
    const isErrorShown = alwaysShowError ? !!formik.errors[name] : !!formik.errors[name] && !!formik.touched[name];
    const isDisabled = (!preventDisableOnSubmit && formik.isSubmitting) || disabled;

    return (
        <TextField
            name={name}
            autoComplete="off"
            disabled={isDisabled}
            data-testid={props['data-testid'] ? props['data-testid'] : name}
            value={formik.values[name]}
            onChange={onChange}
            error={isErrorShown}
            onBlur={onBlur}
            multiline={multiline}
            helperText={isErrorShown && String(formik.errors[name])}
            startAdornment={startAdornment}
            endAdornment={endAdornment}
            label={`${label}${required ? '*' : ''}`}
            placeholder={placeholder}
            rows={rows}
        />
    );
};
