import { AutocompleteItem } from '@akelius-con/react-ui-kit-components';
import { OrderAssociatedProperty, Property } from '../../../types';

const mapPropertyToAutoCompleteItem = (property: Property | OrderAssociatedProperty): AutocompleteItem => {
    const id = property.id;

    return {
        label: `${property.name}`,
        value: id,
        address: property.address || '',
        city: property.addressDetails.city || '',
        code: property.code || '',
        countryCode: property.addressDetails.country || '',
        name: property.name,
        id,
    };
};

export default mapPropertyToAutoCompleteItem;
