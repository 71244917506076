import { Autocomplete, AutocompleteItem } from '@akelius-con/react-ui-kit-components';
import { OnChange } from '@akelius-con/react-ui-kit-components/Autocomplete';
import { Grid } from '@mui/material';
import { FormikProps } from 'formik';
import { Apartment, Property } from 'modules/order/types';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import prepareApartmentsDropdownData from '../PropertyCarrierOfCostInfo/addEditCarrierOfCostModal/prepareApartmentDropdownData';
import SelectPropertyDropdown, { getPropertyOptionFromProperty } from '../SelectPropertyDropdown';

interface Props {
    apartments: Apartment[];
    onPropertyChange: (property: Property | null) => void;
    onApartmentChange: (apartment: Apartment | null) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik?: FormikProps<any>;
    selectedProperty: Property | null;
    selectedApartment: Apartment | null;
}

export const getApartmentOption = (apartment: Apartment | null): AutocompleteItem | null => {
    return apartment ? { label: apartment.name, value: apartment.id } : null;
};

export const getFullApartment = (apartments: Apartment[], apartment: AutocompleteItem | null): Apartment | null => {
    const findApartment = apartments.find(a => a.id === apartment?.value);
    return findApartment || null;
};

const SelectApartmentDropdown: FC<Props> = props => {
    const { onApartmentChange, apartments, selectedApartment, formik, selectedProperty, onPropertyChange } = props;
    const { t } = useTranslation();

    const onChangeApartment: OnChange = useCallback(
        (_event, apartment) => {
            const fullApartment = getFullApartment(apartments, apartment);
            onApartmentChange(fullApartment);
        },
        [apartments, onApartmentChange],
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <SelectPropertyDropdown
                    required
                    value={getPropertyOptionFromProperty(selectedProperty)}
                    onChange={onPropertyChange}
                    formik={formik}
                />
            </Grid>
            <Grid item xs={6}>
                <Autocomplete
                    data-testid="apartment-selection"
                    onChange={onChangeApartment}
                    options={prepareApartmentsDropdownData(apartments)}
                    disabled={formik?.isSubmitting || !formik?.values.propertyId || !apartments}
                    loadingText={t('common.loading')}
                    value={getApartmentOption(selectedApartment)}
                    noOptionsText={t('common.no-matching-result')}
                    inputProps={{
                        label: t('purchase-order.carrier-of-cost.apartment'),
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default SelectApartmentDropdown;
