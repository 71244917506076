import { Locales } from './types';

const currencyFormatter = (price: number, currencyCode: string, locales: Locales): string => {
    const options = {
        minimumFractionDigits: 2,
        currency: currencyCode,
    };
    return new Intl.NumberFormat(locales, options).format(price);
};

export default currencyFormatter;
