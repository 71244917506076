import { Button, DialogTitle, makeStyles } from '@akelius-con/react-ui-kit-components';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { cacheKeys } from 'modules/order/constants';
import createContact from 'modules/order/graphql/mutations/createAdditionalSupplierContact';
import updateContact from 'modules/order/graphql/mutations/updateAdditionalSupplierContact';
import { IOrderResponse } from 'modules/order/graphql/queries/useGetOrder';
import { useGetUser } from 'modules/order/utils/useGetUser';
import { MouseEventHandler, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikTextField } from 'shared/components/FormikTextField';
import MandatoryFieldDisclaimer from 'shared/components/MandatoryFieldDisclaimer';
import { snackbar } from 'shared/components/Snackbar';
import { SupplierContact } from 'shared/graphql/queries/getSuppliers';
import * as Yup from 'yup';
import { getUpdateAdditionalContactCache } from '../OrderContactsTab/getUpdateAdditionalContactCache';

interface Props {
    onClose: () => void;
    editContactContext: SupplierContact | null;
    open: boolean;
    orderId: string;
}

const useStyles = makeStyles()({
    hide: {
        display: 'none',
    },
    buttonMargin: {
        marginRight: '8px',
    },
});

const AddEditAdditionalContactDialog = (props: Props) => {
    const { open, onClose: closeDialog, editContactContext, orderId } = props;
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { classes } = useStyles();
    const { getToken } = useGetUser();
    const cacheKey = cacheKeys.order(orderId);

    const createContactMutation = useMutation(createContact(), {
        onSuccess: response => {
            queryClient.setQueryData<IOrderResponse>(cacheKey, prevData => {
                const updatedContacts = response.createOrderSupplierContact.supplier.additionalContacts;

                if (prevData) {
                    return getUpdateAdditionalContactCache(prevData, updatedContacts);
                }
            });

            snackbar.success(t('purchase-order.snackbar.contact.create-success'));

            formik.resetForm();
            closeDialog();
        },
        onError: () => {
            snackbar.error(t('purchase-order.snackbar.contact-create-failed'));
        },
    });

    const editContactMutation = useMutation(updateContact(), {
        onSuccess: response => {
            queryClient.setQueryData<IOrderResponse>(cacheKey, prevData => {
                const updatedContacts = response.updateOrderSupplierContact.supplier.additionalContacts;

                if (prevData) {
                    return getUpdateAdditionalContactCache(prevData, updatedContacts);
                }
            });

            snackbar.success(t('purchase-order.snackbar.contact-update-success'));

            formik.resetForm();
            closeDialog();
        },
        onError: () => {
            snackbar.success(t('purchase-order.snackbar.contact-update-failed'));
        },
    });

    interface FormValues {
        name: string;
        email: string;
        phone?: string;
    }

    const initialValues: FormValues = {
        name: '',
        email: '',
        phone: '',
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required().label(t('purchase-order.contacts.form.name')),
        email: Yup.string().email().required().label(t('purchase-order.contacts.form.email')),
        phone: Yup.string().label(t('purchase-order.contacts.form.phone')),
    });

    const onSubmit = (values: FormValues) => {
        if (editContactContext) {
            editContactMutation.mutate({
                getToken,
                contactId: editContactContext.contactId,
                orderId,
                input: {
                    name: values.name,
                    email: values.email,
                    phone: values.phone,
                },
            });
        } else {
            createContactMutation.mutate({
                getToken,
                orderId,
                input: {
                    name: values.name,
                    email: values.email,
                    phone: values.phone,
                },
            });
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    const { setValues } = formik;

    useEffect(() => {
        if (editContactContext) {
            const { name, email, phone } = editContactContext;
            setValues({
                name,
                email,
                phone: phone || '',
            });
        }
    }, [editContactContext, setValues]);

    const backDropClickHandler = useCallback((_event: MouseEventHandler<HTMLButtonElement>, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (reason !== 'backdropClick') return;
    }, []);

    const modalCloseHandler = useCallback(() => {
        closeDialog();
        formik.resetForm();
    }, [formik, closeDialog]);

    return (
        <Dialog maxWidth="md" fullWidth data-testid="add-edit-contact-dialog" open={open} onClose={backDropClickHandler}>
            <DialogTitle
                onClose={modalCloseHandler}
                title={editContactContext ? t('purchase-order.edit-contact') : t('purchase-order.add-contact')}
            />
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid md={12} item>
                            <FormikTextField
                                data-testid="additional-contact-name"
                                name="name"
                                label={`${t('purchase-order.contacts.form.name')} *`}
                                formik={formik}
                            />
                        </Grid>
                        <Grid md={6} item>
                            <FormikTextField
                                data-testid="additional-contact-email"
                                name="email"
                                label={`${t('purchase-order.contacts.form.email')} *`}
                                formik={formik}
                            />
                        </Grid>
                        <Grid md={6} item>
                            <FormikTextField
                                data-testid="additional-contact-phone"
                                name="phone"
                                label={t('purchase-order.contacts.form.phone')}
                                formik={formik}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <MandatoryFieldDisclaimer />
                        </Grid>
                    </Grid>
                    <button className={classes.hide} type="submit" />
                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    className={classes.buttonMargin}
                    variant="outlined"
                    label={t('common.cancel')}
                    onClick={modalCloseHandler}
                    data-testid="close-additional-contact-dialog"
                />
                <Button
                    isLoading={formik.isSubmitting}
                    variant="contained"
                    data-testid="additional-contact-submit"
                    label={t('purchase-order.add')}
                    onClick={() => formik.submitForm()}
                    disabled={!formik.isValid || !formik.dirty}
                    color="primary"
                />
            </DialogActions>
        </Dialog>
    );
};

export default AddEditAdditionalContactDialog;
