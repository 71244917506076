import { TFunction } from 'i18next';
import { FieldName, OrderValidationErrors } from './validateOrder';

const generateReleaseInfoErrors = (errors: OrderValidationErrors, t: TFunction) => {
    let releaseInfo: string = '';
    errors.forEach((value: string, field: FieldName) => {
        releaseInfo += `- ${t(`purchase-order.release-info.validation.${field}.${value}`)}\n `;
    });

    return releaseInfo;
};

export default generateReleaseInfoErrors;
