import { gql } from 'graphql-request';
import { GetToken } from 'modules/order/utils/useGetUser';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';
export interface CompanyCostCenter {
    active: boolean;
    code: string;
    name: string;
}

export interface Address {
    address1: string | null;
    address2: string | null;
    streetName: string;
    streetNumber: string;
    postalCode: string;
    city: string;
    state: string;
    country: string;
}

export interface CompanyMinimal {
    id: string;
    code: string;
    name: string;
    vatNumber: string;
}

export interface Company extends CompanyMinimal {
    active: boolean;
    costCenters: CompanyCostCenter[];
    isPropertyCompany: boolean;
}

export interface CompanyQueryResponse {
    company: Company | null;
}

export interface GetCompanyParam {
    companyId: string | null;
    getToken: GetToken;
}

export const getCompany = (param: GetCompanyParam) => async () => {
    const { getToken, ...queryVars } = param;
    const { graphQLClient } = await getGraphqlClient(getToken);

    const getApartmentsQuery = gql`
        query company($companyId: String!) {
            company(companyId: $companyId) {
                id
                active
                addressDetails {
                    address1
                    address2
                    postalCode
                    city
                    region
                    country
                    streetName
                }
                code
                costCenters {
                    active
                    code
                    name
                }
                isPropertyCompany
                name
                vatNumber
            }
        }
    `;

    const data = await graphQLClient.request<CompanyQueryResponse>(getApartmentsQuery, {
        ...queryVars,
    });

    return data;
};
