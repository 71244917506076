import { makeStyles } from '@akelius-con/react-ui-kit-components';
import { Grid, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { PropertyCarrierOfCostInfo } from 'modules/order/components/PropertyCarrierOfCostInfo';
import { cacheKeys } from 'modules/order/constants';
import { IOrderResponse } from 'modules/order/graphql/queries/useGetOrder';
import { useGetUser } from 'modules/order/utils/useGetUser';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import FormikAutoSave from 'shared/components/FormikAutoSave';
import { FormikTextField } from 'shared/components/FormikTextField';
import MandatoryFieldDisclaimer from 'shared/components/MandatoryFieldDisclaimer';
import DESIGN_CONSTANT from 'shared/designConstant';
import { isOrderReadonlyByStatus } from 'shared/utils/order';
import * as Yup from 'yup';
import { useGetSupplierAttentioRequried } from '../../../../shared/graphql/queries/getSupplier';
import updateOrder, { UpdateOrderResponse } from '../../graphql/mutations/updateOrder';
import { Order, OrderAssociatedProperty, OrderTypesEnum } from '../../types';
import { getOrderType } from '../../utils/getOrderType';
import { AdminCarrierOfCostInfo } from '../AdminCarrierOfCostInfo';
import { ConstructionCarrierOfCostInfo } from '../ConstructionCarrierOfCostInfo';
import LinkToCase from '../LinkToCase';
import useAssociatedPropertyMutation from '../PropertyCarrierOfCostInfo/addEditCarrierOfCostModal/associatedPropertyMutation';
import SupplierCard from '../SupplierCard';

const useStyles = makeStyles()({
    link: {
        textDecoration: 'none',
    },
    orderDetailsBody: {
        maxWidth: DESIGN_CONSTANT.formContentWidth,
    },
    blockBottomMargin: {
        marginBottom: '20px',
    },
    tabTitle: {
        margin: '8px 0 10px !important',
    },
    generalTitle: {
        margin: '20px 0 10px',
    },
    carrierOfCost: {
        marginBottom: '5px',
    },

    vatSwitch: {
        marginTop: '20px',
    },
});

interface FormValues {
    title: string;
    referenceNumber: string;
}
interface IProps {
    order: Order;
}

const GeneralInformationTab: FC<IProps> = ({ order }) => {
    const { classes } = useStyles();
    const { t } = useTranslation();
    const dirtyTracker = useState<boolean>(false);
    const [autoSaveError, setAutoSaveError] = useState<boolean | null>(null);
    const params = useParams<{ orderId: string }>();
    const orderId = params.orderId as string;
    const { getToken } = useGetUser();
    const queryClient = useQueryClient();
    const orderCacheKey = cacheKeys.order(orderId);
    const orderFromCache: IOrderResponse | undefined = queryClient.getQueryData(orderCacheKey);
    const [cocLoading, setCocLoading] = useState<boolean>(false);
    const [cocModalState, setCocModalState] = useState<boolean>(false);

    const triggerCocModal = useCallback(() => {
        setCocModalState(true);
    }, [setCocModalState]);

    const closeCocModal = useCallback(() => {
        setCocModalState(false);
    }, [setCocModalState]);

    const associatePropertyMutation = useAssociatedPropertyMutation({
        orderFromCache,
        orderCacheKey,
        onClose: closeCocModal,
        setMutationLoading: setCocLoading,
    });

    const { title: orderTitle, referenceNumber, supplier: orderSupplier, carrierOfCost } = order;
    const { company, costCenter: costCenterId, costCenterName, property } = carrierOfCost;
    const caseId: string | undefined = order.case?.caseId;
    const isAdminOrder = getOrderType(order) === OrderTypesEnum.ADMIN;
    const isConstructionOrder = getOrderType(order) === OrderTypesEnum.CONSTRUCTION;
    const isPropertyOrder = getOrderType(order) === OrderTypesEnum.PROPERTY;

    const { data: supplierRequiresAttention } = useGetSupplierAttentioRequried(orderSupplier.id);

    const supplier = { ...orderSupplier, requiresAttention: supplierRequiresAttention?.supplier.requiresAttention };

    const validationSchema = Yup.object().shape({
        title: Yup.string().required().max(100).label(t('purchase-order.title')),
        referenceNumber: Yup.string().max(50).label(t('purchase-order.general-info.reference-number')),
    });

    const initialValues = {
        title: orderTitle,
        referenceNumber: referenceNumber || '',
    };

    const updateOrderMutation = useMutation(updateOrder(), {
        onSuccess: (successRes: UpdateOrderResponse) => {
            setTimeout(function () {
                setAutoSaveError(false);
                formik.setSubmitting(false);

                queryClient.setQueryData<IOrderResponse | undefined>(cacheKeys.order(order.id), (orderCache: IOrderResponse | undefined) => {
                    if (orderCache) {
                        return {
                            order: {
                                ...orderCache.order,
                                title: successRes.updateOrder.title,
                                referenceNumber: successRes.updateOrder.referenceNumber,
                                supplier: successRes.updateOrder.supplier,
                            },
                        };
                    }
                });
            }, 50);
        },
        onError: () => {
            setTimeout(function () {
                formik.setSubmitting(false);
                setAutoSaveError(true);
            }, 50);
        },
    });

    const onSubmit = useCallback(
        (values: FormValues) => {
            const queryVars = {
                getToken,
                orderId: order.id,
                input: {
                    title: values.title,
                    referenceNumber: values.referenceNumber,
                },
            };
            updateOrderMutation.mutate(queryVars);
        },
        [getToken, order.id, updateOrderMutation],
    );

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    const isReadOnly = isOrderReadonlyByStatus(order.status);

    const handleCarrierOfCostSelection = useCallback(
        ({ propertyId, apartmentId }: { propertyId: string; apartmentId?: string }) => {
            associatePropertyMutation.mutate({
                getToken,
                orderId,
                input: { propertyId, apartmentId },
            });
        },
        [associatePropertyMutation, getToken, orderId],
    );

    const saveCarrierOfCost = (property: OrderAssociatedProperty) => {
        setCocLoading(true);
        handleCarrierOfCostSelection({ apartmentId: property.apartment?.id, propertyId: property.id });
    };

    return (
        <div>
            <form onSubmit={formik.handleSubmit} className={classes.orderDetailsBody} data-testid="general-information">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography className={classes.tabTitle} variant="h2">
                            {t('purchase-order.general-info.general')}
                        </Typography>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <FormikTextField
                            disabled={isReadOnly}
                            preventDisableOnSubmit
                            data-testid="title-input"
                            name="title"
                            required
                            label={t('purchase-order.general-info.title')}
                            formik={formik}
                        />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        {caseId && <LinkToCase caseId={caseId} />}
                        {!caseId && getOrderType(order) !== OrderTypesEnum.CONSTRUCTION && (
                            <FormikTextField
                                disabled={isReadOnly}
                                preventDisableOnSubmit
                                data-testid="reference-input"
                                name="referenceNumber"
                                label={t('purchase-order.general-info.reference-number')}
                                formik={formik}
                            />
                        )}
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        {isPropertyOrder && property && (
                            <PropertyCarrierOfCostInfo
                                triggerCocModal={triggerCocModal}
                                closeCocModal={closeCocModal}
                                cocModalState={cocModalState}
                                onSave={saveCarrierOfCost}
                                profitCenter={carrierOfCost?.costCenter}
                                property={property}
                                readonly={isOrderReadonlyByStatus(order.status)}
                                isLoading={cocLoading}
                                caseOrder={!!order?.case?.caseId}
                            />
                        )}

                        {isAdminOrder && company && costCenterName && (
                            <AdminCarrierOfCostInfo
                                disableEdit={isOrderReadonlyByStatus(order.status)}
                                company={company}
                                costCenterId={costCenterId}
                                costCenterName={costCenterName}
                            />
                        )}

                        {isConstructionOrder && property && <ConstructionCarrierOfCostInfo order={order} />}
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <SupplierCard supplier={supplier} readonly={isOrderReadonlyByStatus(order.status)} />
                    </Grid>
                    <FormikAutoSave error={autoSaveError} formik={formik} dirtyTracker={dirtyTracker} />
                    <Grid item xs={12}>
                        <MandatoryFieldDisclaimer />
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};

export default GeneralInformationTab;
