import { colors } from '@akelius-con/react-theme';
import { makeStyles } from '@akelius-con/react-ui-kit-components';
import { AkBed, AkBuilding, AkCostCenter } from '@akelius-con/react-ui-kit-icons';
import { Grid, Theme, Typography } from '@mui/material';
import { Apartment, Property } from 'modules/order/types';
import { getPropertyImage } from 'modules/order/utils/getPropertyImage';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()((theme: Theme) => ({
    contentContainer: {
        padding: `24px 16px`,
        position: 'relative',
    },
    mediaContainer: {
        position: 'relative',
        width: '100%',
        height: '200px',
    },
    media: {
        width: '100%',
        height: 200,
        objectFit: 'none',
    },
    wrapper: {
        backgroundColor: 'transparent',
        padding: 0,
        textAlign: 'left',
        border: `1px solid ${colors.lineGrey}`,
        borderRadius: '3px',
    },
    propertySubInfo: {
        display: 'flex',
        paddingBottom: '16px',
        '& .MuiSvgIcon-root': {
            paddingRight: '10px',
            fontSize: '24px',
            color: colors.black,
        },
    },
    propertyInfoContainer: {
        paddingLeft: '50px',
    },
    title: {
        marginTop: '24px',
        marginBottom: theme.spacing(2),
    },
}));

interface Props {
    property: Property;
    profitCenter: string;
    apartment: Apartment | null;
}

const PropertyCard = (props: Props) => {
    const { t } = useTranslation();
    const { property, apartment, profitCenter } = props;
    const { address, name, id } = property;
    const { classes } = useStyles();

    const setFallbackImage = () => require('./assets/property-placeholder.png').default; // eslint-disable-line

    return (
        <>
            <Typography className={classes.title} variant="h2">
                {`${t('purchase-order.general-info.carrier-of-cost')}`}
            </Typography>
            <div className={classes.wrapper}>
                <Grid container className={classes.contentContainer}>
                    <Grid item xs={4} className={classes.mediaContainer}>
                        <img
                            className={classes.media}
                            src={getPropertyImage(id, 600)}
                            alt="property"
                            onError={e => {
                                (e.target as HTMLImageElement).onerror = null;
                                (e.target as HTMLImageElement).src = setFallbackImage();
                            }}
                        />
                    </Grid>
                    <Grid item xs={8} className={classes.propertyInfoContainer}>
                        <div className={classes.propertySubInfo}>
                            <Typography variant="body1">{name}</Typography>
                        </div>

                        {address && (
                            <div className={classes.propertySubInfo}>
                                <AkBuilding />
                                <Typography variant="body1">{address}</Typography>
                            </div>
                        )}

                        {profitCenter && (
                            <div className={classes.propertySubInfo}>
                                <AkCostCenter />
                                <Typography variant="body1">{profitCenter}</Typography>
                            </div>
                        )}

                        {apartment && (
                            <div className={classes.propertySubInfo}>
                                <AkBed />
                                <Typography variant="body1">{apartment.name}</Typography>
                            </div>
                        )}
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default PropertyCard;
