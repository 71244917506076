import { gql } from 'graphql-request';
import { GetToken } from 'modules/order/utils/useGetUser';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';

interface GetResponsiblePersons {
    propertyId?: string;
    getToken: GetToken;
}

export interface UserForProperty {
    name: string;
    email: string;
    role?: string;
    phone?: string;
}

export interface ResponsiblePersonsQueryResponse {
    responsiblePersons: UserForProperty[];
}

export const getResponsiblePersons = (param: GetResponsiblePersons) => async () => {
    const { getToken, ...queryVars } = param;
    const { graphQLClient } = await getGraphqlClient(getToken);

    const getResponsiblePersonsQuery = gql`
        query responsiblePersons($propertyId: String!) {
            responsiblePersons(propertyId: $propertyId) {
                ... on UserForProperty {
                    name
                    email
                    role
                }
            }
        }
    `;

    return await graphQLClient.request<ResponsiblePersonsQueryResponse>(getResponsiblePersonsQuery, {
        ...queryVars,
    });
};
