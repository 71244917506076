import { BreadcrumbItem, Breadcrumbs, Button, LoadingSpinner, MessageBox, makeStyles } from '@akelius-con/react-ui-kit-components';
import { Box, Grid, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { cacheKeys, routeConst } from 'modules/order/constants';
import { getProperty } from 'modules/order/graphql/queries/getProperty';
import CaseOrderContent, { FormValues } from 'modules/order/pages/CreateCaseOrder/components/CaseOrderContent';
import { OrderAssociatedProperty } from 'modules/order/types';
import { useGetUser } from 'modules/order/utils/useGetUser';
import { useRedirect } from 'modules/order/utils/useRedirect';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { StickyPageFooter } from 'shared/components';
import MandatoryFieldDisclaimer from 'shared/components/MandatoryFieldDisclaimer';
import { snackbar } from 'shared/components/Snackbar';
import { delay } from 'shared/constant';
import { SupplierQueryResponse, getSupplier } from 'shared/graphql/queries/getSupplier';
import { Supplier } from 'shared/graphql/queries/getSuppliers';
import createOrder from '../../graphql/mutations/createOrder';

const useStyles = makeStyles()({
    pageWrapper: {
        marginTop: '20px',
        width: '100%',
    },
    tabsContent: {
        width: '100%',
    },
    chip: {
        alignItems: 'center',
        display: 'flex',
    },
    title: {
        marginTop: '16px',
    },
    lineBreak: {
        whiteSpace: 'pre-line',
    },
});

interface CaseInfo {
    supplierId: string;
    propertyId: string;
    caseId: string;
    apartmentId: string | undefined;
}

const CreateCaseOrder = () => {
    const { classes } = useStyles();
    const { t } = useTranslation();
    const redirect = useRedirect();

    const [searchParams] = useSearchParams();
    const [caseInfo, setCaseInfo] = useState<CaseInfo>({
        supplierId: '',
        propertyId: '',
        caseId: '',
        apartmentId: undefined,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSupplier, setIsLoadingSupplier] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [selectedSupplier, setSelectedSupplier] = useState<Supplier | null>(null);
    const [orderFormValues, setOrderFormValues] = useState<FormValues>({
        title: '',
        referenceNumber: '',
    });
    const { title: userTitle, phoneNumber: userPhone, email: userEmail, fullName: userFullName, getToken } = useGetUser();

    const { isLoading: supplierInfoLoading, refetch: fetchSupplierInfo } = useQuery<SupplierQueryResponse>(
        cacheKeys.case.supplier,
        getSupplier({ getToken, supplierId: caseInfo.supplierId || null }),
        {
            retry: false,
            enabled: false,
            onSuccess: data => {
                setSelectedSupplier(data.supplier);
            },
            onSettled: () => {
                setIsLoadingSupplier(false);
            },
        },
    );

    const {
        isLoading: propertyInfoLoading,
        data: propertyInfo,
        error: propertyFetchError,
        refetch: fetchProperty,
    } = useQuery(cacheKeys.case.property, getProperty({ getToken, propertyId: caseInfo.propertyId }), {
        retry: false,
        enabled: false,
    });

    const createOrderMutation = useMutation(createOrder(), {
        onSettled: () => {
            setIsLoading(false);
        },
        onSuccess: ({ createOrder }) => {
            snackbar.success(t('purchase-order.snackbar.order-creation-successful'));
            setTimeout(() => {
                redirect.toOrderDetails(createOrder.id);
            }, delay.redirectDelay);
        },
        onError: () => {
            snackbar.error(t('purchase-order.snackbar.order-creation-fail'));
        },
    });

    const onChangeSupplier = (supplier: Supplier | null) => {
        setCaseInfo(prevState => ({
            ...prevState,
            supplierId: supplier?.id || '',
        }));
    };

    const onSaveCarrierOfCost = (property: OrderAssociatedProperty) => {
        setCaseInfo(prevState => ({
            ...prevState,
            apartmentId: property.apartment?.id,
        }));

        snackbar.info(t('purchase-order.snackbar.carrier-of-cost.apartment-change-info'));
    };

    const saveDraftOrder = async () => {
        setIsLoading(true);

        const contactForSupplier = {
            email: userEmail,
            name: userFullName,
            phone: userPhone,
            title: userTitle,
        };

        createOrderMutation.mutate({
            getToken,
            input: { ...caseInfo, title: orderFormValues.title, contactForSupplier },
        });
    };

    const supplierId = searchParams.get('supplier_id');
    const caseId = searchParams.get('case_id');
    const propertyId = searchParams.get('property_id');
    const apartmentId = searchParams.get('apartment_id');

    useEffect(() => {
        if (propertyId && caseId && supplierId) {
            setCaseInfo({
                supplierId,
                caseId,
                propertyId,
                apartmentId: apartmentId || undefined,
            });
        }
    }, [propertyId, supplierId, caseId, apartmentId]);

    useEffect(() => {
        if (caseInfo.supplierId && caseInfo.propertyId && caseInfo.caseId) {
            fetchSupplierInfo();
            fetchProperty();
        }
    }, [caseInfo, fetchProperty, fetchSupplierInfo]);

    useEffect(() => {
        setOrderFormValues(prevState => ({
            ...prevState,
            title: '',
        }));
    }, []);

    useEffect(() => {
        if (caseInfo.propertyId && caseInfo.supplierId) {
            fetchSupplierInfo();
            fetchProperty();
        }
    }, [caseInfo.propertyId, caseInfo.supplierId, fetchProperty, fetchSupplierInfo]);

    if (supplierInfoLoading || propertyInfoLoading) {
        return <LoadingSpinner areaHeight="calc(100vh - 130px)" />;
    }

    return (
        <>
            <Box className={classes.pageWrapper}>
                <Breadcrumbs>
                    <BreadcrumbItem label={t('dashboard.order-list-page-title')} href={routeConst.order} />
                </Breadcrumbs>
            </Box>

            <Typography className={classes.title} data-testid="title" variant="h1">
                {t('purchase-order.case.title')}
            </Typography>

            <Grid container spacing={2}>
                <>
                    {!supplierId && (
                        <Grid xs={12} item>
                            <MessageBox type="error">{t('purchase-order.order-from-case.error.supplier-id-missing')}</MessageBox>
                        </Grid>
                    )}

                    {!caseId && (
                        <Grid xs={12} item>
                            <MessageBox type="error">{t('purchase-order.order-from-case.error.case-id-missing')}</MessageBox>
                        </Grid>
                    )}

                    {(!propertyId || propertyFetchError) && (
                        <Grid xs={12} item>
                            <>
                                {!propertyId && <MessageBox type="error">{t('purchase-order.order-from-case.error.property-id-missing')}</MessageBox>}
                                {propertyFetchError && (
                                    <MessageBox type="error">{t('purchase-order.add-carrier-of-cost.property-fetch-error')}</MessageBox>
                                )}
                            </>
                        </Grid>
                    )}

                    {caseId && propertyInfo && selectedSupplier && (
                        <>
                            <Grid item xs={12}>
                                <CaseOrderContent
                                    isLoadingSupplier={isLoadingSupplier}
                                    setIsFormValid={setIsFormValid}
                                    setOrderFormValues={setOrderFormValues}
                                    supplier={selectedSupplier}
                                    property={propertyInfo.property}
                                    apartmentId={caseInfo.apartmentId}
                                    caseId={caseInfo.caseId}
                                    onChangeSupplier={onChangeSupplier}
                                    onSaveCarrierOfCost={onSaveCarrierOfCost}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MandatoryFieldDisclaimer />
                            </Grid>
                        </>
                    )}
                </>
            </Grid>

            <StickyPageFooter>
                <Grid container spacing={3} justifyContent="space-between">
                    <Grid item />
                    <Grid item>
                        <Button
                            isLoading={isLoading}
                            disabled={!isFormValid || isLoading}
                            variant="contained"
                            data-testid="create-button"
                            onClick={saveDraftOrder}
                            label={t('common.create')}
                        />
                    </Grid>
                </Grid>
            </StickyPageFooter>
        </>
    );
};

export default CreateCaseOrder;
