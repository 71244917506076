import currencyFormatter from './currencyFormatter';
import { Locales } from './types';

const formatAmount = (amount: number, currencyCode: string = 'EUR', locales: Locales = 'en-US'): string =>
    amount >= 0 ? `${currencyFormatter(amount, currencyCode, locales)} ${currencyCode}` : '';

const formatAmountWithoutCurrency = (amount: number, currencyCode: string = 'EUR', locales: Locales = 'en-US'): string =>
    amount >= 0 ? currencyFormatter(amount, currencyCode, locales) : '';

const removeThousandsSeparator = (amount: string): string => amount.replace(/,/g, '');

export { formatAmount, formatAmountWithoutCurrency, removeThousandsSeparator };
