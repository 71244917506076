import { LoadingSpinner, makeStyles } from '@akelius-con/react-ui-kit-components';
import { AkDelete, AkEdit } from '@akelius-con/react-ui-kit-icons';
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import cn from 'classnames';
import { OrderStatusEnum } from 'modules/order/graphql/queries/useGetOrder';
import { Currency, OrderItem, TotalVatAmountBreakdown } from 'modules/order/types';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { centsToPrice } from 'shared/helpers/priceConverter';
import { formatAmountWithoutCurrency } from 'shared/utils/amountFormatter';
import { isOrderReadonlyByStatus } from 'shared/utils/order';
import transformToRows from './transformToRows';
import { colors } from '@akelius-con/react-theme';

const useStyles = makeStyles()({
    tableContainer: {
        marginTop: '50px',
        marginBottom: '50px',
    },
    tableTitle: {
        marginBottom: '20px',
    },
    tableCell: {
        padding: '16px',
        fontSize: '1rem',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
    },
    tableCellFirstRow: {
        borderBottomWidth: 0,
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
    },
    tableCellSecondRow: {
        paddingTop: 0,
        paddingLeft: 0,
        borderBottomWidth: '1px',
    },
    tableCellTop: {
        verticalAlign: 'top',
    },
    tableHeadCell: {
        top: 0,
        zIndex: 2,
        position: 'sticky',
        backgroundColor: colors.white,
        borderBottom: `1px solid ${colors.black}`,
    },
    totalAmount: {
        padding: '16px 0',
        fontSize: '1rem',
        fontWeight: 'bold',
    },
    tableWrapper: {
        position: 'relative',
    },
    itemsLoading: {
        position: 'absolute',
        top: 0,
        zIndex: 3,
        left: 0,
        height: '100%',
        width: '100%',
        backgroundColor: 'rgb(255 255 255 / 80%)',
    },
    actionButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& > div:first-of-type': {
            marginRight: '20px',
        },
        '& button': {
            marginRight: 0,
        },
    },
    multilineText: {
        whiteSpace: 'pre-line',
    },
    innerTable: {
        width: 'auto',
    },
    innerTableCell: {
        border: 'none',
        padding: '2px 16px',
    },
});

interface Header {
    title: string;
    align: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
}

interface Props {
    orderStatus: OrderStatusEnum;
    items: OrderItem[];
    currency: Currency;
    deleteLoading: boolean;
    onEditItem: (id: string) => void;
    onDeleteItem: (id: string) => void;
    totalPriceCentsNet: number;
    totalPriceCentsGross: number;
    totalVatAmountBreakdown: TotalVatAmountBreakdown;
}

const OrderItemsTable: FC<Props> = props => {
    const { classes } = useStyles();
    const {
        currency,
        deleteLoading,
        items,
        onDeleteItem,
        onEditItem,
        orderStatus,
        totalPriceCentsGross,
        totalPriceCentsNet,
        totalVatAmountBreakdown,
    } = props;
    const { t } = useTranslation();

    const currencyLabel = t(`purchase-order.${currency.toLowerCase()}`);

    const headers: Header[] = [
        { title: t('purchase-order.item.position'), align: 'left' },
        { title: t('purchase-order.item.description'), align: 'left' },
        { title: t('purchase-order.item.quantity'), align: 'left' },
        { title: t('purchase-order.item.unit-price'), align: 'left' },
        { title: t('purchase-order.item.total-price-excl-vat'), align: 'left' },
        { title: t('purchase-order.item.vat-rate'), align: 'left' },
        { title: t('purchase-order.item.vat-amount'), align: 'left' },
        { title: t('purchase-order.item.total-price-inc-vat'), align: 'left' },
        { title: t('purchase-order.item.action'), align: 'right' },
    ];

    const rows = transformToRows(items, t);

    return (
        <div className={classes.tableWrapper}>
            <TableContainer component={Box} className={classes.tableContainer} data-testid="order-items-table">
                <Table>
                    <TableHead>
                        <TableRow>
                            {headers.map(({ title, align }) => (
                                <TableCell className={cn(classes.tableCell, classes.tableHeadCell)} key={title} align={align}>
                                    <b>{title}</b>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {rows.map((row, rowIndex) => {
                                const {
                                    id,
                                    description,
                                    quantity,
                                    unit,
                                    unitPriceCentsNet,
                                    totalPriceCentsNet,
                                    totalPriceCentsGross,
                                    vatRatePercent,
                                    totalVatAmountCents,
                                    supplierPartNumber,
                                    manufacturerPartNumber,
                                    deliverySingleDate,
                                    deliveryPeriod,
                                    note,
                                } = row;

                                const itemPosition = rowIndex + 1;
                                const secondRowNeeded = supplierPartNumber || manufacturerPartNumber || deliverySingleDate || deliveryPeriod || note;
                                const tableCellClass = secondRowNeeded ? classes.tableCellFirstRow : classes.tableCell;

                                return (
                                    <Fragment key={id}>
                                        <TableRow data-testid={itemPosition}>
                                            <TableCell className={tableCellClass} data-testid="order-item-position" scope="row">
                                                <b>{itemPosition}</b>
                                            </TableCell>
                                            <TableCell className={tableCellClass} data-testid="order-item-description" scope="row">
                                                <b>{description}</b>
                                            </TableCell>
                                            <TableCell className={tableCellClass} data-testid="order-item-quantity" scope="row">
                                                {quantity}&nbsp;{unit}
                                            </TableCell>
                                            <TableCell className={tableCellClass} data-testid="order-item-unit-price" align="left">
                                                {`${currencyLabel} ${formatAmountWithoutCurrency(centsToPrice(unitPriceCentsNet))}`}
                                            </TableCell>
                                            <TableCell className={tableCellClass} data-testid="order-item-total-price-excl-vat">
                                                {`${currencyLabel} ${formatAmountWithoutCurrency(centsToPrice(totalPriceCentsNet))}`}
                                            </TableCell>
                                            <TableCell className={tableCellClass} data-testid="order-item-vat-rate">
                                                {vatRatePercent}%
                                            </TableCell>
                                            <TableCell className={tableCellClass} data-testid="order-item-vat-amount">
                                                {`${currencyLabel} ${formatAmountWithoutCurrency(centsToPrice(totalVatAmountCents))}`}
                                            </TableCell>
                                            <TableCell className={tableCellClass} data-testid="order-item-total-price-inc-vat">
                                                {`${currencyLabel} ${formatAmountWithoutCurrency(centsToPrice(totalPriceCentsGross))}`}
                                            </TableCell>
                                            <TableCell className={tableCellClass} data-testid="order-item-action" align="right">
                                                <div className={classes.actionButtonContainer}>
                                                    <Tooltip title={t('purchase-order.item.edit.tooltip') || ''}>
                                                        <div>
                                                            <IconButton
                                                                size="small"
                                                                data-testid="edit-item"
                                                                onClick={() => id && onEditItem(id)}
                                                                disabled={isOrderReadonlyByStatus(orderStatus)}
                                                            >
                                                                <AkEdit />
                                                            </IconButton>
                                                        </div>
                                                    </Tooltip>
                                                    <Tooltip title={t('purchase-order.item.delete.tooltip') || ''}>
                                                        <div>
                                                            <IconButton
                                                                size="small"
                                                                data-testid="delete-item"
                                                                onClick={() => id && onDeleteItem(id)}
                                                                disabled={isOrderReadonlyByStatus(orderStatus)}
                                                            >
                                                                <AkDelete />
                                                            </IconButton>
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        {secondRowNeeded && (
                                            <TableRow data-testid={`${itemPosition}-second-row`}>
                                                <TableCell className={cn(classes.tableCell, classes.tableCellSecondRow)}>&nbsp;</TableCell>
                                                <TableCell className={cn(classes.tableCell, classes.tableCellSecondRow)} colSpan={8}>
                                                    <Table className={classes.innerTable}>
                                                        <TableBody>
                                                            {supplierPartNumber && (
                                                                <TableRow>
                                                                    <TableCell className={classes.innerTableCell} scope="row">
                                                                        {t('purchase-order.item.supplier-part-number')}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.innerTableCell}
                                                                        data-testid="order-item-supplier-part-number"
                                                                    >
                                                                        {supplierPartNumber}
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}
                                                            {manufacturerPartNumber && (
                                                                <TableRow>
                                                                    <TableCell className={classes.innerTableCell} scope="row">
                                                                        {t('purchase-order.item.manufacturer-part-number')}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.innerTableCell}
                                                                        data-testid="order-item-manufacturer-part-number"
                                                                    >
                                                                        {manufacturerPartNumber}
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}
                                                            {deliverySingleDate && (
                                                                <TableRow>
                                                                    <TableCell className={classes.innerTableCell} scope="row">
                                                                        {t('purchase-order.item.delivery-date')}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.innerTableCell}
                                                                        data-testid="order-item-delivery-date"
                                                                    >
                                                                        {deliverySingleDate}
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}
                                                            {deliveryPeriod && (
                                                                <TableRow>
                                                                    <TableCell className={classes.innerTableCell} scope="row">
                                                                        {t('purchase-order.item.delivery-period')}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.innerTableCell}
                                                                        data-testid="order-item-delivery-period"
                                                                    >
                                                                        {deliveryPeriod}
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}
                                                            {note && (
                                                                <TableRow>
                                                                    <TableCell
                                                                        className={cn(classes.innerTableCell, classes.tableCellTop)}
                                                                        scope="row"
                                                                    >
                                                                        {t('purchase-order.item.note')}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={cn(classes.innerTableCell, classes.multilineText)}
                                                                        data-testid="order-item-note"
                                                                    >
                                                                        {note}
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </Fragment>
                                );
                            })}
                            <TableRow>
                                <TableCell className={classes.tableCell} scope="row" colSpan={7}>
                                    <b>{t('purchase-order.summary.total-price-net')}</b>
                                </TableCell>
                                <TableCell className={classes.tableCell} data-testid="order-summary-total-price-net" colSpan={2}>
                                    <b>{`${currencyLabel} ${formatAmountWithoutCurrency(totalPriceCentsNet / 100)}`}</b>
                                </TableCell>
                            </TableRow>
                            {totalVatAmountBreakdown.map((vatBreakdownEntry, vatBreakdownEntryIndex) => {
                                return (
                                    <TableRow key={vatBreakdownEntry.vatRate}>
                                        <TableCell className={classes.tableCell} scope="row" colSpan={7}>
                                            <b>
                                                {t('purchase-order.summary.total-vat-amount')}
                                                &nbsp;
                                                {vatBreakdownEntry.vatRate * 100}%
                                            </b>
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableCell}
                                            data-testid={`order-summary-total-vat-amount-${vatBreakdownEntryIndex}`}
                                            colSpan={2}
                                        >
                                            <b>{`${currencyLabel} ${formatAmountWithoutCurrency(vatBreakdownEntry.amountCents / 100)}`}</b>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            <TableRow>
                                <TableCell className={classes.tableCell} scope="row" colSpan={7}>
                                    <b>{t('purchase-order.summary.total-price-gross')}</b>
                                </TableCell>
                                <TableCell className={classes.tableCell} data-testid="order-summary-total-price-gross" colSpan={2}>
                                    <b>{`${currencyLabel} ${formatAmountWithoutCurrency(totalPriceCentsGross / 100)}`}</b>
                                </TableCell>
                            </TableRow>
                        </>
                    </TableBody>
                </Table>
            </TableContainer>
            {deleteLoading && (
                <div className={classes.itemsLoading}>
                    <LoadingSpinner areaHeight="auto" label={t('purchase-order.common.please-wait')} />
                </div>
            )}
        </div>
    );
};

export default OrderItemsTable;
