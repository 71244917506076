import { gql } from 'graphql-request';
import { GetToken } from 'modules/order/utils/useGetUser';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';

export interface PropertyAssociationPayload {
    propertyId: string;
}

export interface QueryVars {
    getToken: GetToken;
    orderId: string;
}

const releaseOrder = () => async (param: QueryVars) => {
    const { getToken, ...payload } = param;
    const { graphQLClient } = await getGraphqlClient(getToken);
    const releaseOrderMutation = gql`
        mutation releaseOrder($orderId: String!) {
            releaseOrder(orderId: $orderId) {
                id
                status
            }
        }
    `;

    const data = await graphQLClient.request(releaseOrderMutation, {
        ...payload,
    });

    return data;
};

export default releaseOrder;
