import { AkExternalLink } from '@akelius-con/react-ui-kit-icons';
import { Link, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@akelius-con/react-ui-kit-components';
import { colors } from '@akelius-con/react-theme';

const useStyles = makeStyles()({
    linkIcon: {
        color: `${colors.black}`,
        position: 'relative',
        top: '2px',
        fontSize: '16px',
    },
    link: {
        fontSize: '16px',
        color: colors.black,
        textDecoration: 'none',
        '&:hover': {
            textDecorationColor: colors.black,
            textDecoration: 'underline',
        },
    },
    label: {
        color: colors.black,
    },
});

interface Props {
    caseId: string;
    noLabel?: boolean;
}

const LinkToCase: FC<Props> = props => {
    const { caseId, noLabel } = props;
    const { classes } = useStyles();
    const { t } = useTranslation();
    const caseUrl = `${import.meta.env.VITE_APP_AKELIUS_CUM_URL}cases/${caseId}`;

    return (
        <div data-testid="case-id">
            {!noLabel && (
                <Typography className={classes.label} variant="body1" data-testid="case-id-label">
                    {t('purchase-order.general-info.case-id')}
                </Typography>
            )}
            <Link target="_blank" href={caseUrl} className={classes.link} data-testid="case-id-link">
                <Typography variant="body1" className={classes.label}>
                    {caseId} <AkExternalLink className={classes.linkIcon} />
                </Typography>
            </Link>
        </div>
    );
};

export default LinkToCase;
