import { theme } from '@akelius-con/react-theme';
import { makeStyles } from '@akelius-con/react-ui-kit-components';
import { AkClose, AkEvent } from '@akelius-con/react-ui-kit-icons';
import { IconButton, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FormikProps } from 'formik';
import { useCallback, useState } from 'react';

const useStyles = makeStyles()(() => ({
    datePicker: {
        position: 'relative',
    },
}));

interface Props {
    'data-testid'?: string;
    label: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik?: FormikProps<any>;
    preventDisableOnSubmit?: boolean;
    disabled?: boolean;
    disablePast?: boolean;
    value: Date | null;
    onBlur?: () => void;
    name?: string;
    clearDate?: () => void;
    onChange(selectedDate: Date | null): void;
}

const DatePickerWrapper = (props: Props) => {
    const { clearDate, label, value, onChange, onBlur, formik, name, disabled, disablePast, preventDisableOnSubmit } = props;
    const [open, setOpen] = useState(false);
    const { classes } = useStyles();

    const handleOnBlur = useCallback(() => {
        onBlur && onBlur();
    }, [onBlur]);

    const onClearHandler = useCallback(() => {
        if (clearDate && formik && name) {
            clearDate();
            formik.setFieldTouched(name);
            setTimeout(function () {
                formik.validateForm();
            });
        }
    }, [clearDate, formik, name]);

    const isDisabled = (!preventDisableOnSubmit && formik?.isSubmitting) || disabled;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className={classes.datePicker}>
                <DatePicker
                    disabled={isDisabled}
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    disablePast={disablePast}
                    inputFormat="yyyy-MM-dd"
                    // fix for CI e2e tests only.
                    // by default, a mobile friendly datepicker is shown for "pointer: fine".
                    // chromium headless is using this mobile datepicker, which would break out e2e tests.
                    desktopModeMediaQuery={theme.breakpoints.up('md')}
                    label={label}
                    value={value}
                    onChange={onChange}
                    renderInput={params => (
                        <TextField
                            {...params}
                            error={!!(formik && name && formik.errors[name])}
                            inputProps={{
                                ...params.inputProps,
                                onBlur: handleOnBlur,
                            }}
                        />
                    )}
                    components={{
                        OpenPickerIcon: AkEvent,
                    }}
                    OpenPickerButtonProps={{
                        size: 'small',
                    }}
                />
                {clearDate && value && (
                    <IconButton disabled={isDisabled} onClick={onClearHandler} sx={{ position: 'absolute', right: '36px', bottom: '22px' }}>
                        <AkClose />
                    </IconButton>
                )}
            </div>
        </LocalizationProvider>
    );
};

export default DatePickerWrapper;
