import { makeStyles } from '@akelius-con/react-ui-kit-components';
import { AkDocument } from '@akelius-con/react-ui-kit-icons';
import { FC } from 'react';
import documentIcons from './documentIconsMap';

interface Props {
    mimeType?: string;
    filename?: string;
}

const useStyles = makeStyles()({
    size: {
        fontSize: '24px !important',
    },
});

const getFileIconByMimeType = (mimeType: string, className: string) => {
    const iconDetails = documentIcons.find(item => item.mimeTypes.includes(mimeType));
    if (iconDetails) {
        return <iconDetails.icon className={className} />;
    }
    return <AkDocument className={className} />;
};

const getFileIconByFilename = (filename: string, className: string) => {
    const ext = filename.split('.').pop() || '';
    const iconDetails = documentIcons.find(item => item.ext === ext.toLowerCase());
    if (iconDetails) {
        return <iconDetails.icon className={className} />;
    }
    return <AkDocument className={className} />;
};

const FileTypeIcon: FC<Props> = ({ mimeType, filename }) => {
    const { classes } = useStyles();
    if (mimeType) return <>{getFileIconByMimeType(mimeType, classes.size)}</>;
    if (filename) return <>{getFileIconByFilename(filename, classes.size)}</>;

    throw new Error('mimeType or filename should be passed as props');
};

export default FileTypeIcon;
