import { Order, OrderTypesEnum } from '../types';

export const getOrderType = (order: Order): OrderTypesEnum => {
    const {
        carrierOfCost: { property, project },
    } = order;

    if (property && project) return OrderTypesEnum.CONSTRUCTION;

    if (property && !project) {
        return OrderTypesEnum.PROPERTY;
    }

    return OrderTypesEnum.ADMIN;
};
