import { Autocomplete, AutocompleteItem, MessageBox } from '@akelius-con/react-ui-kit-components';
import { OnChange } from '@akelius-con/react-ui-kit-components/Autocomplete';
import { Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FormikProps } from 'formik';
import { debounce } from 'lodash';
import { cacheKeys } from 'modules/order/constants';
import getCompanies, { ICompaniesQueryResponse } from 'modules/order/graphql/queries/getCompanies';
import { Company, CompanyCostCenter } from 'modules/order/graphql/queries/getCompany';
import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSelectedCountry } from 'shared/utils/serviceCountry';
import { prepareCompaniesDropdownData } from './prepareCompaniesDropdownData';
import { prepareCostCenterDropdownData } from './prepareCostCenterDropdownData';
import { DEBOUNCE_INTERVAL } from 'shared/constant';
import { useGetUser } from 'modules/order/utils/useGetUser';

interface IProps {
    selectedCompany: Company | null;
    selectedCostCenter: AutocompleteItem | null;
    onCompanyChange: (company: Company | null) => void;
    onCostCenterChange: (costCenter: AutocompleteItem | null) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik?: FormikProps<any>;
    isColumnGrid?: boolean;
    isLoading?: boolean;
    required?: boolean;
}

export const getSelectedCompanyOption = (company?: Company | null) => {
    return company ? { value: company.id, label: `${company.code} - ${company.name}` } : null;
};

export const getSelectedCostCenter = (costCenters: CompanyCostCenter[], costCenterId: string | null) => {
    return costCenters.find(cc => cc.code === costCenterId);
};

export const getCompanyFullForm = (companies: Company[], companyId?: string) => {
    return companies.find(c => c.id === companyId);
};

const SelectAdminCompanyInfo: FC<IProps> = props => {
    const { isColumnGrid, onCompanyChange, onCostCenterChange, formik, selectedCompany, selectedCostCenter, isLoading, required } = props;
    const { t } = useTranslation();
    const { getToken } = useGetUser();
    const selectedCountry = getSelectedCountry();
    const [companyFilters, setCompanyFilters] = useState({ countryCode: selectedCountry, isActive: true, isPropertyCompany: false, term: '' });

    const {
        isError: companiesDataError,
        data: companiesData,
        isLoading: companiesDataLoading,
        refetch: fetchCompanies,
    } = useQuery<ICompaniesQueryResponse>(cacheKeys.companies, getCompanies({ getToken, pageSize: 10, filter: companyFilters }), {
        enabled: true,
        refetchOnWindowFocus: false,
    });

    const onChangeCompany: OnChange = useCallback(
        (_event, companyOption) => {
            if (companyOption && companiesData && Array.isArray(companiesData?.companies.items) && selectedCompany?.id !== companyOption.value) {
                const newSelectedCompany = getCompanyFullForm(companiesData.companies.items, companyOption?.value);
                onCompanyChange(newSelectedCompany || null);
            }

            if (!companyOption) {
                onCompanyChange(null);
                setCompanyFilters({ ...companyFilters, term: '' });
                setTimeout(function () {
                    fetchCompanies();
                });
            }
        },
        [companiesData, selectedCompany, companyFilters, onCompanyChange, fetchCompanies],
    );

    const debouncedFetchCompanies = useMemo(() => debounce(fetchCompanies, DEBOUNCE_INTERVAL), [fetchCompanies]);

    const onChangeCompanyFilter = useCallback(
        (event: ChangeEvent<{ value: string }>) => {
            const term = event.target.value;
            const filters = { ...companyFilters, term };
            onCompanyChange(null);
            setCompanyFilters(filters);
            debouncedFetchCompanies();
        },
        [companyFilters, debouncedFetchCompanies, onCompanyChange],
    );

    const onChangeCostCenter: OnChange = useCallback(
        (_event, costCenter) => {
            onCostCenterChange(costCenter || null);
        },
        [onCostCenterChange],
    );

    const isCostCenterDisabled = useCallback(() => {
        if (isLoading) return true;

        if (formik && (formik?.isSubmitting || !formik?.values.companyId)) {
            return true;
        }

        return !selectedCompany?.costCenters.length;
    }, [formik, isLoading, selectedCompany]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={isColumnGrid ? 12 : 6}>
                <Grid container spacing={1} wrap="nowrap" alignItems="flex-start">
                    <Grid item xs={12}>
                        <Autocomplete
                            data-testid="company-selection"
                            onChange={onChangeCompany}
                            filterOptions={x => x}
                            onBlur={() => formik?.setFieldTouched('companyId')}
                            options={prepareCompaniesDropdownData(companiesData)}
                            disabled={formik?.isSubmitting || companiesDataLoading}
                            loadingText={t('common.loading')}
                            value={getSelectedCompanyOption(selectedCompany)}
                            noOptionsText={t('common.no-matching-result')}
                            error={!!formik?.errors.companyId && !!formik?.touched.companyId}
                            inputProps={{
                                helperText: !!formik?.errors.companyId && formik?.touched.companyId ? (formik?.errors.companyId as string) : '',
                                label: `${t('purchase-order.common.company')} ${required ? '*' : ''}`,
                                placeholder: t('purchase-order.common.company.placeholder'),
                                onChange: onChangeCompanyFilter,
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={isColumnGrid ? 12 : 6}>
                <Autocomplete
                    data-testid="cost-center-selection"
                    onChange={onChangeCostCenter}
                    onBlur={() => formik?.setFieldTouched('costCenter')}
                    options={prepareCostCenterDropdownData(selectedCompany?.costCenters || [])}
                    disabled={isCostCenterDisabled()}
                    loadingText={t('common.loading')}
                    value={selectedCostCenter}
                    noOptionsText={t('common.no-matching-result')}
                    error={!!formik?.errors.costCenter && !!formik?.touched.costCenter && !formik?.errors.companyId}
                    inputProps={{
                        label: `${t('purchase-order.common.cost-center')} ${required ? '*' : ''}`,
                        error: !!formik?.errors.costCenter && !!formik?.touched.costCenter,
                        helperText: !!formik?.errors.costCenter && formik?.touched.costCenter ? (formik?.errors.costCenter as string) : '',
                    }}
                />
            </Grid>
            {companiesDataError && (
                <Grid xs={12} item>
                    <MessageBox type="error">{t('purchase-order.admin-order.company-fetch-error')}</MessageBox>
                </Grid>
            )}
        </Grid>
    );
};

export default SelectAdminCompanyInfo;
