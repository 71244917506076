import { Button, DialogTitle } from '@akelius-con/react-ui-kit-components';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
    open: boolean;
    onDeleteConfirm: () => void;
    onClose: () => void;
}

const DeleteOrderItemConfirm = (props: Props) => {
    const { open, onDeleteConfirm, onClose } = props;
    const { t } = useTranslation();
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle onClose={onClose} title={t('purchase-order.item.delete-title')} />
            <DialogContent>
                <DialogContentText>{t('purchase-order.item.delete-description')}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" label={t('common.cancel')} onClick={onClose} data-testid="cancel" />
                <Button
                    variant="contained"
                    data-testid="dialog-confirm"
                    label={t('common.action.delete')}
                    onClick={onDeleteConfirm}
                    color="primary"
                    appearance="danger"
                    autoFocus
                />
            </DialogActions>
        </Dialog>
    );
};

export default DeleteOrderItemConfirm;
