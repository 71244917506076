import { Button, DialogTitle } from '@akelius-con/react-ui-kit-components';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SupplierContact } from 'shared/graphql/queries/getSuppliers';

interface Props {
    onClose: () => void;
    onDelete: (contact: SupplierContact) => void;
    deleteContactContext: SupplierContact | null;
}

const DeleteAdditionalContactConfirm = (props: Props) => {
    const { onClose, onDelete, deleteContactContext } = props;
    const { t } = useTranslation();

    if (!deleteContactContext) {
        return null;
    }

    return (
        <Dialog open={!!deleteContactContext} onClose={onClose}>
            <DialogTitle onClose={onClose} title={t('purchase-order.contacts.delete')} />
            <DialogContent data-testid="cancel-order-confirm-modal">
                <DialogContentText>{t('purchase-order.contacts.delete-description')}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" label={t('common.cancel')} onClick={onClose} data-testid="cancel-delete-contact" />

                <Button
                    variant="contained"
                    data-testid="delete-contact-confirm"
                    label={t('common.action.delete')}
                    onClick={() => onDelete(deleteContactContext)}
                    color="primary"
                    appearance="danger"
                    autoFocus
                />
            </DialogActions>
        </Dialog>
    );
};

export default DeleteAdditionalContactConfirm;
