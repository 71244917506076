import { gql } from 'graphql-request';
import { GetToken } from 'modules/order/utils/useGetUser';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';

export interface CancelOrderParam {
    getToken: GetToken;
    orderId: string;
}

export interface CancelOrderResponse {
    cancelOrder: {
        id: string;
    };
}

const cancelOrder = () => async (param: CancelOrderParam) => {
    const { getToken, orderId } = param;
    const { graphQLClient } = await getGraphqlClient(getToken);

    const cancelOrderMutation = gql`
        mutation cancelOrder($orderId: String!) {
            cancelOrder(orderId: $orderId) {
                id
            }
        }
    `;

    const data = await graphQLClient.request<CancelOrderResponse>(cancelOrderMutation, {
        orderId,
    });

    return data;
};

export default cancelOrder;
