import { gql } from 'graphql-request';
import { Order, UserPrice } from 'modules/order/types';
import { GetToken } from 'modules/order/utils/useGetUser';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';

export interface Payload {
    note: string;
    description: string;
    supplierPartNumber: string | null;
    manufacturerPartNumber: string | null;
    quantity: {
        value: number;
        unit: string;
    };
    userPrice: UserPrice;
    deliveryDate?: {
        start: string;
        end: string;
    };
}

export interface UpdateOrderItemParam {
    getToken: GetToken;
    orderId: string;
    itemId: string;
    input: Payload;
}

export interface IUpdateOrderResponse {
    updateOrderItem: Order;
}

const updateOrderItem = () => async (params: UpdateOrderItemParam) => {
    const { getToken, ...payload } = params;
    const { graphQLClient } = await getGraphqlClient(getToken);
    const updateOrderItem = gql`
        mutation updateOrderItem($orderId: String!, $itemId: String!, $input: OrderItemUpdate) {
            updateOrderItem(orderId: $orderId, itemId: $itemId, input: $input) {
                ... on Order {
                    id
                    status
                    items {
                        ... on OrderItem {
                            id
                            description
                            quantity {
                                value
                                unit
                            }
                            userPrice {
                                value
                                vatIncluded
                                vatRate
                            }
                            creator {
                                id
                                name
                            }
                            unitPriceCentsNet
                            totalPriceCentsNet
                            totalPriceCentsGross
                            totalVatAmountCents
                            supplierPartNumber
                            manufacturerPartNumber
                            note
                            createdDate
                            updatedDate
                            deliveryDate {
                                start
                                end
                            }
                        }
                    }
                    updatedDate
                    totalPriceCentsNet
                    totalPriceCentsGross
                    totalVatAmountBreakdown {
                        vatRate
                        amountCents
                    }
                }
            }
        }
    `;

    const data = await graphQLClient.request<IUpdateOrderResponse>(updateOrderItem, {
        ...payload,
    });

    return data;
};

export default updateOrderItem;
