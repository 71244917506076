import { TFunction } from 'i18next';
import { OrderItem } from 'modules/order/types';

const transformToRows = (items: OrderItem[], t: TFunction) => {
    return items.map((item: OrderItem) => {
        const {
            id,
            description,
            quantity,
            unitPriceCentsNet,
            totalPriceCentsNet,
            totalPriceCentsGross,
            deliveryDate,
            userPrice: { vatRate },
            totalVatAmountCents,
            supplierPartNumber,
            manufacturerPartNumber,
            note,
        } = item;

        const vatRatePercent = vatRate * 100;
        let deliverySingleDate = null;
        let deliveryPeriod = null;

        if (deliveryDate && deliveryDate.start && deliveryDate.end) {
            if (deliveryDate.start === deliveryDate.end) {
                deliverySingleDate = deliveryDate.start;
            } else {
                deliveryPeriod = `${deliveryDate.start} - ${deliveryDate.end}`;
            }
        }

        const transformedValues = {
            id,
            description,
            quantity: quantity.value,
            unit: t(`purchase-order.item.units.${quantity.unit.toLowerCase()}`),
            unitPriceCentsNet,
            totalPriceCentsNet,
            totalPriceCentsGross,
            vatRatePercent,
            totalVatAmountCents,
            supplierPartNumber,
            manufacturerPartNumber,
            deliverySingleDate,
            deliveryPeriod,
            note,
        };

        return transformedValues;
    });
};

export default transformToRows;
