import { AkExternalLink } from '@akelius-con/react-ui-kit-icons';
import { Link, Typography, TypographyPropsVariantOverrides } from '@mui/material';
import { FC } from 'react';
import { OverridableStringUnion } from '@mui/types';
import { makeStyles } from '@akelius-con/react-ui-kit-components';
import { Variant } from '@mui/material/styles/createTypography';
import { colors } from '@akelius-con/react-theme';

const useStyles = makeStyles()({
    linkIcon: {
        color: `${colors.black}`,
        position: 'relative',
        top: '2px',
    },
    link: {
        fontSize: '16px',
        color: colors.black,
        textDecoration: 'none',
        '&:hover': {
            textDecorationColor: colors.black,
            textDecoration: 'underline',
        },
    },
    label: {
        color: colors.black,
    },
});

interface Props {
    projectId: string;
    variant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
    className?: string;
    iconSize?: number;
    ['data-testid']?: string;
}

export const LinkToAcmProject: FC<Props> = props => {
    const { projectId, variant, className, iconSize, 'data-testid': testId } = props;
    const { classes } = useStyles();
    const acmUrl = `${import.meta.env.VITE_APP_AKELIUS_ACM_URL}jump_to/project/${projectId}`;

    return (
        <div className={className} data-testid={testId || 'link-to-acm-project-container'}>
            <Link target="_blank" href={acmUrl} className={classes.link}>
                <Typography variant={variant || 'body1'} className={classes.label}>
                    {projectId} <AkExternalLink className={classes.linkIcon} style={{ fontSize: iconSize || '16px' }} />
                </Typography>
            </Link>
        </div>
    );
};
