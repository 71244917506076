import { LoadingSpinner, makeStyles } from '@akelius-con/react-ui-kit-components';
import { Typography } from '@mui/material';
import OrderRow from 'modules/order/components/OrderRow';
import { Order } from 'modules/order/types';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { GraphqlErrorNotification } from 'shared/components';
import { PagedResponseDto } from 'shared/types/Response.interface';
import { IFiltersFormatted } from '../OrderFilters/Filters.interface';
import { useOrderInfiniteQueryWrapper } from './useOrderInfiniteQueryWrapper';
import { GraphqlErrorType } from 'shared/types/GraphqlErrorType';

interface Props {
    onDelete: (id: string) => void;
    onCopyOrder: (id: string) => void;
    onHistory: (id: string) => void;
    filters: IFiltersFormatted;
}

const useStyles = makeStyles()({
    ordersRowWrapper: {
        overflow: 'auto',
        marginTop: '22px',
    },
    resultsTitle: {
        marginBottom: '22px',
    },
});

const Orders: FC<Props> = props => {
    const { onDelete, onCopyOrder, onHistory, filters } = props;
    const { t } = useTranslation();
    const { classes } = useStyles();

    const getNextPageParam = useCallback((lastPage: PagedResponseDto<Order>) => {
        const hasNextPageFromResponse = lastPage.orders.hasNextPage;
        const endCursor = lastPage.orders.endCursor;

        if (!hasNextPageFromResponse) {
            return;
        }

        return { endCursor };
    }, []);

    const infiniteRes = useOrderInfiniteQueryWrapper({ filters, getNextPageParam });
    const { fetchNextPage, data: orderData, hasNextPage, isLoading, isFetchingNextPage } = infiniteRes;
    const totalCount = orderData?.pages[0].orders.totalCount;
    const error = infiniteRes.error as GraphqlErrorType;

    const loadMoreData = useCallback(() => {
        if (!isFetchingNextPage) fetchNextPage();
    }, [fetchNextPage, isFetchingNextPage]);

    if (error?.response?.errors) {
        return <GraphqlErrorNotification key="error" error={error} />;
    }

    if (isLoading) {
        return <LoadingSpinner key="content-loader" areaHeight="280px" />;
    }

    return (
        <div data-testid="order-rows-wrapper" className={classes.ordersRowWrapper}>
            {!!totalCount && (
                <Typography variant="h3" className={classes.resultsTitle}>
                    {t('purchase-order.results')} <span data-testid="orders-count">{totalCount}</span>
                </Typography>
            )}

            <div data-testid="order-list">
                <InfiniteScroll
                    hasMore={hasNextPage}
                    loadMore={loadMoreData}
                    loader={
                        <LoadingSpinner
                            key="loading-spinner"
                            areaHeight="80px"
                            dimensions={{
                                size: 20,
                                thickness: 2.5,
                            }}
                            labelPosition="right"
                        />
                    }
                    useWindow
                >
                    <>
                        {orderData?.pages.map(page => (
                            <div key={page.orders.endCursor}>
                                {page.orders.items.map(order => (
                                    <OrderRow onHistory={onHistory} key={order.id} order={order} onDelete={onDelete} onCopyOrder={onCopyOrder} />
                                ))}
                            </div>
                        ))}
                    </>
                </InfiniteScroll>
            </div>
        </div>
    );
};

export default Orders;
