import { gql } from 'graphql-request';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';
import { Supplier } from 'shared/graphql/queries/getSuppliers';
import { GetToken } from 'modules/order/utils/useGetUser';

export interface UpdateSupplierParam {
    getToken: GetToken;
    orderId: string;
    input: {
        supplierId: string;
    };
}

interface IUpdateOrderSupplierResponse {
    updateOrderSupplier: {
        supplier: Supplier;
    };
}

const updateOrderSupplier = () => async (param: UpdateSupplierParam) => {
    const { getToken, ...queryVars } = param;
    const { graphQLClient } = await getGraphqlClient(getToken);

    const updateOrderSupplierMutation = gql`
        mutation updateOrderSupplier($orderId: String!, $input: UpdateOrderSupplier!) {
            updateOrderSupplier(orderId: $orderId, input: $input) {
                supplier {
                    id
                    name
                    address
                    addressDetails {
                        city
                        country
                    }
                    contacts {
                        contactId
                        name
                        email
                        isSelected
                    }
                }
            }
        }
    `;

    const data = await graphQLClient.request<IUpdateOrderSupplierResponse>(updateOrderSupplierMutation, {
        ...queryVars,
    });

    return data;
};

export default updateOrderSupplier;
