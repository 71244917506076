import { InfiniteOrdersCache } from 'modules/order/graphql/queries/useGetOrders';

export const deleteOrderFromInfiniteOrderCache = (cache: InfiniteOrdersCache, orderId: string) => {
    return {
        ...cache,
        pages: cache.pages.map(page => ({
            ...page,
            orders: {
                ...page.orders,
                items: page.orders.items.filter(order => order.id !== orderId),
            },
        })),
    };
};
