import { BreadcrumbItem, Breadcrumbs, Button, LoadingSpinner, MessageBox, makeStyles } from '@akelius-con/react-ui-kit-components';
import { Box, Grid, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { noop } from 'lodash';
import { cacheKeys, routeConst } from 'modules/order/constants';
import { createConstructionOrder } from 'modules/order/graphql/mutations/createConstructionOrder';
import { ConstructionOrderContent } from 'modules/order/pages/CreateConstructionOrder/components/ConstructionOrderContent';
import { useGetUser } from 'modules/order/utils/useGetUser';
import { useRedirect } from 'modules/order/utils/useRedirect';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { StickyPageFooter } from 'shared/components';
import MandatoryFieldDisclaimer from 'shared/components/MandatoryFieldDisclaimer';
import { snackbar } from 'shared/components/Snackbar';
import { delay } from 'shared/constant';
import { getProject } from 'shared/graphql/queries/getProject';
import * as Yup from 'yup';

const useStyles = makeStyles()({
    pageWrapper: {
        marginTop: '20px',
        width: '100%',
    },
    tabsContent: {
        width: '100%',
    },
    chip: {
        alignItems: 'center',
        display: 'flex',
    },
    title: {
        marginTop: '16px',
    },
    lineBreak: {
        whiteSpace: 'pre-line',
    },
});

const CreateConstructionOrder = () => {
    const { classes } = useStyles();
    const { t } = useTranslation();
    const redirect = useRedirect();
    const { title: userTitle, phoneNumber: userPhone, email: userEmail, fullName: userFullName, getToken } = useGetUser();
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const projectId = searchParams.get('project_id');
    const projectCacheKey = cacheKeys.project(projectId || '');

    const {
        isLoading: projectInfoLoading,
        data: projectData,
        error: projectFetchError,
        refetch: fetchProjectDetails,
    } = useQuery(projectCacheKey, getProject({ getToken, projectId }), {
        retry: false,
        enabled: false,
    });

    const validationSchema = Yup.object().shape({
        title: Yup.string().required().max(100).label(t('purchase-order.title')),
        supplierId: Yup.string().required().label(t('purchase-order.general-info.supplier')),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: '',
            supplierId: '',
        },
        validationSchema,
        onSubmit: noop,
    });

    const createOrderMutation = useMutation(createConstructionOrder(), {
        onSuccess: ({ createProjectOrder }) => {
            snackbar.success(t('purchase-order.snackbar.order-creation-successful'));

            setTimeout(() => {
                setIsLoading(false);
                redirect.toOrderDetails(createProjectOrder.id);
            }, delay.redirectDelay);
        },
        onError: () => {
            setIsLoading(false);
            snackbar.error(t('purchase-order.snackbar.order-creation-fail'));
        },
    });

    const saveDraftOrder = async () => {
        setIsLoading(true);
        const contactForSupplier = { email: userEmail, name: userFullName, phone: userPhone, title: userTitle };

        if (projectId) {
            createOrderMutation.mutate({
                getToken,
                input: { ...formik.values, projectId, contactForSupplier },
            });
        }
    };

    useEffect(() => {
        if (projectId) {
            fetchProjectDetails();
        }
    }, [fetchProjectDetails, projectId]);

    if (projectInfoLoading) {
        return <LoadingSpinner areaHeight="calc(100vh - 130px)" />;
    }

    return (
        <>
            <div className={classes.pageWrapper}>
                <Breadcrumbs>
                    <BreadcrumbItem label={t('dashboard.order-list-page-title')} href={routeConst.order} />
                </Breadcrumbs>
            </div>

            <Typography className={classes.title} data-testid="title" variant="h1">
                {t('purchase-order.construction-order.title')}
            </Typography>

            <Grid container spacing={2}>
                <>
                    {(!projectId || projectFetchError) && (
                        <Grid xs={12} item mt={2}>
                            <>
                                {!projectId && (
                                    <MessageBox type="error">{t('purchase-order.construction-order.error.project-id-missing')}</MessageBox>
                                )}
                                <Box mt={2} />
                                {projectFetchError && (
                                    <MessageBox type="error">{t('purchase-order.construction-order.project-fetch-error')}</MessageBox>
                                )}
                            </>
                        </Grid>
                    )}

                    {projectId && !projectData?.project ? (
                        <Grid item>
                            <Box mt={5} />
                            <MessageBox type="error">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: t('common.project-id.not-found', {
                                            projectId,
                                        }),
                                    }}
                                />
                            </MessageBox>
                        </Grid>
                    ) : (
                        <>
                            <Grid item xs={12}>
                                <ConstructionOrderContent formik={formik} projectDetails={projectData?.project} />
                            </Grid>
                            <Grid item xs={12}>
                                <MandatoryFieldDisclaimer />
                            </Grid>
                        </>
                    )}
                </>
            </Grid>

            <StickyPageFooter>
                <Grid container spacing={3} justifyContent="space-between">
                    <Grid item />
                    <Grid item>
                        <Button
                            isLoading={isLoading}
                            disabled={!formik.isValid || !formik.dirty || isLoading}
                            variant="contained"
                            data-testid="create-button"
                            onClick={saveDraftOrder}
                            label={t('common.create')}
                        />
                    </Grid>
                </Grid>
            </StickyPageFooter>
        </>
    );
};

export default CreateConstructionOrder;
