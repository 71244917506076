import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

if (import.meta.env.VITE_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: import.meta.env.VITE_APP_SENTRY_DSN,
        environment: import.meta.env.VITE_APP_ENVIRONMENT || 'testing',
        integrations: [
            new CaptureConsoleIntegration({
                levels: ['error'],
            }),
        ],
        beforeSend: event => {
            if (event.message && !!event.message.match(/:first-child/)) {
                return null;
            }
            return event;
        },
        tracesSampleRate: 0.5,
    });
}

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);
