import { gql } from 'graphql-request';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';
import { ServiceCountriesEnum } from 'shared/types/ServicesCountriesEnum';
import { Project } from './getProject';
import { GetToken } from 'modules/order/utils/useGetUser';

interface ProjectFilter {
    term?: string;
    countryCode: ServiceCountriesEnum;
}
interface GetProjectsParam {
    getToken: GetToken;
    pageSize: number;
    filter: ProjectFilter;
}

export interface IProjectsQueryResponse {
    projects: {
        items: Project[];
    };
}

const getProjects = (param: GetProjectsParam) => async () => {
    const { getToken, ...queryVars } = param;
    const { graphQLClient } = await getGraphqlClient(getToken);

    const getProjectsQuery = gql`
        query projects($pageSize: Int!, $filter: ProjectFilter) {
            projects(pageSize: $pageSize, filter: $filter) {
                endCursor
                items {
                    ... on Project {
                        projectId
                        country
                        costCenter
                        type
                        subType
                        propertyId
                        unitId
                        property {
                            id
                            name
                            code
                            address
                            addressDetails {
                                address1
                                address2
                                streetName
                                streetNumber
                                postalCode
                                city
                                region
                                country
                            }
                            apartment {
                                id
                                code
                                name
                            }
                        }
                    }
                }
            }
        }
    `;

    const data = await graphQLClient.request<IProjectsQueryResponse>(getProjectsQuery, {
        ...queryVars,
    });

    return data;
};

export default getProjects;
