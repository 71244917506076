import { makeStyles } from '@akelius-con/react-ui-kit-components';
import { Box, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Order } from '../../types';
import DeliveryAddressForm from '../DeliveryAddressForm';

const useStyles = makeStyles()({
    blockTitle: {
        margin: '18px 0 10px !important',
    },
});

interface IProps {
    order: Order;
}

const OrderAddressesTab: FC<IProps> = ({ order }) => {
    const { classes } = useStyles();
    const { t } = useTranslation();

    const { invoiceAddress } = order;

    let address1 = '',
        address2 = '',
        streetName = '',
        streetNumber = '',
        postalCode = '',
        city = '',
        country = '';

    if (invoiceAddress) {
        ({ address1, address2, streetName, streetNumber, postalCode, city, country } = invoiceAddress);
    }

    return (
        <Grid container>
            <DeliveryAddressForm order={order} />

            <Grid item xs={2}>
                <Box mb={2} />
            </Grid>

            <Grid item xs={12}>
                <Typography className={classes.blockTitle} variant="h2">
                    {t('purchase-order.invoice-address-title')}
                </Typography>
            </Grid>

            {invoiceAddress && (
                <Grid item xs={12}>
                    <Typography variant="body1">{address1}</Typography>
                    <Typography variant="body1">{address2}</Typography>
                    <Typography variant="body1">{`${streetNumber} ${streetName}`}</Typography>
                    <Typography variant="body1">{`${postalCode} ${city}`}</Typography>
                    <Typography variant="body1">{country}</Typography>
                </Grid>
            )}
        </Grid>
    );
};

export default OrderAddressesTab;
