import { IUsersForPropertyQueryResponse } from 'modules/order/graphql/queries/getUsers';
import { AutocompleteItem } from '@akelius-con/react-ui-kit-components';
import mapUserAutoCompleteItem from './mapUserAutoCompleteItem';

const prepareUsersDropdownData = (usersResponse?: IUsersForPropertyQueryResponse): AutocompleteItem[] => {
    const users = usersResponse?.users?.items;

    if (!users?.length) {
        return [];
    }

    const mapToOSelect = users.map(mapUserAutoCompleteItem);

    const uniqueList = mapToOSelect.reduce((acc: AutocompleteItem[], current: AutocompleteItem) => {
        const found = acc.find(item => item.value === current.value);
        if (!found) {
            acc.push(current);
        }
        return acc;
    }, []);

    return uniqueList;
};

export default prepareUsersDropdownData;
