import { ContentLayout, SidebarNav } from '@akelius-con/react-ui-kit-components';
import { Drawer } from '@mui/material';
import { CreateContractRedirect } from 'modules/order/pages/CreateContractRedirect';
import { routeConst as orderRouteConst } from 'modules/order/constants';
import CreateCaseOrder from 'modules/order/pages/CreateCaseOrder';
import CreateConstructionOrder from 'modules/order/pages/CreateConstructionOrder';
import CreateOrder from 'modules/order/pages/CreateOrder';
import Dashboard from 'modules/order/pages/Dashboard';
import DebugInfo from 'modules/order/pages/DebugInfo';
import OrderDetails from 'modules/order/pages/OrderDetails';
import { RedirectToFirstTab } from 'modules/order/pages/OrderDetails/RedirectToOrderDetailsTab';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Header from 'shared/components/Header';
import { ServiceCountriesEnum } from 'shared/types/ServicesCountriesEnum';
import { getSelectedCountry } from 'shared/utils/serviceCountry';

export const RouteDefinitions: FC = () => {
    const [drawer, setDrawer] = useState<boolean>(false);
    const selectedCountry = getSelectedCountry();

    const onMenuClick = useCallback(() => {
        setDrawer(true);
    }, []);

    const { t } = useTranslation();

    const getSidebarMenuItems = [
        {
            id: 0,
            title: t('common.suppliers'),
            isExternalLink: true,
            route: `${import.meta.env.VITE_APP_AKELIUS_SUA_URL}suppliers`,
        },
        {
            id: 1,
            title: t('dashboard.order-list-page-title'),
            route: '/',
        },
        {
            id: 2,
            title: t('contracts.contracts-list.title'),
            isExternalLink: true,
            route: `${import.meta.env.VITE_APP_AKELIUS_SUA_URL}suppliers/contracts`,
            isDisabled: selectedCountry !== ServiceCountriesEnum.FR,
        },
    ];

    const closeDrawer = useCallback(() => {
        setDrawer(prev => {
            if (prev) return false;
            return true;
        });
    }, []);

    return (
        <BrowserRouter>
            <Header title="Order" onMenuClick={onMenuClick} link={orderRouteConst.order} />
            <ContentLayout>
                <>
                    <Drawer open={drawer} onClose={closeDrawer}>
                        <SidebarNav headerText="order" onClose={closeDrawer} data={getSidebarMenuItems} />
                    </Drawer>

                    <Routes>
                        <Route path={`/`} element={<Navigate to={orderRouteConst.order} />} />
                        <Route path={`/${orderRouteConst.order}`} element={<Dashboard />} />
                        <Route path={`/debug-info`} element={<DebugInfo />} />
                        <Route path={`${orderRouteConst.order}/create`} element={<CreateOrder />} />
                        <Route path={`${orderRouteConst.order}/create-from-case`} element={<CreateCaseOrder />} />
                        <Route path={`${orderRouteConst.order}/create-from-project`} element={<CreateConstructionOrder />} />
                        <Route path={`${orderRouteConst.order}/:orderId/:tabName`} element={<OrderDetails />} />
                        <Route path={`${orderRouteConst.order}/:orderId/`} element={<RedirectToFirstTab />} />
                        <Route path={`contracts/create-from-project`} element={<CreateContractRedirect />} />
                    </Routes>
                </>
            </ContentLayout>
        </BrowserRouter>
    );
};
