import { theme } from '@akelius-con/react-theme';
import { BreadcrumbItem, Breadcrumbs, LoadingSpinner, MessageBox, makeStyles } from '@akelius-con/react-ui-kit-components';
import { Box, Chip, Grid, Tab, Tabs, Typography } from '@mui/material';
import CancelOrderConfirm from 'modules/order/components/CancelOrderConfirm';
import CopyOrderConfirm from 'modules/order/components/CopyOrderConfirm';
import DeleteOrderConfirm from 'modules/order/components/DeleteOrderConfirm';
import ForwardOrderConfirm from 'modules/order/components/ForwardOrderConfirm';
import GeneralInformationTab from 'modules/order/components/GeneralInformationTab';
import InvoicesTab from 'modules/order/components/InvoicesTab';
import OrderAddressesTab from 'modules/order/components/OrderAddressesTab';
import OrderAttachmentsTab from 'modules/order/components/OrderAttachmentsTab';
import OrderContactTab from 'modules/order/components/OrderContactsTab';
import { OrderHistoryDialog } from 'modules/order/components/OrderHistoryDialog';
import OrderItemsTab from 'modules/order/components/OrderItemsTab';
import OrderNotesTab from 'modules/order/components/OrderNotesTab';
import OrderStatus from 'modules/order/components/OrderStatus';
import { routeConst } from 'modules/order/constants';
import { useGetOrder } from 'modules/order/graphql/queries/useGetOrder';
import { Order } from 'modules/order/types';
import { useRedirect } from 'modules/order/utils/useRedirect';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TabItem } from 'shared/types/TabItem.interface';
import ReleaseOrderConfirm from '../../components/ReleaseOrderConfirm';
import { Footer } from './Footer';
import { TabNameEnum } from './TabNameEnum';
import generateReleaseInfoErrors from './generateReleaseInfoErrors';
import validateOrder from './validateOrder';

const useStyles = makeStyles()({
    pageWrapper: {
        marginTop: '20px',
        width: '100%',
    },
    tabsContent: {
        width: '100%',
        marginTop: theme.spacing(2),
        marginBottom: '50px',
    },
    titleWrapper: {
        marginTop: '-4px',
    },
    title: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        lineHeight: '1.5',
        marginTop: '8px !important',
    },
    chipWrapper: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'flex-start',
        },
    },
    chipLeftMargin: {
        marginLeft: '16px !important',
    },
});

interface SupportInfoLabels {
    releaseInfo: string;
}

const OrderDetails = () => {
    const { classes } = useStyles();
    const { t } = useTranslation();
    const redirect = useRedirect();
    const params = useParams<{ orderId: string; tabName: TabNameEnum }>();
    const orderId = params.orderId as string;
    const tabName = params.tabName as TabNameEnum;
    const [copyOrderContext, setCopyOrderContext] = useState<string | null>(null);
    const [deleteOrderContext, setDeleteOrderContext] = useState<string | null>(null);
    const [cancelOrderContext, setCancelOrderContext] = useState<Order | null>(null);
    const [releaseOrderContext, setReleaseOrderContext] = useState<string | null>(null);
    const [forwardOrderContext, setForwardOrderContext] = useState<string | null>(null);
    const [openHistoryDialog, setOpenHistory] = useState<boolean>(false);
    const [supportInfoLabels, setSupportInfoLabels] = useState<SupportInfoLabels>({ releaseInfo: '' });
    const { data, isLoading, error } = useGetOrder(orderId);
    const order = data?.order;
    const isPropertyOrder = Boolean(order?.carrierOfCost.property?.id);

    const onHistoryDialogHandlerOpen = useCallback(() => {
        setOpenHistory(true);
    }, []);

    const onHistoryDialogHandlerClose = useCallback(() => {
        setOpenHistory(false);
    }, []);

    const openCopyOrderDialog = useCallback(() => {
        setCopyOrderContext(orderId);
    }, [orderId]);

    const openDeleteOrderDialog = useCallback(() => {
        setDeleteOrderContext(orderId);
    }, [orderId]);

    const openCancelOrderDialog = useCallback(() => {
        setCancelOrderContext(order || null);
    }, [order]);

    const closeCancelOrderDialog = useCallback(() => {
        setCancelOrderContext(null);
    }, []);

    const onCloseDeleteConfirm = useCallback(() => {
        setDeleteOrderContext(null);
    }, []);

    const onDeleteSuccess = useCallback(() => {
        redirect.toOrders();
    }, [redirect]);

    const closeCopyOrderDialog = useCallback(() => {
        setCopyOrderContext(null);
    }, []);

    const openReleaseOrderDialog = useCallback(() => {
        setReleaseOrderContext(orderId);
    }, [orderId]);

    const openForwardOrderDialog = useCallback(() => {
        setForwardOrderContext(orderId);
    }, [orderId]);

    const closeReleaseOrderDialog = useCallback(() => {
        setReleaseOrderContext(null);
    }, []);

    const closeForwardOrderDialog = useCallback(() => {
        setForwardOrderContext(null);
    }, []);

    const tabList: Record<TabNameEnum, TabItem<{ order: Order }>> = useMemo(
        () => ({
            [TabNameEnum.GENERAL]: {
                index: 0,
                testid: 'general-info-tab',
                label: `${t('purchase-order.general-information')}*`,
                component: GeneralInformationTab,
            },
            [TabNameEnum.ITEMS]: {
                index: 1,
                testid: 'items-tab',
                label: `${t('purchase-order.items')}*`,
                component: OrderItemsTab,
            },
            [TabNameEnum.ADDRESSES]: {
                index: 2,
                testid: 'addresses-tab',
                label: `${t('purchase-order.addresses')}*`,
                component: OrderAddressesTab,
            },
            [TabNameEnum.ATTACHMENTS]: {
                index: 3,
                testid: 'attachments-tab',
                label: t('purchase-order.attachments'),
                component: OrderAttachmentsTab,
            },
            [TabNameEnum.CONTACT]: {
                index: 4,
                testid: 'contacts-tab',
                label: `${t('purchase-order.contacts')}*`,
                component: OrderContactTab,
            },
            [TabNameEnum.NOTES]: {
                index: 5,
                testid: 'notes-tab',
                label: t('purchase-order.notes'),
                component: OrderNotesTab,
            },
            [TabNameEnum.INVOICES]: {
                index: 6,
                testid: 'invoices-tab',
                label: t('purchase-order.invoices'),
                component: InvoicesTab,
                isDisabled: !order?.orderNumber,
            },
        }),
        [order?.orderNumber, t],
    );

    const handleTabChange = useCallback(
        (_: SyntheticEvent<Element, Event>, tabIndex: number) => {
            const tabName = Object.keys(tabList)[tabIndex] as TabNameEnum;
            redirect.toOrderDetails(orderId, tabName);
        },
        [orderId, redirect, tabList],
    );

    useEffect(() => {
        if (!order) return;

        const releaseInfoTitle = `${t('purchase-order.release-info.validation.missing-fields')}\n`;
        const releaseInfoErrors = generateReleaseInfoErrors(validateOrder(order), t);

        if (releaseInfoErrors) {
            setSupportInfoLabels(prevData => ({
                ...prevData,
                releaseInfo: `${releaseInfoTitle} ${generateReleaseInfoErrors(validateOrder(order), t)}`,
            }));
        }
    }, [order, t]);

    if (isLoading) {
        return <LoadingSpinner areaHeight="calc(100vh - 120px)" label={t('purchase-order.common.please-wait')} />;
    }

    if (error?.response.errors[0].errorType === 'NOT_FOUND') {
        return (
            <Grid container mt={3} justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={8} md={6}>
                    <MessageBox type="error">
                        <Typography variant="body1">{t('purchase-order.common.order-not-found')}</Typography>
                    </MessageBox>
                </Grid>
            </Grid>
        );
    }

    if (error) {
        return (
            <Grid container mt={3} justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={8} md={6}>
                    <MessageBox type="error">
                        <Typography variant="body1">{t('common.error')}</Typography>
                    </MessageBox>
                </Grid>
            </Grid>
        );
    }

    const SelectedTabContent = tabList[tabName]?.component;

    return (
        <>
            <div className={classes.pageWrapper}>
                <Breadcrumbs>
                    <BreadcrumbItem label={t('dashboard.order-list-page-title')} href={routeConst.order} />
                </Breadcrumbs>
            </div>

            <Grid container spacing={2} className={classes.titleWrapper}>
                <Grid item xs={12}>
                    <Typography data-testid="order-title" className={classes.title} variant="h1">
                        {order?.title}
                    </Typography>
                </Grid>
                <Grid sx={{ flexGrow: 1 }} item />
                <Grid item className={classes.chipWrapper}>
                    {order && order.status && <OrderStatus status={order.status} />}
                    {order?.orderNumber && <Chip variant="outlined" className={classes.chipLeftMargin} label={order.orderNumber} />}
                </Grid>
            </Grid>

            <div className={classes.tabsContent}>
                <Tabs value={tabList[tabName].index} onChange={handleTabChange}>
                    {Object.values(tabList)
                        .filter(t => !t.isDisabled)
                        .map(tab => (
                            <Tab key={tab.index} label={tab.label} disabled={tab.isDisabled} data-testid={tab.testid} />
                        ))}
                </Tabs>

                <Box data-testid="tab-content" mt={2}>
                    {order && !!SelectedTabContent && <SelectedTabContent order={order} />}
                </Box>
            </div>

            {order && (
                <Footer
                    order={order}
                    onHistoryDialogHandlerOpen={onHistoryDialogHandlerOpen}
                    supportInfoLabels={supportInfoLabels}
                    openCopyOrderDialog={openCopyOrderDialog}
                    openDeleteOrderDialog={openDeleteOrderDialog}
                    openCancelOrderDialog={openCancelOrderDialog}
                    openReleaseOrderDialog={openReleaseOrderDialog}
                    openForwardOrderDialog={openForwardOrderDialog}
                />
            )}

            {openHistoryDialog && <OrderHistoryDialog onClose={onHistoryDialogHandlerClose} orderId={orderId} />}
            <DeleteOrderConfirm onSuccess={onDeleteSuccess} onClose={onCloseDeleteConfirm} deleteContext={deleteOrderContext} />
            {cancelOrderContext && <CancelOrderConfirm closeCancelOrderDialog={closeCancelOrderDialog} order={cancelOrderContext} />}
            {copyOrderContext && <CopyOrderConfirm closeCopyOrderDialog={closeCopyOrderDialog} orderId={copyOrderContext} />}
            {releaseOrderContext && <ReleaseOrderConfirm closeReleaseOrderDialog={closeReleaseOrderDialog} orderId={releaseOrderContext} />}
            {forwardOrderContext && (
                <ForwardOrderConfirm
                    closeForwardOrderDialog={closeForwardOrderDialog}
                    isPropertyOrder={isPropertyOrder}
                    propertyId={order?.carrierOfCost.property?.id}
                    orderId={forwardOrderContext}
                />
            )}
        </>
    );
};

export default OrderDetails;
