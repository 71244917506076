import { gql } from 'graphql-request';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';
import { UserForProperty } from './getResponsiblePersons';
import { GetToken } from 'modules/order/utils/useGetUser';

interface UserFilter {
    name?: string;
    countryCode?: string;
    propertyId?: string;
}

interface GetUsersParam {
    getToken: GetToken;
    filters?: UserFilter;
    pageSize?: number;
}

interface IParam {
    pageParam?: {
        pageSize: number;
        filters: UserFilter;
        cursor: string;
    };
}

export interface IUsersForPropertyQueryResponse {
    users: {
        items: UserForProperty[];
    };
}

export const getUsers =
    (param: GetUsersParam) =>
    async ({ pageParam }: IParam) => {
        const { getToken, filters, pageSize } = param;
        const { graphQLClient } = await getGraphqlClient(getToken);

        let queryVars = { pageSize, filters };

        if (pageParam) {
            queryVars = pageParam;
        }

        const getUsersQuery = gql`
            query users($pageSize: Int!, $filters: UserFilter) {
                users(pageSize: $pageSize, filter: $filters) {
                    items {
                        ... on UserForProperty {
                            name
                            email
                            role
                            phone
                        }
                    }
                }
            }
        `;

        return await graphQLClient.request<IUsersForPropertyQueryResponse>(getUsersQuery, {
            ...queryVars,
        });
    };
