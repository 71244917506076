import { theme } from '@akelius-con/react-theme';
import { Autocomplete, AutocompleteItem, MessageBox } from '@akelius-con/react-ui-kit-components';
import { OnChange } from '@akelius-con/react-ui-kit-components/Autocomplete';
import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { cacheKeys } from 'modules/order/constants';
import getApartments from 'modules/order/graphql/queries/getApartments';
import { useGetUser } from 'modules/order/utils/useGetUser';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import prepareApartmentsDropdownData from '../PropertyCarrierOfCostInfo/addEditCarrierOfCostModal/prepareApartmentDropdownData';
import getSelectedApartment from './getSelectedApartment';

interface IProps {
    propertyId: string | null;
    apartmentId: string | null;
    onChange: OnChange;
}

const SelectOrderApartmentDropdown: FC<IProps> = props => {
    const { propertyId, apartmentId = '', onChange } = props;
    const { t } = useTranslation();
    const { getToken } = useGetUser();
    const [apartments, setApartments] = useState<AutocompleteItem[]>([]);

    const {
        isError: apartmentsDataError,
        refetch: fetchApartments,
        isLoading: apartmentDataLoading,
    } = useQuery(cacheKeys.apartments, getApartments({ getToken, propertyId: propertyId || '' }), {
        enabled: false,
        onSuccess: data => {
            const apartmentsOptions = prepareApartmentsDropdownData(data?.property.apartments || []);
            setApartments(apartmentsOptions);
        },
    });

    useEffect(() => {
        if (propertyId) fetchApartments();
    }, [fetchApartments, propertyId]);

    return (
        <>
            {apartmentsDataError && (
                <>
                    <MessageBox type="error">{t('purchase-order.filter.apartment-fetch-error')}</MessageBox>
                    <Box height={theme.spacing(2)} />{' '}
                </>
            )}

            <Autocomplete
                data-testid="apartment-selection"
                onChange={onChange}
                options={apartments}
                disabled={!propertyId || !apartments || apartmentDataLoading}
                loadingText={t('common.loading')}
                value={getSelectedApartment(apartments, apartmentId)}
                noOptionsText={t('common.no-matching-result')}
                inputProps={{
                    label: t('purchase-order.filter.apartment'),
                }}
            />
        </>
    );
};

export default SelectOrderApartmentDropdown;
