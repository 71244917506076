import { Grid, Switch, Typography } from '@mui/material';

import React, { useCallback, useEffect, useState } from 'react';
import { FeatureToggleKeys, getFeatureToggleState, setFeatureToggleState } from 'shared/utils/featureToggle';

interface Props {
    featureKey: FeatureToggleKeys;
    label: string;
}

export const FeatureToggleListItem = (props: Props) => {
    const [featureState, setFeatureState] = useState(false);
    const { featureKey, label } = props;

    useEffect(() => {
        const state = getFeatureToggleState({ byKey: featureKey });
        setFeatureState(state);
    }, [featureKey]);

    const onChangeFeatureToggle = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const { checked } = event.target;
            setFeatureToggleState(featureKey, checked);
            setFeatureState(checked);
        },
        [featureKey],
    );

    return (
        <>
            <Grid item xs={4}>
                <Typography variant="body1">{label}</Typography>
            </Grid>
            <Grid item xs={8}>
                <Switch data-testid={featureKey} onChange={onChangeFeatureToggle} name={featureKey} checked={featureState} />
            </Grid>
        </>
    );
};
