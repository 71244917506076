import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@akelius-con/react-ui-kit-components';
import { colors } from '@akelius-con/react-theme';

const useStyles = makeStyles()({
    required: {
        color: `${colors.black} !important`,
    },
});

export const MandatoryFieldDisclaimer = () => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    return (
        <Typography variant="body1" className={classes.required}>
            {t('common.required-msg')}
        </Typography>
    );
};

export default MandatoryFieldDisclaimer;
