import { Supplier } from 'shared/graphql/queries/getSuppliers';
import { CompanyMinimal } from './graphql/queries/getCompany';
import { OrderAssociatedProject, OrderStatusEnum } from './graphql/queries/useGetOrder';
import { Approval } from './graphql/queries/useGetOrderApprovalHistory';

export enum OrderTypesEnum {
    PROPERTY = 'PROPERTY',
    ADMIN = 'ADMIN',
    CONSTRUCTION = 'CONSTRUCTION',
}

export interface UserFilter {
    name?: string;
    countryCode?: string;
    propertyId?: string;
}

export interface Creator {
    id: string | null;
    name: string | null;
}

export type Currency = string;

export enum PurchasingUnit {
    ACT = 'ACT',
    MTQ = 'MTQ',
    EA = 'EA',
    HUR = 'HUR',
    KGM = 'KGM',
    LTR = 'LTR',
    MTR = 'MTR',
    MON = 'MOM',
    XPK = 'XPK',
    XPX = 'XPX',
    MTK = 'MTK',
    TNE = 'TNE',
    WEE = 'WEE',
    DAY = 'DAY',
    ANN = 'ANN',
}

export interface ProjectDetails {
    code: string;
    costCenter: string;
}

export interface Address {
    address1: string;
    address2: string;
    streetName: string;
    streetNumber: string;
    postalCode: string;
    city: string;
    region: string | null;
    country: string;
}

export interface OrderDocument {
    createdDate: string;
    creatorName: string;
    docType: string;
    fileTitle: string;
    id?: string;
    sendToSupplier: boolean;
    status?: string;
    sendToApproval?: boolean;
    updatedDate?: string;
    creatorId?: string;
    updaterId?: string;
    updaterName?: string;
    sizeInBytes?: number;
}

export interface Order {
    carrierOfCost: CarrierOfCost;
    id: string;
    orderNumber: string | null;
    supplier: Supplier;
    title: string;
    status: OrderStatusEnum;
    currency: Currency;
    metadata?: string;
    items: OrderItem[];
    approval?: Approval;
    price?: number;
    creator: Creator;
    createdDate: string;
    updatedDate?: string | null;
    releasedDate?: string | null;
    releaseUser?: Creator | null;
    cursor?: string;
    invoiceAddress: Address | null;
    deliveryAddress: Address | null;
    documents: OrderDocument[];
    totalPriceCentsNet: number;
    totalPriceCentsGross: number;
    totalVatAmountBreakdown: TotalVatAmountBreakdown;
    referenceNumber?: string | null;
    noteToSupplier: string | null;
    contactForSupplier?: ContactForSupplier;
    case?: {
        caseId: string;
    };
}

export type IdAndName = {
    id: string;
    name: string;
};

export interface UserPrice {
    value: number;
    vatIncluded: boolean;
    vatRate: number;
}

export interface DeliveryDate {
    start: string;
    end: string;
}
export interface OrderItem {
    id?: string;
    description: string;
    creator?: IdAndName;
    createdDate?: string | null;
    updatedDate?: string | null;
    quantity: {
        value: number;
        unit: PurchasingUnit;
    };
    userPrice: UserPrice;
    unitPriceCentsNet: number;
    totalPriceCentsNet: number;
    totalPriceCentsGross: number;
    totalVatAmountCents: number;
    note: string;
    supplierPartNumber?: string | null;
    manufacturerPartNumber?: string | null;
    deliveryDate: null | DeliveryDate;
}

export interface OrderItemTransFormedRow {
    id: string;
    description: string;
    quantity: number;
    unit: PurchasingUnit;
    userPrice: UserPrice;
    unitPriceCentsNet: number;
    totalPriceCentsNet: number;
    totalPriceCentsGross: number;
    deliveryStartDate?: string;
    deliveryEndDate?: string;
}

export interface PropertyDetails extends Property {
    code: string;
    erpId?: string;
}

export interface Apartment {
    id: string;
    name: string;
    code: string;
}

export interface CarrierOfCost {
    costCenter: string;
    costCenterName?: string;
    countryCode: string;
    company?: CompanyMinimal;
    property?: OrderAssociatedProperty;
    project?: OrderAssociatedProject;
}

interface PropertyCommon {
    id: string;
    name: string;
    code?: string;
    address?: string;
    addressDetails: {
        address1?: string | null;
        address2?: string | null;
        streetName?: string;
        streetNumber?: string;
        postalCode?: string;
        city?: string;
        region?: string;
        country: string;
    };
}

export interface Property extends PropertyCommon {
    costCenter: string;
    apartments: Apartment[];
    company: {
        id: string;
        code: string;
        name: string;
    };
}

export interface OrderAssociatedProperty extends PropertyCommon {
    apartment?: Apartment | null;
}

export interface PropertyFilters {
    countryCode: string;
    city?: string;
    postalCode?: string;
    name?: string;
    term?: string;
}

export interface SignedUrlsMap {
    filename: string;
    signedUrl: string;
    httpHeaders: string[];
}

export type Headers = {
    [key: string]: string | {};
};

export enum DocType {
    CONTRACT = 'CONTRACT',
    ROOM_DESC = 'ROOM_DESC',
    DRAWING = 'DRAWING',
    OFFER = 'OFFER',
    OTHER = 'OTHER',
    PHOTO = 'PHOTO',
    PRICE_LIST = 'PRICE_LIST',
    PRODUCT_SHEET = 'PRODUCT_SHEET',
    STATEMENT_WORK = 'STATEMENT_WORK',
}

export const availableDocTypes: DocType[] = Object.values(DocType);

export interface ContactForSupplier {
    email?: string;
    name?: string;
    phone?: string;
    title?: string;
}

export type TotalVatAmountBreakdown = {
    vatRate: number;
    amountCents: number;
}[];

export type InvoiceList = {
    invoices: Invoice[];
    grossTotal: number;
    currency: string;
};

export type Invoice = {
    id: string;
    number: string;
    currency: string;
    grossTotal: number;
    invoiceDate: string;
    dueDate?: string;
    status: InvoiceStatus;
};

export enum InvoiceStatus {
    APPROVED = 'APPROVED',
    PAID = 'PAID',
}
