import { gql } from 'graphql-request';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';
import { Property } from '../../types';

export interface IPropertyQueryResponse {
    property: Property;
}

export interface GetPropertyParam {
    propertyId: string | null;
    getToken: () => Promise<string>;
}

export const getProperty = (param: GetPropertyParam) => async () => {
    const { getToken, ...queryVars } = param;
    const { graphQLClient } = await getGraphqlClient(getToken);

    const getPropertyQuery = gql`
        query property($propertyId: String!) {
            property(propertyId: $propertyId) {
                id
                name
                code
                costCenter
                address
                addressDetails {
                    postalCode
                    region
                    city
                    country
                }
                company {
                    id
                    code
                    name
                }
                apartments {
                    id
                    name
                    code
                }
            }
        }
    `;

    const data = await graphQLClient.request<IPropertyQueryResponse>(getPropertyQuery, {
        ...queryVars,
    });

    return data;
};
