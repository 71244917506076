import { SERVICE_COUNTRY } from 'shared/constant';
import { ServiceCountriesEnum } from 'shared/types/ServicesCountriesEnum';

export const getDefaultTenant = async (availableCountry: ServiceCountriesEnum) => {
    return availableCountry[0];
};

export const setSelectedCountryToLocalStorage = async (country: ServiceCountriesEnum) => {
    localStorage.setItem(SERVICE_COUNTRY, country);
};

export const getSelectedCountry = (): ServiceCountriesEnum => {
    return localStorage.getItem(SERVICE_COUNTRY) as ServiceCountriesEnum;
};

export const removeSelectedCountry = () => {
    localStorage.removeItem(SERVICE_COUNTRY);
};
