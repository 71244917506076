import { AutocompleteItem, MessageBox } from '@akelius-con/react-ui-kit-components';
import { useQuery } from '@tanstack/react-query';
import { cacheKeys } from 'shared/constant';
import SelectAdminCompanyInfo, { getSelectedCostCenter } from 'modules/order/components/SelectAdminCompanyInfo';
import { Company, CompanyQueryResponse, getCompany } from 'modules/order/graphql/queries/getCompany';
import { useGetUser } from 'modules/order/utils/useGetUser';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CacheConst } from 'shared/constant';

export interface IDs {
    companyId: string | null;
    costCenter: string | null;
}

export interface IOnCompanyDropdownChange {
    (ids: IDs): void;
}

interface IProps {
    companyId: string | null;
    costCenterId: string | null;
    onChange: IOnCompanyDropdownChange;
}

export const CompanyDropdownProvider: FC<IProps> = props => {
    const { t } = useTranslation();
    const { companyId, costCenterId, onChange } = props;
    const { getToken } = useGetUser();
    const cacheKey = cacheKeys.company(companyId);
    const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
    const [selectedCostCenter, setSelectedCostCenter] = useState<AutocompleteItem | null>(null);

    /**
     * @description get the full version of company with the costCenter
     * */
    const { error, refetch } = useQuery<CompanyQueryResponse>(cacheKey, getCompany({ companyId, getToken }), {
        retry: false,
        enabled: false,
        staleTime: CacheConst.company,
        onSuccess: data => {
            setSelectedCompany(data.company);
            const costCenterFull = data.company && getSelectedCostCenter(data.company.costCenters, costCenterId);
            if (costCenterFull) {
                setSelectedCostCenter({
                    label: `${costCenterFull.code} - ${costCenterFull.name}`,
                    value: costCenterFull.code,
                });
            }
        },
    });

    const onChangeCompanyHandler = useCallback(
        (company: Company | null) => {
            if (company) {
                onChange({ companyId: company.id, costCenter: null });

                setSelectedCompany(company);
                return;
            }

            setSelectedCompany(null);
            onChange({ companyId: null, costCenter: null });
        },
        [onChange],
    );

    const onChangeCostCenterHandler = useCallback(
        (costCenter: AutocompleteItem | null) => {
            if (costCenter && selectedCompany) {
                onChange({ companyId: selectedCompany.id, costCenter: costCenter.value });
                setSelectedCostCenter(costCenter);
                return;
            }

            setSelectedCompany(null);
            onChange({ companyId: selectedCompany?.id || null, costCenter: null });
        },
        [onChange, selectedCompany],
    );

    useEffect(() => {
        if (companyId) {
            refetch();
        }
    }, [refetch, companyId]);

    return (
        <>
            {error && <MessageBox type="error">{t('purchase-order.admin-order.company-fetch-error')}</MessageBox>}
            <SelectAdminCompanyInfo
                selectedCompany={selectedCompany}
                selectedCostCenter={selectedCostCenter}
                onCompanyChange={onChangeCompanyHandler}
                onCostCenterChange={onChangeCostCenterHandler}
            />
        </>
    );
};
