import { gql } from 'graphql-request';
import { OrderAssociatedProperty } from 'modules/order/types';
import { GetToken } from 'modules/order/utils/useGetUser';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';

export interface Project {
    projectId: string;
    type: string;
    subType: string;
    costCenter: string;
    country?: string;
    propertyId: string;
    unitId?: string;
    property: OrderAssociatedProperty;
}

export interface ProjectQueryResponse {
    project: Project;
}

export interface GetProjectParam {
    projectId: string | null;
    getToken: GetToken;
}

export const getProject = (param: GetProjectParam) => async () => {
    const { projectId, getToken } = param;
    const { graphQLClient } = await getGraphqlClient(getToken);
    const getProjectQuery = gql`
        query project($projectId: String!) {
            project(projectId: $projectId) {
                projectId
                type
                subType
                costCenter
                country
                propertyId
                unitId
                property {
                    id
                    name
                    code
                    address
                    addressDetails {
                        address1
                        address2
                        streetName
                        streetNumber
                        postalCode
                        city
                        region
                        country
                    }
                    apartment {
                        id
                        code
                        name
                    }
                }
            }
        }
    `;

    const data = await graphQLClient.request<ProjectQueryResponse>(getProjectQuery, {
        projectId,
    });

    return data;
};
