import { MessageBox } from '@akelius-con/react-ui-kit-components';
import { useQuery } from '@tanstack/react-query';
import { cacheKeys } from 'shared/constant';
import SelectPropertyDropdown, { getPropertyOptionFromProperty } from 'modules/order/components/SelectPropertyDropdown';
import { getProperty } from 'modules/order/graphql/queries/getProperty';
import { Property } from 'modules/order/types';
import { useGetUser } from 'modules/order/utils/useGetUser';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CacheConst } from 'shared/constant';

export interface IOnPropertyDropdownChange {
    (property: Property | null): void;
}

interface IProps {
    propertyId: string | null;
    onChange: IOnPropertyDropdownChange;
}

export const SelectPropertyDropdownProvider: FC<IProps> = props => {
    const { t } = useTranslation();
    const { propertyId, onChange } = props;
    const { getToken } = useGetUser();
    const cacheKey = cacheKeys.property(propertyId);
    const [selectedProperty, setSelectedProperty] = useState<Property | null>(null);

    /**
     * @description get the full version of property
     * */
    const { error, refetch } = useQuery(cacheKey, getProperty({ getToken, propertyId }), {
        retry: false,
        enabled: false,
        staleTime: CacheConst.property,
        onSuccess: data => {
            setSelectedProperty(data.property);
        },
    });

    const onChangePropertyHandler: IOnPropertyDropdownChange = useCallback(
        property => {
            if (property) {
                onChange(property);
                setSelectedProperty(property);
                return;
            }

            setSelectedProperty(null);
            onChange(null);
        },
        [onChange],
    );

    useEffect(() => {
        if (propertyId) {
            refetch();
        }
    }, [refetch, propertyId]);

    return (
        <>
            {error && <MessageBox type="error">{t('purchase-order.add-carrier-of-cost.property-fetch-error')}</MessageBox>}
            <SelectPropertyDropdown value={getPropertyOptionFromProperty(selectedProperty)} onChange={onChangePropertyHandler} />
        </>
    );
};
