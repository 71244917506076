import { theme } from '@akelius-con/react-theme';
import { AcImageOption, Autocomplete, AutocompleteItem, MessageBox } from '@akelius-con/react-ui-kit-components';
import { OnChange, OnInputChange, RenderOption } from '@akelius-con/react-ui-kit-components/Autocomplete';
import { Box, Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FormikProps } from 'formik';
import debounce from 'lodash.debounce';
import { cacheKeys } from 'modules/order/constants';
import getProperties from 'modules/order/graphql/queries/getProperties';
import { Property, PropertyFilters } from 'modules/order/types';
import { getPropertyImage } from 'modules/order/utils/getPropertyImage';
import { useGetUser } from 'modules/order/utils/useGetUser';
import { FC, HTMLAttributes, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEBOUNCE_INTERVAL } from 'shared/constant';
import { getSelectedCountry } from 'shared/utils/serviceCountry';
import preparePropertiesDropdownData from '../PropertyCarrierOfCostInfo/addEditCarrierOfCostModal/preparePropertiesDropdownData';

interface IProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik?: FormikProps<any>;
    onChange: (property: Property | null) => void;
    required?: boolean;
    value: AutocompleteItem | null;
    disabled?: boolean;
}

export const getPropertyOptionFromProperty = (property: Property | null): AutocompleteItem | null => {
    return property ? { label: property.name, value: property.id } : null;
};

const getFullProperty = (properties: Property[], property: AutocompleteItem | null): Property | null => {
    const findProperty = properties.find(p => p.id === property?.value);
    return findProperty || null;
};

const SelectPropertyDropdown: FC<IProps> = props => {
    const { onChange, formik, required, value, disabled } = props;
    const { t } = useTranslation();
    const selectedCountry = getSelectedCountry();
    const [propertyFilters, setPropertyFilters] = useState<PropertyFilters>({ countryCode: selectedCountry, term: '' });
    const [properties, setProperties] = useState<Property[]>([]);
    const { getToken } = useGetUser();

    const {
        isError: propertiesDataError,
        isLoading: propertiesDataLoading,
        refetch: fetchProperties,
    } = useQuery(cacheKeys.properties(), getProperties({ pageSize: 10, filters: propertyFilters, getToken }), {
        onSuccess: data => {
            setProperties(data.properties.items);
        },
        enabled: true,
        refetchOnWindowFocus: false,
    });

    const onChangeHandler: OnChange = useCallback(
        (_event, property) => {
            const fullProperty = getFullProperty(properties, property);
            onChange(fullProperty);
            if (!property) {
                const filters = { ...propertyFilters, term: '' };
                setPropertyFilters(filters);
                setTimeout(function () {
                    fetchProperties();
                });
            }
        },
        [fetchProperties, onChange, properties, propertyFilters],
    );

    const debouncedFetchProperties = useMemo(() => debounce(_b => fetchProperties(), DEBOUNCE_INTERVAL), [fetchProperties]);

    const onInputChange: OnInputChange = useCallback(
        (_event, value) => {
            setPropertyFilters({ ...propertyFilters, term: value });
            debouncedFetchProperties({ ...propertyFilters, term: value });
        },
        [propertyFilters, debouncedFetchProperties],
    );

    const renderOption: RenderOption = (props: HTMLAttributes<HTMLLIElement>, option: AutocompleteItem) => {
        return <AcImageOption {...props} title={option.label} subtitle={option.address} thumbnail={getPropertyImage(option.value)} />;
    };

    return (
        <>
            {propertiesDataError && (
                <>
                    <MessageBox type="error">{t('purchase-order.add-carrier-of-cost.property-fetch-error')}</MessageBox>
                    <Box height={theme.spacing(2)} />
                </>
            )}

            <Grid container spacing={1} wrap="nowrap" alignItems="flex-start">
                <Grid item xs={12}>
                    <Autocomplete
                        data-testid="property-selection"
                        onChange={onChangeHandler}
                        filterOptions={x => x}
                        onBlur={() => formik?.setFieldTouched('propertyId')}
                        options={preparePropertiesDropdownData(properties)}
                        disabled={formik?.isSubmitting || propertiesDataLoading || disabled}
                        loadingText={t('common.loading')}
                        value={value}
                        onInputChange={onInputChange}
                        noOptionsText={t('common.no-matching-result')}
                        renderOption={renderOption}
                        error={!!formik?.errors.propertyId && !!formik?.touched.propertyId}
                        inputProps={{
                            helperText: !!formik?.errors.propertyId && formik?.touched.propertyId ? (formik?.errors.propertyId as string) : '',
                            label: `${t('purchase-order.common.property')} ${required ? '*' : ''}`,
                            placeholder: t('purchase-order.common.property.placeholder'),
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default SelectPropertyDropdown;
