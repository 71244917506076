import { QueryFunctionContext, useInfiniteQuery } from '@tanstack/react-query';
import { cacheKeys } from 'modules/order/constants';
import getOrders from 'modules/order/graphql/queries/useGetOrders';
import { IFiltersFormatted } from 'modules/order/pages/Dashboard/components/OrderFilters/Filters.interface';
import { Order } from 'modules/order/types';
import { useGetUser } from 'modules/order/utils/useGetUser';
import { useCallback } from 'react';
import { PagedResponseDto } from 'shared/types/Response.interface';

interface Params {
    filters: IFiltersFormatted;
    getNextPageParam?: (lastPage: PagedResponseDto<Order>) =>
        | {
              endCursor: string | null;
          }
        | undefined;
}

export function useOrderInfiniteQueryWrapper({ filters, getNextPageParam }: Params) {
    const pageSize = 20;
    const { getToken } = useGetUser();

    const queryFn = useCallback(
        async (context: QueryFunctionContext) => {
            /**
             * it is undefined in the first call
             * context.pageParam would contain anything passed by `getNextPageParam` callback
             */

            if (context.pageParam) {
                return getOrders({ pageSize, filters, endCursor: context.pageParam.endCursor, getToken });
            }

            return getOrders({ pageSize, filters, getToken });
        },
        [filters, getToken],
    );

    return useInfiniteQuery(cacheKeys.ordersInfinite(filters), queryFn, { getNextPageParam });
}
