import { colors, theme } from '@akelius-con/react-theme';
import { Autocomplete, AutocompleteItem, MessageBox, makeStyles } from '@akelius-con/react-ui-kit-components';
import { OnChange } from '@akelius-con/react-ui-kit-components/Autocomplete';
import { Box, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FormikProps } from 'formik';
import debounce from 'lodash.debounce';
import { cacheKeys } from 'modules/order/constants';
import { useGetUser } from 'modules/order/utils/useGetUser';
import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEBOUNCE_INTERVAL } from 'shared/constant';
import { Project } from 'shared/graphql/queries/getProject';
import getProjects, { IProjectsQueryResponse } from 'shared/graphql/queries/getProjects';
import { getSelectedCountry } from 'shared/utils/serviceCountry';

interface IProps {
    disabled?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik?: FormikProps<any>;
    required?: boolean;
    label: string;
    preventDisableOnSubmit?: boolean;
    selectedProject?: AutocompleteItem | null;
    onValueChange: (option: Project | null) => void;
}

interface GetProjectFullForm {
    projects?: Project[];
    project: AutocompleteItem | null;
}

const useStyles = makeStyles()({
    hide: {
        display: 'none',
    },
    error: {
        color: colors.error.main,
    },
});

export const getProjectFullForm = ({ projects, project }: GetProjectFullForm): Project | null => {
    if (!projects) return null;
    return projects.find(p => p.projectId === project?.value) || null;
};

export const getSelectedProjectOption = (project: Project | null): AutocompleteItem | null => {
    if (!project) return null;
    const projectId = project.projectId;

    return {
        label: projectId,
        value: projectId,
    };
};

const prepareProjectsDropdownData = (projectsData: IProjectsQueryResponse | undefined) => {
    const projectOptions = projectsData?.projects.items.map(({ projectId }) => ({
        label: projectId,
        value: projectId,
    }));

    return projectOptions || [];
};

export const getProjectOptionFromProject = (project: Project | null) => {
    return project ? { label: project.projectId, value: project?.projectId } : null;
};

export const SelectProjectDropdown: FC<IProps> = props => {
    const { onValueChange, selectedProject, preventDisableOnSubmit, formik, required, label, disabled } = props;
    const { t } = useTranslation();
    const { classes } = useStyles();
    const [searchStr, setSearchStr] = useState<string>('');
    const selectedCountry = getSelectedCountry();
    const { getToken } = useGetUser();

    const {
        data: projectsData,
        isLoading,
        isError,
        refetch,
    } = useQuery(
        cacheKeys.projects,
        getProjects({
            getToken,
            pageSize: 10,
            filter: {
                countryCode: selectedCountry,
                term: searchStr,
            },
        }),
        {
            refetchOnWindowFocus: false,
        },
    );

    const handleChangeProject: OnChange = (_event, project) => {
        const fullForm = getProjectFullForm({ projects: projectsData?.projects.items, project });

        onValueChange(fullForm);

        if (project === null) {
            setSearchStr('');
            onValueChange(null);
            setTimeout(function () {
                refetch();
            });
            return;
        }
    };

    const debouncedRefetch = useMemo(() => debounce(() => refetch(), DEBOUNCE_INTERVAL), [refetch]);

    const filterProjectByQuery = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target;
            setSearchStr(value.toLowerCase());
            onValueChange(null);
            debouncedRefetch();
        },
        [debouncedRefetch, onValueChange],
    );

    return (
        <div>
            {isError && (
                <>
                    <MessageBox type="error">{t('purchase-order.project.fetch-error')}</MessageBox>
                    <Box height={theme.spacing(2)} />
                </>
            )}

            <Autocomplete
                onChange={handleChangeProject}
                options={prepareProjectsDropdownData(projectsData)}
                filterOptions={options => options}
                disabled={disabled || (!preventDisableOnSubmit && formik && formik.isSubmitting)}
                loading={isLoading}
                error={formik && !!(formik.errors.projectId && formik.touched.projectId)}
                loadingText={t('common.loading')}
                onBlur={() => formik && formik.setFieldTouched('projectId')}
                value={selectedProject || null}
                noOptionsText={t('common.no-matching-result')}
                data-testid="project-selection"
                inputProps={{
                    onChange: filterProjectByQuery,
                    label: `${label} ${required ? '*' : ''}`,
                }}
            />

            {formik && formik.errors.projectId && formik.touched.projectId && (
                <Typography variant="body1" className={classes.error}>
                    {formik.errors.projectId as string}
                </Typography>
            )}
        </div>
    );
};
