import { Button, DialogTitle, makeStyles } from '@akelius-con/react-ui-kit-components';
import { Dialog, DialogActions, DialogContent, DialogContentText, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRedirect } from 'modules/order/utils/useRedirect';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { snackbar } from 'shared/components/Snackbar';
import { cacheKeys } from '../../constants';
import releaseOrder from '../../graphql/mutations/releaseOrder';
import { useGetUser } from 'modules/order/utils/useGetUser';

const useStyles = makeStyles()({
    showNewLines: {
        whiteSpace: 'pre-wrap',
    },
});

interface Props {
    closeReleaseOrderDialog: () => void;
    orderId: string;
}

const ReleaseOrderConfirm = ({ closeReleaseOrderDialog, orderId }: Props) => {
    const [actionDisabled, setActionDisabled] = useState<boolean>(false);
    const redirect = useRedirect();
    const queryClient = useQueryClient();
    const cacheKey = cacheKeys.order(orderId);
    const { t } = useTranslation();
    const { getToken } = useGetUser();
    const { classes } = useStyles();

    const releaseOrderMutation = useMutation(releaseOrder(), {
        onSuccess: () => {
            queryClient.refetchQueries(cacheKey);
            snackbar.success(t('purchase-order.snackbar.release-order-success'));
            setActionDisabled(false);
            redirect.toOrders();
            closeReleaseOrderDialog();
        },
        onError: () => {
            snackbar.error(t('purchase-order.snackbar.release-order-failure'));
            setActionDisabled(false);
            closeReleaseOrderDialog();
        },
    });

    const handleReleaseClick = async () => {
        setActionDisabled(true);
        return releaseOrderMutation.mutate({ orderId, getToken });
    };

    return (
        <Dialog open={!!orderId} onClose={closeReleaseOrderDialog} data-testid="release-order-confirm-modal">
            <DialogTitle onClose={closeReleaseOrderDialog} title={t('purchase-order.release.title')} />
            <DialogContent>
                <DialogContentText>
                    <Typography variant="body1" className={classes.showNewLines}>
                        {t('purchase-order.release.description')}
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={actionDisabled}
                    variant="outlined"
                    label={t('common.cancel')}
                    onClick={closeReleaseOrderDialog}
                    data-testid="cancel"
                />
                <Button
                    variant="contained"
                    data-testid="release"
                    label={t('purchase-order.button.release')}
                    disabled={actionDisabled}
                    onClick={handleReleaseClick}
                    color="primary"
                    autoFocus
                />
            </DialogActions>
        </Dialog>
    );
};

export default ReleaseOrderConfirm;
