import {
    AkFileCab,
    AkFileDoc,
    AkFileDocx,
    AkFileDotx,
    AkFileDwg,
    AkFileDxf,
    AkFileGif,
    AkFileGzip,
    AkFileHtml,
    AkFileJpg,
    AkFileJson,
    AkFilePdf,
    AkFilePng,
    AkFilePpt,
    AkFilePptx,
    AkFilePst,
    AkFileRfc,
    AkFileRtf,
    AkFileTiff,
    AkFileTxt,
    AkFileXls,
    AkFileXlsm,
    AkFileXlsx,
    AkFileXltx,
    AkFileXps,
    AkFileZip,
    AkFile_7z,
} from '@akelius-con/react-ui-kit-icons';
import React from 'react';

export interface DocumentIcon {
    icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
    mimeTypes: string[];
    ext: string;
}

const documentIcons: DocumentIcon[] = [
    {
        // eslint-disable-next-line react/jsx-pascal-case
        icon: AkFile_7z,
        mimeTypes: ['application/x-7z-compressed'],
        ext: '7z',
    },
    {
        icon: AkFileCab,
        mimeTypes: ['application/x-tika-ooxml'],
        ext: 'xml',
    },
    {
        icon: AkFileGzip,
        mimeTypes: ['application/gzip'],
        ext: 'gzip',
    },
    {
        icon: AkFileZip,
        mimeTypes: ['application/zip'],
        ext: 'zip',
    },
    {
        icon: AkFileJson,
        mimeTypes: ['application/json'],
        ext: 'json',
    },
    {
        icon: AkFileDocx,
        mimeTypes: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
        ext: 'docx',
    },
    {
        icon: AkFileDoc,
        mimeTypes: ['application/msword'],
        ext: 'doc',
    },
    {
        icon: AkFileDotx,
        mimeTypes: ['application/vnd.openxmlformats-officedocument.wordprocessingml.template'],
        ext: 'dotx',
    },
    {
        icon: AkFileXlsm,
        mimeTypes: ['application/vnd.ms-excel.sheet.macroEnabled.12'],
        ext: 'xlsm',
    },
    {
        icon: AkFileXlsx,
        mimeTypes: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
        ext: 'xlsx',
    },
    {
        icon: AkFileXls,
        mimeTypes: ['application/vnd.ms-excel'],
        ext: 'xls',
    },
    {
        icon: AkFilePpt,
        mimeTypes: ['application/vnd.ms-powerpoint'],
        ext: 'ppt',
    },
    {
        icon: AkFilePptx,
        mimeTypes: ['application/vnd.openxmlformats-officedocument.presentationml.presentation'],
        ext: 'pptx',
    },
    {
        icon: AkFileXltx,
        mimeTypes: ['application/vnd.openxmlformats-officedocument.spreadsheetml.template'],
        ext: 'xltx',
    },
    {
        icon: AkFilePdf,
        mimeTypes: ['application/pdf'],
        ext: 'pdf',
    },
    {
        icon: AkFileGif,
        mimeTypes: ['image/gif'],
        ext: 'gif',
    },
    {
        icon: AkFileJpg,
        mimeTypes: ['image/jpeg'],
        ext: 'jpg',
    },
    {
        icon: AkFilePng,
        mimeTypes: ['image/png'],
        ext: 'png',
    },
    {
        icon: AkFileDwg,
        mimeTypes: ['image/x-dwg', 'image/vnd.dwg'],
        ext: 'dwg',
    },
    {
        icon: AkFileDxf,
        mimeTypes: ['image/x-dxf', 'image/vnd.dxf'],
        ext: 'dxf',
    },
    {
        icon: AkFileTxt,
        mimeTypes: ['text/plain'],
        ext: 'txt',
    },
    {
        icon: AkFileRtf,
        mimeTypes: ['application/rtf', 'text/rtf'],
        ext: 'rtf',
    },
    {
        icon: AkFilePst,
        mimeTypes: ['application/vnd.ms-outlook'],
        ext: 'pst',
    },
    {
        icon: AkFileXps,
        mimeTypes: ['application/vnd.ms-xpsdocument'],
        ext: 'xps',
    },
    {
        icon: AkFileTiff,
        mimeTypes: ['image/tiff'],
        ext: 'tiff',
    },
    {
        icon: AkFileRfc,
        mimeTypes: ['message/rfc822'],
        ext: 'rfc',
    },
    {
        icon: AkFileHtml,
        mimeTypes: ['text/html'],
        ext: 'html',
    },
];

export default documentIcons;
