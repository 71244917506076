import { MessageBox } from '@akelius-con/react-ui-kit-components';
import { useQuery } from '@tanstack/react-query';
import { cacheKeys } from 'shared/constant';
import { useGetUser } from 'modules/order/utils/useGetUser';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectProjectDropdown, getSelectedProjectOption } from 'shared/components/SelectProjectDropDown';
import { CacheConst } from 'shared/constant';
import { Project, ProjectQueryResponse, getProject } from 'shared/graphql/queries/getProject';

export interface IOnProjectDropdownChange {
    (project: Project | null): void;
}

interface IProps {
    projectId: string | null;
    onChange: IOnProjectDropdownChange;
}

export const ProjectDropdownProvider: FC<IProps> = props => {
    const { t } = useTranslation();
    const { projectId, onChange } = props;
    const cacheKey = cacheKeys.project(projectId);
    const { getToken } = useGetUser();
    const [selectedProject, setSelectedProject] = useState<Project | null>(null);

    /**
     * @description get the full version of project
     * */
    const { error, refetch } = useQuery<ProjectQueryResponse>(cacheKey, getProject({ getToken, projectId }), {
        retry: false,
        enabled: false,
        staleTime: CacheConst.project,
        onSuccess: data => {
            setSelectedProject(data.project);
        },
    });

    const onChangeProjectHandler: IOnProjectDropdownChange = useCallback(
        project => {
            if (project) {
                onChange(project);
                setSelectedProject(project);
                return;
            }

            setSelectedProject(null);
            onChange(null);
        },
        [onChange],
    );

    useEffect(() => {
        if (projectId) {
            refetch();
        }
    }, [refetch, projectId]);

    return (
        <>
            {error && <MessageBox type="error">{t('purchase-order.project.fetch-error')}</MessageBox>}
            <SelectProjectDropdown
                label={t('purchase-order.construction-order.project')}
                selectedProject={getSelectedProjectOption(selectedProject)}
                onValueChange={onChangeProjectHandler}
            />
        </>
    );
};
