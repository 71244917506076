import dateFormatter from './dateFormatter';

const defaultTimeZone = 'Europe/Berlin';
// Akelius uses Canadian date formatting everywhere (YYYY-MM-DD)
const dateLocale = 'en-CA';
// Akelius uses German time formatting everywhere (HH:MM)
const timeLocale = 'de-DE';

export function formatDate(date: Date | string, timeZone = defaultTimeZone): string {
    const setTimeZone = new Date(date).toLocaleDateString(dateLocale, { timeZone: timeZone });
    return dateFormatter(setTimeZone.toString());
}

export default function formatDateTime(date: string | Date, timeZone = defaultTimeZone): string {
    const dateObj = new Date(date);
    const formattedDate = formatDate(dateObj, timeZone);
    const formattedTime = dateObj.toLocaleTimeString(timeLocale, { timeZone, hour: '2-digit', minute: '2-digit' });

    return `${formattedDate}, ${formattedTime}`;
}
