import { Button, makeStyles } from '@akelius-con/react-ui-kit-components';
import { AkThreeDotsHorizontal } from '@akelius-con/react-ui-kit-icons';
import { Backdrop, IconButton, Menu, PopoverOrigin } from '@mui/material';
import { FC, MouseEvent, useCallback, useState } from 'react';
import { ContextMenuItem, IMenuItem } from './ContextMenuItem';

const useStyles = makeStyles()({
    contextMenu: {
        border: 0,
        width: '40px',
        height: '40px',
        minWidth: 'auto',
        borderColor: 'transparent',
        '&:hover': {
            borderColor: 'transparent',
        },
        '& svg': {
            fontSize: '24px',
        },
    },
});

interface IContextProps {
    label?: string;
    items: IMenuItem[];
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    'data-testid'?: string;
}

const defaultAnchorOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
};

const defaultTransformOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'right',
};

export const ContextMenu: FC<IContextProps> = props => {
    const { label, anchorOrigin, transformOrigin, items, 'data-testid': testid } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const onClose = useCallback(() => {
        setAnchorEl(null);
    }, []);
    const { classes } = useStyles();

    return (
        <>
            {open && <Backdrop open={open} onClick={onClose} sx={{ zIndex: theme => theme.zIndex.drawer + 1 }} />}
            {!label ? (
                <IconButton data-testid={testid} className={classes.contextMenu} onClick={handleClick}>
                    <AkThreeDotsHorizontal />
                </IconButton>
            ) : (
                <Button variant="text" data-testid={testid} label={label} startIcon={<AkThreeDotsHorizontal />} onClick={handleClick} />
            )}
            <Menu
                anchorOrigin={anchorOrigin || defaultAnchorOrigin}
                transformOrigin={transformOrigin || defaultTransformOrigin}
                anchorEl={anchorEl}
                open={open}
                onClose={onClose}
            >
                {items.map((item: IMenuItem) => (
                    <ContextMenuItem key={item.id} onClose={onClose} item={item} />
                ))}
            </Menu>
        </>
    );
};
