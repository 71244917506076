import { MessageBox } from '@akelius-con/react-ui-kit-components';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { GraphqlError, GraphqlErrorType } from 'shared/types/GraphqlErrorType';
import errorMap from './errorMap';

interface Props {
    error: GraphqlErrorType;
}

const getTranslation = (t: TFunction<string>, errorType: string): string => {
    const errorMessage = errorMap[errorType];

    if (errorMessage) {
        return t(errorMessage);
    }

    return t('common.backend-errors.default', 'something went wrong');
};

const GraphqlErrorNotification = ({ error }: Props) => {
    const { t } = useTranslation();

    return (
        <MessageBox type="error">
            <div data-testid="error-message">
                {error?.response?.errors.map((err: GraphqlError) => {
                    return (
                        <span key={err.message}>
                            {getTranslation(t, err.errorType)} <br />
                        </span>
                    );
                })}
            </div>
        </MessageBox>
    );
};

export default GraphqlErrorNotification;
