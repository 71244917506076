import { AutocompleteItem, Button, DialogTitle, MessageBox } from '@akelius-con/react-ui-kit-components';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { cacheKeys } from 'modules/order/constants';
import { UpdateAdminOrderParam, updateAdminCostCenter } from 'modules/order/graphql/mutations/updateAdminCostCenter';
import { CostCenter } from 'modules/order/graphql/queries/getCompanies';
import { Company, CompanyQueryResponse, getCompany } from 'modules/order/graphql/queries/getCompany';
import { IOrderResponse } from 'modules/order/graphql/queries/useGetOrder';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { snackbar } from 'shared/components/Snackbar';
import SelectAdminCompanyInfo from '../../SelectAdminCompanyInfo';
import { useGetUser } from 'modules/order/utils/useGetUser';

interface IProps {
    open: boolean;
    onClose: () => void;
    selectedCompanyId: string;
    selectedCostCenterId: string;
}

const getCostCenterAutoCompleteItem = (costCenterId: string | null, costCenters: CostCenter[] = []) => {
    const costCenter = costCenters.find(costCenter => costCenter.code === costCenterId);
    if (!costCenter) return null;
    return { label: `${costCenter.code} - ${costCenter.name}`, value: costCenter.code };
};

export const AddEditAdminCarrierOfCostInfoModal = (props: IProps) => {
    const { open, onClose, selectedCostCenterId, selectedCompanyId } = props;
    const params = useParams<{ orderId: string }>();
    const orderId = params.orderId as string;
    const { getToken } = useGetUser();

    const { error: isCompanyError, data: companyDataRes } = useQuery<CompanyQueryResponse>(
        cacheKeys.company(selectedCompanyId),
        getCompany({ companyId: selectedCompanyId, getToken }),
    );

    const [newSelectedCompany, setNewSelectedCompany] = useState<Company | null>(null);
    const [newSelectedCostCenter, setNewSelectedCostCenter] = useState<string | null>(selectedCostCenterId);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const queryClient = useQueryClient();
    const cacheKey = cacheKeys.order(orderId);

    useEffect(() => {
        if (companyDataRes?.company) {
            setNewSelectedCompany(companyDataRes.company);
        }
    }, [companyDataRes?.company]);

    const isDirty = () => {
        if (companyDataRes?.company?.id !== newSelectedCompany?.id) {
            return true;
        }

        if (selectedCostCenterId !== newSelectedCostCenter) {
            return true;
        }

        return false;
    };

    const updateAdminCostCenterMutation = useMutation(updateAdminCostCenter(), {
        onSuccess: ({ updateOrderCostCenter }) => {
            snackbar.success(t('purchase-order.snackbar.admin-cost-center-update-successful'));

            queryClient.setQueriesData<IOrderResponse | undefined>(cacheKey, (currentCache: IOrderResponse | undefined) => {
                if (currentCache && newSelectedCompany) {
                    return {
                        ...currentCache,
                        order: {
                            ...currentCache.order,
                            carrierOfCost: updateOrderCostCenter.carrierOfCost,
                        },
                    };
                }
                return;
            });

            setIsLoading(false);
            onClose();
        },
        onError: () => {
            snackbar.error(t('purchase-order.snackbar.admin-cost-center-update-failed'));
            setIsLoading(false);
        },
    });

    const saveCarrierOfCost = useCallback(() => {
        setIsLoading(true);

        if (newSelectedCompany?.id && newSelectedCostCenter) {
            const variables: UpdateAdminOrderParam = {
                getToken,
                orderId,
                input: {
                    companyId: newSelectedCompany.id,
                    costCenter: newSelectedCostCenter,
                },
            };
            updateAdminCostCenterMutation.mutate(variables);
        }
    }, [getToken, newSelectedCompany?.id, newSelectedCostCenter, orderId, updateAdminCostCenterMutation]);

    return (
        <Dialog disableEscapeKeyDown maxWidth="sm" fullWidth open={open} onClose={onClose} data-testid="add-edit-admin-carrier-of-cost-modal">
            <DialogTitle onClose={onClose} title={t('purchase-order.edit-carrier-of-cost')} />
            <DialogContent>
                <>
                    {companyDataRes?.company && (
                        <SelectAdminCompanyInfo
                            required
                            isColumnGrid
                            isLoading={isLoading}
                            selectedCompany={newSelectedCompany}
                            selectedCostCenter={getCostCenterAutoCompleteItem(newSelectedCostCenter, companyDataRes.company.costCenters)}
                            onCompanyChange={setNewSelectedCompany}
                            onCostCenterChange={(costCenter: AutocompleteItem | null) => setNewSelectedCostCenter(costCenter?.value || null)}
                        />
                    )}

                    {isCompanyError && <MessageBox type="error">{t('purchase-order.company.fetch-error')}</MessageBox>}
                </>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" label={t('common.cancel')} onClick={onClose} data-testid="cancel" />
                <Button
                    isLoading={isLoading}
                    onClick={saveCarrierOfCost}
                    data-testid="admin-coc-save-button"
                    variant="contained"
                    type="submit"
                    disabled={!isDirty() || !newSelectedCompany?.id || !newSelectedCostCenter}
                    label={t('common.save')}
                />
            </DialogActions>
        </Dialog>
    );
};
