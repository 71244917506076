import { colors } from '@akelius-con/react-theme';
import { makeStyles } from '@akelius-con/react-ui-kit-components';
import { AkClose, AkInfo, AkSearch } from '@akelius-con/react-ui-kit-icons';
import { Box, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()({
    searchField: {
        '& input': {
            paddingLeft: '38px',
        },
        '& label.MuiInputLabel-root': {
            transform: 'translate(38px, 18px) scale(1)',
        },
        '& label.MuiInputLabel-shrink': {
            transform: 'translate(38px, 3px) scale(1)',
        },
    },
    clearButton: {
        padding: '4px',
        minWidth: 'auto',
        border: 0,
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: colors.warning.light,
        },
    },
});

interface IProps {
    label: string;
    placeholder: string;
    ['data-testid']?: string;
    value: string;
    disabled?: boolean;
    queryClear: () => void;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const OrderSearchField: FC<IProps> = props => {
    const { value, disabled, queryClear, ...rest } = props;
    const { classes } = useStyles();
    const { t } = useTranslation();

    const endAdornment = (
        <InputAdornment position="end">
            <>
                {!disabled && value && (
                    <IconButton onClick={queryClear} className={classes.clearButton}>
                        <AkClose />
                    </IconButton>
                )}
            </>
        </InputAdornment>
    );

    return (
        <Box sx={{ display: 'flex', position: 'relative', alignItems: 'center' }}>
            <Box sx={{ position: 'absolute', zIndex: 1, bottom: '14px', left: 6 }}>
                <AkSearch />
            </Box>
            <TextField
                {...rest}
                value={value || ''}
                className={classes.searchField}
                InputProps={{
                    endAdornment,
                }}
            />
            <Box sx={{ display: 'flex', alignItems: 'end', marginLeft: 1 }}>
                <Tooltip title={t('purchase-order.filter.search-placeholder')}>
                    <IconButton size="small" sx={{ mt: 1.2, cursor: 'default' }}>
                        <AkInfo />
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
    );
};

export default OrderSearchField;
