import { Button, MessageBox, makeStyles } from '@akelius-con/react-ui-kit-components';
import { AkPlus } from '@akelius-con/react-ui-kit-icons';
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { cacheKeys } from 'modules/order/constants';
import deleteContact, { DeleteContactRes } from 'modules/order/graphql/mutations/deleteAdditionalSupplierContact';
import updateOrder, { IUpdateOrderParam, UpdateOrderResponse } from 'modules/order/graphql/mutations/updateOrder';
import { IOrderResponse } from 'modules/order/graphql/queries/useGetOrder';
import { ChangeEvent, FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { snackbar } from 'shared/components/Snackbar';
import { SupplierContact } from 'shared/graphql/queries/getSuppliers';
import { isOrderReadonlyByStatus } from 'shared/utils/order';
import { Order } from '../../types';
import AddEditAdditionalContactDialog from '../AddEditAdditionalContactDialog';
import { AkeliusContact } from '../AkeliusContact';
import DeleteAdditionalContactConfirm from '../DeleteAdditionalContactConfirm';
import ContactRow from './ContactRow';
import { useGetUser } from 'modules/order/utils/useGetUser';
import { getUpdateAdditionalContactCache } from './getUpdateAdditionalContactCache';

interface IProps {
    order: Order;
}

const useStyles = makeStyles()({
    tabTitle: {
        margin: '12px 0 0 !important',
    },
});

const OrderContactsTab: FC<IProps> = ({ order }) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const contacts = order.supplier.contacts;
    const additionalContacts = order.supplier?.additionalContacts || [];
    const params = useParams<{ orderId: string }>();
    const orderId = params.orderId as string;
    const [contactStateLoading, setContactStateLoading] = useState<boolean>(false);
    const [openAddContactDialog, setOpenAddContactDialog] = useState<boolean>(false);
    const [editContactContext, setEditContactContext] = useState<SupplierContact | null>(null);
    const [deleteContactContext, setDeleteContactContext] = useState<SupplierContact | null>(null);

    const queryClient = useQueryClient();
    const cacheKey = cacheKeys.order(orderId);
    const cachedData: IOrderResponse | undefined = queryClient.getQueryData(cacheKey);
    const { getToken } = useGetUser();
    const deleteContactMutation = useMutation(deleteContact(), {
        onSuccess: (data: DeleteContactRes) => {
            queryClient.setQueryData<IOrderResponse | undefined>(cacheKey, (prevData: IOrderResponse | undefined) => {
                const updatedContacts = data.removeOrderSupplierContact.supplier.additionalContacts;

                if (prevData) {
                    return getUpdateAdditionalContactCache(prevData, updatedContacts);
                }
            });

            snackbar.success(t('purchase-order.snackbar.contact.delete-success'));
        },
        onError: () => {
            snackbar.error(t('purchase-order.snackbar.contact.delete-failed'));
        },
    });

    const addContactHandler = useCallback(() => {
        setOpenAddContactDialog(true);
    }, [setOpenAddContactDialog]);

    const editAdditionalContact = useCallback(
        (additionalContact: SupplierContact) => {
            setEditContactContext(additionalContact);
            setOpenAddContactDialog(true);
        },
        [setOpenAddContactDialog],
    );

    const closeAddContactDialog = useCallback(() => {
        setOpenAddContactDialog(false);
        setEditContactContext(null);
    }, [setOpenAddContactDialog]);

    const updateOrderMutation = useMutation(updateOrder(), {
        onSuccess: (successRes: UpdateOrderResponse) => {
            setContactStateLoading(false);
            snackbar.success(t('purchase-order.snackbar.update-success'));

            if (!cachedData) return;

            const updatedCache = {
                ...cachedData,
                order: {
                    ...cachedData.order,
                    supplier: {
                        ...cachedData.order.supplier,
                        contacts: successRes.updateOrder.supplier.contacts,
                    },
                },
            };

            queryClient.setQueryData(cacheKey, updatedCache);
        },
        onError: () => {
            snackbar.error(t('purchase-order.snackbar.update-failed'));
        },
    });

    const onSelectContact = (event: ChangeEvent<HTMLInputElement>) => {
        const contactId = event.target.name;
        const isChecked = event.target.checked;
        setContactStateLoading(true);
        const selectedContacts = order.supplier.contacts.filter(c => c.isSelected).map(c => c.contactId) || [];

        const queryVars: IUpdateOrderParam = {
            getToken,
            orderId: order.id,
            input: {
                selectedContacts: isChecked ? [contactId, ...selectedContacts] : selectedContacts.filter(c => c !== contactId),
            },
        };

        updateOrderMutation.mutate(queryVars);
    };

    const onCloseDeleteConfirm = useCallback(() => {
        setDeleteContactContext(null);
    }, []);

    const onDeleteHandler = useCallback(
        (contact: SupplierContact) => {
            deleteContactMutation.mutate({
                getToken,
                orderId,
                contactId: contact.contactId,
            });
            onCloseDeleteConfirm();
        },
        [deleteContactMutation, getToken, orderId, onCloseDeleteConfirm],
    );

    const handleDeleteContact = useCallback((contact: SupplierContact) => {
        setDeleteContactContext(contact);
    }, []);

    const isReadOnly = isOrderReadonlyByStatus(order.status);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography className={classes.tabTitle} variant="h2">{`${t('purchase-order.contacts.title')}*`}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Button
                    disabled={isReadOnly}
                    label={t('purchase-order.add-contact')}
                    variant="outlined"
                    startIcon={<AkPlus />}
                    data-testid="add-contact-button"
                    onClick={addContactHandler}
                />
            </Grid>

            {!contacts.filter(c => c.isSelected)?.length && !additionalContacts.length && (
                <Grid item xs={12}>
                    <Box mt={2} />
                    <MessageBox type="warning">
                        <Typography data-testid="contact-select-warning" variant="body1">
                            {t('purchase-order.contacts.contact-warning')}
                        </Typography>
                    </MessageBox>
                </Grid>
            )}

            {!contacts?.length && !additionalContacts.length && (
                <Grid item xs={12}>
                    <MessageBox type="error">
                        <Typography variant="body1">
                            <span dangerouslySetInnerHTML={{ __html: t('purchase-order.contacts.recipient-error') }}></span>
                        </Typography>
                    </MessageBox>
                </Grid>
            )}

            {!!(contacts.length || additionalContacts.length) && (
                <Grid item xs={12}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>{t('purchase-order.contacts.form.name')}</TableCell>
                                    <TableCell>{t('purchase-order.contacts.form.email')}</TableCell>
                                    <TableCell align="right">{t('purchase-order.contacts.form.phone')}</TableCell>
                                    <TableCell align="right" />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {contacts.map((c: SupplierContact, i: number) => (
                                    <ContactRow
                                        data-testid={`primary-contact-${i}`}
                                        key={c.contactId}
                                        contactStateLoading={contactStateLoading}
                                        isReadOnly={isReadOnly}
                                        onSelectContact={onSelectContact}
                                        editAdditionalContact={editAdditionalContact}
                                        handleDeleteContact={handleDeleteContact}
                                        contact={c}
                                    />
                                ))}

                                {additionalContacts.map((c: SupplierContact, i: number) => (
                                    <ContactRow
                                        data-testid={`additional-contact-${i}`}
                                        key={c.contactId}
                                        contactStateLoading={contactStateLoading}
                                        isReadOnly={isReadOnly}
                                        onSelectContact={onSelectContact}
                                        editAdditionalContact={editAdditionalContact}
                                        handleDeleteContact={handleDeleteContact}
                                        contact={c}
                                        isAdditionalContact
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}

            <Grid item xs={12}>
                <AkeliusContact disabled={isReadOnly} order={order} />
            </Grid>

            <DeleteAdditionalContactConfirm onClose={onCloseDeleteConfirm} onDelete={onDeleteHandler} deleteContactContext={deleteContactContext} />
            <AddEditAdditionalContactDialog
                orderId={orderId}
                editContactContext={editContactContext}
                open={openAddContactDialog}
                onClose={closeAddContactDialog}
            />
        </Grid>
    );
};

export default OrderContactsTab;
