import { Order } from 'modules/order/types';
import isAttachmentMaxSizeOver from 'modules/order/utils/isAttachmentMaxSizeOver';

export type FieldName = 'title' | 'items' | 'address' | 'street' | 'postal-code' | 'city' | 'attachments' | 'contacts' | 'contactForSupplier';
type ErrorType = 'missing' | 'incomplete' | 'max-size' | 'min-one';
export type OrderValidationErrors = Map<FieldName, ErrorType>;

const validateOrder = (order: Order): OrderValidationErrors => {
    const errors = new Map<FieldName, ErrorType>();

    const contactForSupplier = order?.contactForSupplier;

    if (!order.deliveryAddress?.address1?.length) errors.set('address', 'missing');
    if (!order.deliveryAddress?.streetName?.length) errors.set('street', 'missing');
    if (!order.deliveryAddress?.postalCode?.length) errors.set('postal-code', 'missing');
    if (!order.deliveryAddress?.city?.length) errors.set('city', 'missing');

    if (!order.title.length) errors.set('title', 'missing');
    if (!order.items.length) errors.set('items', 'min-one');
    if (isAttachmentMaxSizeOver(order.documents)) errors.set('attachments', 'max-size');

    const recipients = order.supplier.contacts.filter(c => c.isSelected).concat(order.supplier.additionalContacts || []);
    if (!recipients.length) errors.set('contacts', 'min-one');

    if (!contactForSupplier?.phone || !contactForSupplier?.email || !contactForSupplier?.name || !contactForSupplier?.title) {
        errors.set('contactForSupplier', 'incomplete');
    }

    if (!contactForSupplier?.phone && !contactForSupplier?.email && !contactForSupplier?.name && !contactForSupplier?.title) {
        errors.set('contactForSupplier', 'missing');
    }

    return errors;
};

export default validateOrder;
