import { gql } from 'graphql-request';
import { Order } from 'modules/order/types';
import { GetToken } from 'modules/order/utils/useGetUser';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';

export interface DeleteOrderItemParam {
    getToken: GetToken;
    orderId: string;
    itemId: string;
}

export interface IRemoveOrderItemResponse {
    removeOrderItem: Order;
}

const deleteOrderItem = () => async (param: DeleteOrderItemParam) => {
    const { getToken, ...payload } = param;
    const { graphQLClient } = await getGraphqlClient(getToken);

    const deleteOrderItemMutation = gql`
        mutation removeOrderItem($orderId: String!, $itemId: String!) {
            removeOrderItem(orderId: $orderId, itemId: $itemId) {
                ... on Order {
                    id
                    status
                    items {
                        ... on OrderItem {
                            id
                            description
                            quantity {
                                value
                                unit
                            }
                            userPrice {
                                value
                                vatIncluded
                                vatRate
                            }
                            creator {
                                id
                                name
                            }
                            unitPriceCentsNet
                            totalPriceCentsNet
                            totalPriceCentsGross
                            totalVatAmountCents
                            supplierPartNumber
                            manufacturerPartNumber
                            note
                            createdDate
                            updatedDate
                            deliveryDate {
                                start
                                end
                            }
                        }
                    }
                    updatedDate
                    totalPriceCentsNet
                    totalPriceCentsGross
                    totalVatAmountBreakdown {
                        vatRate
                        amountCents
                    }
                }
            }
        }
    `;

    const data = await graphQLClient.request<IRemoveOrderItemResponse>(deleteOrderItemMutation, {
        ...payload,
    });

    return data;
};

export default deleteOrderItem;
