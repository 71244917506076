import { gql } from 'graphql-request';
import { GetToken } from 'modules/order/utils/useGetUser';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';

export interface CopyOrderParams {
    orderId: string;
    getToken: GetToken;
}

export interface CopyOrderResponse {
    copyOrder: {
        id: string;
    };
}

const copyOrder = () => async (params: CopyOrderParams) => {
    const { getToken, ...queryVars } = params;
    const { graphQLClient } = await getGraphqlClient(getToken);

    const copyOrderMutation = gql`
        mutation copyOrder($orderId: String!) {
            copyOrder(orderId: $orderId) {
                id
            }
        }
    `;

    const data = await graphQLClient.request<CopyOrderResponse>(copyOrderMutation, {
        ...queryVars,
    });

    return data;
};

export default copyOrder;
