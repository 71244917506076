import { gql } from 'graphql-request';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';
import { SupplierContact } from 'shared/graphql/queries/getSuppliers';
import { GetToken } from 'modules/order/utils/useGetUser';

export interface CreateContactParams {
    getToken: GetToken;
    orderId: string;
    input: {
        name: string;
        email: string;
        phone?: string;
    };
}

export interface CreateContactRes {
    createOrderSupplierContact: {
        supplier: {
            additionalContacts: SupplierContact[];
        };
    };
}

const createContact = () => async (param: CreateContactParams) => {
    const { getToken, ...payload } = param;
    const { graphQLClient } = await getGraphqlClient(getToken);

    const createOrderSupplierContactMutation = gql`
        mutation createOrderSupplierContact($orderId: String!, $input: OrderSupplierContactCreate!) {
            createOrderSupplierContact(orderId: $orderId, input: $input) {
                supplier {
                    ...AdditionalContacts
                }
            }
        }

        fragment AdditionalContacts on OrderSupplier {
            additionalContacts {
                contactId
                name
                email
                phone
            }
        }
    `;

    const data = await graphQLClient.request<CreateContactRes>(createOrderSupplierContactMutation, {
        ...payload,
    });

    return data;
};

export default createContact;
