import { Ref, forwardRef } from 'react';
import { IMaskInput } from 'react-imask';

interface PriceMaskInputProps {
    onChange: (value: string) => void;
    name: string;
}

export const PriceMaskInput = forwardRef<HTMLElement, PriceMaskInputProps>(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            scale={2}
            radix={'.'}
            mapToRadix={[',', '.']}
            thousandsSeparator=","
            mask={Number}
            onAccept={value => onChange(value as string)}
            inputRef={ref as Ref<HTMLInputElement>}
            overwrite={false}
        />
    );
});
