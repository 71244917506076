import { colors } from '@akelius-con/react-theme';
import { Button, makeStyles } from '@akelius-con/react-ui-kit-components';
import { AkPlus } from '@akelius-con/react-ui-kit-icons';
import { Grid, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormInputs } from 'modules/order/components/AddItemsToOrderForm';
import DeleteOrderItemConfirm from 'modules/order/components/DeleteOrderItemConfirm';
import { cacheKeys } from 'modules/order/constants';
import createOrderItems, {
    ICreateOrderResponse,
    Payload as createOrderItemPayload,
    CreateOrderItemsParam as createOrderItemQueryVars,
} from 'modules/order/graphql/mutations/createOrderItem';
import deleteOrderItem, { DeleteOrderItemParam, IRemoveOrderItemResponse } from 'modules/order/graphql/mutations/deleteOrderItem';
import updateOrderItem, {
    IUpdateOrderResponse,
    UpdateOrderItemParam,
    Payload as updateOrderItemPayload,
} from 'modules/order/graphql/mutations/updateOrderItem';
import { Order, OrderItem } from 'modules/order/types';
import { useGetUser } from 'modules/order/utils/useGetUser';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import MandatoryFieldDisclaimer from 'shared/components/MandatoryFieldDisclaimer';
import { snackbar } from 'shared/components/Snackbar';
import { commaToDotSeparator } from 'shared/helpers/numberConverters';
import { SingleItemResponseDto } from 'shared/types/Response.interface';
import { removeThousandsSeparator } from 'shared/utils/amountFormatter';
import dateFormatter from 'shared/utils/dateFormatter';
import { isOrderReadonlyByStatus } from 'shared/utils/order';
import OrderedItems from '../OrderedItems';
import { AddEditOrderItemsModal } from './OrderItemModal';

interface IProps {
    order: Order;
}

const useStyles = makeStyles()({
    itemsTitle: {
        margin: '0 0 15px',
    },
    tabTitle: {
        margin: '26px 0 16px !important',
    },
    modal: {
        '& > .MuiGrid-root': {
            overflowX: 'hidden',
            minHeight: '580px',
        },
        '& [class*="headerContainer"]': {
            height: '50px',
            position: 'sticky',
            top: 0,
            zIndex: 2,
            backgroundColor: colors.white,
        },
    },
});

const OrderItemsTab: FC<IProps> = ({ order }) => {
    const { currency, items, status, supplier, totalPriceCentsNet, totalPriceCentsGross, totalVatAmountBreakdown } = order;
    const queryClient = useQueryClient();
    const { classes } = useStyles();
    const { t } = useTranslation();
    const { getToken } = useGetUser();
    const [orderItemsLoading, setOrderItemsLoading] = useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const params = useParams<{ orderId: string }>();
    const orderId = params.orderId as string;
    const [addItemModalOpen, setAddItemModalOpen] = useState<boolean>(false);

    const [initialFormValues, setInitialFormValues] = useState<FormInputs>({
        quantity: '',
        price: '',
        description: '',
        unit: '',
        note: '',
        vat: null,
        vatRate: '',
        supplierPartNumber: null,
        manufacturerPartNumber: null,
        deliveryStartDate: null,
        deliveryEndDate: null,
    });

    const [itemToEdit, setItemToEdit] = useState<OrderItem | null>(null);
    const [itemIdToDelete, setItemIdToDelete] = useState<string | null>(null);
    const [openDeleteOrderItemConfirm, setOpenDeleteOrderItemConfirm] = useState<boolean>(false);
    const cacheKey = cacheKeys.order(orderId);

    const closeAddItemModal = () => {
        setAddItemModalOpen(false);
    };

    const openAddItemModal = () => {
        setAddItemModalOpen(true);
    };

    const updateCachedOrder = (newData: Order) => {
        const oldData: SingleItemResponseDto<Order> | undefined = queryClient.getQueryData(cacheKey);
        if (oldData?.order) {
            const { order } = oldData;
            const updatedData = {
                order: {
                    ...order,
                    items: newData.items,
                    status: newData.status,
                    totalPriceCentsNet: newData.totalPriceCentsNet,
                    totalPriceCentsGross: newData.totalPriceCentsGross,
                    totalVatAmountBreakdown: newData.totalVatAmountBreakdown,
                },
            };

            queryClient.setQueryData(cacheKey, updatedData);
        }
    };

    const handleMutationSuccess = (data: Order, successMessage: string) => {
        updateCachedOrder(data);
        snackbar.success(successMessage);
        setOrderItemsLoading(false);
    };

    const handleMutationFailure = (failureMessage: string) => {
        snackbar.error(failureMessage);
        setOrderItemsLoading(false);
    };

    const createOrderItemsMutation = useMutation(cacheKey, createOrderItems(), {
        onError: () => handleMutationFailure(t('purchase-order.snackbar.item-add-failed')),
        onSuccess: ({ addOrderItem }: ICreateOrderResponse) => {
            closeAddItemModal();
            handleMutationSuccess(addOrderItem, t('purchase-order.snackbar.item-add-successful'));
        },
    });

    const updateOrderItemMutation = useMutation(cacheKey, updateOrderItem(), {
        onError: () => handleMutationFailure(t('purchase-order.snackbar.item-update-failed')),
        onSuccess: ({ updateOrderItem }: IUpdateOrderResponse) => {
            closeAddItemModal();
            handleMutationSuccess(updateOrderItem, t('purchase-order.snackbar.item-update-successful'));
        },
    });

    const handleEditItemSubmit = (values: FormInputs) => {
        setTimeout(() => {
            const { description, quantity, unit, price, note, deliveryStartDate, deliveryEndDate, supplierPartNumber, manufacturerPartNumber } =
                values;
            const input: updateOrderItemPayload = {
                note,
                userPrice: {
                    value: Number(commaToDotSeparator(price)),
                    vatIncluded: values.vat === 'INCLUDED',
                    vatRate: Number(values.vatRate) / 100,
                },
                description,
                supplierPartNumber: supplierPartNumber || null,
                manufacturerPartNumber: manufacturerPartNumber || null,
                quantity: {
                    unit,
                    value: Number(commaToDotSeparator(quantity)),
                },
            };

            if (deliveryStartDate && deliveryEndDate) {
                input.deliveryDate = {
                    start: dateFormatter(deliveryStartDate.toString()),
                    end: dateFormatter(deliveryEndDate.toString()),
                };
            }

            if (itemToEdit && itemToEdit.id) {
                const { id } = itemToEdit;

                const param: UpdateOrderItemParam = { getToken, orderId, itemId: id, input };
                updateOrderItemMutation.mutate(param);
            }
        }, 200);
    };

    const handleAddItemsSubmit = (values: FormInputs) => {
        setTimeout(() => {
            const { description, quantity, unit, price, note, deliveryStartDate, deliveryEndDate, supplierPartNumber, manufacturerPartNumber } =
                values;
            const input: createOrderItemPayload = {
                note,
                description,
                userPrice: {
                    value: Number(commaToDotSeparator(price)),
                    vatIncluded: values.vat === 'INCLUDED',
                    vatRate: Number(values.vatRate) / 100,
                },
                supplierPartNumber: supplierPartNumber || null,
                manufacturerPartNumber: manufacturerPartNumber || null,
                quantity: {
                    unit,
                    value: Number(commaToDotSeparator(quantity)),
                },
            };

            if (deliveryStartDate && deliveryEndDate) {
                input.deliveryDate = {
                    start: dateFormatter(deliveryStartDate.toString()),
                    end: dateFormatter(deliveryEndDate.toString()),
                };
            }

            const queryVars: createOrderItemQueryVars = { getToken, orderId, input };
            createOrderItemsMutation.mutate(queryVars);
        }, 200);
    };

    const handleAddOrUpdateItemSubmit = (values: FormInputs) => {
        setOrderItemsLoading(true);

        const payload = {
            ...values,
            price: removeThousandsSeparator(values.price),
        };

        if (initialFormValues.description) {
            handleEditItemSubmit(payload);
        } else {
            handleAddItemsSubmit(payload);
        }
    };

    const handleEditItem = (id: string) => {
        const itemToEdit = items.find((item: OrderItem) => item.id === id);

        if (itemToEdit) {
            const { quantity, userPrice, description, note, deliveryDate, supplierPartNumber, manufacturerPartNumber } = itemToEdit;

            let deliveryStartDate = null;
            let deliveryEndDate = null;

            if (deliveryDate) {
                deliveryStartDate = deliveryDate.start;
                deliveryEndDate = deliveryDate.end;
            }

            setInitialFormValues({
                quantity: quantity.value.toString(),
                price: userPrice.value.toString(),
                description,
                unit: quantity.unit,
                note: note || '',
                vat: 'EXCLUDED',
                vatRate: (Number(userPrice.vatRate) * 100).toString(),
                supplierPartNumber: supplierPartNumber || '',
                manufacturerPartNumber: manufacturerPartNumber || '',
                deliveryStartDate: deliveryStartDate ? new Date(deliveryStartDate) : null,
                deliveryEndDate: deliveryEndDate ? new Date(deliveryEndDate) : null,
            });
            setItemToEdit(itemToEdit);
            openAddItemModal();
        }
    };

    const initDeleteOrderItem = (id: string) => {
        setItemIdToDelete(id);
        setOpenDeleteOrderItemConfirm(true);
    };

    const handleAddItemInit = () => {
        setInitialFormValues({
            quantity: '',
            price: '',
            description: '',
            unit: '',
            note: '',
            vat: 'EXCLUDED',
            vatRate: '',
            manufacturerPartNumber: '',
            supplierPartNumber: '',
            deliveryStartDate: null,
            deliveryEndDate: null,
        });
        openAddItemModal();
    };

    const deleteOrderItemMutation = useMutation(cacheKey, deleteOrderItem(), {
        onSuccess: ({ removeOrderItem }: IRemoveOrderItemResponse) => {
            updateCachedOrder(removeOrderItem);
            snackbar.success(t('purchase-order.snackbar.order-item-delete-successful'));
            setDeleteLoading(false);
        },
        onError: () => {
            snackbar.error(t('purchase-order.snackbar.order-item-delete-failed'));
            setDeleteLoading(false);
        },
    });

    const handleDeleteOrderItem = () => {
        if (!itemIdToDelete) return false;
        setDeleteLoading(true);
        const queryVars: DeleteOrderItemParam = { getToken, orderId, itemId: itemIdToDelete };
        deleteOrderItemMutation.mutate(queryVars);
        setItemIdToDelete(null);
    };

    const closeDeleteOrderItemConfirm = () => setOpenDeleteOrderItemConfirm(false);

    return (
        <>
            <Typography variant="h2" className={classes.tabTitle}>
                {`${t('purchase-order.items')}*`}
            </Typography>
            <Button
                data-testid="add-items-modal-button"
                onClick={handleAddItemInit}
                startIcon={<AkPlus />}
                variant="outlined"
                label={t('purchase-order.item.add-item-button')}
                disabled={isOrderReadonlyByStatus(status)}
            />

            <OrderedItems
                deleteLoading={deleteLoading}
                currency={currency}
                items={items}
                onEditItem={handleEditItem}
                onDeleteItem={initDeleteOrderItem}
                orderStatus={status}
                totalPriceCentsNet={totalPriceCentsNet}
                totalPriceCentsGross={totalPriceCentsGross}
                totalVatAmountBreakdown={totalVatAmountBreakdown}
            />

            {addItemModalOpen && (
                <AddEditOrderItemsModal
                    supplier={supplier}
                    currency={currency}
                    handleAddItemsSubmit={handleAddOrUpdateItemSubmit}
                    initialFormValues={initialFormValues}
                    open={addItemModalOpen}
                    onClose={closeAddItemModal}
                    loading={orderItemsLoading}
                />
            )}

            {itemIdToDelete && (
                <DeleteOrderItemConfirm
                    open={openDeleteOrderItemConfirm}
                    onClose={closeDeleteOrderItemConfirm}
                    onDeleteConfirm={handleDeleteOrderItem}
                />
            )}
            <Grid item xs={12}>
                <MandatoryFieldDisclaimer />
            </Grid>
        </>
    );
};

export default OrderItemsTab;
