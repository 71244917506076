import { gql } from 'graphql-request';
import { OrderAssociatedProperty } from 'modules/order/types';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';
import { OrderAssociatedProject } from '../queries/useGetOrder';
import { GetToken } from 'modules/order/utils/useGetUser';

export interface UpdateOrderProjectParams {
    getToken: GetToken;
    orderId: string;
    input: {
        projectId: string;
    };
}

export interface UpdateOrderProjectResponse {
    updateOrderProject: {
        carrierOfCost: {
            project: OrderAssociatedProject;
            property: OrderAssociatedProperty;
        };
    };
}

export const updateOrderProject = () => async (params: UpdateOrderProjectParams) => {
    const { getToken, ...payload } = params;
    const { graphQLClient } = await getGraphqlClient(getToken);

    const updateOrderMutation = gql`
        mutation updateOrderProject($orderId: String!, $input: UpdateOrderProject!) {
            updateOrderProject(orderId: $orderId, input: $input) {
                carrierOfCost {
                    project {
                        id
                        type
                        subType
                        costCenter
                        state
                    }
                    property {
                        id
                        name
                        code
                        address
                        addressDetails {
                            address1
                            address2
                            streetName
                            streetNumber
                            postalCode
                            city
                            region
                            country
                        }
                        apartment {
                            id
                            code
                            name
                        }
                    }
                }
            }
        }
    `;

    const data = await graphQLClient.request<UpdateOrderProjectResponse>(updateOrderMutation, {
        ...payload,
    });

    return data;
};
