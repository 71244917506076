import { gql } from 'graphql-request';
import { ContactForSupplier } from 'modules/order/types';
import { GetToken } from 'modules/order/utils/useGetUser';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';

export interface Input {
    title: string;
    supplierId: string;
    propertyId: string;
    apartmentId?: string;
    caseId?: string;
    contactForSupplier: ContactForSupplier;
}

export interface ICreateOrderParam {
    getToken: GetToken;
    input: Input;
}

export interface ICreateOrderResponse {
    createOrder: {
        id: string;
    };
}

const createOrder = () => async (param: ICreateOrderParam) => {
    const { getToken, ...payload } = param;
    const { graphQLClient } = await getGraphqlClient(getToken);

    const createOrderMutation = gql`
        mutation createOrder($input: OrderCreate!) {
            createOrder(input: $input) {
                id
            }
        }
    `;

    const data = await graphQLClient.request<ICreateOrderResponse>(createOrderMutation, {
        ...payload,
    });

    return data;
};

export default createOrder;
