import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { camelToSnakeCase } from './camelToSnake';
import { transformFiltersToUrlParamObj } from './transformFiltersToUrlParamObj';
import debounce from 'lodash.debounce';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SearchParamFilters = Record<string, any>;

interface OnSearch {
    (filters: SearchParamFilters): void;
}

export function useSetSearchParams(initialFilters: SearchParamFilters, onSearch: OnSearch) {
    const [filters, setFilters] = useState(initialFilters);
    const [searchParams, setSearchParam] = useSearchParams();

    const debouncedSearch = useMemo(
        () =>
            debounce((filters: SearchParamFilters) => {
                onSearch(filters);
            }, 300),
        [onSearch],
    );

    const patchFilter = useCallback(
        (partial: Partial<SearchParamFilters>) => {
            const newFilters = {
                ...filters,
                ...partial,
            };

            setSearchParam(transformFiltersToUrlParamObj(newFilters));
        },
        [filters, setSearchParam],
    );
    const filterKeys = useMemo(() => {
        return Object.keys(initialFilters).reduce((acc: Record<string, string>, current: string) => {
            acc[current] = camelToSnakeCase(current);
            return acc;
        }, {});
    }, [initialFilters]);

    useEffect(() => {
        const udpatedFilters = Object.entries(filterKeys).reduce((acc: SearchParamFilters, current) => {
            const [camelKey, snakeKey] = current;

            if (camelKey && snakeKey) {
                acc[camelKey] = searchParams.get(snakeKey) || undefined;
            }

            return acc;
        }, {});

        setFilters(udpatedFilters);
        debouncedSearch(udpatedFilters);
    }, [debouncedSearch, filterKeys, onSearch, searchParams]);

    return { patchFilter, filters };
}
