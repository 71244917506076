import { Button, DialogTitle, makeStyles } from '@akelius-con/react-ui-kit-components';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { useFormik } from 'formik';
import { FC, MouseEventHandler, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Supplier } from 'shared/graphql/queries/getSuppliers';
import AddItemsToOrderForm, { FormInputs } from '../../AddItemsToOrderForm';
import { getValidationSchema } from './validationSchema';

interface IProps {
    open: boolean;
    onClose: () => void;
    supplier: Supplier;
    currency: string;
    handleAddItemsSubmit: (values: FormInputs) => void;
    initialFormValues: FormInputs;
    loading: boolean;
}

const useStyles = makeStyles()({
    fullWidth: {
        maxWidth: '100%',
    },
    itemsLoading: {
        position: 'absolute',
        top: 0,
        zIndex: 10,
        left: 0,
        height: '100%',
        width: '100%',
        backgroundColor: 'rgb(255 255 255 / 80%)',
    },
});

export const AddEditOrderItemsModal: FC<IProps> = props => {
    const { open, onClose, supplier, loading, currency, handleAddItemsSubmit, initialFormValues } = props;
    const { t } = useTranslation();
    const { classes } = useStyles();
    const validationSchema = getValidationSchema(t);

    const formik = useFormik({
        initialValues: initialFormValues,
        validationSchema,
        onSubmit: handleAddItemsSubmit,
        enableReinitialize: true,
    });

    const modalCloseHandler = useCallback(
        (_event: MouseEventHandler<HTMLButtonElement>, reason: 'backdropClick' | 'escapeKeyDown') => {
            if (reason !== 'backdropClick') onClose();
        },
        [onClose],
    );

    return (
        <Dialog maxWidth="lg" fullWidth open={open} onClose={modalCloseHandler} data-testid="add-item-modal">
            <DialogTitle
                onClose={onClose}
                title={
                    initialFormValues.description ? t('purchase-order.item.update-item-modal-title') : t('purchase-order.item.add-item-modal-title')
                }
            />
            <form onSubmit={formik.handleSubmit} className={classes.fullWidth}>
                <DialogContent>
                    <AddItemsToOrderForm
                        supplier={supplier}
                        formik={formik}
                        currency={currency}
                        closeModal={onClose}
                        handleAddItemsSubmit={handleAddItemsSubmit}
                        initialFormValues={initialFormValues}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" label={t('common.cancel')} onClick={onClose} data-testid="cancel" />
                    <Button
                        data-testid="add-item-submit-button"
                        disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                        variant="contained"
                        type="submit"
                        label={initialFormValues.description ? t('purchase-order.item.update-item') : t('purchase-order.item.add-item')}
                        isLoading={loading}
                    />
                </DialogActions>
            </form>
        </Dialog>
    );
};
