import { Button, makeStyles, useResponsiveSpacing } from '@akelius-con/react-ui-kit-components';
import { AkPlus } from '@akelius-con/react-ui-kit-icons';
import { Grid, Typography } from '@mui/material';
import cn from 'classnames';
import CopyOrderConfirm from 'modules/order/components/CopyOrderConfirm';
import DeleteOrderConfirm from 'modules/order/components/DeleteOrderConfirm';
import { OrderFilters } from 'modules/order/pages/Dashboard/components/OrderFilters';
import { Filters, IFiltersFormatted } from 'modules/order/pages/Dashboard/components/OrderFilters/Filters.interface';
import { OrderHistoryDialog } from 'modules/order/components/OrderHistoryDialog';
import { cacheKeys, routeConst } from 'modules/order/constants';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { usePermissions } from 'shared/components/HandleAuthPermissionsSettings/usePermission';
import { getSelectedCountry } from 'shared/utils/serviceCountry';
import Orders from './components/Orders';
import { PermissionEnum } from 'shared/components/HandleAuthPermissionsSettings/PermissionEnum';
import { initialFilters } from './components/OrderFilters/initialFilters';

const useStyles = makeStyles()({
    pageWrapper: {
        marginTop: '20px',
        width: '100%',
    },
    noUnderline: {
        textDecoration: 'none',
    },
    linkDisabled: {
        pointerEvents: 'none',
    },
    actionRow: {
        marginTop: 0,
        marginBottom: '40px',
    },
});

const Dashboard: FC = () => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const responsiveSpacing = useResponsiveSpacing();
    const [orderFilters, setOrderFilters] = useState<IFiltersFormatted>(initialFilters);
    const { useIsPermitted } = usePermissions();
    const isCreateOrderPermitted = useIsPermitted(PermissionEnum.CREATE_ORDER);
    const [onHistoryContext, setOnHistoryContext] = useState<string | null>(null);
    const [deleteContext, setDeleteContext] = useState<string | null>(null);
    const [copyOrderContext, setCopyOrderContext] = useState<string | null>(null);

    const openCopyOrderDialog = useCallback(
        (orderId: string) => {
            setCopyOrderContext(orderId);
        },
        [setCopyOrderContext],
    );

    const closeCopyOrderDialog = useCallback(() => {
        setCopyOrderContext(null);
    }, [setCopyOrderContext]);

    const openDeleteConfirmation = useCallback(
        (orderId: string) => {
            setDeleteContext(orderId);
        },
        [setDeleteContext],
    );

    const closeDeleteConfirmation = useCallback(() => {
        setDeleteContext(null);
    }, [setDeleteContext]);

    const onHistoryDialogHandlerOpen = useCallback((orderId: string) => {
        setOnHistoryContext(orderId);
    }, []);

    const onHistoryDialogHandlerClose = useCallback(() => {
        setOnHistoryContext(null);
    }, []);

    const onSearch = useCallback(
        (filters: Filters) => {
            setOrderFilters({
                ...filters,
                status: filters?.status?.split(','),
            });
        },
        [setOrderFilters],
    );

    const filterWithCountry = { ...orderFilters, countryCode: getSelectedCountry() };

    return (
        <div className={classes.pageWrapper} data-testid="dashboard-page">
            <Typography data-testid="page-title" variant="h1">
                {t('dashboard.order-list-page-title')}
            </Typography>
            <Grid container spacing={responsiveSpacing()} className={classes.actionRow}>
                <Grid item xs={6}>
                    <Link
                        to={routeConst.orderCreate()}
                        className={cn({
                            [classes.noUnderline]: true,
                            [classes.linkDisabled]: !isCreateOrderPermitted,
                        })}
                    >
                        <Button
                            startIcon={<AkPlus />}
                            data-testid="create-purchase-order"
                            label={t('purchase-order')}
                            variant="contained"
                            disabled={!isCreateOrderPermitted}
                        />
                    </Link>
                </Grid>
            </Grid>

            <OrderFilters onSearch={onSearch} />

            <div data-testid="orders-wrapper">
                <Orders
                    filters={filterWithCountry}
                    onDelete={openDeleteConfirmation}
                    onHistory={onHistoryDialogHandlerOpen}
                    onCopyOrder={openCopyOrderDialog}
                />
            </div>

            {onHistoryContext && <OrderHistoryDialog onClose={onHistoryDialogHandlerClose} orderId={onHistoryContext} />}
            <DeleteOrderConfirm
                queryKey={cacheKeys.ordersInfinite(filterWithCountry)}
                onClose={closeDeleteConfirmation}
                deleteContext={deleteContext}
            />
            {copyOrderContext && <CopyOrderConfirm closeCopyOrderDialog={closeCopyOrderDialog} orderId={copyOrderContext} />}
        </div>
    );
};

export default Dashboard;
