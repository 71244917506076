import { gql } from 'graphql-request';
import { GetToken } from 'modules/order/utils/useGetUser';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';
import { SingleItemResponseDto } from 'shared/types/Response.interface';

export interface IDeleteOrderParam {
    getToken: GetToken;
    documentId: string;
}

export type DeleteDocumentResponse = SingleItemResponseDto<{ removeDocument: boolean }>;

const deleteDocument = () => async (param: IDeleteOrderParam) => {
    const { getToken, ...payload } = param;
    const { graphQLClient } = await getGraphqlClient(getToken);

    const deleteDocumentMutation = gql`
        mutation removeDocument($documentId: String!) {
            removeDocument(documentId: $documentId)
        }
    `;

    const data = await graphQLClient.request<DeleteDocumentResponse>(deleteDocumentMutation, {
        ...payload,
    });

    return data;
};

export default deleteDocument;
