import { FC, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const CreateContractRedirect: FC = () => {
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get('project_id');

    useEffect(() => {
        // Redirect to SUA contracts
        window.location.href = `${import.meta.env.VITE_APP_AKELIUS_SUA_URL}suppliers/contracts/create?projectId=${projectId}`;
    }, [projectId]);

    return null;
};
